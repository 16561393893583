import { Fragment, useEffect, useState } from 'react';
import './CampaignTabs.css';
import { Link, useLocation, useParams } from 'react-router-dom';

const CampaignTabs = ({ disabled = false }) => {
	const tabs = [
		{ slug: 'setup', label: 'Site Setup' },
		{ slug: 'design', label: 'Design' },
		{ slug: 'keywords', label: 'Keywords' },
		{ slug: 'locations', label: 'Locations' },
		{ slug: 'landing-pages', label: 'Landing Pages' },
		{ slug: 'finish', label: 'Finish' }
	];
	const [ activeTab, setActiveTab ] = useState();
	const { campaignId } = useParams();
	const location = useLocation();

	useEffect(
		() => {
			let tempActiveTab = null;

			tabs.every((tab, index) => {
				let urlSlug = location.pathname.replace('/setup/' + campaignId, '');

				if (urlSlug.indexOf(`/${tab.slug}`) > -1) {
					tempActiveTab = tab.slug;
					return false;
				} else if (urlSlug === '/' || urlSlug === '') {
					tempActiveTab = 'setup';
				}

				return true;
			});

			setActiveTab(tempActiveTab);
		},
		[ location ]
	);

	return (
		<Fragment>
			<ul className="campaign-tabs nav nav-tabs" id="tabCampaign" role="tablist">
				{tabs.map((tab, index) => (
					<li className={`nav-item ${disabled ? 'disabled' : ''}`} role="presentation" key={index}>
						<Link
							className={`nav-link ${activeTab === tab.slug ? 'active' : ''}`}
							to={`/setup/${campaignId}/${tab.slug === 'setup' ? '' : tab.slug}`}
						>
							{tab.label}
						</Link>
					</li>
				))}
			</ul>
		</Fragment>
	);
};

export default CampaignTabs;
