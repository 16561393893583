import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import GridReport from '../../components/gridReport/GridReport';
import GridReportV2 from '../../components/gridReport/GridReportV2';
import RankReport from '../../components/rankReport/RankReport';
import Spinner from '../../components/spinner/Spinner';
import { GetCampaign, GetCampaignReports, GetSEOReport } from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import './PublicCampaignReportScreen.css';

const PublicCampaignReportScreen = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const { campaignId, reportId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ report, setReport ] = useState();
	const [ reportTime, setReportTime ] = useState();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId && reportId) {
				navigate(`/view/report/${campaignId}/${reportId}`);
				// getCampaign();
			}
		},
		[ campaignId, reportId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getReport();
			}
		},
		[ campaign ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaign(response);
		}
	};

	const getReport = async () => {
		let targetId = campaign.googlePlaceId
			? campaign.googlePlaceId
			: campaign.googleCid;

		const response = await GetSEOReport(campaignId, reportId, targetId);

		if (response.success) {
			console.log(response);
			setReport(response);
		} else {
			const response = await GetCampaignReports(campaign.id);

			if (response && response.reports && response.reports.length > 0) {
				const latestReport = response.reports[0];
				setReport(latestReport);
			}
		}
	};

	useEffect(
		() => {
			if (campaign && report) {
				setIsLoading(false);
			}
		},
		[ campaign, report ]
	);

	return (
		<div className="campaign-p-report">
			{isLoading && <Spinner />}

			{!isLoading && (
				<Fragment>
					<div className="report-header">
						<div className="report-header-left">
							<h1 className="display-5">{campaign.name}</h1>
							<div className="text-muted">{`${campaign.streetAddress1}, ${campaign.city}, ${campaign.postalCode} ${campaign.state}`}</div>
						</div>

						<div className="report-header-right">
							<small
							>{`Report generated on ${UnixTimestampToDateTime(
								report.reportGeneratedAtUnix
							)}`}</small>
						</div>
					</div>

					<div className="container">
						<div className="report-body">
							{report.gridResponse && (
								<GridReportV2
									gridData={report.gridResponse}
									placeId={campaign.googlePlaceId}
									placeCid={campaign.googleCid}
									showAllKeywords
								/>
							)}

							{report.rankResponse && (
								<RankReport rankData={report.rankResponse} />
							)}
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default PublicCampaignReportScreen;
