import { Fragment, useEffect, useState } from 'react';
import './RankReportSummary.css';
import { useStateValue } from '../../../../StateProvider';
import { actionTypes } from '../../../../reducer';
import RankingFiltersSummary from './rankingFilters/RankingFiltersSummary';

const RankReportSummary = ({ rankData, compareToRankData }) => {
	const [ activeFilter, setActiveFilter ] = useState('all');
	const [ compareToActiveFilter, setCompareToActiveFilter ] = useState('all');
	const [ rankings, setRankings ] = useState();
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(
		() => {
			if (rankData && rankData.results && rankData.results.length > 0) {
				//remove possible duplicates
				var distinctResults = rankData.results.reduce((accumulator, current) => {
					if (!accumulator.find((item) => item.location === current.location)) {
						accumulator.push(current);
					}
					return accumulator;
				}, []);

				if (activeFilter == 'all') {
					setRankings(distinctResults);
				} else if (activeFilter == 'top3') {
					setRankings(distinctResults.filter((r) => r.ranking && r.ranking < 4));
				} else if (activeFilter == 'top10') {
					setRankings(distinctResults.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3));
				} else if (activeFilter == 'top20') {
					setRankings(distinctResults.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10));
				} else if (activeFilter == 'over20') {
					setRankings(distinctResults.filter((r) => r.ranking && r.ranking > 20));
				} else if (activeFilter == 'notRanked') {
					setRankings(distinctResults.filter((r) => r.ranking && !r.ranking));
				}
			} else {
				setRankings();
			}
		},
		[ rankData, compareToRankData, activeFilter, compareToActiveFilter ]
	);

	const handleFilterChange = (filter) => {
		setActiveFilter(filter);
		setCompareToActiveFilter(filter);
	};

	const handleRankLocationUrl = (locationUrl) => {
		let urlObj = new URL(locationUrl);
		let q = urlObj.searchParams.get('q');

		if (q) {
			//remove comma
			q = q.replace(',', '');
		}

		let newLocationUrl = `${urlObj.origin}${urlObj.pathname}?q=${encodeURI(q)}`;

		return newLocationUrl;
	};

	return (
		<Fragment>
			<div className="row grid-ranking-filters-container">
				<div className="col-12 px-1">
					<RankingFiltersSummary
						activeFilter={activeFilter}
						onChange={handleFilterChange}
						//targetKeyword={targetKeyword}
						rankData={rankData}
						compareToRankData={compareToRankData}
						mobileReportView={mobileReportView}
						reportType="base"
					/>
				</div>
			</div>

			<div className="row ranking-data-container">
				<div className="col-12 px-0">
					{rankings &&
					rankings.length > 0 && (
						<div className="rank-report">
							<table className="table b-0 rank-table">
								<thead>
									<tr>
										<th scope="col" className="rank" colSpan={2}>
											<div className="rank-table-header">
												<div>Rank</div>
												<div className="text-right">
													{`${rankings.length} ${rankings.length > 1 ? 'results' : 'result'}`}
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{rankings.map((result, index) => {
										let rowClass = 'btn-secondary';

										if (result.ranking) {
											if (result.ranking < 4) {
												rowClass = 'btn-success';
											} else if (result.ranking < 11) {
												rowClass = 'btn-warning';
											} else {
												rowClass = 'btn-danger';
											}
										} else {
											rowClass = 'btn-secondary';
										}

										return (
											<tr key={`${result.keyword}-${result.location}`}>
												{/* <td className="rank">{`${result.ranking ? result.ranking : '0'}`}</td> */}
												<td>
													<a
														className={`btn ${rowClass} rank-location-btn`}
														href={handleRankLocationUrl(result.searchLink)}
														target="_blank"
													>
														<span className="rank">{`${result.ranking
															? result.ranking
															: '0'}`}</span>

														<span className="rank-location">{result.location}</span>
														{compareToRankData &&
														!!result.improvement &&
														// result.improvement !== 0 && (
														result.improvement > 0 && (
															<Fragment>
																<span
																	className={`rank-difference ${result.improvement > 0
																		? 'good'
																		: 'bad'}`}
																>
																	<i
																		className={`fa-solid ${result.improvement > 0
																			? 'fa-arrow-up'
																			: 'fa-arrow-down'}`}
																	/>
																	<span className="rank-difference-value">
																		{result.improvement}
																	</span>
																</span>
															</Fragment>
														)}
													</a>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default RankReportSummary;
