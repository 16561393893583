import { Fragment, useEffect, useState, useRef } from 'react';
import './CompanyTaskDetails.css';
import GridMapImage from '../../../components/gridReport/gridMapImage/GridMapImage';
import Spinner from '../../../components/spinner/Spinner';
import { GetSalesCompanyDetailsBasic, GetSalesCompanyTaskDetails } from '../../../Services';
import { useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import GridMapDataGrid from '../../dataGrid/GridMapDataGrid';
import { copyToClipboard } from '../../../utils/Common';
import { useStateValue } from '../../../StateProvider';
import html2canvas from 'html2canvas';

const CompanyTaskDetails = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ company, setCompany ] = useState();
	const [ task, setTask ] = useState();
	const [ gridData, setGridData ] = useState();
	const { salesCompanyId, taskId } = useParams();
	const [ { host }, dispatch ] = useStateValue();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const dataGridMapContainerRef = useRef();

	useEffect(() => {
		if (salesCompanyId) {
			getSalesCompanyDetails();
		}
	}, []);

	useEffect(() => {
		if (taskId) {
			getTaskDetails();
		}
	}, []);

	const getSalesCompanyDetails = async () => {
		const response = await GetSalesCompanyDetailsBasic(salesCompanyId);

		if (response) {
			setCompany(response);
		} else {
			//setIsLoading(false);
		}
	};

	const getTaskDetails = async () => {
		const responseTask = await GetSalesCompanyTaskDetails(taskId);

		if (responseTask) {
			setTask(responseTask);

			//parse grid data
			if (responseTask.content) {
				let gridReportData = JSON.parse(responseTask.content);

				if (gridReportData) {
					setGridData({
						lat              : responseTask.lat,
						lng              : responseTask.lng,
						horizontalPoints : responseTask.gridPoints,
						verticalPoints   : responseTask.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});
				}
			}
		}

		setIsLoading(false);
	};

	const exportMapAsImage = () => {
		let $mapContainer = document.querySelector('#report-map-0');

		if ($mapContainer) {
			dataGridMapContainerRef.current.classList.add('no-transition');

			html2canvas($mapContainer, { useCORS: true })
				.then((canvas) => {
					var imgSrc = canvas.toDataURL('image/png');

					var link = document.createElement('a');
					if (typeof link.download !== 'string') {
						window.open(imgSrc);
					} else {
						link.href = imgSrc;
						link.download = 'grid-preview.png';
						link.click();
						//accountForFirefox(clickLink, link);
					}
				})
				.finally(() => dataGridMapContainerRef.current.classList.remove('no-transition'));
		}
	};

	const getReportShareLink = () => {
		var url = new URL(`${host}/sales/${salesCompanyId}/reports/${taskId}`);

		copyToClipboard(url.href);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 1000);
	};

	return (
		<div className="container text-center mt-3">
			{isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					{company &&
					task &&
					gridData && (
						<Fragment>
							<PageHeadline headline={`${company.name}: ${task.keyword}`} />

							<div className="data-grid-map-container" ref={dataGridMapContainerRef}>
								<Fragment>
									<GridMapDataGrid
										key="map-0"
										mapIdValue="report-map-0"
										gridData={gridData}
										lat={task.lat}
										lng={task.lng}
										googlePlaceId={company.googlePlaceId}
										numberOfPoints={task.gridPoints}
										zoom={13}
									/>
								</Fragment>
							</div>
							<div className="text-start my-3">
								<button type="button" className="btn btn-primary" onClick={getReportShareLink}>
									<span>{showShareLinkCopiedMessage ? 'Copied!' : 'Get Share Link'}</span>
								</button>
								<button type="button" className="btn btn-primary ms-2" onClick={exportMapAsImage}>
									<span>Export As Image</span>
								</button>
							</div>
						</Fragment>
					)}

					{/* <div className="map-preview">{gridData && <GridMapImage gridData={gridData} />}</div> */}
				</Fragment>
			)}
		</div>
	);
};

export default CompanyTaskDetails;
