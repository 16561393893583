import { useEffect, useState } from 'react';

const GridMapPreview = ({
	gridData,
	lat,
	lng,
	radiusInMiles,
	numberOfPoints,
	numbers,
	width,
	height,
	hideCircle,
	onCircleMove,
	zoom
}) => {
	const [ mapId, setMapId ] = useState();
	const [ markers, setMarkers ] = useState();
	const [ circleMarkers, setCircleMarkers ] = useState();

	useEffect(
		() => {
			if (gridData) {
				setMapId('report-map-1');
				clearMarkers();
			} else {
				setMapId();
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (!markers) {
				if (mapId) {
					initializeGrid();
				}
			}
		},
		[ markers, mapId ]
	);

	useEffect(
		() => {
			if (mapId) {
				initializeGrid();
			}
		},
		[ hideCircle ]
	);

	const clearMarkers = () => {
		if (markers && markers.length > 0) {
			markers.forEach((marker) => {
				marker.map = null;
			});

			setMarkers();
		}
	};

	const clearCircleMarkers = () => {
		if (circleMarkers && circleMarkers.length > 0) {
			circleMarkers.forEach((circleMarker) => {
				circleMarker.map = null;
			});

			setCircleMarkers();
		}
	};

	const initializeGrid = () => {
		let tempLat = typeof lat === 'number' ? parseFloat(lat.toFixed(5)) : parseFloat(lat).toFixed(5);
		let tempLng = typeof lng === 'number' ? parseFloat(lng.toFixed(5)) : parseFloat(lng).toFixed(5);

		const center = { lat: parseFloat(tempLat), lng: parseFloat(tempLng) };
		let circle;

		const google = window.google;

		const map = new google.maps.Map(document.getElementById(mapId), {
			//zoom   : 12,
			center : center,
			mapId  : mapId
		});

		if (hideCircle === undefined || hideCircle === false) {
			//add map circle
			circle = new google.maps.Circle({
				map           : map,
				strokeColor   : '#000000',
				strokeOpacity : 0.6,
				strokeWeight  : 2,
				fillColor     : '#000000',
				fillOpacity   : 0.1,
				center        : center,
				radius        : radiusInMiles * 1609.34,
				draggable     : true
			});
		}

		let bounds = new google.maps.LatLngBounds();

		const keyword = gridData;

		let tempMarkers = [];

		//set numbers
		let numbersArr = [];
		let pointCounter = 1;

		if (numbers) {
			numbersArr = numbers.split(/\r?\n/);
		}

		for (let i = 0; i < numberOfPoints; i++) {
			for (let j = 0; j < numberOfPoints; j++) {
				const x = i + 1;
				const y = j + 1;
				const targetPoint = keyword.points.find((p) => p.pointXIndex == x && p.pointYIndex == y);

				if (targetPoint) {
					const targetPosition = {
						lat : targetPoint.lat,
						lng : targetPoint.lng
					};

					let pointPosition = '21+';

					if (numbersArr && numbersArr[pointCounter - 1] !== undefined) {
						pointPosition = numbersArr[pointCounter - 1];
					}

					// var distance = google.maps.geometry.spherical.computeDistanceBetween(targetPosition, center);

					// if (distance < radiusInMiles * 1609.34) {
					const markerView = new google.maps.marker.AdvancedMarkerView({
						map,
						position : {
							lat : targetPosition.lat,
							lng : targetPosition.lng
						},
						content  : buildContent(pointPosition)
					});

					tempMarkers.push(markerView);
					//}

					bounds.extend(targetPosition);
				}

				pointCounter++;
			}
		}

		//set circle drag event to be able to set lat/lng on circle move
		if (circle) {
			circle.addListener('dragend', function(event) {
				let tempCircleMarkers = [];
				clearCircleMarkers();

				let circleCenter = this.getCenter();

				//create marker in circle center
				var circleCenterMarker = new google.maps.Marker({
					position  : circleCenter,
					draggable : true,
					icon      : {
						url    : 'https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png',
						size   : new google.maps.Size(8, 8),
						anchor : new google.maps.Point(4, 4)
					},
					map       : map
				});

				tempCircleMarkers.push(circleCenterMarker);
				setCircleMarkers(tempCircleMarkers);

				let newLat = circleCenter.lat().toFixed(5);
				let newLng = circleCenter.lng().toFixed(5);

				handleCircleMove({ lat: parseFloat(newLat), lng: parseFloat(newLng) });
			});
		}

		map.fitBounds(bounds);

		setTimeout(() => {
			map.setZoom(zoom ? parseInt(zoom) : 12);
		}, 50);

		setMarkers(tempMarkers);
	};

	function buildContent(position) {
		const content = document.createElement('div');

		content.classList.add('ranking-bubble');

		let className = 'bad';

		if (position === 'NF' || position === 'nf') {
			className = 'not-found';
		} else if (position <= 3) {
			className = 'excellent';
		} else if (position <= 10) {
			className = 'decent';
		} else if (position > 20) {
			position = '21+';
		}

		content.classList.add(className);

		content.innerHTML = `<div class="icon"><span class="value">${position}</span></div>`;

		return content;
	}

	const handleCircleMove = (center) => {
		if (onCircleMove) {
			onCircleMove(center);
		}
	};

	return (
		<div
			className="map map-preview"
			id={mapId}
			style={{ width: width ? width + 'px' : '100%', height: height ? height + 'px' : '800px' }}
		/>
	);
};

export default GridMapPreview;
