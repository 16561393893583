import { Fragment, useEffect, useState } from 'react';
import './CreateOfferItem.css';

const CreateOfferItem = ({ item, onUpdate, onDelete, isDisabled, index }) => {
	const [ itemDetails, setItemDetails ] = useState(null);

	useEffect(
		() => {
			if (!item.price) {
				setItemDetails({
					...item,
					price : 0.0
				});
			} else {
				setItemDetails(item);
			}
		},
		[ item ]
	);

	const handleChange = (e) => {
		let value = e.target.value;

		// if (e.target.name === 'name') {
		// 	if (value !== '') {
		// 		// existing item, find it and set the new details
		// 		let itemIndex = products.findIndex((x) => x.name === value);

		// 		if (itemIndex > -1) {
		// 			let newItem = products.find((x) => x.name === value);

		// 			if (onUpdate) {
		// 				onUpdate(item, newItem);
		// 			}
		// 		}
		// 	} else {
		// 		// empty item selected
		// 		if (onUpdate) {
		// 			onUpdate(item, {
		// 				name        : '',
		// 				description : '',
		// 				price       : null
		// 			});
		// 		}
		// 	}
		// } else {
		// 	setItemDetails({
		// 		...itemDetails,
		// 		[e.target.name]: value
		// 	});
		// }

		setItemDetails({
			...itemDetails,
			[e.target.name]: value
		});
	};

	const notifyPriceUpdate = (e) => {
		if (onUpdate) {
			onUpdate(item, {
				...itemDetails,
				[e.target.name]: !isNaN(e.target.value) ? parseFloat(e.target.value) : 0
			});
		}
	};

	const handleDeleteItem = () => {
		if (onDelete) {
			onDelete(item);
		}
	};

	return (
		<Fragment>
			{itemDetails && (
				<Fragment>
					<div className="invoice-item-container">
						<div className="invoice-item-details">
							<div className="invoice-item">
								{/* {products &&
								products.length > 0 && (
									<Fragment>
										<select
											className="form-select"
											name="name"
											value={itemDetails.name}
											onChange={handleChange}
											disabled={isDisabled}
										>
											<option />
											{products.map((product, index) => (
												<option key={index}>{product.name}</option>
											))}
										</select>
									</Fragment>
								)} */}
								<span>{`Payment ${index + 1}`}</span>
							</div>

							<div className="invoice-item-amount">
								<div className="input-group">
									<div className="input-group-prepend">
										<div className="input-group-text">$</div>
									</div>
									<input
										type="number"
										className="form-control"
										name="price"
										value={itemDetails.price}
										onChange={handleChange}
										onBlur={notifyPriceUpdate}
										disabled={isDisabled}
									/>
								</div>
							</div>

							<div className="invoice-item-days">
								<input
									type="number"
									className="form-control"
									name="paymentDaysDelay"
									value={itemDetails.paymentDaysDelay}
									onChange={handleChange}
									disabled={isDisabled}
								/>
							</div>

							<div className="invoice-item-action">
								<button
									type="button"
									className="btn btn-outline-danger"
									onClick={handleDeleteItem}
									disabled={isDisabled}
								>
									<i className="far fa-trash-alt m-0" />
								</button>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default CreateOfferItem;
