import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GridReportSingleKeywordV3 from '../../components/gridReport/v3/GridReportSingleKeywordV3';
import Spinner from '../../components/spinner/Spinner';
import { GetCampaign, GetCampaignReports, GetSEOReport } from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import RankReportV3 from '../../components/gridReport/v3/rankReport/RankReportV3';
import './PublicViewCampaignReportScreenV3.css';
import { GetV4Notice, StoreV4Notice } from '../../utils/StorageUtil';
import ModalDialog from '../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../components/modalDialog/modalDialogButtons/ModalDialogButtons';

const PublicViewCampaignReportScreenV3 = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoadingReport, setIsLoadingReport ] = useState(false);
	const { campaignId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ campaignReports, setCampaignReports ] = useState();
	const [ report, setReport ] = useState();
	const [ reportIdValue, setReportIdValue ] = useState();
	const [ activeKeyword, setActiveKeyword ] = useState();
	const [ selectedReportName, setSelectedReportName ] = useState('');
	const [ compareToReportId, setCompareToReportId ] = useState('');
	const [ compareToReport, setCompareToReport ] = useState();
	const [ compareToReportName, setCompareToReportName ] = useState('');
	const [ compareToReports, setCompareToReports ] = useState();
	const [ mobileReportView, setMobileReportView ] = useState('base');
	const [ showV4NoticeDialog, setShowV4NoticeDialog ] = useState(false);

	useEffect(() => {
		const v4Notice = GetV4Notice();

		if (!v4Notice) {
			setShowV4NoticeDialog(true);
		} else {
			handleRedirectToV4();
		}
	}, []);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			getCampaignReports();
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (reportIdValue && compareToReportId) {
				if (compareToReportId !== '0') {
					getReport(reportIdValue);
					getCompareToReport(compareToReportId);
				} else {
					getReport(reportIdValue);
				}
			}
		},
		[ reportIdValue, compareToReportId ]
	);

	useEffect(
		() => {
			if (campaignReports && reportIdValue) {
				let currentReport = campaignReports.find((r) => r.id === reportIdValue);

				if (currentReport) {
					let currentReportDateUnix = Math.floor(new Date(currentReport.dateCreated).getTime() / 1000);

					let tempCompareToReports = campaignReports.filter(
						//(r) => r.id !== reportIdValue && r.dateUnix < report.reportGeneratedAtUnix
						(r) => r.id !== currentReport.id && r.dateUnix < currentReportDateUnix
					);

					setCompareToReports(tempCompareToReports);

					//find the oldest report
					if (tempCompareToReports && tempCompareToReports.length > 0) {
						const oldestCompareToReport = tempCompareToReports.reduce(
							(r, o) => (o.dateCreated < r.dateCreated ? o : r)
						);

						if (oldestCompareToReport) {
							setCompareToReportId(oldestCompareToReport.id);
							//getCompareToReport(oldestCompareToReport.id);
						} else {
							setCompareToReportId('0');
						}
					} else {
						setCompareToReportId('0');
					}
				} else {
					setCompareToReportId('0');
				}
			} else if (!isLoading && campaignReports && !reportIdValue) {
				//setReportIdValue(campaignReports[0].id);
			} else {
				setCompareToReports();
			}
		},
		[ campaignReports, reportIdValue ]
	);

	useEffect(
		() => {
			if (campaign && report) {
				if (compareToReportId && compareToReportId !== '0') {
					if (compareToReport) {
						setIsLoading(false);
					}
				} else {
					setIsLoading(false);
				}
			}
		},
		[ campaign, report, compareToReport ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaign(response);
		}
	};

	const getCampaignReports = async () => {
		if (campaign) {
			const response = await GetCampaignReports(campaignId);

			if (response && response.reports && response.reports.length > 0) {
				setCampaignReports(response.reports);

				setReportIdValue(response.reports[0].id);
				//await getReport(response.reports[0].id);
			} else {
				setCampaignReports();
				setCompareToReports();
			}
		}
	};

	const getReport = async (id) => {
		setIsLoadingReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaignId, id, targetId, compareToReportId);

		if (response.success) {
			setReport(response);
			setSelectedReportName(getReportNameByDate(response.reportGeneratedAtUnix));
			//console.log('base report', response);
		} else {
			setReport();
			setReportIdValue();
		}

		setIsLoadingReport(false);
	};

	const getCompareToReport = async (id) => {
		setIsLoadingReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaignId, id, targetId);

		if (response.success) {
			setCompareToReport(response);
			setCompareToReportName(getReportNameByDate(response.reportGeneratedAtUnix));
			//console.log('compare report', response);
		} else {
			setCompareToReport();
		}

		setIsLoadingReport(false);
	};

	const handleKeywordSelected = (keyword) => {
		setActiveKeyword(keyword);
	};

	const handleMobileReportView = (type) => {
		setMobileReportView(type);
	};

	const getReportNameByDate = (timestamp) => {
		const format = new Intl.DateTimeFormat('en-US', {
			year  : 'numeric',
			month : 'long',
			day   : '2-digit'
		});

		return format.format(new Date(timestamp * 1000));
	};

	const handleRedirectToV4 = () => {
		//redirect user to v4 version
		let currentUrl = window.location.href;

		if (currentUrl.indexOf('/v4/') === -1) {
			StoreV4Notice();

			let newUrl = currentUrl.replace('/v3/', '/v4/');
			window.location.replace(newUrl);
		}
	};

	return (
		<div className="campaign-v-p-report-v3">
			{showV4NoticeDialog ? (
				<Fragment>
					<ModalDialog>
						<ModalDialogContent align="start">
							{/* <h4 className="mb-3">Notice</h4> */}
							<div className="updated-version-notice pt-2 px-3 text-center">
								We have updated our reporting tool to version 4. Click Continue to be redirected to the
								new version of your reports.
							</div>
						</ModalDialogContent>
						<ModalDialogButtons shaded>
							<button className="btn btn-primary" onClick={handleRedirectToV4}>
								<span>Continue</span>
							</button>
						</ModalDialogButtons>
					</ModalDialog>
				</Fragment>
			) : isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					<div className="report-header container">
						<div className="report-header-left">
							<h1 className="display-5 text-truncate">{campaign.name}</h1>
						</div>
						<div className="report-header-right">
							<div className="text-muted text-truncate">{`${campaign.streetAddress1}, ${campaign.city}, ${campaign.postalCode} ${campaign.state}`}</div>
						</div>
					</div>
					{!!campaignReports &&
					campaignReports.length > 0 && (
						<div className="campaign-reports container">
							<div className="row">
								<div className="col-lg-6 col-12 mb-lg-0 mb-2">
									<div className="flex-fill">
										<small className="text-muted">Report Date</small>
									</div>
									<div>
										<select
											value={reportIdValue}
											className="form-select"
											onChange={(e) => {
												setReportIdValue(e.target.value);
												setCompareToReportId('');
												setReport();
												setIsLoading(true);
											}}
										>
											{campaignReports.map((report) => (
												<option value={report.id} key={report.id}>
													{UnixTimestampToDateTime(report.dateUnix)}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									{!!compareToReports &&
									compareToReports.length > 0 && (
										<Fragment>
											<div className="flex-fill">
												<small className="text-muted">Compare To</small>
											</div>
											<div>
												<select
													className="form-select"
													onChange={(e) => {
														setCompareToReportId(e.target.value);
														//setCompareToReportChanged(true);
													}}
													value={compareToReportId}
												>
													<option value="" disabled>
														Compare to...
													</option>
													{compareToReports.map((report) => (
														<option value={report.id} key={report.id}>
															{UnixTimestampToDateTime(report.dateUnix)}
														</option>
													))}
												</select>
											</div>
										</Fragment>
									)}
								</div>
							</div>
						</div>
					)}

					<div className="container">
						<div className="report-body">
							{isLoadingReport ? (
								<Spinner />
							) : (
								<Fragment>
									{report.gridResponse &&
										(compareToReportId && compareToReportId !== '0' ? (
											compareToReport && (
												<GridReportSingleKeywordV3
													gridData={report.gridResponse}
													gridDataCompareTo={compareToReport.gridResponse}
													placeId={campaign.googlePlaceId}
													placeCid={campaign.googleCid}
													onKeywordSelected={handleKeywordSelected}
													onMobileReportViewChange={handleMobileReportView}
													reportName={selectedReportName}
													compareToReportName={compareToReportName}
												/>
											)
										) : (
											<GridReportSingleKeywordV3
												gridData={report.gridResponse}
												placeId={campaign.googlePlaceId}
												placeCid={campaign.googleCid}
												onKeywordSelected={handleKeywordSelected}
												reportName={selectedReportName}
												compareToReportName={compareToReportName}
											/>
										))}

									{report.rankResponse && (
										<RankReportV3
											rankData={report.rankResponse}
											compareToRankData={
												compareToReport && compareToReport.rankResponse ? (
													compareToReport.rankResponse
												) : null
											}
											targetKeyword={activeKeyword}
											onMobileReportViewChange={handleMobileReportView}
											reportName={selectedReportName}
											compareToReportName={compareToReportName}
										/>
									)}
								</Fragment>
							)}
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default PublicViewCampaignReportScreenV3;
