import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { Fragment, useState, useEffect } from 'react';
import PublicCampaignReportScreen from './screens/publicCampaignReport/PublicCampaignReportScreen';
import PublicViewCampaignReportScreen from './screens/publicViewCampaignReport/PublicViewCampaignReportScreen';
import PublicViewCampaignReportScreenV3 from './screens/publicViewCampaignReportV3/PublicViewCampaignReportScreenV3';
import MapSettingsScreen from './tools/mapSettingsScreen/MapSettingsScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicViewCampaignReportScreenV4 from './screens/publicViewCampaignReportV4/PublicViewCampaignReportScreenV4';
import { GetUser } from './Services';
import { GetAdminUser, deleteToken, getToken } from './utils/StorageUtil';
import { actionTypes } from './reducer';
import MainApp from './components/mainApp/MainApp';
import Loader from './components/loader/Loader';
import CompanyReportPublicView from './tools/sales/companyReportPublicView/CompanyReportPublicView';
import PublicViewCampaignReportScreenV5 from './screens/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5';
import PublicViewCampaignReportScreenSummary from './screens/publicViewCampaignReportSummary/PublicViewCampaignReportScreenSummary';
import Login from './components/login/Login';
import PublicCustomerReportInfoPage from './screens/publicCustomerReportInfoPage/PublicCustomerReportInfoPage';
import PublicViewCampaignReportScreenV5FromFiles from './screens/publicViewCampaignReportV5FromFiles/PublicViewCampaignReportScreenV5FromFiles';
import RequirementsApprovalRequestPage from './screens/orders/requirementsApprovalRequestPage/RequirementsApprovalRequestPage';
import RequirementsApprovalThankYouPage from './screens/orders/requirementsApprovalThankYouPage/RequirementsApprovalThankYouPage';
import RequirementsApprovalChangeThankYouPage from './screens/orders/requirementsApprovalChangeThankYouPage/RequirementsApprovalChangeThankYouPage';
import PublicViewCampaignReportScreenV5sproc from './screens/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5sproc';
import BottomNote from './components/bottomNote/BottomNote';
import KeywordTool from './components/keyword-tool/KeywordTool';
import LocationsGeneratorV2 from './components/locationsGeneratorV2/LocationsGeneratorV2';
import PublicViewCampaignReportScreenV5_WithSummaryTable from './screens/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5_WithSummaryTable';
import PublicViewCampaignReportScreenV5New from './screens/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5New';
import NewReportApiTest from './tools/newReportApiTest/NewReportApiTest';

function App() {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');
	const [ chatLoaded, setChatLoaded ] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getUser();
		handleLiveChat();
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('bottom-center');
		} else {
			setToastifyPosition('bottom-left');
		}
	};

	const getUser = async () => {
		let token = getToken();

		if (token) {
			try {
				const response = await GetUser();

				if (response && response.id) {
					const { ...tempUser } = response;

					dispatch({
						type : actionTypes.SET_USER,
						user : tempUser
					});

					if (GetAdminUser()) {
						let adminUser = GetAdminUser();

						dispatch({
							type      : actionTypes.SET_ADMIN_USER,
							adminUser : adminUser
						});
					}
				} else {
					let tempToken = getToken();

					if (tempToken) {
						deleteToken();
					}
				}

				setIsLoading(false);
			} catch (error) {
				let tempToken = getToken();

				if (tempToken) {
					deleteToken();
				}

				setIsLoading(false);
			}
		} else {
			setTimeout(function() {
				setIsLoading(false);
			}, 300);
		}
	};

	const handleLiveChat = () => {
		let pageSlugs = [
			'/customer/report',
			//'/map-boost-approval',
			'/map-boost-approval-change-thank-you',
			'/map-boost-approval-thank-you'
		];
		let currentUrl = window.location.href;

		if (pageSlugs.some((x) => currentUrl.includes(x)) && !chatLoaded) {
			// load LC script
			window.__lc = window.__lc || {};
			window.__lc.license = 10943002;

			if (user) {
				if (user.Status === 'Trial') {
					window.__lc.group = 3;
				} else {
					window.__lc.group = 2;
				}
			} else {
				window.__lc.group = 2;
			}

			var lc = document.createElement('script');
			lc.type = 'text/javascript';
			lc.async = true;
			lc.src =
				('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(lc, s);

			setChatLoaded(true);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="app">
			<Fragment>
				<Routes>
					<Route
						path="/view/report/:campaignId/:reportId/:compareToId?"
						element={<PublicViewCampaignReportScreen />}
					/>
					<Route path="/demo/:demoId?" element={<PublicViewCampaignReportScreenV5New />} />
					<Route path="/view/report/v3/:campaignId/*" element={<PublicViewCampaignReportScreenV3 />} />
					<Route path="/view/report/v4/:campaignId/*" element={<PublicViewCampaignReportScreenV4 />} />
					{/* <Route
						path="/view/report/v5/:campaignId/*"
						element={
							host && (host.indexOf('localhost') > -1 || host.indexOf('dev.') > -1) ? (
								<PublicViewCampaignReportScreenV5sproc />
							) : (
								<PublicViewCampaignReportScreenV5 />
							)
						}
					/> */}
					<Route path="/view/report/v5/old/:campaignId/*" element={<PublicViewCampaignReportScreenV5 />} />
					<Route path="/view/report/v5/:campaignId/*" element={<PublicViewCampaignReportScreenV5New />} />
					<Route
						path="/view/report/v5/new/:campaignId/*"
						element={<PublicViewCampaignReportScreenV5_WithSummaryTable />}
					/>
					<Route
						path="/view/report-single-kw/v5/:campaignId/*"
						element={<PublicViewCampaignReportScreenV5sproc />}
					/>
					<Route
						path="/view/report/files/v5/:campaignId/*"
						element={<PublicViewCampaignReportScreenV5FromFiles />}
					/>
					<Route path="/customer/report/:reportHistoryId/:cl?" element={<PublicCustomerReportInfoPage />} />
					<Route
						path="/customer/report/:reportHistoryId/:reportHistoryId2?/:cl?"
						element={<PublicCustomerReportInfoPage />}
					/>
					<Route
						path="/map-boost-approval/:requirementsId/:cl?/:approved?"
						element={<RequirementsApprovalRequestPage />}
					/>
					{/* <Route
						path="/map-boost-approval-change-thank-you"
						element={<RequirementsApprovalChangeThankYouPage />}
					/>
					<Route path="/map-boost-approval-thank-you" element={<RequirementsApprovalThankYouPage />} /> */}
					<Route
						path="/view/report/summary/:campaignId/*"
						element={<PublicViewCampaignReportScreenSummary />}
					/>
					<Route path="/reports/:campaignId/:reportId" element={<PublicCampaignReportScreen />} />
					<Route path="/tools/gridsize" element={<MapSettingsScreen />} />
					<Route path="/sales/:salesCompanyId/reports/:reportId" element={<CompanyReportPublicView />} />
					<Route path="/keywords-tool" element={<KeywordTool />} />
					<Route path="/locations-tool" element={<LocationsGeneratorV2 />} />

					{user ? (
						<Route path="/report-api/test" element={<NewReportApiTest />} />
					) : (
						<Route path="*" element={<Login />} />
					)}

					{user ? <Route path="/*" element={<MainApp />} /> : <Route path="*" element={<Login />} />}
				</Routes>
				<ToastContainer
					position={toastifyPosition}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					limit={1}
				/>

				{user && <BottomNote />}
			</Fragment>
		</div>
	);
}

export default App;
