import { Fragment, useState } from 'react';
import { Route, Routes } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import CompanyList from './companyList/CompanyList';
import GbpSelectScreen from '../../screens/gbpSelectScreen/GbpSelectScreen';
import GridSizeSelector from './gridSizeSelector/GridSizeSelector';
import DataGrid from './dataGrid/DataGrid';
import ConfirmBusinessSelectionScreen from '../../screens/confirmBusinessSelectionScreen/ConfirmBusinessSelectionScreen';
import CompanyTasksList from './companyTasksList/CompanyTasksList';
import CompanyTaskDetails from './companyTaskDetails/CompanyTaskDetails';
import Login from '../../components/login/Login';

const Sales = () => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<Routes>
			<Fragment>
				{user && (user.isAdmin || user.isSalesUser) ? (
					<Fragment>
						<Route path="/new" element={<GbpSelectScreen />} />
						<Route path="/confirm-selection" element={<ConfirmBusinessSelectionScreen />} />
						{/* <Route path="/:salesUserId?/companies" element={<CompanyList />} /> */}
						<Route path="/:salesCompanyId/grid-select" element={<GridSizeSelector />} />
						<Route path="/:salesCompanyId/datagrid" element={<DataGrid />} />
						<Route path="/:salesCompanyId/tasks/:taskId" element={<CompanyTaskDetails />} />
						<Route path="/:salesCompanyId/tasks" element={<CompanyTasksList />} />
						<Route path="/" element={<CompanyList />} />
					</Fragment>
				) : (
					<Fragment>
						<Route path="/*" element={<Login />} />
					</Fragment>
				)}
			</Fragment>
		</Routes>
	);
};

export default Sales;
