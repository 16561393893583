import './GoogleBusinessProfile.css';

const GoogleBusinessProfile = ({ profile }) => {
	return (
		<div className="gbp-profile-card">
			<div className="gbp-map shadow">
				<iframe
					width="600"
					height="450"
					loading="lazy"
					allowFullScreen
					referrerPolicy="no-referrer-when-downgrade"
					src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg&q=place_id:${profile.place_id}`}
				/>
			</div>
			{profile.name && (
				<h5 className="business-name mt-2">{profile.name}</h5>
			)}
			<small className="business-address text-muted">
				{profile.formatted_address}
			</small>

			<div className="business-stats">
				{profile.user_ratings_total && (
					<div className="business-stat">
						<div className="icon">
							<i className="fa-solid fa-star" />
							<span className="value">
								{profile.user_ratings_total}
							</span>
						</div>
						<div className="label text-muted">Reviews</div>
					</div>
				)}
				{profile.rating && (
					<div className="business-stat">
						<div className="icon rating">
							<i className="fa-solid fa-star" />
							<span className="value">{profile.rating}</span>
						</div>
						<div className="label text-muted">Rating</div>
					</div>
				)}
			</div>

			{(profile.website ||
				profile.formatted_phone_number ||
				profile.url) && (
				<div className="business-actions">
					{profile.website && (
						<a
							href={profile.website}
							target="_blank"
							className="btn btn-primary btn-sm"
						>
							<i className="fa-solid fa-earth-americas" />
							<span>Website</span>
						</a>
					)}

					{profile.formatted_phone_number && (
						<a
							href={`tel:${profile.formatted_phone_number}`}
							className="btn btn-outline-primary btn-sm"
						>
							<i className="fa-solid fa-phone" />
							<span>{profile.formatted_phone_number}</span>
						</a>
					)}

					{profile.url && (
						<a
							href={profile.url}
							target="_blank"
							className="btn btn-warning btn-sm"
						>
							{/* <i className="fa-solid fa-location-dot" /> */}
							<i className="fa-solid fa-map-location-dot m-0" />
						</a>
					)}
				</div>
			)}
		</div>
	);
};

export default GoogleBusinessProfile;
