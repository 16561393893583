import { Fragment, useEffect, useState } from 'react';
import { GetCities, GetLocations } from '../../Services';
import Spinner from '../spinner/Spinner';
import './LocationsGenerator.css';

const LocationsGenerator = ({
	location,
	generatedLocations,
	onLocationsUpdate
}) => {
	const [ isEditMode, setIsEditMode ] = useState(true);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ lat, setLat ] = useState('');
	const [ lng, setLng ] = useState('');
	const [ radius, setRadius ] = useState('7.5');
	const [ locations, setLocations ] = useState();
	const [ locationsString, setLocationsString ] = useState();

	useEffect(
		() => {
			if (location) {
				setLat(location.lat.toString());
				setLng(location.lng.toString());
			}
		},
		[ location ]
	);

	useEffect(
		() => {
			console.log('generatedLocations', generatedLocations);
			setIsEditMode(
				!generatedLocations ||
					!generatedLocations.cities ||
					generatedLocations.cities.length === 0
			);
		},
		[ generatedLocations ]
	);

	const toggleEditMode = () => {
		setIsEditMode((prev) => !prev);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (lat.trim() === '' || lng.trim() === '' || radius.trim() === '') {
			return;
		}

		setIsGenerating(true);

		let data = {
			lat         : lat,
			lng         : lng,
			radiusMiles : radius
		};

		const response = await GetCities(data);

		console.log(response);

		if (response.success) {
			if (onLocationsUpdate) {
				onLocationsUpdate(response);
				// onLocationsUpdate([
				// 	{
				// 		name      : 'Sarasota',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	},
				// 	{
				// 		name      : 'Bradenton',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	},
				// 	{
				// 		name      : 'Tampa',
				// 		state     : 'Florida',
				// 		stateAbbr : 'FL'
				// 	}
				// ]);
			}
		}

		// setLocationsString(response);

		setIsGenerating(false);
	};

	if (isGenerating) {
		return (
			<div className="locations-generator">
				<Spinner />
			</div>
		);
	}

	return (
		<div className="locations-generator">
			<h5 className="mb-3">Locations Generator</h5>
			{isEditMode ? (
				<Fragment>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="latitude" className="form-label">
								Latitude:
							</label>
							<input
								type="text"
								className="form-control"
								id="latitude"
								placeholder="Latitude"
								value={lat}
								onChange={(e) => setLat(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="longitude" className="form-label">
								Longitude:
							</label>
							<input
								type="text"
								className="form-control"
								id="longitude"
								placeholder="Longitude"
								value={lng}
								onChange={(e) => setLng(e.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="radius" className="form-label">
								Radius (miles):
							</label>
							<input
								type="text"
								className="form-control"
								id="radius"
								placeholder="Radius"
								value={radius}
								onChange={(e) => setRadius(e.target.value)}
							/>
						</div>
						<div className="buttons-container">
							<button type="submit" className="btn btn-primary">
								<span>Generate Locations</span>
							</button>
						</div>
					</form>
				</Fragment>
			) : (
				<Fragment>
					<button
						className="btn btn-outline-primary mb-3"
						onClick={toggleEditMode}
					>
						<span>Change Location</span>
					</button>
					{generatedLocations &&
					generatedLocations.cities &&
					generatedLocations.cities.length > 0 && (
						<table className="table">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Name</th>
									<th scope="col">State</th>
									<th scope="col">State Abbr.</th>
								</tr>
							</thead>
							<tbody>
								{generatedLocations.cities.map(
									(generatedLocation, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{generatedLocation.city}</td>
											<td>
												{generatedLocation.stateName}
											</td>
											<td>
												{generatedLocation.stateAbbr}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default LocationsGenerator;
