import { Fragment, useEffect, useState } from 'react';

const KeywordStatItem = ({ label, changeSymbol, value, change }) => {
	const [ changeIcon, setChangeIcon ] = useState('fas fa-arrow-up');

	useEffect(
		() => {
			if (change < 0) {
				setChangeIcon('bad fa-solid fa-arrow-trend-down');
				// setChangeIcon('');
			} else if (change > 0) {
				setChangeIcon('good fa-solid fa-arrow-trend-up');
			} else {
				setChangeIcon('');
			}
		},
		[ change ]
	);

	return (
		<Fragment>
			<div className="keyword-stat-item">
				<Fragment>
					<div className="stat-data">
						{label && <small className="label">{label}</small>}
						<div className="value-container">
							<Fragment>
								{label === 'Average' &&
								(value === null || value >= 21) ? (
									<div className="value">21+</div>
								) : (
									<div className="value">{`${value % 1 > 0
										? value.toFixed(2)
										: value}`}</div>
								)}
							</Fragment>

							<div
								className={`change ${change && change > 0
									? 'good'
									: 'bad'}`}
							>
								{!!change &&
								change !== 0 && (
									<Fragment>
										<span>
											{change > 0 ? '+' : ''}

											{`${change % 1 > 0
												? change.toFixed(2)
												: change.toFixed(
														0
													)} ${changeSymbol
												? changeSymbol
												: ''}`}
										</span>
									</Fragment>
								)}
							</div>
						</div>
					</div>
					<div className="stat-icon">
						<i className={changeIcon} />
					</div>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default KeywordStatItem;
