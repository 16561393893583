import { Fragment, useEffect } from 'react';
import './KeywordMapStatsV4.css';
import KeywordStatItemV4 from '../keywordMapStats/keywordStatItem/KeywordStatItemV4';

const KeywordMapStatsV4 = ({
	keywordData,
	mobileReportView,
	showValue = true,
	selectable = false,
	onItemSelect,
	preselectValue = null
}) => {
	const handleSelectItem = (itemSelected, label) => {
		if (onItemSelect) {
			onItemSelect(itemSelected, label);
		}
	};

	return (
		<div className="keyword-map-stats-v4">
			{keywordData && (
				<Fragment>
					<KeywordStatItemV4
						label={selectable ? 'All' : 'Average'}
						changeSymbol="%"
						value={showValue ? keywordData.averageRank || 0 : ''}
						change={keywordData.averageRankChangePercentage || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
						preselectValue={preselectValue}
					/>

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV4
						label="Top 3"
						value={showValue ? keywordData.top3 || 0 : ''}
						change={keywordData.top3Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
					<KeywordStatItemV4
						label="4-10"
						value={showValue ? keywordData.top10 || 0 : ''}
						change={keywordData.top10Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV4
						label="11-20"
						value={showValue ? keywordData.top20 || 0 : ''}
						change={keywordData.top20Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
					<KeywordStatItemV4
						label="21+"
						value={showValue ? keywordData.over20 || 0 : ''}
						change={keywordData.over20Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
				</Fragment>
			)}
		</div>
	);
};

export default KeywordMapStatsV4;
