import { Fragment, useEffect, useState, useRef } from 'react';
import GridMapV3 from './../v3/gridMap/GridMapV3';
import KeywordMapStatsV4 from './keywordMapStats/KeywordMapStatsV4';
import './GridReportSingleKeywordV4.css';
import GridReportToggleTabs from './../v4/gridReportToggleTabs/GridReportToggleTabsV4';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import GridReportToggleTabsV4 from './../v4/gridReportToggleTabs/GridReportToggleTabsV4';

const GridReportSingleKeywordV4 = ({
	gridData,
	gridDataCompareTo,
	placeId,
	placeCid,
	showAllKeywords,
	reportName,
	compareToReportName,
	onMobileReportViewChange,
	targetKeywordData,
	compareToKeywordData,
	onMapFullScreen
}) => {
	const [ compareToGridExists, setCompareToGridExists ] = useState(false);
	const [ allGridsReady, setAllGridsReady ] = useState(false);
	const [ mapFullScreen, setMapFullScreen ] = useState(false);
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(
		() => {
			if (gridData && !showAllKeywords) {
				if (gridDataCompareTo) {
					setCompareToGridExists(true);
				}

				setAllGridsReady(true);
			}
		},
		[ gridData, placeId, placeCid, showAllKeywords ]
	);

	const handleMapFullScreen = () => {
		setMapFullScreen(!mapFullScreen);

		if (onMapFullScreen) {
			onMapFullScreen(!mapFullScreen);
		}

		if (!mapFullScreen) {
			document.querySelector('body').scrollIntoView({
				behavior : 'instant'
			});
		}

		document.body.classList.toggle('body-full-screen');
	};

	return (
		<Fragment>
			{!showAllKeywords ? (
				<Fragment>
					{allGridsReady &&
						targetKeywordData &&
						(compareToGridExists ? (
							<Fragment>
								<div className="row grid-keyword-map-stats-container">
									<GridReportToggleTabsV4
										reportName={reportName}
										compareToReportName={compareToReportName}
									/>

									{(!mobileReportView || mobileReportView === 'compare') && (
										<div className="col-lg-6 col-12 ps-0">
											<KeywordMapStatsV4
												keywordData={compareToKeywordData}
												mobileReportView={mobileReportView}
											/>
										</div>
									)}

									{(!mobileReportView || mobileReportView === 'base') && (
										<div className="col-lg-6 col-12 px-lg-0">
											<KeywordMapStatsV4
												keywordData={targetKeywordData}
												mobileReportView={mobileReportView}
											/>
										</div>
									)}
								</div>

								<div className={`row grid-report-map-container-v4 ${mapFullScreen && 'full-screen'}`}>
									{(!mobileReportView || mobileReportView === 'compare') && (
										<div className="map-wrapper col-lg-6 col-12 mt-3 mt-lg-0">
											<GridMapV3
												key="map-1"
												gridData={compareToKeywordData}
												placeId={placeId}
												placeCid={placeCid}
												isFullScreen={mapFullScreen}
											/>
										</div>
									)}

									{(!mobileReportView || mobileReportView === 'base') && (
										<div
											className={`map-wrapper ${gridDataCompareTo
												? 'col-lg-6'
												: ''} col-12 pe-lg-1`}
										>
											<GridMapV3
												key="map-0"
												gridData={targetKeywordData}
												placeId={placeId}
												placeCid={placeCid}
												isFullScreen={mapFullScreen}
											/>
										</div>
									)}

									<div className="map-full-screen-wrapper">
										<button className="btn btn-link" onClick={handleMapFullScreen}>
											{mapFullScreen ? 'Exit Full Screen' : 'View Maps Full Screen'}
										</button>
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="row grid-keyword-map-stats-container">
									<div className="col-12 p-0">
										<KeywordMapStatsV4
											keywordData={targetKeywordData}
											mobileReportView={mobileReportView}
										/>
									</div>
								</div>
								<div className={`row grid-report-map-container-v4 ${mapFullScreen && 'full-screen'}`}>
									<div className="map-wrapper col-12">
										<GridMapV3
											key="map-0"
											gridData={targetKeywordData}
											placeId={placeId}
											placeCid={placeCid}
											isFullScreen={mapFullScreen}
											isSinglePublicView={true}
										/>
									</div>
									<div className="map-full-screen-wrapper">
										<button className="btn btn-link" onClick={handleMapFullScreen}>
											{mapFullScreen ? 'Exit Full Screen' : 'View Maps Full Screen'}
										</button>
									</div>
								</div>
							</Fragment>
						))}
				</Fragment>
			) : (
				<Fragment>
					{gridData &&
					gridData.data &&
					gridData.data.length > 0 && (
						<Fragment>
							{gridData.data.map((keyword, index) => {
								const keywordData = {
									lat              : gridData.lat,
									lng              : gridData.lng,
									horizontalPoints : gridData.horizontalPoints,
									verticalPoints   : gridData.verticalPoints,
									...keyword
								};

								return (
									<Fragment key={`fragment-${index}`}>
										<h5 key={`title-${index}`} className="mt-3 mb-3">
											{keyword.keyword}
										</h5>
										<GridMapV3
											key={`map-${index}`}
											gridData={keywordData}
											placeId={placeId}
											placeCid={placeCid}
										/>
									</Fragment>
								);
							})}
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default GridReportSingleKeywordV4;
