import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../spinner/Spinner';
import './GridKeywordPicker.css';

const GridKeywordPicker = ({ gridData, onKeywordSelected, activeKeyword }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ keywords, setKeywords ] = useState();

	useEffect(
		() => {
			if (gridData) {
				let tempKeywords = [];
				gridData.data.forEach((keyword) => {
					tempKeywords.push({
						...keyword
					});
				});

				console.log('tempKeywords', tempKeywords);

				setKeywords(tempKeywords);
			} else {
				setKeywords([]);
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (keywords) {
				setIsLoading(false);
			}
		},
		[ keywords ]
	);

	const handleKeywordClick = (keyword) => {
		if (onKeywordSelected) {
			onKeywordSelected(keyword);
		}
	};

	return (
		<div className="grid-report-keywords">
			{isLoading && <Spinner />}

			{!isLoading &&
			keywords &&
			keywords.length > 0 && (
				<Fragment>
					{keywords.map((keyword) => (
						<div
							key={keyword.keyword}
							className={`grid-report-keyword ${activeKeyword.keyword ===
							keyword.keyword
								? 'active'
								: ''}`}
							onClick={() => handleKeywordClick(keyword)}
						>
							<div>{keyword.keyword}</div>
						</div>
					))}
				</Fragment>
			)}
		</div>
	);
};

export default GridKeywordPicker;
