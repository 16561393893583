import { Fragment, useEffect, useState } from 'react';
import RankingFilters from './rankingFilters/RankingFilters';
import './RankReport.css';

const RankReport = ({ rankData, targetKeyword = null }) => {
	const [ rankStats, setRankStats ] = useState();
	const [ activeFilter, setActiveFilter ] = useState('all');
	const [ rankings, setRankings ] = useState();

	useEffect(
		() => {
			if (rankData && rankData.results && rankData.results.length > 0) {
				let tempRankStats = {};

				if (!targetKeyword) {
					tempRankStats = {
						total     : rankData.results.length,
						notRanked : rankData.results.filter((r) => !r.ranking).length,
						top3      : rankData.results.filter((r) => r.ranking && r.ranking < 4).length,
						top10     : rankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3).length,
						other     : rankData.results.filter((r) => r.ranking && r.ranking > 10).length
					};
				} else {
					tempRankStats = {
						total     : rankData.results.filter((r) => r.keyword == targetKeyword).length,
						notRanked : rankData.results.filter((r) => r.keyword == targetKeyword && !r.ranking).length,
						top3      : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4
						).length,
						top10     : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
						).length,
						other     : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking > 10
						).length
					};
				}

				console.log(tempRankStats);
				console.log(rankData);

				setRankStats(tempRankStats);
			} else {
				setRankStats();
			}

			setActiveFilter('all');
		},
		[ rankData, targetKeyword ]
	);

	const handleFilterChange = (filter) => {
		setActiveFilter(filter);
	};

	useEffect(
		() => {
			// console.log('targetKeyword', targetKeyword);
			if (rankData && rankData.results && rankData.results.length > 0) {
				if (!targetKeyword) {
					if (activeFilter == 'all') {
						setRankings(rankData.results);
					} else if (activeFilter == 'top3') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking < 4));
					} else if (activeFilter == 'top10') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3));
					} else if (activeFilter == 'other') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking > 10));
					} else if (activeFilter == 'notRanked') {
						setRankings(rankData.results.filter((r) => r.ranking && !r.ranking));
					}
				} else {
					if (activeFilter == 'all') {
						setRankings(rankData.results.filter((r) => r.keyword == targetKeyword));
					} else if (activeFilter == 'top3') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4)
						);
					} else if (activeFilter == 'top10') {
						setRankings(
							rankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
							)
						);
					} else if (activeFilter == 'other') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && r.ranking > 10)
						);
					} else if (activeFilter == 'notRanked') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && !r.ranking)
						);
					}
				}
			} else {
				setRankings();
			}
		},
		[ rankData, activeFilter, targetKeyword ]
	);

	return (
		<Fragment>
			<RankingFilters activeFilter={activeFilter} onChange={handleFilterChange} />

			{rankings &&
			rankings.length > 0 && (
				<div className="rank-report">
					{/* <h6 className="display-5">Rank Report</h6> */}

					{/* {rankStats && (
						<div className="rank-stats">
							{rankStats.top3 > 0 && (
								<div className="alert alert-success">
									{`Top 3: ${rankStats.top3}`}
								</div>
							)}
							{rankStats.top10 > 0 && (
								<div className="alert alert-warning">
									{`4-10: ${rankStats.top10}`}
								</div>
							)}
							{rankStats.other > 0 && (
								<div className="alert alert-secondary">
									{`10+: ${rankStats.other}`}
								</div>
							)}
							{rankStats.notRanked > 0 && (
								<div className="alert alert-danger">
									{`Not Ranked Yet: ${rankStats.notRanked}`}
								</div>
							)}
						</div>
					)} */}

					<table className="table b-0 rank-table">
						<thead>
							<tr>
								<th scope="col" className="rank" colSpan={2}>
									<div className="rank-table-header">
										<div>Rank</div>
										<div className="text-right">
											{`${rankings.length} ${rankings.length > 1 ? 'results' : 'result'}`}
										</div>
									</div>
								</th>
								{/* {!targetKeyword && <th scope="col">Keyword</th>} */}
								{/* <th scope="col" className="actions" colSpan={2}>
									
								</th> */}
							</tr>
						</thead>
						<tbody>
							{rankings.map((result) => {
								let rowClass = 'btn-secondary';

								if (result.ranking) {
									if (result.ranking < 4) {
										rowClass = 'btn-success';
									} else if (result.ranking < 11) {
										rowClass = 'btn-warning';
									} else {
										rowClass = 'btn-danger';
									}
								} else {
									rowClass = 'btn-secondary';
								}

								return (
									<tr key={`${result.keyword}-${result.location}`}>
										<td className="rank">{`${result.ranking ? result.ranking : '0'}`}</td>
										{/* {!targetKeyword && (
											<td>{result.keyword}</td>
										)} */}
										<td>
											<a
												className={`btn ${rowClass} d-block`}
												href={`${result.searchLink}`}
												target="_blank"
											>
												{result.location}
												{/* {result.searchLink &&
												result.searchLink != '' ? (
													<a
														href={result.searchLink}
														target="_blank"
														title="View in Google Search"
													>
														{result.location}
													</a>
												) : (
													<Fragment>
														{result.location}
													</Fragment>
												)} */}
											</a>
										</td>
										{/* <td className="actions">
											{result.url &&
											result.url != '' && (
												<a
													href={result.url}
													target="_blank"
													title="View Ranked Page in &quot;New Tab&quot;"
												>
													<i class="fa-solid fa-up-right-from-square" />
												</a>
											)}

											{result.searchLink &&
											result.searchLink != '' && (
												<a
													href={result.searchLink}
													target="_blank"
													title="View in Google Search"
												>
													<i class="fa-solid fa-magnifying-glass" />
												</a>
											)}
										</td> */}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
		</Fragment>
	);
};

export default RankReport;
