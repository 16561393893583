import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GridReportSingleKeyword from '../../components/gridReport/GridReportSingleKeyword';
// import GridReport from '../../components/gridReport/GridReport';
import GridReportV2 from '../../components/gridReport/GridReportV2';
import RankReport from '../../components/rankReport/RankReport';
import Spinner from '../../components/spinner/Spinner';
import { GetCampaign, GetCampaignReports, GetSEOReport } from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import './PublicViewCampaignReportScreen.css';

const PublicViewCampaignReportScreen = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const { campaignId, reportId, compareToId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ campaignReports, setCampaignReports ] = useState();
	const [ report, setReport ] = useState();
	const [ reportTime, setReportTime ] = useState();
	const [ reportIdValue, setReportIdValue ] = useState();
	const [ activeKeyword, setActiveKeyword ] = useState();
	const [ selectedReport, setSelectedReport ] = useState();
	const [ compareToReport, setCompareToReport ] = useState('');
	const [ compareToReports, setCompareToReports ] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		//redirect user to v3 version
		let currentUrl = window.location.href;

		if (currentUrl.indexOf('/v3/') === -1) {
			let newUrl = currentUrl.replace('/view/report/', '/view/report/v3/');
			window.location.replace(newUrl);
		}
	}, []);

	useEffect(
		() => {
			if (campaignId && reportId) {
				getCampaign();
			}
		},
		[ campaignId, reportId ]
	);

	// useEffect(
	// 	() => {
	// 		if (compareToId && compareToId !== '') {
	// 			setCompareToReport(compareToId);
	// 		}
	// 	},
	// 	[ compareToId ]
	// );

	useEffect(
		() => {
			if (campaign) {
				if (reportId && reportId !== '1') {
					console.log('getting report and setting selected report');
					getReport(reportId);
					setReportIdValue(reportId);
					setSelectedReport(reportId);
				}
			}

			getCampaignReports();
		},
		[ campaign, reportId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaign(response);
		}
	};

	const getCampaignReports = async () => {
		if (campaign) {
			const response = await GetCampaignReports(campaignId);

			if (response && response.reports && response.reports.length > 0) {
				setCampaignReports(response.reports);

				if (reportId === '1' || response.reports.findIndex((r) => r.id === reportId) === -1) {
					console.log('setting report id value to', response.reports[0].id);
					setReportIdValue(response.reports[0].id);
					await getReport(response.reports[0].id);
				}
			} else {
				setCampaignReports();
				setCompareToReports();
			}
		}
	};

	const getReport = async (id) => {
		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		console.log('getting report:', campaignId, id, targetId, compareToReport);

		const response = await GetSEOReport(campaignId, id, targetId, compareToReport);

		console.log('response:', response);

		if (response.success) {
			console.log(response);
			setReport(response);
			// setReportIdValue(response.id);
			// setReportIdValue(response.id);
		} else {
			setReport();
			setReportIdValue();
		}
	};

	useEffect(
		() => {
			if (campaign && report) {
				setIsLoading(false);
			}
		},
		[ campaign, report ]
	);

	useEffect(
		() => {
			if (compareToReport) {
				getReport(reportIdValue);
			}
		},
		[ compareToReport ]
	);

	useEffect(
		() => {
			if (campaignReports && report) {
				let tempCompareToReports = campaignReports.filter(
					(r) => r.id !== reportId && r.dateUnix < report.reportGeneratedAtUnix
				);

				// console.log('compare reports: ', tempCompareToReports);

				setCompareToReports(tempCompareToReports);
				// setReportIdValue(reportId);
			} else if (!isLoading && campaignReports && !report) {
				getReport(campaignReports[0].id);
				setReportIdValue(campaignReports[0].id);
				console.log('report id value: ', campaignReports[0].id);
			} else {
				setCompareToReports();
			}
		},
		[ campaignReports, report ]
	);

	const handleKeywordSelected = (keyword) => {
		setActiveKeyword(keyword);
	};

	return (
		<div className="campaign-v-p-report">
			{isLoading && <Spinner />}

			{!isLoading && (
				<Fragment>
					<div className="report-header container">
						<div className="report-header-left">
							<h1 className="display-5 text-truncate">{campaign.name}</h1>
							<div className="text-muted text-truncate">{`${campaign.streetAddress1}, ${campaign.city}, ${campaign.postalCode} ${campaign.state}`}</div>
						</div>

						{report &&
						report.reportGeneratedAtUnix && (
							<div className="report-header-right">
								<small>{`Report generated on ${UnixTimestampToDateTime(
									report.reportGeneratedAtUnix
								)}`}</small>
							</div>
						)}
					</div>
					{!!campaignReports &&
					campaignReports.length > 0 && (
						<div className="campaign-reports container mb-3">
							<div className="d-flex flex-row justify-content-start">
								<div className="flex-fill">
									<small className="text-muted">Report Date</small>
								</div>
								{!!compareToReports &&
								compareToReports.length > 0 && (
									<div className="flex-fill">
										<small className="text-muted">Compare To</small>
									</div>
								)}
							</div>
							<div className="input-group">
								<select
									value={selectedReport}
									className="form-select"
									onChange={(e) => {
										// setSelectedReport(e.target.value);
										// getReport(e.target.value);
										setReportIdValue(e.target.value);
										setCompareToReport('');
										setReport();
										setIsLoading(true);

										navigate(`/view/report/${campaign.id}/${e.target.value}`);
									}}
								>
									{campaignReports.map((report) => (
										<option value={report.id}>{UnixTimestampToDateTime(report.dateUnix)}</option>
									))}
								</select>

								{!!compareToReports &&
								compareToReports.length > 0 && (
									<select
										className="form-select"
										onChange={(e) => {
											setCompareToReport(e.target.value);
											// console.log(
											// 	'redirecting to report id value:',
											// 	reportIdValue
											// );
											// navigate(
											// 	`/view/report/${campaign.id}/${reportIdValue}/${e
											// 		.target.value}`
											// );
										}}
										value={compareToReport}
									>
										<option value="" disabled>
											Compare to...
										</option>
										{compareToReports.map((report) => (
											<option value={report.id}>
												{UnixTimestampToDateTime(report.dateUnix)}
											</option>
										))}
									</select>
								)}

								{compareToReport && (
									<a
										className="btn btn-primary"
										href={`/view/report/${campaign.id}/${compareToReport}`}
										target="_blank"
									>
										View
									</a>
								)}
							</div>
						</div>
					)}

					<div className="container">
						<div className="report-body">
							{report.gridResponse && (
								<GridReportSingleKeyword
									gridData={report.gridResponse}
									placeId={campaign.googlePlaceId}
									placeCid={campaign.googleCid}
									onKeywordSelected={handleKeywordSelected}
								/>
							)}

							{report.rankResponse && (
								<RankReport rankData={report.rankResponse} targetKeyword={activeKeyword} />
							)}
						</div>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default PublicViewCampaignReportScreen;
