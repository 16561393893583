import { useState, useEffect, Fragment } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	BarElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './Chart.css';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	BarElement,
	ChartDataLabels
);

const Chart = ({ type = 'line', labels, data, headline }) => {
	const [ options, setOptions ] = useState({
		responsive          : true,
		maintainAspectRatio : false,
		plugins             : {
			legend : {
				position        : 'top',
				useBorderRadius : true,
				borderRadius    : 5
			},
			title  : {
				display : headline && headline !== '',
				text    : headline
			}
		},
		scales              : {
			x : {
				grid : {
					color : function(context) {
						return '#bbbbbb';
					}
				}
			},
			y : {
				grid : {
					color : function(context) {
						return '#eaeaea';
					}
				}
			}
		}
	});
	const [ chartData, setChartData ] = useState();

	useEffect(
		() => {
			let datasets = [];

			if (data) {
				let top3GoogleProfileRankingColor = '#419667';
				let top3LocationRankingColor = '#59b1eb'; //59b1eb

				if (data.top3GoogleProfileRankingItems && data.top3GoogleProfileRankingItems.length) {
					datasets.push({
						label           : 'Top 3 Google Profiles',
						data            : data.top3GoogleProfileRankingItems,
						borderColor     : top3GoogleProfileRankingColor,
						backgroundColor : top3GoogleProfileRankingColor,
						tension         : 0.4,
						datalabels      : {
							borderColor     : top3GoogleProfileRankingColor,
							backgroundColor : top3GoogleProfileRankingColor,
							color           : '#ffffff',
							borderRadius    : '3'
						}
					});
				}

				if (data.top3LocationRankingItems && data.top3LocationRankingItems.length) {
					datasets.push({
						label           : 'Top 3 Pages',
						data            : data.top3LocationRankingItems,
						borderColor     : top3LocationRankingColor,
						backgroundColor : top3LocationRankingColor,
						tension         : 0.4,
						datalabels      : {
							borderColor     : top3LocationRankingColor,
							backgroundColor : top3LocationRankingColor,
							color           : '#ffffff',
							borderRadius    : '3'
						}
					});
				}
			}

			let tempData = {
				labels   : labels,
				datasets : datasets
			};

			setChartData(tempData);
		},
		[ labels, data ]
	);

	return (
		<Fragment>
			{options &&
			chartData && (
				<div className="chart-container">
					{type === 'line' ? (
						<Line options={options} data={chartData} />
					) : type === 'pie' ? (
						<Pie options={options} data={chartData} />
					) : type === 'bar' ? (
						<Bar options={options} data={chartData} />
					) : (
						''
					)}
				</div>
			)}
		</Fragment>
	);
};

export default Chart;
