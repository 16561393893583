import { Fragment, useEffect, useState } from 'react';
import './KeywordsList.css';

const KeywordsList = ({ keywords, onKeywordsUpdate, locations = null }) => {
	const [ keywordsText, setKeywordsText ] = useState('');
	const [ isEditMode, setIsEditMode ] = useState(true);

	useEffect(
		() => {
			setIsEditMode(
				keywords === undefined ||
					keywords === null ||
					keywords.length === 0
			);
		},
		[ keywords ]
	);

	const toggleEditMode = () => {
		setIsEditMode((prev) => !prev);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (keywordsText.trim() !== '') {
			var keywordsArray = keywordsText.split('\n');

			console.log(keywordsArray);

			const parsedArray = [];

			keywordsArray.map((keyword) => {
				if (
					keyword.trim() !== '' &&
					keyword.trim().replace('\n', '') !== ''
				) {
					parsedArray.push(keyword);
				}
			});

			if (onKeywordsUpdate) {
				onKeywordsUpdate(parsedArray);
			}
		} else {
			if (onKeywordsUpdate) {
				onKeywordsUpdate();
			}
		}
	};

	return (
		<div className="keywords-list">
			<h5 className="mb-3">Keywords List</h5>
			{isEditMode ? (
				<form onSubmit={handleSubmit}>
					<label htmlFor="keywordsText" className="form-label">
						Enter keywords (one per line):
					</label>
					<textarea
						id="keywordsText"
						className="form-control"
						rows={8}
						value={keywordsText}
						onChange={(e) => setKeywordsText(e.target.value)}
					/>

					<div className="buttons-container">
						<button type="submit" className="btn btn-primary">
							<span>Save Keywords</span>
						</button>
					</div>
				</form>
			) : (
				<Fragment>
					<button
						className="btn btn-outline-primary mb-3"
						onClick={toggleEditMode}
					>
						<span>Change Keywords</span>
					</button>
					{keywords &&
					keywords.length > 0 && (
						<table className="table">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Keyword</th>
								</tr>
							</thead>
							<tbody>
								{keywords.map((keyword, index) => (
									<tr key={index}>
										<th scope="row">{index + 1}</th>
										<td>{keyword}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default KeywordsList;
