import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	GetCampaign,
	GetRequirementDetails,
	GetRequirementsMessages,
	SendApprovedConfirmationEmails
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignRequirementsScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';
import moment from 'moment';
import { capitalize } from '../../../utils/Common';

const CampaignRequirementsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ requirementsData, setRequirementsData ] = useState();
	const [ submittedData, setSubmittedData ] = useState();
	const [ requirementsMessages, setRequirementsMessages ] = useState([]);
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const displayData = [
		{ slug: 'mobilePhone', label: 'Mobile Number', inputType: 'text' },
		{ slug: 'company', label: 'Company Name', inputType: 'text' },
		{ slug: 'website', label: 'Website URL', inputType: 'text' },
		{ slug: 'googleBusinessUrl', label: 'Google Map URL', inputType: 'text' },
		{ slug: 'address', label: 'Business Address', inputType: 'text' },
		{ slug: 'businessPhone', label: 'Business Phone Number', inputType: 'text' },
		{ slug: 'industry', label: 'Business industry', inputType: 'text' },
		{ slug: 'services', label: 'Services', inputType: 'textarea' },
		{
			slug      : 'specialInstructions',
			label     : 'Special Instructions',
			inputType : 'textarea'
		}
	];

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign && campaign.requirementId) {
				getRequirementsDetails(campaign.requirementId);
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && campaign.requirementId) {
				getRequirementsDetails(campaign.requirementId);
				getRequirementsMessages(campaign.requirementId);
			}
		},
		[ requirementsData ]
	);

	const getCampaign = async () => {
		setIsLoading(true);

		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = 'User requirements - ' + response.name;
			setCampaign(response);
		}

		setIsLoading(false);
	};

	const getRequirementsDetails = async (id) => {
		const response = await GetRequirementDetails(id);

		if (response) {
			setRequirementsData(response);

			if (response.initialData) {
				setSubmittedData(JSON.parse(response.initialData));
			}
		}

		setIsLoading(false);
	};

	const getRequirementsMessages = async (id) => {
		const response = await GetRequirementsMessages(id);

		if (response.success && response.data) {
			//sort messages from latest to oldest
			let tempMessages = response.data.sort(function(a, b) {
				return new Date(b.dateCreated) - new Date(a.dateCreated);
			});

			setRequirementsMessages(tempMessages);
		}
	};

	const handleSendApprovedConfirmation = async (id) => {
		if (requirementsData && requirementsData.id) {
			let userEmail = requirementsData.user.email;
			let salesRepEmail = requirementsData.order.salesRep.email || null;

			if (
				window.confirm(
					`Approved confirmation email will be sent to: ${userEmail
						? ` user: ${userEmail}, `
						: ''}${salesRepEmail ? ` sales rep: ${salesRepEmail}, and ` : ''} mapboost@realwebsite.com. OK?`
				)
			) {
				const response = await SendApprovedConfirmationEmails(requirementsData.id);

				if (response.success) {
					toast.success('Approved confirmation emails sent successfully');
				}
			}
		}
	};

	return (
		<div className="container-fluid campaign-main-container">
			<Fragment>
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>
				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="campaign-subform-container">
								<ul className="nav nav-tabs" id="tabRequirementsData" role="tablist">
									<li className="nav-item" role="presentation" key="submitted-data">
										<button
											className="nav-link active"
											id="submitted-data-tab"
											data-bs-toggle="tab"
											data-bs-target="#submitted-data-tab-pane"
											type="button"
											role="tab"
											aria-controls="submitted-data-tab-pane"
											aria-selected="true"
										>
											Submitted
										</button>
									</li>
									<li className="nav-item" role="presentation" key="approved-data">
										<button
											className="nav-link"
											id="approved-data-tab"
											data-bs-toggle="tab"
											data-bs-target="#approved-data-tab-pane"
											type="button"
											role="tab"
											aria-controls="approved-data-tab-pane"
											aria-selected="true"
										>
											Approved
										</button>
									</li>
									<li className="nav-item" role="presentation" key="messages">
										<button
											className="nav-link"
											id="messages-tab"
											data-bs-toggle="tab"
											data-bs-target="#messages-tab-pane"
											type="button"
											role="tab"
											aria-controls="messages-tab-pane"
											aria-selected="true"
										>
											Messages
										</button>
									</li>
								</ul>
								<div className="tab-content border" id="tabRequirementsDataContent">
									<div
										className="tab-pane fade p-3 show active"
										id="submitted-data-tab-pane"
										role="tabpanel"
										aria-labelledby="submitted-data-tab"
										tabIndex="0"
									>
										{submittedData &&
											Object.keys(submittedData).map((reqDataItemKey, index) => {
												let itemValue = submittedData[reqDataItemKey];
												let parsedItemValue = '';

												let itemToDisplay = displayData.find(
													(x) => capitalize(x.slug) === reqDataItemKey
												);

												if (itemToDisplay) {
													if (itemValue) {
														if (typeof itemValue === 'object') {
															for (var itemValueKey in itemValue) {
																if (itemValue.hasOwnProperty(itemValueKey)) {
																	parsedItemValue += itemValue[itemValueKey] + ', ';
																}
															}

															if (
																parsedItemValue &&
																parsedItemValue.length > 2 &&
																parsedItemValue.trim().endsWith(',')
															) {
																parsedItemValue = parsedItemValue.substring(
																	0,
																	parsedItemValue.length - 2
																);
															}
														} else {
															if (
																reqDataItemKey === 'Keywords' ||
																reqDataItemKey === 'Services'
															) {
																//itemValue = itemValue.replaceAll('\n', ', ');
															} else if (reqDataItemKey === 'dateCreated') {
																itemValue = moment(itemValue).format(
																	'DD/MM/YYYY HH:mm'
																);
															}

															parsedItemValue = itemValue;
														}
													}

													return (
														<div className="row mb-3 text-start" key={index}>
															<label className="form-label col-2">
																{`${itemToDisplay.label}:`}
															</label>
															<div className="col-6">
																{itemToDisplay.inputType === 'text' && (
																	<input
																		type="text"
																		className="form-control"
																		name={`${itemToDisplay.slug}`}
																		defaultValue={parsedItemValue}
																		readOnly
																	/>
																)}
																{itemToDisplay.inputType === 'textarea' && (
																	<textarea
																		className="form-control"
																		name={`${itemToDisplay.slug}`}
																		defaultValue={parsedItemValue}
																		readOnly
																		rows={5}
																	/>
																)}
															</div>
														</div>
													);
												}
											})}
									</div>
									<div
										className="tab-pane fade p-3"
										id="approved-data-tab-pane"
										role="tabpanel"
										aria-labelledby="approved-data-tab"
										tabIndex="0"
									>
										{requirementsData &&
											Object.keys(requirementsData).map((reqDataItemKey, index) => {
												let itemValue = requirementsData[reqDataItemKey];
												let parsedItemValue = '';

												let itemToDisplay = displayData.find((x) => x.slug === reqDataItemKey);

												if (itemToDisplay) {
													if (itemValue) {
														if (typeof itemValue === 'object') {
															for (var itemValueKey in itemValue) {
																if (itemValue.hasOwnProperty(itemValueKey)) {
																	parsedItemValue += itemValue[itemValueKey] + ', ';
																}
															}

															if (
																parsedItemValue &&
																parsedItemValue.length > 2 &&
																parsedItemValue.trim().endsWith(',')
															) {
																parsedItemValue = parsedItemValue.substring(
																	0,
																	parsedItemValue.length - 2
																);
															}
														} else {
															if (
																reqDataItemKey === 'Keywords' ||
																reqDataItemKey === 'Services'
															) {
																//itemValue = itemValue.replaceAll('\n', ', ');
															} else if (reqDataItemKey === 'dateCreated') {
																itemValue = moment(itemValue).format(
																	'DD/MM/YYYY HH:mm'
																);
															}

															parsedItemValue = itemValue;
														}
													}

													return (
														<div className="row mb-3 text-start" key={index}>
															<label className="form-label col-2">
																{`${itemToDisplay.label}:`}
															</label>
															<div className="col-6">
																{itemToDisplay.inputType === 'text' && (
																	<input
																		type="text"
																		className="form-control"
																		name={`${itemToDisplay.slug}`}
																		defaultValue={parsedItemValue}
																		readOnly
																	/>
																)}
																{itemToDisplay.inputType === 'textarea' && (
																	<textarea
																		className="form-control"
																		name={`${itemToDisplay.slug}`}
																		defaultValue={parsedItemValue}
																		readOnly
																		rows={5}
																	/>
																)}
															</div>
														</div>
													);
												}
											})}
									</div>
									<div
										className="tab-pane fade p-3 w-50"
										id="messages-tab-pane"
										role="tabpanel"
										aria-labelledby="messages-tab"
										tabIndex="0"
									>
										{requirementsMessages && (
											<Fragment>
												{requirementsMessages.map((message, index) => {
													let messageDate = moment(message.dateCreated).format(
														'DD/MM/YYYY HH:mm'
													);

													return (
														<Fragment key={index}>
															<div
																className={`message-wrapper ${message.direction.toLowerCase()}`}
															>
																<div className={`message-details`}>
																	<pre className="mb-0">{message.message}</pre>
																	<div className="message-date">{messageDate}</div>
																</div>
															</div>
														</Fragment>
													);
												})}
											</Fragment>
										)}
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
			{requirementsData &&
			requirementsData.id && (
				<CampaignButtonsContainer
					campaign={campaign}
					showSaveButton={false}
					saveNextButtonText="Send Approved Confirmation"
					onSaveNext={() => handleSendApprovedConfirmation()}
				/>
			)}
		</div>
	);
};

export default CampaignRequirementsScreen;
