import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignsList.css';
import {
	AddCampaignProcess,
	ApproveRequirements,
	CreateCampaign,
	CreateCampaignOrder,
	CreateOrderRequirements,
	DeleteCampaignOrder,
	DeleteReportHistoryItem,
	DeleteRequirements,
	DuplicateCampaignOrder,
	GenerateSEOReport,
	GetActiveCampaigns,
	GetApprovedCampaigns,
	GetCampaign,
	GetCampaignDefaultSettings,
	GetCampaignMessages,
	GetCampaignOrderDetails,
	GetCampaignOrders,
	GetCampaignProcessDetails,
	GetCampaigns,
	GetCampaignsForRunReports,
	GetCanceledCampaigns,
	GetCompletedProcesses,
	GetNewCampaigns,
	GetUserOffers,
	GetReportHistoryListWithSproc,
	GetReportHistoryTrackingLogs,
	GetRunningProcesses,
	GetRequirementDetails,
	GetRequirements,
	GetRequirementsMessages,
	GetSalesUsers,
	GetUsers,
	MarkReadyContentAsCompleted,
	RegisterUser,
	RemoveRunningCampaignProcess,
	RequirementsMessagesMarkAsSeen,
	SendRequirementsForApproval,
	UpdateCampaign,
	UpdateCampaignDefaultSettings,
	UpdateCampaignOrder,
	UpdateCampaignProcess,
	UpdateCampaignReportDate,
	UpdateCampaignStatus,
	UpdateOrderRequirements,
	UpdateReportHistoryRecord,
	GetSystemOffers,
	GetUserPayments,
	GetCampaignTimeZones,
	DeleteCampaign,
	GetQueueProcesses,
	RemoveCampaignProcessById,
	RemoveCampaignReportQueueById
} from '../../../Services';
import { Link, useNavigate } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import { campaignDefaultSettings, getDuration } from '../../../utils/Common';
import { useStateValue } from '../../../StateProvider';
import SendReportDialog from '../../../components/gridReport/sendReportDialog/SendReportDialog';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import GbpSelectScreen from '../../gbpSelectScreen/GbpSelectScreen';
import { RemoveBusiness } from '../../../utils/StorageUtil';
import TableFooter from '../../../components/table/footer/TableFooter';
import GenerateContentDialog from '../../../components/gridReport/generateContentDialog/GenerateContentDialog';
import OffersList from '../../offers/OffersList';
import PaymentsList from '../../payments/PaymentsList';

const CampaignsList_NEW = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingActiveCampaigns, setIsLoadingActiveCampaigns ] = useState(false);
	const [ isLoadingRunningJobs, setIsLoadingRunningJobs ] = useState(false);
	const [ isLoadingQueueJobs, setIsLoadingQueueJobs ] = useState(false);
	const [ isLoadingCompletedJobs, setIsLoadingCompletedJobs ] = useState(false);
	const [ isLoadingHistory, setIsLoadingHistory ] = useState(false);
	const [ isLoadingOrders, setIsLoadingOrders ] = useState(false);
	const [ isLoadingRequirements, setIsLoadingRequirements ] = useState(false);
	const [ allCampaigns, setAllCampaigns ] = useState([]);
	const [ newCampaigns, setNewCampaigns ] = useState([]);
	const [ newCampaignsListView, setNewCampaignsListView ] = useState([]);
	const [ activeCampaigns, setActiveCampaigns ] = useState([]);
	const [ activeCampaignsListView, setActiveCampaignsListView ] = useState([]);
	const [ canceledCampaigns, setCanceledCampaigns ] = useState([]);
	const [ canceledCampaignsListView, setCanceledCampaignsListView ] = useState([]);
	const [ activeOrders, setActiveOrders ] = useState([]);
	const [ activeOrdersForList, setActiveOrdersForList ] = useState([]);
	const [ activeOrdersForSelect, setActiveOrdersForSelect ] = useState([]);
	const [ inProgressOrders, setInProgressOrders ] = useState([]);
	const [ requirements, setRequirements ] = useState([]);
	const [ approvalPendingOrders, setApprovalPendingOrders ] = useState([]);
	const [ approvedOrders, setApprovedOrders ] = useState([]);
	const [ approvedOrdersListView, setApprovedOrdersListView ] = useState([]);
	const [ allProcesses, setAllProcesses ] = useState();
	const [ runningProcesses, setRunningProcesses ] = useState();
	const [ runningReportProcesses, setRunningReportProcesses ] = useState();
	const [ runningContentProcesses, setRunningContentProcesses ] = useState();
	const [ queueReportProcesses, setQueueReportProcesses ] = useState();
	const [ completedProcesses, setCompletedProcesses ] = useState();
	const [ reportQueues, setReportQueues ] = useState();
	const [ showJobDetailsDialog, setShowJobDetailsDialog ] = useState(false);
	const [ jobData, setJobData ] = useState({});
	const [ jobErrorData, setJobErrorData ] = useState([]);
	const [ allReportHistory, setAllReportHistory ] = useState();
	const [ readyReports, setReadyReports ] = useState();
	const [ runReportsCampaigns, setRunReportsCampaigns ] = useState();
	const [ runReportsCampaignsListView, setRunReportsCampaignsListView ] = useState();
	const [ sentReports, setSentReports ] = useState();
	const [ readyContentRecords, setReadyContentRecords ] = useState();
	const [ completedContentRecords, setCompletedContentRecords ] = useState();
	const [ showSendReportDialog, setShowSendReportDialog ] = useState(false);
	const [ { user, host, reportSendToEmail }, dispatch ] = useStateValue();
	const [ editedHistoryRecord, setEditedHistoryRecord ] = useState();
	const defaultCampaignSettings = campaignDefaultSettings();
	const [ selectedCampaignIds, setSelectedCampaignIds ] = useState([]);
	const [ showGenerateContentDialog, setShowGenerateContentDialog ] = useState(false);
	const [ generateContentCampaignId, setGenerateContentCampaignId ] = useState();
	const [ isFilteringList, setIsFilteringList ] = useState(false);
	const [ sortOrder, setSortOrder ] = useState('');
	const [ sortColumn, setSortColumn ] = useState('');
	const [ showViewJobErrorsDialog, setShowViewJobErrorsDialog ] = useState(false);
	const [ showTrackingDetailsDialog, setShowTrackingDetailsDialog ] = useState(false);
	const [ trackingDetailsData, setTrackingDetailsData ] = useState([]);
	const [ showOrderDetailsDialog, setShowOrderDetailsDialog ] = useState(false);
	const [ orderDetailsData, setOrderDetailsData ] = useState({});
	const [ editedOrder, setEditedOrder ] = useState({});
	const [ editedOrderId, setEditedOrderId ] = useState();
	const [ isLoadingDialogDetails, setIsLoadingDialogDetails ] = useState(false);
	const [ isSavingOrderDetails, setIsSavingOrderDetails ] = useState(false);
	const [ isSendingForApproval, setIsSendingForApproval ] = useState(false);
	const [ isApproving, setIsApproving ] = useState(false);
	const [ allUsers, setAllUsers ] = useState([]);
	const [ allUsersForSelect, setAllUsersForSelect ] = useState([]);
	const [ salesReps, setSalesReps ] = useState([]);
	const [ selectedSalesRep, setSelectedSalesRep ] = useState();
	const [ requirementSelectedSalesRep, setRequirementSelectedSalesRep ] = useState();
	const [ showRequirementsDetailsDialog, setShowRequirementsDetailsDialog ] = useState(false);
	const [ requirementsDetailsData, setRequirementsDetailsData ] = useState({});
	const [ requirementsInitialData, setRequirementsInitialData ] = useState();
	const [ editedRequirements, setEditedRequirements ] = useState({});
	const [ showSelectUserDialog, setShowSelectUserDialog ] = useState(false);
	const [ selectedUser, setSelectedUser ] = useState();
	const [ requirementsMessages, setRequirementsMessages ] = useState([]);
	const [ unreadApprovalMessages, setUnreadApprovalMessages ] = useState();
	const [ userDetailsData, setUserDetailsData ] = useState({});
	const [ showCreateNewUserPanel, setShowCreateNewUserPanel ] = useState(false);
	const [ isCreatingNewUserDialog, setIsCreatingNewUserDialog ] = useState(false);
	const [ showApprovalMessagesDialog, setShowApprovalMessagesDialog ] = useState(false);
	const [ showOrderSetupDialog, setShowOrderSetupDialog ] = useState(false);
	const [ approvedOrderSetup, setApprovedOrderSetup ] = useState();
	const [ isLoadingOffers, setIsLoadingOffers ] = useState(true);
	const [ userOffers, setUserOffers ] = useState([]);
	const [ userOffersListView, setUserOffersListView ] = useState([]);
	const [ systemOffers, setSystemOffers ] = useState([]);
	const [ systemOffersListView, setSystemOffersListView ] = useState([]);
	const [ isLoadingPayments, setIsLoadingPayments ] = useState(true);
	const [ pendingPayments, setPendingPayments ] = useState([]);
	const [ pendingPaymentsListView, setPendingPaymentsListView ] = useState([]);
	const [ paidPayments, setPaidPayments ] = useState([]);
	const [ paidPaymentsListView, setPaidPaymentsListView ] = useState([]);
	const [ overDuePayments, setOverDuePayments ] = useState([]);
	const searchListInputRef = useRef();
	const [ nextReportDateSearchFrom, setNextReportDateSearchFrom ] = useState();
	const [ nextReportDateSearchTo, setNextReportDateSearchTo ] = useState();
	const chkShowReadyContentTypeContentRef = useRef();
	const chkShowReadyContentTypeContentUpdateRef = useRef();
	const navigate = useNavigate();
	const [ timeZones, setTimeZones ] = useState([]);
	const [ activeCampaignsTotalCount, setActiveCampaignsTotalCount ] = useState(0);
	const [ activeCampaignsPage, setActiveCampaignsPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState();

	useEffect(() => {
		//redirect user to gbpboost domain
		let currentUrl = window.location.href;

		if (currentUrl.indexOf('localrankreport.com') > -1 && currentUrl.indexOf('dev.localrankreport.com') === -1) {
			let newUrl = currentUrl.replace('localrankreport.com', 'app.gbpboost.com');
			window.location.replace(newUrl);
		}
	}, []);

	useEffect(() => {
		document.title = 'Campaigns List';

		getActiveCampaigns(true, 1);
		//getCanceledCampaigns(true);
		getNewCampaigns(true);
		getCampaignsForRunReports(true);
		getOrders(true);
		getApprovedOrders(true);
		getRequirements(true);
		getOffers(true);
		getSystemOffers(true);
		getPayments(true);
		getReportHistory(true);
		getRunningProcesses(true);
		getQueueProcesses(true);
		getTimeZones();

		let processesInterval = setInterval(() => {
			//getActiveCampaigns();
			//getCanceledCampaigns();
			//getNewCampaigns();
			getOrders();
			getApprovedOrders();
			getRequirements();
			getCampaignsForRunReports();
			getReportHistory();
			getRunningProcesses();
			getQueueProcesses();
		}, 10000);

		return () => {
			clearInterval(processesInterval);
		};
	}, []);

	useEffect(
		() => {
			//set reports count for ready reports history
			if (allCampaigns && allCampaigns.length && allReportHistory && allReportHistory.length) {
				let tempReadyReportHistory = allReportHistory.filter(
					(x) => x.type === 'Report' && x.status === 'Ready'
				);

				if (searchListInputRef.current && searchListInputRef.current.value) {
					tempReadyReportHistory = tempReadyReportHistory.filter((x) =>
						x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
					);
				}

				tempReadyReportHistory = tempReadyReportHistory.sort(function(a, b) {
					return (
						parseInt(a.reportsCount) - parseInt(b.reportsCount) ||
						new Date(b.dateCreated) - new Date(a.dateCreated)
					);
				});

				setReadyReports(tempReadyReportHistory);
			}
		},
		[ allCampaigns, allReportHistory ]
	);

	const filterList = () => {
		if (activeCampaigns && activeCampaigns.length > 0) {
			let tempActiveCampaigns = applyCampaignsSearchFilter(activeCampaigns, true); //{ ...activeCampaigns };
			setActiveCampaignsListView(tempActiveCampaigns);
		}

		if (canceledCampaigns && canceledCampaigns.length > 0) {
			let tempCanceledCampaigns = applyCampaignsSearchFilter(canceledCampaigns);
			setCanceledCampaignsListView(tempCanceledCampaigns);
		}

		if (newCampaigns && newCampaigns.length > 0) {
			let tempNewCampaigns = applyCampaignsSearchFilter(newCampaigns);
			setNewCampaignsListView(tempNewCampaigns);
		}

		if (runReportsCampaigns && runReportsCampaigns.length > 0) {
			let tempRunReportsCampaigns = applyCampaignsSearchFilter(runReportsCampaigns);
			setRunReportsCampaignsListView(tempRunReportsCampaigns);
		}

		if (approvedOrders && approvedOrders.length > 0) {
			let tempApprovedOrders = applyCampaignsSearchFilter(approvedOrders);
			setApprovedOrdersListView(tempApprovedOrders);
		}

		// if (allProcesses && allProcesses.length > 0) {
		// 	setProcessesView(allProcesses);
		// }

		if (runningProcesses && runningProcesses.length > 0) {
			setRunningProcessesView(runningProcesses);
		}

		if (completedProcesses && completedProcesses.length > 0) {
			setCompletedProcessesView(completedProcesses);
		}

		if (allReportHistory && allReportHistory.length > 0) {
			setReportHistoryView(allReportHistory);
		}

		if (userOffers && userOffers.length > 0) {
			let filteredOffers = setOffersView(userOffers);
			setUserOffersListView(filteredOffers);
		}

		if (systemOffers && systemOffers.length > 0) {
			let filteredOffers = setOffersView(systemOffers);
			setSystemOffersListView(filteredOffers);
		}
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			if (searchListInputRef.current.value === '') {
				searchListInputRef.current.classList.remove('has-value');
			} else {
				searchListInputRef.current.classList.add('has-value');
			}
		}

		filterList();
	};

	const onDateFilterChange = (e) => {
		let nextReportDateSearchFromInput = document.querySelector('#nextReportDateSearchFrom');
		if (nextReportDateSearchFromInput) {
			if (nextReportDateSearchFromInput.value === '') {
				nextReportDateSearchFromInput.classList.remove('has-value');
			} else {
				nextReportDateSearchFromInput.classList.add('has-value');
			}
		}

		let nextReportDateSearchToInput = document.querySelector('#nextReportDateSearchTo');
		if (nextReportDateSearchToInput) {
			if (nextReportDateSearchToInput.value === '') {
				nextReportDateSearchToInput.classList.remove('has-value');
			} else {
				nextReportDateSearchToInput.classList.add('has-value');
			}
		}

		if (e.target.name === 'nextReportDateSearchFrom') {
			setNextReportDateSearchFrom(e.target.value);
		} else if (e.target.name === 'nextReportDateSearchTo') {
			setNextReportDateSearchTo(e.target.value);
		}
	};

	const clearDateFilters = () => {
		setNextReportDateSearchFrom();
		setNextReportDateSearchTo();

		let nextReportDateSearchFromInput = document.querySelector('#nextReportDateSearchFrom');
		if (nextReportDateSearchFromInput) {
			nextReportDateSearchFromInput.classList.remove('has-value');
		}

		let nextReportDateSearchToInput = document.querySelector('#nextReportDateSearchTo');
		if (nextReportDateSearchToInput) {
			nextReportDateSearchToInput.classList.remove('has-value');
		}
	};

	useEffect(
		() => {
			let tempActiveCampaigns = applyCampaignsSearchFilter(activeCampaigns, true); //{ ...activeCampaigns };
			setActiveCampaignsListView(tempActiveCampaigns);
		},
		[ nextReportDateSearchFrom, nextReportDateSearchTo ]
	);

	const getActiveCampaigns = async (fullReload = false, page = 1) => {
		if (fullReload) {
			setIsLoadingActiveCampaigns(true);
		}

		let data = {
			searchTerm :
				searchListInputRef.current && searchListInputRef.current.value ? searchListInputRef.current.value : '',
			page       : page
		};

		let response = await GetActiveCampaigns(data);

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response.success) {
			let tempActiveCampaigns = response.data;
			// setActiveCampaignsTotalCount(response.totalCount);
			// setPageSize(response.pageSize);

			tempActiveCampaigns = tempActiveCampaigns.sort((a, b) => a.name.localeCompare(b.name));
			tempActiveCampaigns = applyCampaignsSearchFilter(tempActiveCampaigns, true);

			if (tempActiveCampaigns && tempActiveCampaigns.length > 0) {
				if (sortOrder) {
					if (sortColumn === 'nextReportDate') {
						if (sortOrder === 'asc') {
							tempActiveCampaigns = tempActiveCampaigns.sort(function(a, b) {
								return new Date(b.nextReportDate) - new Date(a.nextReportDate);
							});
						} else if (sortOrder === 'desc') {
							tempActiveCampaigns = tempActiveCampaigns.sort(function(a, b) {
								return new Date(a.nextReportDate) - new Date(b.nextReportDate);
							});
						}
					}
				}
			}

			setActiveCampaigns(tempActiveCampaigns);
			setActiveCampaignsListView(tempActiveCampaigns);
		}

		setIsLoadingActiveCampaigns(false);
	};

	useEffect(
		() => {
			if (activeCampaigns) {
				if (newCampaigns) {
					setAllCampaigns(activeCampaigns.concat(newCampaigns));
				} else {
					setAllCampaigns(activeCampaigns);
				}
			}
		},
		[ activeCampaigns, newCampaigns ]
	);

	const getCanceledCampaigns = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetCanceledCampaigns();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			response = response.sort((a, b) => a.name.localeCompare(b.name));
			response = applyCampaignsSearchFilter(response);

			setCanceledCampaigns(response);
			setCanceledCampaignsListView(response);
		}

		setIsLoading(false);
	};

	const getNewCampaigns = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetNewCampaigns();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			response = response.sort((a, b) => a.name.localeCompare(b.name));
			response = applyCampaignsSearchFilter(response);

			setNewCampaigns(response);
			setNewCampaignsListView(response);
		}

		setIsLoading(false);
	};

	const getCampaignsForRunReports = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetCampaignsForRunReports();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			//response = response.sort((a, b) => a.name.localeCompare(b.name));
			response = applyCampaignsSearchFilter(response);

			setRunReportsCampaigns(response);
			setRunReportsCampaignsListView(response);
		}

		setIsLoading(false);
	};

	const applyCampaignsSearchFilter = (campaignsList, useDateFilter = false) => {
		if (campaignsList) {
			if (searchListInputRef.current && searchListInputRef.current.value) {
				campaignsList = campaignsList.filter((x) => {
					let nameCond = x.name.toLowerCase().includes(searchListInputRef.current.value.toLowerCase());
					let emailCond =
						x.email && x.email !== ''
							? x.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false;
					let userEmailCond =
						x.userEmail && x.userEmail !== ''
							? x.userEmail.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false;

					return nameCond || emailCond || userEmailCond;
				});
			}

			if (useDateFilter) {
				if (nextReportDateSearchFrom) {
					campaignsList = campaignsList.filter((x) => {
						return (
							x.nextReportDate &&
							moment(x.nextReportDate).isSameOrAfter(moment(nextReportDateSearchFrom), 'day')
						);
					});
				}

				if (nextReportDateSearchTo) {
					campaignsList = campaignsList.filter((x) => {
						return (
							x.nextReportDate &&
							moment(x.nextReportDate).isSameOrBefore(moment(nextReportDateSearchTo), 'day')
						);
					});
				}
			}
		}

		return campaignsList;
	};

	const getRunningProcesses = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingRunningJobs(true);
		}

		let response = await GetRunningProcesses();

		if (response && response.length > 0) {
			setRunningProcessesView(response);
		} else {
			setRunningProcesses();
			setRunningReportProcesses();
			setRunningContentProcesses();
			setReportQueues();
		}

		setIsLoadingRunningJobs(false);
	};

	const setRunningProcessesView = (processesList) => {
		if (searchListInputRef.current && searchListInputRef.current.value) {
			processesList = processesList.filter((x) =>
				x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		let tempRunningProcesses = processesList;
		setRunningProcesses(tempRunningProcesses);
		setRunningReportProcesses(tempRunningProcesses.filter((x) => x.type === 'Report'));
		setRunningContentProcesses(
			tempRunningProcesses.filter((x) => x.type === 'Content' || x.type === 'Content Update')
		);
		setReportQueues(tempRunningProcesses.filter((x) => x.isAutoQueueProcess === true));
	};

	const getQueueProcesses = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingQueueJobs(true);
		}

		let response = await GetQueueProcesses();

		if (response && response.length > 0) {
			setQueueProcessesView(response);
		} else {
			setQueueReportProcesses();
		}

		setIsLoadingQueueJobs(false);
	};

	const setQueueProcessesView = (processesList) => {
		if (searchListInputRef.current && searchListInputRef.current.value) {
			processesList = processesList.filter((x) =>
				x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		let tempQueueProcesses = processesList;
		setQueueReportProcesses(tempQueueProcesses.filter((x) => x.type === 'Report'));
		//setQueueAutoReports(tempQueueProcesses.filter((x) => x.isAutoQueueProcess === true));
	};

	const getCompletedProcesses = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingCompletedJobs(true);
		}

		let response = await GetCompletedProcesses();

		if (response && response.length > 0) {
			setCompletedProcessesView(response);
		} else {
			setCompletedProcesses();
		}

		setIsLoadingCompletedJobs(false);
	};

	const setCompletedProcessesView = (processesList) => {
		if (searchListInputRef.current && searchListInputRef.current.value) {
			processesList = processesList.filter((x) =>
				x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		let tempCompletedProcesses = processesList.filter((x) => x.status !== 'Running');
		tempCompletedProcesses = tempCompletedProcesses.sort(function(a, b) {
			return new Date(b.startDate) - new Date(a.startDate);
		});
		setCompletedProcesses(tempCompletedProcesses);
	};

	const getOrders = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingOrders(true);
		}

		let response = await GetCampaignOrders();

		if (response.IsSuccessful === false) {
		}

		if (response.success && response.data) {
			//get only orders that are not applied to any requirements
			let tempOrders = response.data;

			tempOrders = tempOrders.sort(function(a, b) {
				return new Date(a.dateCreated) - new Date(b.dateCreated);
			});

			setOrdersView(tempOrders);
		}

		setIsLoadingOrders(false);
	};

	const getApprovedOrders = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetApprovedCampaigns();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			response = response.sort((a, b) => a.name.localeCompare(b.name));
			response = applyCampaignsSearchFilter(response);

			setApprovedOrders(response);
			setApprovedOrdersListView(response);
		}

		setIsLoading(false);
	};

	const setOrdersView = (ordersList) => {
		let tempActiveOrders;

		if (ordersList) {
			if (searchListInputRef.current && searchListInputRef.current.value) {
				ordersList = ordersList.filter(
					(x) =>
						(x.firstName
							? x.firstName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false) ||
						(x.lastName
							? x.lastName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false) ||
						(x.email
							? x.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false) ||
						(x.subscriptionID
							? x.subscriptionID.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false) ||
						(x.salesRep !== null
							? x.salesRep.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase()) ||
								x.salesRep.firstName
									.toLowerCase()
									.includes(searchListInputRef.current.value.toLowerCase()) ||
								x.salesRep.lastName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: true)
				);
			}

			tempActiveOrders = ordersList.filter((x) => x.status === 'Active');
		}

		setActiveOrders(tempActiveOrders || []);
		setActiveOrdersForList(
			tempActiveOrders ? tempActiveOrders.filter((x) => x.isAppliedToRequirements === false) : []
		); //orders not applied to requirement

		// //orders applied to requirement
		// let tempInProgressOrders = tempActiveOrders
		// 	? tempActiveOrders.filter((x) => x.isAppliedToRequirements === true)
		// 	: [];
		// tempInProgressOrders = tempInProgressOrders.sort(function(a, b) {
		// 	return new Date(b.dateCreated) - new Date(a.dateCreated);
		// });
		// setInProgressOrders(tempInProgressOrders);
	};

	const getRequirements = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingRequirements(true);
		}

		let response = await GetRequirements();

		if (response.IsSuccessful === false) {
		}

		if (response.success && response.data) {
			let tempRequirements = response.data;

			tempRequirements = tempRequirements.sort(function(a, b) {
				return new Date(a.dateCreated) - new Date(b.dateCreated);
			});

			if (tempRequirements) {
				if (searchListInputRef.current && searchListInputRef.current.value) {
					tempRequirements = tempRequirements.filter(
						(x) =>
							(x.company
								? x.company.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
								: false) ||
							(x.fullName
								? x.fullName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
								: false)
					);
				}

				setRequirements(tempRequirements.filter((x) => x.status === 'Active'));
			}

			let tempApprovalPendingOrders = tempRequirements
				? tempRequirements.filter((x) => x.status === 'Approval')
				: [];

			setApprovalPendingOrders(tempApprovalPendingOrders);

			let tempUnreadApprovalMessages;
			for (let i = 0; i < tempApprovalPendingOrders.length; i++) {
				if (tempApprovalPendingOrders[i].unreadMessagesCount) {
					if (!tempUnreadApprovalMessages) {
						tempUnreadApprovalMessages = 0;
					}
					tempUnreadApprovalMessages += parseInt(tempApprovalPendingOrders[i].unreadMessagesCount);
				}
			}
			setUnreadApprovalMessages(tempUnreadApprovalMessages);
		}

		setIsLoadingRequirements(false);
	};

	const getReportHistory = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingHistory(true);
		}

		let response = await GetReportHistoryListWithSproc();

		if (response && response.length > 0) {
			setReportHistoryView(response);
		} else {
			setReadyReports();
			setSentReports();
			setReadyContentRecords();
			setCompletedContentRecords();
		}

		setIsLoadingHistory(false);
	};

	const setReportHistoryView = (historyList) => {
		setAllReportHistory(historyList);

		if (searchListInputRef.current && searchListInputRef.current.value) {
			historyList = historyList.filter((x) =>
				x.campaignName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		//reports ---------------
		let tempSentReportHistory = historyList.filter((x) => x.type === 'Report' && x.status === 'Sent');
		tempSentReportHistory = tempSentReportHistory.sort(function(a, b) {
			return new Date(b.dateSent) - new Date(a.dateSent);
		});

		setSentReports(tempSentReportHistory);

		//content ----------------
		let tempReadyContentHistory = historyList.filter(
			(x) => (x.type === 'Content' || x.type === 'Content Update') && x.status === 'Ready'
		);

		if (chkShowReadyContentTypeContentRef.current && !chkShowReadyContentTypeContentRef.current.checked) {
			tempReadyContentHistory = tempReadyContentHistory.filter((x) => x.type !== 'Content');
		}
		if (
			chkShowReadyContentTypeContentUpdateRef.current &&
			!chkShowReadyContentTypeContentUpdateRef.current.checked
		) {
			tempReadyContentHistory = tempReadyContentHistory.filter((x) => x.type !== 'Content Update');
		}

		tempReadyContentHistory = tempReadyContentHistory.sort(function(a, b) {
			return new Date(b.dateCreated) - new Date(a.dateCreated);
		});

		let tempCompletedContentHistory = historyList.filter(
			(x) => (x.type === 'Content' || x.type === 'Content Update') && x.status === 'Completed'
		);
		tempCompletedContentHistory = tempCompletedContentHistory.sort(function(a, b) {
			return new Date(b.dateSent) - new Date(a.dateSent);
		});

		setReadyContentRecords(tempReadyContentHistory);
		setCompletedContentRecords(tempCompletedContentHistory);
	};

	const getOffers = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingOffers(true);
		}

		let response = await GetUserOffers();

		if (response && response.length) {
			let tempOffers = setOffersView(response);

			setUserOffers(tempOffers);
			setUserOffersListView(tempOffers);
		} else {
			setUserOffers();
			setUserOffersListView();
		}

		setIsLoadingOffers(false);
	};

	const getSystemOffers = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingOffers(true);
		}

		let response = await GetSystemOffers();

		if (response && response.length) {
			setSystemOffers(response);
			setSystemOffersListView(response);
		} else {
			setSystemOffers();
			setSystemOffersListView();
		}

		setIsLoadingOffers(false);
	};

	const setOffersView = (offersList) => {
		if (searchListInputRef.current && searchListInputRef.current.value) {
			offersList = offersList.filter(
				(x) =>
					x.name.toLowerCase().includes(searchListInputRef.current.value.toLowerCase()) ||
					(x.user
						? x.user.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
						: false)
			);
		}

		//sort offers by date desc
		offersList = offersList.sort(function(a, b) {
			return new Date(b.dateCreated) - new Date(a.dateCreated);
		});

		return offersList;
	};

	const getPayments = async (fullReload = false) => {
		if (fullReload) {
			setIsLoadingPayments(true);
		}

		let response = await GetUserPayments();

		if (response && response.length) {
			let tempPendingPayments = response.filter((x) => {
				return x.status !== 'Paid';
			});
			let tempPaidPayments = response.filter((x) => {
				return x.status === 'Paid';
			});

			//sort pending by DueDate
			tempPendingPayments = tempPendingPayments.sort(function(a, b) {
				return new Date(b.dueDate) - new Date(a.dueDate);
			});

			//sort paid by ProcessedDate
			tempPaidPayments = tempPaidPayments.sort(function(a, b) {
				return new Date(b.processedDate) - new Date(a.processedDate);
			});

			setPendingPayments(tempPendingPayments);
			setPendingPaymentsListView(tempPendingPayments);

			setPaidPayments(tempPaidPayments);
			setPaidPaymentsListView(tempPaidPayments);

			setOverDuePayments(
				tempPendingPayments.filter((x) => {
					return x.dueDate && moment(x.dueDate).isSameOrBefore(moment(), 'day');
				})
			);
		} else {
			setPendingPayments();
			setPendingPaymentsListView();
			setPaidPayments();
			setPaidPaymentsListView();
		}

		setIsLoadingPayments(false);
	};

	const getTimeZones = async () => {
		let response = await GetCampaignTimeZones();

		if (response && response.data && response.data.length) {
			setTimeZones(response.data);
		}
	};

	const getTimezineAbbr = (timeZoneName) => {
		if (timeZones && timeZoneName) {
			let tz = timeZones.find((x) => x.value === timeZoneName);

			return tz ? tz.abbr : timeZoneName;
		}

		return timeZoneName;
	};

	const isCampaignProcessRunning = (id, type) => {
		if (type) {
			if (
				(runningReportProcesses &&
					runningReportProcesses.find(
						(x) => x.campaignId === id && x.type === type && x.isAutoQueueProcess === false
					)) ||
				(runningContentProcesses &&
					runningContentProcesses.find(
						(x) => x.campaignId === id && x.type === type && x.isAutoQueueProcess === false
					))
			) {
				return true;
			}
		} else {
			return (
				runningProcesses && runningProcesses.find((x) => x.campaignId === id && x.isAutoQueueProcess === false)
			);
		}

		return false;
	};

	const isCampaignReportQueueRunning = (id) => {
		return reportQueues && reportQueues.find((x) => x.campaignId === id && x.isAutoQueueProcess === true);
	};

	const getElapsedTime = (startDate) => {
		startDate = new Date(startDate);
		let now = new Date();
		now = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));

		let momentStartDate = moment(startDate, 'DD/MM/YYYY HH:mm:ss');
		let momentNow = moment(now, 'DD/MM/YYYY HH:mm:ss');

		return getDuration(momentStartDate, momentNow);
	};

	const getJobDetails = async (id, historyContentId) => {
		let response = await GetCampaignProcessDetails(id);

		if (response) {
			let tempJob = {};
			tempJob.campaign = response.campaignName;
			tempJob.type = response.type;

			if (historyContentId) {
				tempJob.historyContentItemId = historyContentId;
			}

			if (response.data) {
				let tempJobData = JSON.parse(response.data);

				tempJob = { ...tempJob, ...tempJobData };

				setJobErrorData(tempJobData.error);
			}

			setJobData(tempJob);
		}
	};

	const toggleJobDetailsDialog = async (e, id, historyContentId = null) => {
		if (e) {
			e.preventDefault();
		}

		setShowJobDetailsDialog(!showJobDetailsDialog);

		if (!showJobDetailsDialog) {
			getJobDetails(id, historyContentId);
		} else {
			setJobData({});
			setJobErrorData({});
		}
	};

	const toggleViewJobErrorsDialog = async (id) => {
		setShowViewJobErrorsDialog(!showViewJobErrorsDialog);

		if (!showJobDetailsDialog) {
			getJobDetails(id, null);
		} else {
			setJobData({});
			setJobErrorData({});
		}
	};

	const stopJob = async (campaignId, type) => {
		if (window.confirm('Are you sure that you want to stop the running job?')) {
			let data = {
				campaignId : campaignId,
				type       : type,
				status     : 'Stopped'
			};

			const response = await UpdateCampaignProcess(campaignId, data);

			if (response.success) {
				getRunningProcesses(true);
			} else {
				console.error(response);
			}
		}
	};

	const deleteQueueJob = async (id, isAutoReportQueue) => {
		if (window.confirm('Are you sure that you want to remove the job from queue?')) {
			let response;

			if (isAutoReportQueue) {
				response = await RemoveCampaignReportQueueById(id);
			} else {
				response = await RemoveCampaignProcessById(id);
			}

			if (response) {
				getQueueProcesses(true);
			} else {
				console.error(response);
			}
		}
	};

	const handleReadyReportUrl = (reportUrl, historyRecordId) => {
		let finalUrl = '';

		if (reportUrl) {
			reportUrl = reportUrl.replace('https://app.gbpboost.com', host);

			if (reportUrl.indexOf('?') < 0) {
				finalUrl = `${reportUrl}?hid=${historyRecordId}`;
			} else {
				finalUrl = `${reportUrl}&hid=${historyRecordId}`;
			}
		}

		return finalUrl;
	};

	const toggleSendReportDialog = async (reportHistory) => {
		setShowSendReportDialog(!showSendReportDialog);

		if (reportHistory) {
			setEditedHistoryRecord(reportHistory);
		}
	};

	const generateReports = async () => {
		if (!selectedCampaignIds || selectedCampaignIds.length === 0) {
			toast.error('You have to select at least one campaign');
			return;
		} else {
			if (window.confirm('Are you sure that you want to generate reports for selected campaigns?')) {
				await Promise.all(
					selectedCampaignIds.map(async (currentCampaignId) => {
						let data = {
							id            : currentCampaignId,
							runGridReport : true,
							runRankReport : true,
							gridApiType   : 'dseo',
							rankApiType   : 'dseo'
						};

						await AddCampaignProcess(currentCampaignId, {
							campaignId : currentCampaignId,
							type       : 'Report',
							status     : 'Running',
							data       : JSON.stringify(data)
						});

						const response = await GenerateSEOReport(data);

						if (response.success) {
							//set next report date as 1 month from now
							await UpdateCampaignReportDate(currentCampaignId, {
								nextReportDate : moment().add(1, 'months')
							});
						} else {
							//remove current process
							await RemoveRunningCampaignProcess(currentCampaignId, {
								campaignId : currentCampaignId,
								type       : 'Report'
							});

							//toast.error(response.message);
							console.error(response.message);
						}
					})
				);
			}
		}
	};

	const handleSelectCampaignChange = async (e) => {
		const { name, checked } = e.target;
		let tempSelectedCampaignIds = selectedCampaignIds;

		if (checked) {
			tempSelectedCampaignIds.push(name.replace('chk_', ''));
		} else {
			tempSelectedCampaignIds = tempSelectedCampaignIds.filter((x) => x !== name.replace('chk_', ''));
		}

		setSelectedCampaignIds(tempSelectedCampaignIds);
	};

	const handleDeleteReportHistoryItem = async (id) => {
		if (window.confirm('Are you sure that you want to delete this record?')) {
			let response = await DeleteReportHistoryItem(id);

			if (response.success) {
				getReportHistory();
			}
		}
	};

	const toggleGenerateContentDialog = (campaignId) => {
		setShowGenerateContentDialog(!showGenerateContentDialog);

		if (!showGenerateContentDialog && campaignId) {
			setGenerateContentCampaignId(campaignId);
		} else if (showGenerateContentDialog) {
			setSelectedCampaignIds({});
			setGenerateContentCampaignId();
		}
	};

	const markContentRecordAsCompleted = async (id, campaignId, checked) => {
		if (checked) {
			//setIsSubmitting(true);

			let data = {
				campaignId : campaignId,
				status     : 'Completed',
				dateSent   : new Date()
			};

			const response = await UpdateReportHistoryRecord(id, data);

			if (response.success) {
				toast.success('Content record set as completed successfully.');

				getReportHistory(true);
			}

			toggleJobDetailsDialog(null, id);
			//setIsSubmitting(false);
		}
	};

	const sortActiveCampaignsOnColumnClick = (column) => {
		if (activeCampaigns) {
			let tempActiveCampaigns = activeCampaigns;
			let currentSortOrder = sortOrder;
			let newSortOrder = '';
			let tempSortColumn = '';

			if (column === 'nextReportDate') {
				if (currentSortOrder === '') {
					newSortOrder = 'asc';
					tempSortColumn = 'nextReportDate';

					tempActiveCampaigns = tempActiveCampaigns.sort(function(a, b) {
						return new Date(b.nextReportDate) - new Date(a.nextReportDate);
					});
				} else if (currentSortOrder === 'asc') {
					newSortOrder = 'desc';
					tempSortColumn = 'nextReportDate';

					tempActiveCampaigns = tempActiveCampaigns.sort(function(a, b) {
						return new Date(a.nextReportDate) - new Date(b.nextReportDate);
					});
				} else if (currentSortOrder === 'desc') {
					newSortOrder = '';
					tempSortColumn = '';
				}

				setSortOrder(newSortOrder);
				setSortColumn(tempSortColumn);
				setActiveCampaignsListView(tempActiveCampaigns);
			}
		}
	};

	const getReportTrackingLogs = async (id) => {
		const response = await GetReportHistoryTrackingLogs(id);

		if (response) {
			setTrackingDetailsData(response);
		}
	};

	const toggleTrackingDetailsDialog = (report) => {
		setShowTrackingDetailsDialog(!showTrackingDetailsDialog);

		if (!showTrackingDetailsDialog) {
			getReportTrackingLogs(report.id);
		} else {
			setTrackingDetailsData([]);
		}
	};

	const toggleOrderDetailsDialog = (e, order) => {
		if (e) e.preventDefault();

		setShowOrderDetailsDialog(!showOrderDetailsDialog);

		if (!showOrderDetailsDialog) {
			setIsLoadingDialogDetails(true);

			getSalesUsers();

			if (order && order.id) {
				setEditedOrderId(order.id);
				// setOrderDetailsData(order);
			} else {
				setIsLoadingDialogDetails(false);
			}
		} else {
			setEditedOrderId();
			setOrderDetailsData({});
		}
	};

	useEffect(
		() => {
			if (showOrderDetailsDialog && salesReps) {
				if (editedOrderId) {
					getOrderDetails(editedOrderId);
				} else {
					setSelectedSalesRep(salesReps.find((x) => x.label === reportSendToEmail));
				}
			}
		},
		[ showOrderDetailsDialog, salesReps, editedOrderId ]
	);

	const handleOrderDetailsDataChange = async (e) => {
		setOrderDetailsData({
			...orderDetailsData,
			[e.target.name]: e.target.value
		});
	};

	const getUsers = async () => {
		let response = await GetUsers();

		if (response && response.length) {
			//sort users alphabetically
			response = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

			let tempAllUsers = response;

			let tempUsersForSelect = new Array();
			for (let i = 0; i < tempAllUsers.length; i++) {
				tempUsersForSelect.push({
					value : response[i].id,
					label : `${response[i].firstName || ''} ${response[i].lastName || ''} (${response[i].email})`
				});
			}

			setAllUsers(tempAllUsers);
			setAllUsersForSelect(tempUsersForSelect);
		}

		setIsLoadingDialogDetails(false);
	};

	const getSalesUsers = async () => {
		let response = await GetSalesUsers();

		if (response && response.length) {
			//sort users alphabetically
			let tempSalesReps = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

			let tempSalesRepsForSelect = new Array();
			for (let i = 0; i < tempSalesReps.length; i++) {
				tempSalesRepsForSelect.push({
					value : response[i].id,
					label : response[i].email
				});
			}

			setSalesReps(tempSalesRepsForSelect);
		}
	};

	const getOrderDetails = async (id) => {
		let response = await GetCampaignOrderDetails(id);

		if (response.success) {
			let tempOrder = response.data;

			if (salesReps) {
				if (tempOrder.salesRepId) {
					setSelectedSalesRep(salesReps.find((x) => x.value === tempOrder.salesRepId));
				} else {
					setSelectedSalesRep(salesReps.find((x) => x.label === reportSendToEmail));
				}
			}

			setOrderDetailsData(tempOrder);
		}

		setIsLoadingDialogDetails(false);
	};

	const saveOrderDetails = async () => {
		let isValid = document.querySelector('.order-details-form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSavingOrderDetails(true);

		let data = {
			...orderDetailsData,
			salesRepId : selectedSalesRep.value
		};

		toggleOrderDetailsDialog();
		setIsSavingOrderDetails(false);

		let response;

		if (orderDetailsData.id) {
			response = await UpdateCampaignOrder(orderDetailsData.id, data);
		} else {
			response = await CreateCampaignOrder(data);
		}

		if (response.success) {
			toast.success(`Order ${orderDetailsData.id ? 'updated' : 'created'} successfully`);
			getOrders(true);
		} else {
			let error = response && response.message ? response.message : 'Something went wrong.';

			//toast.error(error);
			console.error(error);
		}

		setIsSavingOrderDetails(false);
	};

	const duplicateOrder = async (id) => {
		if (window.confirm('Are you sure that you want to duplicate this order?')) {
			setIsSavingOrderDetails(true);

			let response = await DuplicateCampaignOrder(id);

			if (response.success) {
				toggleOrderDetailsDialog();
				toast.success(`Order duplicated successfully`);

				getOrders(true);
			} else {
				let error = response && response.message ? response.message : 'Something went wrong.';

				//toast.error(error);
				console.error(error);
			}

			setIsSavingOrderDetails(false);
		}
	};

	const toggleRequirementsDetailsDialog = async (requirements) => {
		setShowRequirementsDetailsDialog(!showRequirementsDetailsDialog);

		if (!showRequirementsDetailsDialog) {
			setIsLoadingDialogDetails(true);

			getSalesUsers();

			if (requirements && requirements.id) {
				setActiveOrdersForSelect(
					activeOrders.filter((x) => !x.isAppliedToRequirements || x.id === requirements.orderId)
				);

				setEditedRequirements(requirements);
			} else {
				setActiveOrdersForSelect(activeOrders.filter((x) => !x.isAppliedToRequirements));

				setIsLoadingDialogDetails(false);
			}
		} else {
			setRequirementsDetailsData({});
			setRequirementsInitialData();
			setOrderDetailsData({});
			setSelectedUser();
		}
	};

	useEffect(
		() => {
			if (showRequirementsDetailsDialog && salesReps && requirementsDetailsData) {
				if (editedRequirements && editedRequirements.id) {
					getRequirementsDetails(editedRequirements.id);
				} else {
					setRequirementSelectedSalesRep(salesReps.find((x) => x.label === reportSendToEmail));
				}
			}
		},
		[ showRequirementsDetailsDialog, salesReps, editedRequirements ]
	);

	const getRequirementsDetails = async (id) => {
		let response = await GetRequirementDetails(id);

		if (response) {
			let tempRequirements = response;

			if (tempRequirements.order) {
				setOrderDetailsData(tempRequirements.order);
			}

			if (salesReps) {
				if (tempRequirements.order && tempRequirements.order.salesRepId) {
					setRequirementSelectedSalesRep(
						salesReps.find((x) => x.value === tempRequirements.order.salesRepId)
					);
				} else {
					setRequirementSelectedSalesRep(salesReps.find((x) => x.label === reportSendToEmail));
				}
			}

			tempRequirements.id = id;
			tempRequirements.order = tempRequirements.orderId;

			if (tempRequirements.user) {
				setSelectedUser({
					value : tempRequirements.user.id,
					label : `${tempRequirements.user.firstName || ''} ${tempRequirements.user.lastName ||
						''} (${tempRequirements.user.email})`,
					email : tempRequirements.user.email
				});
			}

			setRequirementsDetailsData(tempRequirements);

			if (tempRequirements.initialData) {
				//let parsedInitialData = JSON.parse(tempRequirements.initialData.replace(/\"/g, '"'));
				let parsedInitialData = JSON.parse(tempRequirements.initialData.replace(/\\/g, ''));

				setRequirementsInitialData(parsedInitialData);
			}
		}

		setIsLoadingDialogDetails(false);
	};

	const handleRequirementsDetailsDataChange = async (e) => {
		let name = e.target.name;

		if (name === 'req_order') {
			setRequirementsDetailsData({
				...requirementsDetailsData,
				[name.replace('req_', '')]: e.target.value
			});
		} else {
			setRequirementsDetailsData({
				...requirementsDetailsData,
				[name.replace('req_', '')]: e.target.value
			});
		}
	};

	const saveRequirementsDetails = async (sendForApproval) => {
		if (!requirementsDetailsData.company || requirementsDetailsData.company.trim() === '') {
			toast.error('Company is required field');
			return;
		}

		if (sendForApproval) {
			let isValid = document.querySelector('.requirements-details-form').reportValidity();

			if (!isValid) {
				return;
			}

			setIsSendingForApproval(true);
		} else {
			setIsSavingOrderDetails(true);
		}

		let tempRequirementsDetailsData = requirementsDetailsData;

		let data = {
			...tempRequirementsDetailsData
			// orderId : tempRequirementsDetailsData.order,
			// userId  : selectedUser && selectedUser.value !== '0' ? selectedUser.value : null
		};

		if (sendForApproval) {
			data.status = 'Approval';
		}

		let response;

		if (tempRequirementsDetailsData.id) {
			response = await UpdateOrderRequirements(tempRequirementsDetailsData.id, data);
		} else {
			response = await CreateOrderRequirements(data);

			if (response && response.data && response.data.id) {
				await getRequirementsDetails(response.data.id);
			}
		}

		if (response.success) {
			//update order sales rep
			if (orderDetailsData && orderDetailsData.id && orderDetailsData.id === tempRequirementsDetailsData.order) {
				let dataOrder = {
					...orderDetailsData,
					salesRepId : requirementSelectedSalesRep.value
				};

				await UpdateCampaignOrder(orderDetailsData.id, dataOrder);
			}

			//reload the list
			getRequirements(true);

			if (sendForApproval) {
				let responseApproval = await SendRequirementsForApproval(tempRequirementsDetailsData.id);

				if (responseApproval.success) {
					toast.success(`Requirements sent to approval successfully`);
				} else {
					let error =
						responseApproval && responseApproval.message
							? responseApproval.message
							: 'Something went wrong.';

					toast.error(error);
				}
			} else {
				toast.success(`Requirements ${tempRequirementsDetailsData.id ? 'updated' : 'created'} successfully`);
			}
		} else {
			let error = response && response.message ? response.message : 'Something went wrong.';

			//console.error(error);
			toast.error(error);
		}

		if (sendForApproval) {
			toggleRequirementsDetailsDialog();
			setIsSendingForApproval(false);
		} else {
			setIsSavingOrderDetails(false);
		}
	};

	const viewRequirementsMessages = async (id) => {
		let response = await GetRequirementsMessages(id);

		if (response.success && response.data) {
			//sort messages from latest to oldest
			let tempMessages = response.data.sort(function(a, b) {
				return new Date(b.dateCreated) - new Date(a.dateCreated);
			});

			setRequirementsMessages(tempMessages);
		}
	};

	const markMessagesAsSeen = async (id) => {
		let response = await RequirementsMessagesMarkAsSeen(id);

		if (response.success) {
			getRequirements();
		}
	};

	const toggleRequirementsMessagesDialog = (id) => {
		setShowApprovalMessagesDialog(!showApprovalMessagesDialog);

		if (!showApprovalMessagesDialog) {
			markMessagesAsSeen(id);
			viewRequirementsMessages(id);
		}
	};

	const toggleSelectUserDialog = (e) => {
		if (e) {
			e.preventDefault();
		}

		setUserDetailsData({});
		setShowCreateNewUserPanel(false);
		setShowSelectUserDialog(!showSelectUserDialog);

		if (!showSelectUserDialog) {
			setIsLoadingDialogDetails(true);

			getUsers();

			//if order was selected, pre-populate details with order data
			if (requirementsDetailsData.order) {
				//get order data
				let selectedOrder = activeOrdersForSelect.find((x) => x.value === requirementsDetailsData.order);

				if (selectedOrder) {
					userDetailsData.firstName = selectedOrder.firstName;
					userDetailsData.lastName = selectedOrder.lastName;
					userDetailsData.email = selectedOrder.email;
					userDetailsData.phoneNumber = selectedOrder.phoneNumber;
				}
			}

			setIsLoadingDialogDetails(false);
		} else {
			//setOrderDetailsData({});
		}
	};

	const handleUserDetailsDataChange = async (e) => {
		setUserDetailsData({
			...userDetailsData,
			[e.target.name.replace('userDetails_', '')]: e.target.value
		});
	};

	const handleUserSelectDataChange = async (user) => {
		if (user) {
			setSelectedUser(user);

			//fill form data
			let tempUserDetailsData = userDetailsData;

			let currentUser = allUsers.find((x) => x.id === user.value);

			if (currentUser) {
				tempUserDetailsData.firstName = currentUser.firstName;
				tempUserDetailsData.lastName = currentUser.lastName;
				tempUserDetailsData.email = currentUser.email;
				tempUserDetailsData.phoneNumber = currentUser.phoneNumber;
			}
		}
	};

	const saveUserDetails = async () => {
		let isValid = document.querySelector('.user-details-form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsCreatingNewUserDialog(true);

		let data = {
			...userDetailsData,
			password : 'pa$$w0rd',
			status   : 'Active'
		};

		let response = await RegisterUser(data);

		if (response && response.id) {
			setSelectedUser({
				value : response.id,
				label : `${response.firstName || ''} ${response.lastName || ''} (${response.email})`
			});
			//toast.success(`Order ${orderDetailsData.id ? 'updated' : 'created'} successfully`);
		} else if (response && !response.IsSuccessful) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.data &&
				response.Message.response.data.length
			) {
				let errorMessage = response.Message.response.data[0];

				if (errorMessage.indexOf('already taken') > -1) {
					let existingUser = allUsers.find((x) => x.email === userDetailsData.email);

					if (existingUser) {
						setSelectedUser({
							value : existingUser.id,
							label : `${existingUser.firstName || ''} ${existingUser.lastName ||
								''} (${existingUser.email})`
						});
					}
				}
			}
		} else {
			let error = response && response.message ? response.message : 'Something went wrong.';

			//toast.error(error);
			console.error(error);
		}

		setIsCreatingNewUserDialog(false);
		setShowCreateNewUserPanel(false);
	};

	const toggleApprovedOrderSetupDialog = async (approvedOrder) => {
		setShowOrderSetupDialog(!showOrderSetupDialog);

		if (!showOrderSetupDialog) {
			setApprovedOrderSetup(approvedOrder);

			if (approvedOrder && approvedOrder.requirementId) {
				await getRequirementsDetails(approvedOrder.requirementId);
			}
		} else {
			RemoveBusiness();
		}
	};

	const setOrderAsApproved = async () => {
		if (window.confirm('This will set requirements as APPROVED. Are you sure that you want to continue?')) {
			setIsApproving(true);

			const response = await ApproveRequirements(requirementsDetailsData.id);

			if (response.success) {
				getRequirements(true);

				toast.success(`Requirements set as approved successfully`);
			} else {
				let error = response && response.message ? response.message : 'Something went wrong.';

				//toast.error(error);
				console.error(error);
			}

			toggleRequirementsDetailsDialog();
			setIsApproving(false);
		}
	};

	const setOrderForApproval = async () => {
		if (
			window.confirm('This will set requirements for customer approval. Are you sure that you want to continue?')
		) {
			setIsSendingForApproval(true);

			let data = {
				status : 'Approval'
			};

			const response = await UpdateOrderRequirements(approvedOrderSetup.id, data);

			if (response.success) {
				getRequirements(true);

				toast.success(`Requirements set for aproval successfully`);
			} else {
				let error = response && response.message ? response.message : 'Something went wrong.';

				//toast.error(error);
				console.error(error);
			}

			toggleApprovedOrderSetupDialog();
			setIsSendingForApproval(false);
		}
	};

	const markAllReadyContentAsCompleted = async () => {
		if (!readyContentRecords || readyContentRecords.length === 0) {
			toast.error('No records in the list.');
			return;
		}

		if (window.confirm('Are you sure that you want to set all items in the list as Completed?')) {
			let ids = [];
			readyContentRecords.forEach((content) => {
				ids.push(content.id);
			});

			if (ids.length > 0) {
				let data = { ids: ids };

				let response = await MarkReadyContentAsCompleted(data);

				if (response) {
					toast.success(`${readyContentRecords.length} records set as completed successfully`);

					getReportHistory(true);
				}
			}
		}
	};

	const handleContentCheckboxChange = () => {
		if (allReportHistory && allReportHistory.length > 0) {
			setReportHistoryView(allReportHistory);
		}
	};

	const handleDeleteApprovedOrder = async (id) => {
		if (window.confirm('Are you sure that you want to delete approved order?')) {
			const response = await DeleteCampaign(id);

			if (response.success) {
				toast.success('Order deleted successfully');

				getApprovedOrders();
			}
		}
	};

	const handleDeleteCampaignOrder = async (id) => {
		if (window.confirm('Are you sure that you want to delete this order?')) {
			let response = await DeleteCampaignOrder(id);

			if (response) {
				toast.success('Order deleted successfully.');
				getOrders(true);
			}
		}
	};

	const handleDeleteRequirements = async (id) => {
		if (window.confirm('Are you sure that you want to delete requirements?')) {
			let response = await DeleteRequirements(id);

			if (response.success) {
				toast.success('Requirements deleted successfully.');
				getRequirements(true);
			} else {
				toast.error(response.message);
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid p-3">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							headline="Campaigns"
							showSearchBox={true}
							buttonsHtml={
								<Fragment>
									{user &&
									(user.isAdmin || user.isSalesUser) && (
										<div className="d-flex justify-content-end">
											<div className="search-list-wrapper align-self-start">
												<input
													type="search"
													className="form-control"
													name="searchListTerm"
													onChange={onSearchChange}
													placeholder="Search List"
													ref={searchListInputRef}
													tabIndex="0"
												/>
												<button type="button" className="btn btn-link btn-search px-2">
													<i className="fa fa-search me-0" />
												</button>
											</div>
											{/* <button
												className="btn btn-primary align-self-start ms-2"
												type="button"
												onClick={generateReports}
											>
												Generate Reports
											</button> */}
											<a
												className="btn btn-primary align-self-start ms-2"
												href="/keywords-tool"
												target="_blank"
											>
												Keyword Tool
											</a>
										</div>
									)}
								</Fragment>
							}
						/>
					</div>
				</div>

				<Fragment>
					<ul className="nav nav-tabs" id="tabCampaigns" role="tablist">
						{(user.isAdmin || user.isSalesUser) && (
							<li className="nav-item" role="presentation" key="new-campaigns">
								<button
									className={`nav-link ${user.isAdmin || user.isSalesUser ? 'active' : ''}`}
									id="new-campaigns-tab"
									data-bs-toggle="tab"
									data-bs-target="#new-campaigns-tab-pane"
									type="button"
									role="tab"
									aria-controls="new-campaigns-tab-pane"
									aria-selected="true"
								>
									New
								</button>
							</li>
						)}

						<li className="nav-item" role="presentation" key="active-campaigns">
							<button
								className={`nav-link ${user.isAdmin || user.isSalesUser ? '' : 'active'}`}
								id="active-campaigns-tab"
								data-bs-toggle="tab"
								data-bs-target="#active-campaigns-tab-pane"
								type="button"
								role="tab"
								aria-controls="active-campaigns-tab-pane"
								aria-selected="true"
							>
								Active
							</button>
						</li>

						{(user.isAdmin || user.isSalesUser) && (
							<Fragment>
								<li className="nav-item" role="presentation" key="canceled-campaigns">
									<button
										className="nav-link"
										id="canceled-campaigns-tab"
										data-bs-toggle="tab"
										data-bs-target="#canceled-campaigns-tab-pane"
										type="button"
										role="tab"
										aria-controls="canceled-campaigns-tab-pane"
										aria-selected="false"
										onClick={() => getCanceledCampaigns(true)}
									>
										Canceled
									</button>
								</li>
								<li className="nav-item" role="presentation" key="campaigns-jobs">
									<button
										className="nav-link"
										id="jobs-campaigns-tab"
										data-bs-toggle="tab"
										data-bs-target="#jobs-campaigns-tab-pane"
										type="button"
										role="tab"
										aria-controls="jobs-campaigns-tab-pane"
										aria-selected="false"
									>
										Jobs
									</button>
									{runningProcesses &&
									runningProcesses.length > 0 && (
										<span className="notification-counter">{runningProcesses.length}</span>
									)}
								</li>
							</Fragment>
						)}

						<li className="nav-item" role="presentation" key="campaigns-reports">
							<button
								className="nav-link"
								id="reports-tab"
								data-bs-toggle="tab"
								data-bs-target="#reports-tab-pane"
								type="button"
								role="tab"
								aria-controls="reports-tab-pane"
								aria-selected="false"
							>
								Reports
								{(user.isAdmin || user.isSalesUser) &&
								readyReports &&
								readyReports.length > 0 && (
									<span className="notification-counter">{readyReports.length}</span>
								)}
							</button>
						</li>

						{(user.isAdmin || user.isSalesUser) && (
							<Fragment>
								<li className="nav-item" role="presentation" key="campaigns-content">
									<button
										className="nav-link"
										id="content-tab"
										data-bs-toggle="tab"
										data-bs-target="#content-tab-pane"
										type="button"
										role="tab"
										aria-controls="content-tab-pane"
										aria-selected="false"
									>
										Content
										{readyContentRecords &&
										readyContentRecords.length > 0 && (
											<span className="notification-counter">{readyContentRecords.length}</span>
										)}
									</button>
								</li>

								<li className="nav-item" role="presentation" key="offers">
									<button
										className="nav-link"
										id="offers-tab"
										data-bs-toggle="tab"
										data-bs-target="#offers-tab-pane"
										type="button"
										role="tab"
										aria-controls="offers-tab-pane"
										aria-selected="false"
									>
										Offers
									</button>
								</li>

								{user.isAdmin && (
									<li className="nav-item" role="presentation" key="system-offers">
										<button
											className="nav-link"
											id="system-offers-tab"
											data-bs-toggle="tab"
											data-bs-target="#system-offers-tab-pane"
											type="button"
											role="tab"
											aria-controls="system-offers-tab-pane"
											aria-selected="false"
										>
											System Offers
										</button>
									</li>
								)}

								<li className="nav-item" role="presentation" key="payments">
									<button
										className="nav-link"
										id="payments-tab"
										data-bs-toggle="tab"
										data-bs-target="#payments-tab-pane"
										type="button"
										role="tab"
										aria-controls="payments-tab-pane"
										aria-selected="false"
									>
										Payments
									</button>
									{overDuePayments &&
									overDuePayments.length > 0 && (
										<span className="notification-counter" title="Over Due Payments">
											{overDuePayments.length}
										</span>
									)}
								</li>
							</Fragment>
						)}
					</ul>
					<div className="tab-content border" id="tabCampaignsContent">
						{(user.isAdmin || user.isSalesUser) && (
							<Fragment>
								{/* ----- new campaigns ----- */}
								<div
									className={`tab-pane fade p-3 ${user.isAdmin || user.isSalesUser
										? 'show active'
										: ''}`}
									id="new-campaigns-tab-pane"
									role="tabpanel"
									aria-labelledby="new-campaigns-tab"
									tabIndex="0"
								>
									<ul className="nav nav-tabs" id="tabNewCampaigns" role="tablist">
										<li className="nav-item" role="presentation" key="new-campaigns-orders">
											<button
												className="nav-link active"
												id="new-campaigns-orders-tab"
												data-bs-toggle="tab"
												data-bs-target="#new-campaigns-orders-tab-pane"
												type="button"
												role="tab"
												aria-controls="new-campaigns-orders-tab-pane"
												aria-selected="true"
											>
												Orders
											</button>
											{activeOrdersForList &&
											activeOrdersForList.length > 0 && (
												<span className="notification-counter">
													{activeOrdersForList.length}
												</span>
											)}
										</li>
										<li className="nav-item" role="presentation" key="new-campaigns-requirements">
											<button
												className="nav-link"
												id="new-campaigns-requirements-tab"
												data-bs-toggle="tab"
												data-bs-target="#new-campaigns-requirements-tab-pane"
												type="button"
												role="tab"
												aria-controls="new-campaigns-requirements-tab-pane"
												aria-selected="true"
											>
												Requirements
											</button>
											{requirements &&
											requirements.length > 0 && (
												<span className="notification-counter">{requirements.length}</span>
											)}
										</li>
										<li className="nav-item" role="presentation" key="new-campaigns-approval">
											<button
												className="nav-link"
												id="new-campaigns-approval-tab"
												data-bs-toggle="tab"
												data-bs-target="#new-campaigns-approval-tab-pane"
												type="button"
												role="tab"
												aria-controls="new-campaigns-approval-tab-pane"
												aria-selected="true"
											>
												Approval
											</button>
											{approvalPendingOrders &&
											approvalPendingOrders.length > 0 && (
												<span
													className="notification-counter"
													title={`${approvalPendingOrders.length} item(s) for approval`}
												>
													{approvalPendingOrders.length}
												</span>
											)}
										</li>
										<li className="nav-item" role="presentation" key="new-campaigns-approved">
											<button
												className="nav-link"
												id="new-campaigns-approved-tab"
												data-bs-toggle="tab"
												data-bs-target="#new-campaigns-approved-tab-pane"
												type="button"
												role="tab"
												aria-controls="new-campaigns-approved-tab-pane"
												aria-selected="true"
											>
												Approved
											</button>
											{approvedOrdersListView &&
											approvedOrdersListView.length > 0 && (
												<span className="notification-counter">
													{approvedOrdersListView.length}
												</span>
											)}
										</li>
										<li className="nav-item" role="presentation" key="new-campaigns-inprogress">
											<button
												className="nav-link"
												id="new-campaigns-inprogress-tab"
												data-bs-toggle="tab"
												data-bs-target="#new-campaigns-inprogress-tab-pane"
												type="button"
												role="tab"
												aria-controls="new-campaigns-inprogress-tab-pane"
												aria-selected="true"
											>
												In Progress
											</button>
											{newCampaignsListView &&
											newCampaignsListView.length > 0 && (
												<span className="notification-counter">
													{newCampaignsListView.length}
												</span>
											)}
										</li>
									</ul>
									<div className="tab-content border" id="tabNewCampaignsContent">
										{/* ----- Orders ----- */}
										<div
											className="tab-pane p-3 position-relative fade show active"
											id="new-campaigns-orders-tab-pane"
											role="tabpanel"
											aria-labelledby="new-campaigns-orders-tab"
											tabIndex="0"
										>
											<div className="new-order-wrapper">
												<button
													className="btn btn-primary me-2"
													type="button"
													onClick={() => navigate('/')}
												>
													Add New Campaign
												</button>
												<button className="btn btn-primary" onClick={toggleOrderDetailsDialog}>
													New Order
												</button>
											</div>

											{/* <ul className="nav nav-tabs" id="tabOrders" role="tablist">
												<li className="nav-item" role="presentation" key="new-orders">
													<button
														className="nav-link active"
														id="new-orders-tab"
														data-bs-toggle="tab"
														data-bs-target="#new-orders-tab-pane"
														type="button"
														role="tab"
														aria-controls="new-orders-tab-pane"
														aria-selected="true"
													>
														New
													</button>
												</li>
												<li className="nav-item" role="presentation" key="in-progress-orders">
													<button
														className="nav-link"
														id="in-progress-orders-tab"
														data-bs-toggle="tab"
														data-bs-target="#in-progress-orders-tab-pane"
														type="button"
														role="tab"
														aria-controls="in-progress-orders-tab-pane"
														aria-selected="true"
													>
														In Progress
													</button>
												</li>
											</ul> */}
											{/* <div className="tab-content border" id="tabOrdersContent"> */}
											{/* <div
													className="tab-pane fade show active"
													id="new-orders-tab-pane"
													role="tabpanel"
													aria-labelledby="new-orders-tab"
													tabIndex="0"
												> */}
											<div className="table-wrapper">
												{isLoadingOrders ? (
													<Spinner />
												) : (
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																<th scope="col" className="date text-start">
																	Date
																</th>
																<th scope="col">Customer</th>
																<th scope="col">Product</th>
																<th scope="col">Sales Rep</th>
																<th scope="col">Subscription Id</th>
																<th scope="col" />
															</tr>
														</thead>
														<tbody
															className={`${isFilteringList ? 'is-filtering-table' : ''}`}
														>
															{activeOrdersForList.map((order, index) => (
																<tr key={order.id}>
																	<td className="date text-start">
																		<Moment format="MM/DD/YYYY">
																			{order.dateCreated}
																		</Moment>
																	</td>
																	<td>{`${order.firstName} ${order.lastName}`}</td>
																	<td>{order.productName}</td>
																	<td>
																		{order.salesRep ? (
																			`${order.salesRep.firstName} ${order
																				.salesRep.lastName}`
																		) : (
																			''
																		)}
																	</td>
																	<td>{order.subscriptionID}</td>

																	<td className="task-action text-end">
																		{(user.isAdmin || user.isSalesUser) && (
																			<Fragment>
																				<a
																					className="btn btn-link py-0"
																					href={`https://app.mapboost.com/orders/${order.id}/requirements`}
																					target="_blank"
																					title="View Requirements Page"
																				>
																					<i className="fa-solid fa-up-right-from-square" />
																				</a>
																				<button
																					className="btn btn-link py-0"
																					onClick={(e) =>
																						toggleOrderDetailsDialog(
																							e,
																							order
																						)}
																					title="Edit Order"
																				>
																					<i className="fa-solid fa-pen" />
																				</button>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleDeleteCampaignOrder(
																							order.id
																						)}
																					title="Delete Order"
																				>
																					<i className="fa-solid fa-trash-can" />
																				</button>
																			</Fragment>
																		)}
																	</td>
																</tr>
															))}
														</tbody>
														<TableFooter
															list={activeOrdersForList}
															colspan={5}
															showRefreshButton={true}
															onListRefresh={() => getOrders(true)}
														/>
													</table>
												)}
											</div>
											{/* </div> */}

											{/* <div
													className="tab-pane fade"
													id="in-progress-orders-tab-pane"
													role="tabpanel"
													aria-labelledby="in-progress-orders-tab"
													tabIndex="0"
												>
													<div className="table-wrapper">
														{isLoadingOrders ? (
															<Spinner />
														) : (
															<table className="items-list campaigns-list table table-responsive">
																<thead>
																	<tr>
																		<th scope="col" className="date text-start">
																			Date
																		</th>
																		<th scope="col">Customer</th>
																		<th scope="col">Product</th>
																		<th scope="col">Sales Rep</th>
																		<th scope="col">Subscription Id</th>
																		<th scope="col" />
																	</tr>
																</thead>
																<tbody
																	className={`${isFilteringList
																		? 'is-filtering-table'
																		: ''}`}
																>
																	{inProgressOrders.map((order, index) => (
																		<tr key={order.id}>
																			<td className="date text-start">
																				<Moment format="MM/DD/YYYY">
																					{order.dateCreated}
																				</Moment>
																			</td>
																			<td
																			>{`${order.firstName} ${order.lastName}`}</td>
																			<td>{order.productName}</td>
																			<td>
																				{order.salesRep ? (
																					`${order.salesRep.firstName} ${order
																						.salesRep.lastName}`
																				) : (
																					''
																				)}
																			</td>
																			<td>{order.subscriptionID}</td>

																			<td className="task-action text-end">
																				{(user.isAdmin || user.isSalesUser) && (
																					<Fragment>
																						<button
																							className="btn btn-link py-0"
																							onClick={() =>
																								toggleOrderDetailsDialog(
																									order
																								)}
																							title="Edit Order"
																						>
																							<i className="fa-solid fa-pen" />
																						</button>
																					</Fragment>
																				)}
																			</td>
																		</tr>
																	))}
																</tbody>
																<TableFooter
																	list={inProgressOrders}
																	colspan={5}
																	showRefreshButton={true}
																	onListRefresh={() => getOrders(true)}
																/>
															</table>
														)}
													</div>
												</div> */}
											{/* </div> */}
										</div>

										{/* ----- Requirements ----- */}
										<div
											className="tab-pane fade position-relative"
											id="new-campaigns-requirements-tab-pane"
											role="tabpanel"
											aria-labelledby="new-campaigns-requirements-tab"
											tabIndex="0"
										>
											<div className="new-order-wrapper">
												<button
													className="btn btn-primary"
													onClick={toggleRequirementsDetailsDialog}
												>
													New Requirements
												</button>
											</div>
											<div className="table-wrapper">
												{isLoadingRequirements ? (
													<Spinner />
												) : (
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																<th scope="col" className="date text-start">
																	Date
																</th>
																<th scope="col">Company</th>
																<th scope="col" className="setting">
																	Keywords
																</th>
																<th scope="col" className="setting">
																	Profile
																</th>
																<th scope="col" className="setting">
																	Lat/Long
																</th>
																<th scope="col" className="setting">
																	Grid Size
																</th>
																<th scope="col" />
															</tr>
														</thead>
														<tbody
															className={`${isFilteringList ? 'is-filtering-table' : ''}`}
														>
															{requirements.map((requirement, index) => (
																<tr key={requirement.id}>
																	<td className="date text-start">
																		<Moment format="MM/DD/YYYY">
																			{requirement.dateCreated}
																		</Moment>
																	</td>
																	<td>{requirement.company}</td>
																	<td className="setting">
																		{requirement.keywords ? 'Yes' : ''}
																	</td>
																	<td className="setting">
																		{requirement.googleBusinessUrl ? 'Yes' : ''}
																	</td>
																	<td className="setting">
																		{requirement.lat && requirement.lng ? (
																			'Yes'
																		) : (
																			''
																		)}
																	</td>
																	<td className="setting">{``}</td>

																	<td className="task-action text-end">
																		{(user.isAdmin || user.isSalesUser) && (
																			<Fragment>
																				<button
																					className="btn btn-link py-0"
																					onClick={() =>
																						toggleRequirementsDetailsDialog(
																							requirement
																						)}
																					title="Edit Requirements"
																				>
																					<i className="fa-solid fa-pen" />
																				</button>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleDeleteRequirements(
																							requirement.id
																						)}
																					title="Delete Requirements"
																				>
																					<i className="fa-solid fa-trash-can" />
																				</button>
																			</Fragment>
																		)}
																	</td>
																</tr>
															))}
														</tbody>
														<TableFooter
															list={requirements}
															colspan={7}
															showRefreshButton={true}
															onListRefresh={() => getRequirements(true)}
														/>
													</table>
												)}
											</div>
										</div>

										{/* ----- Approval ----- */}
										<div
											className="tab-pane fade p-3"
											id="new-campaigns-approval-tab-pane"
											role="tabpanel"
											aria-labelledby="new-campaigns-approval-tab"
											tabIndex="0"
										>
											<div className="table-wrapper">
												{isLoadingRequirements ? (
													<Spinner />
												) : (
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																<th scope="col" className="date text-start">
																	Date
																</th>
																<th scope="col">Company</th>
																<th scope="col" className="date-time text-start">
																	Last Sent Date
																</th>
																<th scope="col" className="count">
																	Total Sent
																</th>
																<th scope="col" className="setting">
																	Messages
																</th>
																<th scope="col" />
															</tr>
														</thead>
														<tbody
															className={`${isFilteringList ? 'is-filtering-table' : ''}`}
														>
															{approvalPendingOrders.map((order, index) => {
																return (
																	<tr key={order.id}>
																		<td className="date text-start">
																			<Moment format="MM/DD/YYYY">
																				{order.dateCreated}
																			</Moment>
																		</td>
																		<td>{order.company}</td>
																		<td className="date-time text-start">
																			{order.lastSentMessageDate && (
																				<Moment format="MM/DD/YYYY">
																					{order.lastSentMessageDate}
																				</Moment>
																			)}
																		</td>
																		<td className="count">{order.messagesCount}</td>
																		<td className="setting">
																			{order.messagesCount !== 0 && (
																				<a
																					className="btn btn-link p-0"
																					onClick={() =>
																						toggleRequirementsMessagesDialog(
																							order.id
																						)}
																					title="View Messages"
																				>
																					<i className="fa-regular fa-comment" />
																				</a>
																			)}
																		</td>

																		<td className="task-action text-end">
																			{(user.isAdmin || user.isSalesUser) && (
																				<Fragment>
																					<a
																						className="btn btn-link py-0"
																						href={`https://app.mapboost.com/map-boost-approval/${order.id}`}
																						target="_blank"
																						title="Open Approval Page"
																					>
																						<i className="fa-solid fa-file-circle-check" />
																					</a>
																					<button
																						className="btn btn-link py-0"
																						onClick={() =>
																							toggleRequirementsDetailsDialog(
																								order
																							)}
																						title="Edit"
																					>
																						<i className="fa-solid fa-pen" />
																					</button>
																				</Fragment>
																			)}
																		</td>
																	</tr>
																);
															})}
														</tbody>
														<TableFooter
															list={approvalPendingOrders}
															colspan={6}
															showRefreshButton={true}
															onListRefresh={() => getRequirements(true)}
														/>
													</table>
												)}
											</div>
										</div>

										{/* ----- Approved ----- */}
										<div
											className="tab-pane fade"
											id="new-campaigns-approved-tab-pane"
											role="tabpanel"
											aria-labelledby="new-campaigns-approved-tab"
											tabIndex="0"
										>
											{isLoading ? (
												<Spinner />
											) : (
												<div className="table-wrapper">
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																<th scope="col" className="date text-start">
																	Date
																</th>
																<th scope="col">Company</th>
																<th scope="col" className="setting">
																	Keywords
																</th>
																<th scope="col" className="setting">
																	Profile
																</th>
																<th scope="col" className="setting">
																	Lat/Long
																</th>
																<th scope="col" className="setting">
																	Grid Size
																</th>
																<th scope="col" />
															</tr>
														</thead>
														<tbody
															className={`${isFilteringList ? 'is-filtering-table' : ''}`}
														>
															{approvedOrdersListView &&
																approvedOrdersListView.map((order, index) => {
																	return (
																		<tr key={order.id}>
																			<td className="date text-start">
																				<Moment format="MM/DD/YYYY">
																					{order.dateCreated}
																				</Moment>
																			</td>
																			<td>{order.name}</td>
																			<td className="setting">
																				{order.hasKeywords ? 'Yes' : ''}
																			</td>
																			<td className="setting">
																				{order.googleBusinessUrl &&
																				(order.googleCid ||
																					order.googlePlaceId) ? (
																					'Yes'
																				) : (
																					''
																				)}
																			</td>
																			<td className="setting">
																				{order.lat && order.lng ? 'Yes' : ''}
																			</td>
																			<td className="setting">{``}</td>

																			<td className="task-action text-end">
																				{(user.isAdmin || user.isSalesUser) && (
																					<Fragment>
																						<button
																							className="btn btn-link py-0"
																							onClick={() =>
																								toggleApprovedOrderSetupDialog(
																									order
																								)}
																							title="Setup"
																						>
																							<i className="fa-solid fa-gear" />
																						</button>
																						<button
																							className="btn btn-link py-0 ms-2"
																							onClick={() =>
																								handleDeleteApprovedOrder(
																									order.id
																								)}
																							title="Delete"
																						>
																							<i className="fa-solid fa-trash-can" />
																						</button>
																					</Fragment>
																				)}
																			</td>
																		</tr>
																	);
																})}
														</tbody>
														<TableFooter
															list={approvedOrdersListView}
															colspan={7}
															showRefreshButton={true}
															onListRefresh={() => getApprovedOrders(true)}
														/>
													</table>
												</div>
											)}
										</div>

										{/* ----- In Progress ----- */}
										<div
											className="tab-pane fade"
											id="new-campaigns-inprogress-tab-pane"
											role="tabpanel"
											aria-labelledby="new-campaigns-inprogress-tab"
											tabIndex="0"
										>
											{isLoading ? (
												<Spinner />
											) : (
												<div className="table-wrapper">
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																<th scope="col" />
																<th scope="col">#</th>
																<th scope="col">Name</th>
																<th className="setting" scope="col">
																	AH Order Id
																</th>

																{defaultCampaignSettings &&
																	defaultCampaignSettings.map((setting, index) => (
																		<th
																			className="setting"
																			scope="col"
																			key={setting.name}
																		>
																			{setting.label}
																		</th>
																	))}

																<th scope="col" />
															</tr>
														</thead>
														<tbody
															className={`${isFilteringList ? 'is-filtering-table' : ''}`}
														>
															{newCampaignsListView &&
																newCampaignsListView.map((campaign, index) => (
																	<tr
																		key={`${index}_${Math.floor(
																			Math.random() * 100000
																		)}`}
																	>
																		<td>
																			<input
																				type="checkbox"
																				className="form-check-input"
																				name={`chk_${campaign.id}`}
																				id={`chk_${campaign.id}`}
																				onChange={handleSelectCampaignChange}
																			/>
																		</td>
																		<td>{index + 1}</td>
																		<td
																			className="campaign-name text-truncate"
																			title={campaign.name}
																		>
																			{campaign.name}
																			{/* <Fragment>
																				{campaign.mergedIntoDb && (
																					<label
																						className="merged-notice"
																						title="Merged"
																					>
																						<i className="fa-solid fa-database" />
																					</label>
																				)}
																			</Fragment> */}
																		</td>
																		<td className="setting">
																			{campaign.ahOrderId}
																		</td>

																		{defaultCampaignSettings &&
																			defaultCampaignSettings.map(
																				(
																					defaultSetting,
																					defaultSettingIndex
																				) => {
																					let tempSettingsObj;
																					let settingIsDone = false;

																					if (campaign.settings) {
																						tempSettingsObj = JSON.parse(
																							campaign.settings
																						);

																						if (
																							typeof tempSettingsObj ===
																								'object' &&
																							tempSettingsObj[
																								defaultSetting.name
																							] !== undefined &&
																							tempSettingsObj[
																								defaultSetting.name
																							] === true
																						) {
																							settingIsDone = true;
																						}
																					}

																					return (
																						<Fragment
																							key={defaultSettingIndex}
																						>
																							<td className="setting">
																								<input
																									type="checkbox"
																									className="form-check-input setting-checkbox disabled"
																									name={
																										defaultSetting.name
																									}
																									id={
																										defaultSetting.name
																									}
																									defaultChecked={
																										settingIsDone
																									}
																								/>
																							</td>
																						</Fragment>
																					);
																				}
																			)}

																		<td className="task-action text-end">
																			{(user.isAdmin || user.isSalesUser) && (
																				<Fragment>
																					<Link
																						target="_blank"
																						className="btn btn-link py-0"
																						to={`/setup/${campaign.id}`}
																						title="Edit Campaign"
																					>
																						<i className="fa-solid fa-pen" />
																					</Link>
																					<Link
																						target="_blank"
																						className="btn btn-link py-0"
																						to={`/campaigns/report/${campaign.id}`}
																						title="Generate Report"
																					>
																						<i className="fa-solid fa-circle-play" />
																					</Link>
																				</Fragment>
																			)}
																			<Link
																				target="_blank"
																				className="btn btn-link py-0"
																				to={`/view/report/v5/${campaign.id}`}
																				title="View Report"
																			>
																				<i className="fa-solid fa-magnifying-glass-chart" />
																			</Link>
																		</td>
																	</tr>
																))}
														</tbody>
														<TableFooter
															list={newCampaignsListView}
															colspan={11}
															showRefreshButton={true}
															onListRefresh={() => getNewCampaigns(true)}
														/>
													</table>
												</div>
											)}
										</div>
									</div>
								</div>
							</Fragment>
						)}

						{/* ----- active campaigns ----- */}
						<div
							className={`tab-pane fade ${user.isAdmin || user.isSalesUser ? '' : 'show active'}`}
							id="active-campaigns-tab-pane"
							role="tabpanel"
							aria-labelledby="active-campaigns-tab"
							tabIndex="0"
						>
							{isLoadingActiveCampaigns ? (
								<Spinner />
							) : (
								<Fragment>
									<div className="input-group p-3 align-items-center table-search-filter border-bottom">
										<label htmlFor="nextReportDateSearchFrom" className="form-label">
											Next Report Date From:
										</label>
										<div className="ms-2">
											<input
												type="date"
												className="form-control"
												name="nextReportDateSearchFrom"
												id="nextReportDateSearchFrom"
												value={nextReportDateSearchFrom || ''}
												onChange={onDateFilterChange}
											/>
										</div>
										<label htmlFor="nextReportDateSearchFrom" className="form-label ms-4">
											To:
										</label>
										<div className="ms-2">
											<input
												type="date"
												className="form-control"
												name="nextReportDateSearchTo"
												id="nextReportDateSearchTo"
												value={nextReportDateSearchTo || ''}
												onChange={onDateFilterChange}
											/>
										</div>
										<button
											className="btn btn-link app-link-button ms-4"
											onClick={clearDateFilters}
											title="Clear dates filter"
										>
											Clear
										</button>
									</div>
									<table className="items-list campaigns-list table table-responsive">
										<thead>
											<tr>
												{(user.isAdmin || user.isSalesUser) && <th scope="col" />}
												{/* <th scope="col">#</th> */}
												<th scope="col">Name</th>
												<th className="status text-start" scope="col">
													Name Ext
												</th>
												{(user.isAdmin || user.isSalesUser) && (
													<Fragment>
														<th className="setting" scope="col">
															AH Order Id
														</th>
														<th className="date" scope="col">
															Date Created
														</th>
													</Fragment>
												)}
												<th className="date" scope="col">
													Last Report
												</th>
												<th
													className="date"
													scope="col"
													//onClick={() => sortActiveCampaignsOnColumnClick('nextReportDate')}
													//role="button"
												>
													Next Report
													{sortOrder === 'asc' ? (
														<span className="ms-2">
															<i className="fa-solid fa-caret-down" />
														</span>
													) : sortOrder === 'desc' ? (
														<span className="ms-2">
															<i className="fa-solid fa-caret-up" />
														</span>
													) : (
														''
													)}
												</th>
												{(user.isAdmin || user.isSalesUser) && (
													<th className="count" scope="col">
														Day
													</th>
												)}
												<th className="count" scope="col">
													Reports
												</th>
												{(user.isAdmin || user.isSalesUser) && (
													<th className="date" scope="col">
														Job Status
													</th>
												)}
												<th scope="col" />
											</tr>
										</thead>
										<tbody className={`${isFilteringList ? 'is-filtering-table' : ''}`}>
											{activeCampaignsListView && activeCampaignsListView.length > 0 ? (
												activeCampaignsListView.map((campaign, index) => {
													let isJobRunning = false;
													let isQueueRunning = false;

													if (user.isAdmin || user.isSalesUser) {
														isJobRunning = isCampaignProcessRunning(campaign.id);
														isQueueRunning = isCampaignReportQueueRunning(campaign.id);
													}

													return (
														<Fragment key={index}>
															{/* {showItem && ( */}
															<tr
																className={`${isJobRunning
																	? 'job-running'
																	: isQueueRunning ? 'queue-running' : ''}`}
															>
																{(user.isAdmin || user.isSalesUser) && (
																	<td>
																		<input
																			type="checkbox"
																			className="form-check-input"
																			name={`chk_${campaign.id}`}
																			id={`chk_${campaign.id}`}
																			onChange={handleSelectCampaignChange}
																		/>
																	</td>
																)}
																{/* <td>{index + 1}</td> */}
																<td
																	className="campaign-name text-truncate"
																	title={campaign.name}
																>
																	{campaign.name}
																	{/* <Fragment>
																		{campaign.mergedIntoDb && (
																			<label
																				className="merged-notice"
																				title="Merged"
																			>
																				<i className="fa-solid fa-database" />
																			</label>
																		)}
																	</Fragment> */}
																</td>
																<td className="status text-start">
																	{campaign.nameExtension}
																</td>
																{(user.isAdmin || user.isSalesUser) && (
																	<Fragment>
																		<td className="setting">
																			{campaign.ahOrderId}
																		</td>
																		<td className="date">
																			{campaign.dateCreated ? (
																				<Moment format="MM/DD/YYYY">
																					{campaign.dateCreated}
																				</Moment>
																			) : (
																				''
																			)}
																		</td>
																	</Fragment>
																)}
																<td className="date">
																	{campaign.lastReportDate ? (
																		<Moment format="MM/DD/YYYY">
																			{campaign.lastReportDate}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																<td className="date">
																	{campaign.hostingOnly ? (
																		<span className="text-danger">
																			Hosting Only
																		</span>
																	) : campaign.nextReportDate ? (
																		<Moment format="MM/DD/YYYY">
																			{campaign.nextReportDate}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																{(user.isAdmin || user.isSalesUser) && (
																	<td className="count">
																		{campaign.reportDayOfMonth}
																	</td>
																)}
																<td className="count">{campaign.reportsCount}</td>
																{(user.isAdmin || user.isSalesUser) && (
																	<td
																		className="date"
																		id={`report_status_${campaign.id}`}
																	>
																		{runningProcesses &&
																		runningProcesses.length > 0 &&
																		isJobRunning && (
																			<span
																				className="position-relative"
																				title="Running Job"
																			>
																				<i className="fa-solid fa-cog fa-spin" />
																			</span>
																		)}
																		{reportQueues &&
																		reportQueues.length > 0 &&
																		isQueueRunning && (
																			<span
																				className="position-relative"
																				title="Running Auto-Report"
																			>
																				<i className="fa-solid fa-cog fa-spin" />
																				<i className="fa-solid fa-a auto-process-icon" />
																			</span>
																		)}
																	</td>
																)}
																<td className="task-action text-end">
																	{(user.isAdmin || user.isSalesUser) && (
																		<Fragment>
																			<Link
																				target="_blank"
																				className="btn btn-link py-0"
																				to={`/setup/${campaign.id}`}
																				title="Edit Campaign"
																			>
																				<i className="fa-solid fa-pen" />
																			</Link>
																			<Link
																				target="_blank"
																				className="btn btn-link py-0"
																				to={`/campaigns/report/${campaign.id}`}
																				title="Generate Report"
																			>
																				<i className="fa-solid fa-circle-play" />
																			</Link>
																		</Fragment>
																	)}
																	<Link
																		target="_blank"
																		className="btn btn-link py-0"
																		to={`/view/report/v5/${campaign.id}`}
																		title="View Report"
																	>
																		<i className="fa-solid fa-magnifying-glass-chart" />
																	</Link>
																	{(user.isAdmin || user.isSalesUser) && (
																		<button
																			className="btn btn-link py-0"
																			title="Generate Content"
																			onClick={(e) =>
																				toggleGenerateContentDialog(
																					campaign.id
																				)}
																		>
																			<i className="fa-solid fa-file-lines" />
																		</button>
																	)}
																</td>
															</tr>
															{/* )} */}
														</Fragment>
													);
												})
											) : (
												<tr>
													<td className="p-3" colSpan={11}>
														<i>No active campaigns</i>
													</td>
												</tr>
											)}
										</tbody>
										<TableFooter
											list={activeCampaignsListView}
											colspan={11}
											showRefreshButton={true}
											onListRefresh={() => getActiveCampaigns(true, 1)}
											// totalResults={activeCampaignsTotalCount}
											// currentPage={activeCampaignsPage}
											// pageSize={pageSize}
											// onPageChange={(page) => {
											// 	setActiveCampaignsPage(page);
											// 	getActiveCampaigns(false, page);
											// }}
										/>
									</table>
								</Fragment>
							)}
						</div>

						{(user.isAdmin || user.isSalesUser) && (
							<Fragment>
								{/* ----- canceled campaigns ----- */}
								<div
									className="tab-pane fade"
									id="canceled-campaigns-tab-pane"
									role="tabpanel"
									aria-labelledby="canceled-campaigns-tab"
									tabIndex="0"
								>
									{isLoading ? (
										<Spinner />
									) : (
										<table className="items-list campaigns-list table table-responsive">
											<thead>
												<tr>
													{/* <th scope="col">#</th> */}
													<th scope="col">Name</th>
													<th scope="col">AH Order Id</th>
													<th className="date" scope="col">
														Canceled Date
													</th>
													<th scope="col" />
												</tr>
											</thead>
											<tbody>
												{canceledCampaignsListView && canceledCampaignsListView.length > 0 ? (
													canceledCampaignsListView.map((campaign, index) => (
														<tr key={campaign.id}>
															{/* <th scope="row">{index + 1}</th> */}
															<td>{campaign.name}</td>
															<td>{campaign.ahOrderId}</td>
															<td className="date">
																{campaign.dateCanceled ? (
																	<Moment format="MM/DD/YYYY">
																		{campaign.dateCanceled}
																	</Moment>
																) : (
																	''
																)}
															</td>
															<td className="task-action text-end">
																{(user.isAdmin || user.isSalesUser) && (
																	<Fragment>
																		<Link
																			target="_blank"
																			className="btn btn-link py-0"
																			to={`/setup/${campaign.id}`}
																			title="Edit Campaign"
																		>
																			<i className="fa-solid fa-pen" />
																		</Link>
																		<Link
																			target="_blank"
																			className="btn btn-link py-0"
																			to={`/campaigns/report/${campaign.id}`}
																			title="Generate Report"
																		>
																			<i className="fa-solid fa-circle-play" />
																		</Link>
																	</Fragment>
																)}
																<Link
																	target="_blank"
																	className="btn btn-link py-0"
																	to={`/view/report/v5/${campaign.id}`}
																	title="View Report"
																>
																	<i className="fa-solid fa-magnifying-glass-chart" />
																</Link>
															</td>
														</tr>
													))
												) : (
													<tr colSpan={4}>
														<td>No canceled campaigns</td>
													</tr>
												)}
											</tbody>
											<TableFooter
												list={canceledCampaignsListView}
												colspan={4}
												showRefreshButton={true}
												onListRefresh={() => getCanceledCampaigns(true)}
											/>
										</table>
									)}
								</div>

								{/* ----- jobs ----- */}
								<div
									className="tab-pane fade p-3"
									id="jobs-campaigns-tab-pane"
									role="tabpanel"
									aria-labelledby="jobs-campaigns-tab"
									tabIndex="0"
								>
									<ul className="nav nav-tabs" id="tabJobs" role="tablist">
										<li className="nav-item" role="presentation" key="running-jobs">
											<button
												className="nav-link active"
												id="running-jobs-tab"
												data-bs-toggle="tab"
												data-bs-target="#running-jobs-tab-pane"
												type="button"
												role="tab"
												aria-controls="running-jobs-tab-pane"
												aria-selected="true"
											>
												Running
											</button>
										</li>
										<li className="nav-item" role="presentation" key="queue-jobs">
											<button
												className="nav-link"
												id="queue-jobs-tab"
												data-bs-toggle="tab"
												data-bs-target="#queue-jobs-tab-pane"
												type="button"
												role="tab"
												aria-controls="queue-jobs-tab-pane"
												aria-selected="true"
											>
												Queue
											</button>
										</li>
										<li className="nav-item" role="presentation" key="completed-jobs">
											<button
												className="nav-link"
												id="completed-jobs-tab"
												data-bs-toggle="tab"
												data-bs-target="#completed-jobs-tab-pane"
												type="button"
												role="tab"
												aria-controls="completed-jobs-tab-pane"
												aria-selected="true"
												onClick={() => getCompletedProcesses(true)}
											>
												Completed
											</button>
										</li>
									</ul>
									<div className="tab-content border" id="tabJobsContent">
										<div
											className="tab-pane fade show active"
											id="running-jobs-tab-pane"
											role="tabpanel"
											aria-labelledby="running-jobs-tab"
											tabIndex="0"
										>
											{isLoadingRunningJobs ? (
												<Spinner />
											) : (
												<table className="items-list campaigns-list table table-responsive">
													<thead>
														<tr>
															{/* <th scope="col">#</th> */}
															<th scope="col">Name</th>
															<th scope="col">AH Order Id</th>
															<th className="type" scope="col">
																Type
															</th>
															<th className="date-time" scope="col">
																Start Time
															</th>
															<th className="date" scope="col">
																Elapsed Time
															</th>
															<th scope="col" />
														</tr>
													</thead>
													<tbody>
														{runningProcesses && runningProcesses.length > 0 ? (
															runningProcesses.map((process, index) => {
																let elapsedTime = process.startDate
																	? getElapsedTime(process.startDate)
																	: '';

																return (
																	<tr key={index}>
																		{/* <th scope="row">{index + 1}</th> */}
																		<td>
																			{process.campaignName}
																			<Fragment>
																				{process.isAutoQueueProcess && (
																					<label
																						className="merged-notice ms-2"
																						title="Auto process"
																					>
																						<i className="fa-solid fa-a" />
																					</label>
																				)}
																			</Fragment>
																		</td>
																		<td>{process.campaignAHOrderId}</td>
																		<td>{process.type}</td>
																		<td className="date-time">
																			{process.startDate ? (
																				<Moment format="MM/DD/YYYY hh:mm A">
																					{process.startDate}
																				</Moment>
																			) : (
																				''
																			)}
																		</td>
																		<td className="date">{elapsedTime}</td>
																		<td className="task-action text-end">
																			<button
																				target="_blank"
																				className="btn btn-link py-0"
																				title="View Details"
																				onClick={(e) =>
																					toggleJobDetailsDialog(
																						e,
																						process.id
																					)}
																			>
																				<i className="fa-solid fa-circle-info" />
																			</button>
																			<button
																				target="_blank"
																				className="btn btn-link py-0"
																				title="Stop Job"
																				onClick={() =>
																					stopJob(
																						process.campaignId,
																						process.type
																					)}
																			>
																				<i className="fa-solid fa-circle-xmark" />
																			</button>
																		</td>
																	</tr>
																);
															})
														) : (
															<tr>
																<td className="p-3" colSpan={8}>
																	<i>No running jobs</i>
																</td>
															</tr>
														)}
													</tbody>
													<TableFooter
														list={runningProcesses}
														colspan={6}
														showRefreshButton={true}
														onListRefresh={() => getRunningProcesses(true)}
													/>
												</table>
											)}
										</div>
										<div
											className="tab-pane fade"
											id="queue-jobs-tab-pane"
											role="tabpanel"
											aria-labelledby="queue-jobs-tab"
											tabIndex="0"
										>
											{isLoadingQueueJobs ? (
												<Spinner />
											) : (
												<table className="items-list campaigns-list table table-responsive">
													<thead>
														<tr>
															{/* <th scope="col">#</th> */}
															<th scope="col">Name</th>
															<th scope="col">AH Order Id</th>
															<th className="type" scope="col">
																Type
															</th>
															<th className="date-time" scope="col">
																Added To Queue
															</th>
															{/* <th className="date" scope="col">
																Elapsed Time
															</th> */}
															<th scope="col" />
														</tr>
													</thead>
													<tbody>
														{queueReportProcesses && queueReportProcesses.length > 0 ? (
															queueReportProcesses.map((process, index) => {
																let elapsedTime = process.startDate
																	? getElapsedTime(process.startDate)
																	: '';

																return (
																	<tr key={index}>
																		{/* <th scope="row">{index + 1}</th> */}
																		<td>
																			{process.campaignName}
																			<Fragment>
																				{process.isAutoQueueProcess && (
																					<label
																						className="merged-notice ms-2"
																						title="Auto process"
																					>
																						<i className="fa-solid fa-a" />
																					</label>
																				)}
																			</Fragment>
																		</td>
																		<td>{process.campaignAHOrderId}</td>
																		<td>{process.type}</td>
																		<td className="date-time">
																			{process.startDate ? (
																				<Moment format="MM/DD/YYYY hh:mm A">
																					{process.startDate}
																				</Moment>
																			) : (
																				''
																			)}
																		</td>
																		{/* <td className="date">{elapsedTime}</td> */}
																		<td className="task-action text-end">
																			{/* <button
																				target="_blank"
																				className="btn btn-link py-0"
																				title="View Details"
																				onClick={(e) =>
																					toggleJobDetailsDialog(
																						e,
																						process.id
																					)}
																			>
																				<i className="fa-solid fa-circle-info" />
																			</button> */}
																			<button
																				target="_blank"
																				className="btn btn-link py-0"
																				title="Delete Job From Queue"
																				onClick={() =>
																					deleteQueueJob(
																						process.id,
																						process.isAutoQueueProcess
																					)}
																			>
																				<i className="fa-solid fa-circle-xmark" />
																			</button>
																		</td>
																	</tr>
																);
															})
														) : (
															<tr>
																<td className="p-3" colSpan={8}>
																	<i>No queue jobs</i>
																</td>
															</tr>
														)}
													</tbody>
													<TableFooter
														list={queueReportProcesses}
														colspan={6}
														showRefreshButton={true}
														onListRefresh={() => getQueueProcesses(true)}
													/>
												</table>
											)}
										</div>
										<div
											className="tab-pane fade"
											id="completed-jobs-tab-pane"
											role="tabpanel"
											aria-labelledby="completed-jobs-tab"
											tabIndex="0"
										>
											{isLoadingCompletedJobs ? (
												<Spinner />
											) : (
												<table className="items-list campaigns-list table table-responsive">
													<thead>
														<tr>
															{/* <th scope="col">#</th> */}
															<th scope="col">Name</th>
															<th scope="col">AH Order Id</th>
															<th className="type" scope="col">
																Type
															</th>
															<th className="date-time" scope="col">
																Start Time
															</th>
															<th className="date-time" scope="col">
																End Time
															</th>
															<th className="status" scope="col">
																Response
															</th>
															<th scope="col" />
														</tr>
													</thead>
													<tbody>
														{completedProcesses && completedProcesses.length > 0 ? (
															completedProcesses.map(
																(completedProcess, completedIndex) => {
																	let currentJobData = completedProcess.data
																		? JSON.parse(completedProcess.data)
																		: {};
																	let currentJobErrorData = currentJobData
																		? currentJobData.error
																		: {};

																	return (
																		<tr key={completedIndex}>
																			{/* <th scope="row">{completedIndex + 1}</th> */}
																			<td
																				className="campaign-name text-truncate"
																				title={completedProcess.campaignName}
																			>
																				{completedProcess.campaignName}
																				{/* <Fragment>
																					{completedProcess.campaignMergedIntoDb && (
																						<label
																							className="merged-notice"
																							title="Merged"
																						>
																							<i className="fa-solid fa-database" />
																						</label>
																					)}
																				</Fragment> */}
																			</td>
																			<td>
																				{completedProcess.campaignAHOrderId}
																			</td>
																			<td>{completedProcess.type}</td>
																			<td className="date-time">
																				{completedProcess.startDate ? (
																					<Moment format="MM/DD/YYYY hh:mm A">
																						{completedProcess.startDate}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="date-time">
																				{completedProcess.endDate ? (
																					<Moment format="MM/DD/YYYY hh:mm A">
																						{completedProcess.endDate}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="status error">
																				{completedProcess.status === 'Error' ? (
																					<Fragment>
																						<span className="fw-bold text-danger">
																							Error
																						</span>
																						{currentJobErrorData && (
																							<button
																								type="button"
																								className="btn btn-link p-0 ms-2"
																								title="View Errors"
																								onClick={() =>
																									toggleViewJobErrorsDialog(
																										completedProcess.id
																									)}
																							>
																								<i className="fa-solid fa-triangle-exclamation" />
																							</button>
																						)}
																					</Fragment>
																				) : completedProcess.status ===
																				'Stopped' ? (
																					<span className="fw-bold text-warning">
																						Stopped
																					</span>
																				) : (
																					<span className="fw-bold text-success">
																						Success
																					</span>
																				)}
																			</td>
																			<td className="text-end">
																				<button
																					target="_blank"
																					className="btn btn-link py-0"
																					title="View Details"
																					onClick={(e) =>
																						toggleJobDetailsDialog(
																							e,
																							completedProcess.id
																						)}
																				>
																					<i className="fa-solid fa-circle-info" />
																				</button>
																			</td>
																		</tr>
																	);
																}
															)
														) : (
															<tr>
																<td className="p-3" colSpan={8}>
																	<i>No completed jobs</i>
																</td>
															</tr>
														)}
													</tbody>
													<TableFooter
														list={completedProcesses}
														colspan={7}
														showRefreshButton={true}
														onListRefresh={() => getCompletedProcesses(true)}
													/>
												</table>
											)}
										</div>
									</div>
								</div>
							</Fragment>
						)}

						{/* ----- reports ----- */}
						<div
							className="tab-pane fade p-3"
							id="reports-tab-pane"
							role="tabpanel"
							aria-labelledby="reports-tab"
							tabIndex="0"
						>
							<ul className="nav nav-tabs" id="tabReports" role="tablist">
								{(user.isAdmin || user.isSalesUser) && (
									<Fragment>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link active ${user.isAdmin || user.isSalesUser
													? 'active'
													: ''}`}
												id="run-reports-tab"
												data-bs-toggle="tab"
												data-bs-target="#run-reports-tab-pane"
												type="button"
												role="tab"
												aria-controls="run-reports-tab-pane"
												aria-selected="true"
											>
												Run
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link`}
												id="ready-reports-tab"
												data-bs-toggle="tab"
												data-bs-target="#ready-reports-tab-pane"
												type="button"
												role="tab"
												aria-controls="ready-reports-tab-pane"
												aria-selected="true"
											>
												Ready
											</button>
										</li>
									</Fragment>
								)}

								<li className="nav-item" role="presentation">
									<button
										className={`nav-link ${user.isAdmin || user.isSalesUser ? '' : 'active'}`}
										id="sent-reports-tab"
										data-bs-toggle="tab"
										data-bs-target="#sent-reports-tab-pane"
										type="button"
										role="tab"
										aria-controls="sent-reports-tab-pane"
										aria-selected="true"
									>
										Sent
									</button>
								</li>
							</ul>
							<div className="tab-content border" id="tabReportsContent">
								{(user.isAdmin || user.isSalesUser) && (
									<Fragment>
										<div
											className={`tab-pane fade ${user.isAdmin || user.isSalesUser
												? 'show active'
												: ''}`}
											id="run-reports-tab-pane"
											role="tabpanel"
											aria-labelledby="run-reports-tab"
											tabIndex="0"
										>
											{isLoading ? (
												<Spinner />
											) : (
												<table className="items-list campaigns-list table table-responsive">
													<thead>
														<tr>
															{/* <th scope="col">#</th> */}
															<th scope="col">Name</th>
															{/* <th scope="col">Sales Rep</th> */}
															<th className="setting" scope="col">
																AH Order Id
															</th>
															<th className="date text-start" scope="col">
																Next Report Date
															</th>
															<th className="count" scope="col">
																Day
															</th>
															<th className="status" scope="col">
																Timezone
															</th>
															<th className="count" scope="col">
																Reports
															</th>
															<th className="date-time text-start" scope="col">
																Created
															</th>
															<th scope="col" />
														</tr>
													</thead>
													<tbody>
														{runReportsCampaignsListView &&
														runReportsCampaignsListView.length > 0 ? (
															runReportsCampaignsListView.map(
																(runReportsCampaign, index) => {
																	// let reportUrl = handleReadyReportUrl(
																	// 	report.shareUrl,
																	// 	report.id
																	// );

																	let ahOrderid = runReportsCampaign.ahOrderId;
																	// let salesRepName = !ahOrderid
																	// 	? report.salesRepName
																	// 	: '';

																	let tzAbbr = getTimezineAbbr(
																		runReportsCampaign.timeZone
																	);

																	return (
																		<tr
																			key={index}
																			className={
																				runReportsCampaign.isLastReportDateOutdated ? (
																					'report-outdated'
																				) : runReportsCampaign.isReadyForReport ? (
																					'is-today'
																				) : runReportsCampaign.isReadyForReportTomorrow ? (
																					'is-tomorrow'
																				) : (
																					''
																				)
																			}
																		>
																			{/* <th scope="row">{index + 1}</th> */}
																			<td>{runReportsCampaign.name}</td>
																			{/* <td>{salesRepName}</td> */}
																			<td className="setting">{ahOrderid}</td>
																			<td className="date text-start">
																				{runReportsCampaign.nextReportDate ? (
																					<Moment format="MM/DD/YYYY">
																						{
																							runReportsCampaign.nextReportDate
																						}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="count">
																				<Fragment>
																					{
																						runReportsCampaign.reportDayOfMonth
																					}
																				</Fragment>
																			</td>
																			<td
																				className="status"
																				title={runReportsCampaign.timeZone}
																			>
																				{tzAbbr}
																			</td>
																			<td className="count">
																				{runReportsCampaign.reportsCount}
																			</td>
																			<td className="date-time text-start">
																				{runReportsCampaign.dateCreated ? (
																					<Moment format="MM/DD/YYYY hh:mm A">
																						{runReportsCampaign.dateCreated}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="task-action text-end">
																				<Link
																					target="_blank"
																					className="btn btn-link py-0 px-1"
																					to={`/setup/${runReportsCampaign.id}`}
																					title="Edit Campaign"
																				>
																					<i className="fa-solid fa-pen" />
																				</Link>
																				<Link
																					target="_blank"
																					className="btn btn-link py-0 px-1"
																					to={`/campaigns/report/${runReportsCampaign.id}`}
																					title="Generate Report"
																				>
																					<i className="fa-solid fa-circle-play" />
																				</Link>
																				{/* <a
																				href={reportUrl}
																				target="_blank"
																				className="btn btn-link py-0 px-1"
																				title="View Report"
																			>
																				<i className="fa-solid fa-magnifying-glass-chart" />
																			</a> */}
																				<button
																					className="btn btn-link py-0 px-1"
																					title="Generate Content"
																					onClick={(e) =>
																						toggleGenerateContentDialog(
																							runReportsCampaign.id
																						)}
																				>
																					<i className="fa-solid fa-file-lines" />
																				</button>
																				{/* <button
																				className="btn btn-link py-0 px-1"
																				onClick={() =>
																					handleDeleteReportHistoryItem(
																						report.id
																					)}
																				title="Delete Report"
																			>
																				<i className="fa-solid fa-trash-can" />
																			</button> */}
																			</td>
																		</tr>
																	);
																}
															)
														) : (
															<tr>
																<td className="p-3" colSpan={8}>
																	<i>No run reports</i>
																</td>
															</tr>
														)}
													</tbody>
													<TableFooter
														list={runReportsCampaignsListView}
														colspan={7}
														showRefreshButton={true}
														onListRefresh={() => getCampaignsForRunReports(true)}
													/>
												</table>
											)}
										</div>
										<div
											className={`tab-pane fade`}
											id="ready-reports-tab-pane"
											role="tabpanel"
											aria-labelledby="ready-reports-tab"
											tabIndex="0"
										>
											{isLoadingHistory ? (
												<Spinner />
											) : (
												<table className="items-list campaigns-list table table-responsive">
													<thead>
														<tr>
															{/* <th scope="col">#</th> */}
															<th scope="col">Name</th>
															<th scope="col">Sales Rep</th>
															<th className="setting" scope="col">
																AH Order Id
															</th>
															<th className="date text-start" scope="col">
																Next Report Date
															</th>
															<th className="count" scope="col">
																Day
															</th>
															<th className="status" scope="col">
																Timezone
															</th>
															<th className="count" scope="col">
																Reports
															</th>
															<th className="date-time text-start" scope="col">
																Created
															</th>
															<th scope="col" />
														</tr>
													</thead>
													<tbody>
														{readyReports && readyReports.length > 0 ? (
															readyReports.map((report, index) => {
																let reportUrl = handleReadyReportUrl(
																	report.shareUrl,
																	report.id
																);

																let ahOrderid = report.campaignAHOrderId;
																let salesRepName = !ahOrderid
																	? report.salesRepName
																	: '';

																let tzAbbr = getTimezineAbbr(report.campaignTimeZone);

																return (
																	<tr key={index}>
																		{/* <th scope="row">{index + 1}</th> */}
																		<td>{report.campaignName}</td>
																		<td>{salesRepName}</td>
																		<td className="setting">{ahOrderid}</td>
																		<td className="date text-start">
																			{report.campaignNextReportDate ? (
																				<Moment format="MM/DD/YYYY">
																					{report.campaignNextReportDate}
																				</Moment>
																			) : (
																				''
																			)}
																		</td>
																		<td className="count">
																			<Fragment>
																				{report.campaignReportDayOfMonth}
																			</Fragment>
																		</td>
																		<td
																			className="status"
																			title={report.campaignTimeZone}
																		>
																			{tzAbbr}
																		</td>
																		<td className="count">{report.reportsCount}</td>
																		<td className="date-time text-start">
																			{report.dateCreated ? (
																				<Moment format="MM/DD/YYYY hh:mm A">
																					{report.dateCreated}
																				</Moment>
																			) : (
																				''
																			)}
																		</td>
																		<td className="task-action text-end">
																			<Link
																				target="_blank"
																				className="btn btn-link py-0 px-1"
																				to={`/setup/${report.campaignId}`}
																				title="Edit Campaign"
																			>
																				<i className="fa-solid fa-pen" />
																			</Link>
																			<Link
																				target="_blank"
																				className="btn btn-link py-0 px-1"
																				to={`/campaigns/report/${report.campaignId}`}
																				title="Generate Report"
																			>
																				<i className="fa-solid fa-circle-play" />
																			</Link>
																			<a
																				href={reportUrl}
																				target="_blank"
																				className="btn btn-link py-0 px-1"
																				title="View Report"
																			>
																				<i className="fa-solid fa-magnifying-glass-chart" />
																			</a>
																			<button
																				className="btn btn-link py-0 px-1"
																				title="Generate Content"
																				onClick={(e) =>
																					toggleGenerateContentDialog(
																						report.campaignId
																					)}
																			>
																				<i className="fa-solid fa-file-lines" />
																			</button>
																			<button
																				className="btn btn-link py-0 px-1"
																				onClick={() =>
																					handleDeleteReportHistoryItem(
																						report.id
																					)}
																				title="Delete Report"
																			>
																				<i className="fa-solid fa-trash-can" />
																			</button>
																		</td>
																	</tr>
																);
															})
														) : (
															<tr>
																<td className="p-3" colSpan={8}>
																	<i>No ready reports</i>
																</td>
															</tr>
														)}
													</tbody>
													<TableFooter
														list={readyReports}
														colspan={8}
														showRefreshButton={true}
														onListRefresh={() => getReportHistory(true)}
													/>
												</table>
											)}
										</div>
									</Fragment>
								)}

								<div
									className={`tab-pane fade ${user.isAdmin || user.isSalesUser ? '' : 'show active'}`}
									id="sent-reports-tab-pane"
									role="tabpanel"
									aria-labelledby="sent-reports-tab"
									tabIndex="0"
								>
									{isLoadingHistory ? (
										<Spinner />
									) : (
										<table className="items-list campaigns-list table table-responsive">
											<thead>
												<tr>
													{/* <th scope="col">#</th> */}
													<th scope="col">Name</th>
													{(user.isAdmin || user.isSalesUser) && (
														<Fragment>
															<th className="count" scope="col">
																Opened
															</th>
															<th className="count" scope="col">
																Clicked
															</th>
														</Fragment>
													)}
													<th className="date text-start" scope="col">
														Next Report Date
													</th>
													<th className="date-time text-start" scope="col">
														Sent
													</th>
													<th scope="col" />
												</tr>
											</thead>
											<tbody>
												{sentReports && sentReports.length > 0 ? (
													sentReports.map((report, index) => (
														<tr key={index}>
															{/* <th scope="row">{index + 1}</th> */}
															<td>{report.campaignName}</td>
															{(user.isAdmin || user.isSalesUser) && (
																<Fragment>
																	<td className="count">
																		{report.opens ? (
																			<button
																				className="btn btn-link py-0 fw-bold text-decoration-underline"
																				title="View Details"
																				onClick={() =>
																					toggleTrackingDetailsDialog(report)}
																			>
																				{report.opens}
																			</button>
																		) : (
																			'0'
																		)}
																	</td>
																	<td className="count">
																		{report.clicks ? (
																			<button
																				className="btn btn-link py-0 fw-bold text-decoration-underline"
																				title="View Details"
																				onClick={() =>
																					toggleTrackingDetailsDialog(report)}
																			>
																				{report.clicks}
																			</button>
																		) : (
																			'0'
																		)}
																	</td>
																</Fragment>
															)}
															<td className="date text-start">
																{report.campaignNextReportDate ? (
																	<Moment format="MM/DD/YYYY">
																		{report.campaignNextReportDate}
																	</Moment>
																) : (
																	''
																)}
															</td>
															<td className="date-time text-start">
																{report.dateSent ? (
																	<Moment format="MM/DD/YYYY hh:mm A">
																		{report.dateSent}
																	</Moment>
																) : (
																	''
																)}
															</td>
															<td className="task-action text-end">
																{(user.isAdmin || user.isSalesUser) && (
																	<Fragment>
																		<Link
																			target="_blank"
																			className="btn btn-link py-0"
																			to={`/setup/${report.campaignId}`}
																			title="Edit Campaign"
																		>
																			<i className="fa-solid fa-pen" />
																		</Link>
																		<a
																			href={
																				report.shareUrl ? (
																					report.shareUrl.replace(
																						'https://app.gbpboost.com',
																						host
																					)
																				) : (
																					report.shareUrl
																				)
																			}
																			target="_blank"
																			className="btn btn-link py-0"
																			title="View Report"
																		>
																			<i className="fa-solid fa-magnifying-glass-chart" />
																		</a>
																		<button
																			className="btn btn-link py-0"
																			title="Re-send Report"
																			onClick={() =>
																				toggleSendReportDialog(report)}
																		>
																			<i className="fa-regular fa-envelope" />
																		</button>
																	</Fragment>
																)}
																<a
																	href={`/customer/report/${report.id}`}
																	target="_blank"
																	className="btn btn-link py-0"
																	title="View Customer Report Info"
																>
																	<i className="fa-solid fa-up-right-from-square" />
																</a>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td className="p-3" colSpan={5}>
															<i>No sent reports</i>
														</td>
													</tr>
												)}
											</tbody>
											<TableFooter
												list={sentReports}
												colspan={6}
												showRefreshButton={true}
												onListRefresh={() => getReportHistory(true)}
											/>
										</table>
									)}
								</div>
							</div>
						</div>

						{(user.isAdmin || user.isSalesUser) && (
							<Fragment>
								{/* ----- content ----- */}
								<div
									className="tab-pane fade p-3"
									id="content-tab-pane"
									role="tabpanel"
									aria-labelledby="content-tab"
									tabIndex="0"
								>
									<ul className="nav nav-tabs" id="tabContent" role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className="nav-link active"
												id="ready-content-tab"
												data-bs-toggle="tab"
												data-bs-target="#ready-content-tab-pane"
												type="button"
												role="tab"
												aria-controls="ready-content-tab-pane"
												aria-selected="true"
											>
												Ready
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link"
												id="completed-content-tab"
												data-bs-toggle="tab"
												data-bs-target="#completed-content-tab-pane"
												type="button"
												role="tab"
												aria-controls="completed-content-tab-pane"
												aria-selected="true"
											>
												Completed
											</button>
										</li>
									</ul>
									<div className="tab-content border" id="tabContentContent">
										<div
											className="tab-pane position-relative fade show active"
											id="ready-content-tab-pane"
											role="tabpanel"
											aria-labelledby="ready-content-tab"
											tabIndex="0"
										>
											{isLoadingHistory ? (
												<Spinner />
											) : (
												<Fragment>
													<div className="mark-as-completed-wrapper">
														<div className="form-check form-check-inline ms-3">
															<input
																type="checkbox"
																className="form-check-input"
																id="chkShowReadyContentTypeContent"
																defaultChecked={true}
																onChange={handleContentCheckboxChange}
																ref={chkShowReadyContentTypeContentRef}
															/>
															<label
																htmlFor="chkShowReadyContentTypeContent"
																className="form-label"
															>
																Show Content
															</label>
														</div>
														<div className="form-check form-check-inline ms-3">
															<input
																type="checkbox"
																className="form-check-input"
																id="chkShowReadyContentTypeContentUpdate"
																defaultChecked={true}
																onChange={handleContentCheckboxChange}
																ref={chkShowReadyContentTypeContentUpdateRef}
															/>
															<label
																htmlFor="chkShowReadyContentTypeContentUpdate"
																className="form-label"
															>
																Show Content Update
															</label>
														</div>
														<button
															className="btn btn-primary ms-3"
															onClick={markAllReadyContentAsCompleted}
														>
															Mark All As Completed
														</button>
													</div>
													<div className="table-wrapper">
														<table className="items-list campaigns-list table table-responsive">
															<thead>
																<tr>
																	{/* <th scope="col">#</th> */}
																	<th scope="col">Name</th>
																	<th scope="col">AH Order Id</th>
																	<th scope="col">Type</th>
																	<th scope="col">Processed</th>
																	<th className="date-time" scope="col">
																		Start Time
																	</th>
																	<th className="date" scope="col">
																		Elapsed Time
																	</th>
																	<th scope="col" />
																</tr>
															</thead>
															<tbody>
																{readyContentRecords &&
																readyContentRecords.length > 0 ? (
																	readyContentRecords.map((readyContent, index) => {
																		let elapsedTime = readyContent.dateCreated
																			? getElapsedTime(readyContent.dateCreated)
																			: '';

																		return (
																			<tr key={index}>
																				{/* <th scope="row">{index + 1}</th> */}
																				<td>{readyContent.campaignName}</td>
																				<td>
																					{readyContent.campaignAHOrderId}
																				</td>
																				<td>{readyContent.type}</td>
																				<td>{`${readyContent.isProcessingContent
																					? 'Content'
																					: ''} ${readyContent.isProcessingHeadlines
																					? 'Headlines'
																					: ''}`}</td>
																				<td className="date-time">
																					{readyContent.dateCreated ? (
																						<Moment format="MM/DD/YYYY hh:mm A">
																							{readyContent.dateCreated}
																						</Moment>
																					) : (
																						''
																					)}
																				</td>
																				<td className="date">{elapsedTime}</td>
																				<td className="task-action text-end">
																					<Link
																						target="_blank"
																						className="btn btn-link py-0 px-1"
																						to={`/setup/${readyContent.campaignId}`}
																						title="Edit Campaign"
																					>
																						<i className="fa-solid fa-pen" />
																					</Link>
																					<Link
																						target="_blank"
																						className="btn btn-link py-0 px-1"
																						to={`/campaigns/report/${readyContent.campaignId}`}
																						title="Generate Report"
																					>
																						<i className="fa-solid fa-circle-play" />
																					</Link>
																					{/* <a
																				href={reportUrl}
																				target="_blank"
																				className="btn btn-link py-0 px-1"
																				title="View Report"
																			>
																				<i className="fa-solid fa-magnifying-glass-chart" />
																			</a> */}
																					{readyContent.campaignProcessId && (
																						<button
																							target="_blank"
																							className="btn btn-link py-0"
																							title="View Details"
																							onClick={(e) =>
																								toggleJobDetailsDialog(
																									e,
																									readyContent.campaignProcessId,
																									readyContent.id
																								)}
																						>
																							<i className="fa-solid fa-circle-info" />
																						</button>
																					)}
																					<button
																						className="btn btn-link py-0 px-1"
																						onClick={() =>
																							handleDeleteReportHistoryItem(
																								readyContent.id
																							)}
																						title="Delete Content"
																					>
																						<i className="fa-solid fa-trash-can" />
																					</button>
																				</td>
																			</tr>
																		);
																	})
																) : (
																	<tr>
																		<td className="p-3" colSpan={7}>
																			<i>No ready content records</i>
																		</td>
																	</tr>
																)}
															</tbody>
															<TableFooter
																list={readyContentRecords}
																colspan={7}
																showRefreshButton={true}
																onListRefresh={() => getReportHistory(true)}
															/>
														</table>
													</div>
												</Fragment>
											)}
										</div>
										<div
											className="tab-pane fade"
											id="completed-content-tab-pane"
											role="tabpanel"
											aria-labelledby="completed-content-tab"
											tabIndex="0"
										>
											{isLoadingHistory ? (
												<Spinner />
											) : (
												<div className="table-wrapper">
													<table className="items-list campaigns-list table table-responsive">
														<thead>
															<tr>
																{/* <th scope="col">#</th> */}
																<th scope="col">Name</th>
																<th scope="col">Type</th>
																<th scope="col">Processed</th>
																<th className="date text-start" scope="col">
																	Next Report Date
																</th>
																<th className="date-time text-start" scope="col">
																	Completed
																</th>
																<th scope="col" />
															</tr>
														</thead>
														<tbody>
															{completedContentRecords &&
															completedContentRecords.length > 0 ? (
																completedContentRecords.map(
																	(completedContentItem, index) => (
																		<tr key={index}>
																			{/* <th scope="row">{index + 1}</th> */}
																			<td>{completedContentItem.campaignName}</td>
																			<td>{completedContentItem.type}</td>
																			<td
																			>{`${completedContentItem.isProcessingContent
																				? 'Content'
																				: ''} ${completedContentItem.isProcessingHeadlines
																				? 'Headlines'
																				: ''}`}</td>
																			<td className="date text-start">
																				{completedContentItem.campaignNextReportDate ? (
																					<Moment format="MM/DD/YYYY">
																						{
																							completedContentItem.campaignNextReportDate
																						}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="date-time text-start">
																				{completedContentItem.dateSent ? (
																					<Moment format="MM/DD/YYYY hh:mm A">
																						{completedContentItem.dateSent}
																					</Moment>
																				) : (
																					''
																				)}
																			</td>
																			<td className="task-action text-end">
																				<Link
																					target="_blank"
																					className="btn btn-link py-0"
																					to={`/setup/${completedContentItem.campaignId}`}
																					title="Edit Campaign"
																				>
																					<i className="fa-solid fa-pen" />
																				</Link>
																			</td>
																		</tr>
																	)
																)
															) : (
																<tr>
																	<td className="p-3" colSpan={7}>
																		<i>No completed content records</i>
																	</td>
																</tr>
															)}
														</tbody>
														<TableFooter
															list={completedContentRecords}
															colspan={6}
															showRefreshButton={true}
															onListRefresh={() => getReportHistory(true)}
														/>
													</table>
												</div>
											)}
										</div>
									</div>
								</div>

								{/* ----- offers ----- */}
								<div
									className="tab-pane fade position-relative p-3"
									id="offers-tab-pane"
									role="tabpanel"
									aria-labelledby="offers-tab"
									tabIndex="0"
								>
									{isLoadingOffers ? (
										<Spinner />
									) : (
										<OffersList
											allOffers={userOffersListView}
											onSubmit={() => {
												getOffers();
												getSystemOffers();
											}}
											onListRefresh={() => getOffers(true)}
										/>
									)}
								</div>

								{user.isAdmin && (
									<Fragment>
										{/* ----- system offers ----- */}
										<div
											className="tab-pane fade position-relative p-3"
											id="system-offers-tab-pane"
											role="tabpanel"
											aria-labelledby="system-offers-tab"
											tabIndex="0"
										>
											{isLoadingOffers ? (
												<Spinner />
											) : (
												<OffersList
													allOffers={systemOffersListView}
													onSubmit={() => {
														getOffers();
														getSystemOffers();
													}}
													onListRefresh={() => getSystemOffers(true)}
													isSystemList={true}
												/>
											)}
										</div>
									</Fragment>
								)}

								{/* ----- payments ----- */}
								<div
									className="tab-pane fade position-relative p-3"
									id="payments-tab-pane"
									role="tabpanel"
									aria-labelledby="payments-tab"
									tabIndex="0"
								>
									<ul className="nav nav-tabs" id="tabPayments" role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className="nav-link active"
												id="pending-payments-tab"
												data-bs-toggle="tab"
												data-bs-target="#pending-payments-tab-pane"
												type="button"
												role="tab"
												aria-controls="pending-payments-tab-pane"
												aria-selected="true"
											>
												Pending
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className="nav-link"
												id="paid-payments-tab"
												data-bs-toggle="tab"
												data-bs-target="#paid-payments-tab-pane"
												type="button"
												role="tab"
												aria-controls="paid-payments-tab-pane"
												aria-selected="true"
											>
												Paid
											</button>
										</li>
									</ul>
									<div className="tab-content border" id="tabPaymentsContent">
										<div
											className="tab-pane position-relative fade show active"
											id="pending-payments-tab-pane"
											role="tabpanel"
											aria-labelledby="pending-payments-tab"
											tabIndex="0"
										>
											{isLoadingPayments ? (
												<Spinner />
											) : (
												<PaymentsList
													payments={pendingPaymentsListView}
													onSubmit={() => {
														getPayments();
													}}
													onListRefresh={() => getPayments(true)}
													status="pending"
												/>
											)}
										</div>
										<div
											className="tab-pane position-relative fade"
											id="paid-payments-tab-pane"
											role="tabpanel"
											aria-labelledby="paid-payments-tab"
											tabIndex="0"
										>
											{isLoadingPayments ? (
												<Spinner />
											) : (
												<PaymentsList
													payments={paidPaymentsListView}
													onSubmit={() => {
														getPayments();
													}}
													onListRefresh={() => getPayments(true)}
													status="paid"
												/>
											)}
										</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>

					{showSendReportDialog &&
					editedHistoryRecord && (
						<SendReportDialog
							campaignId={editedHistoryRecord.campaignId}
							reportHistoryId={editedHistoryRecord.id}
							onClose={toggleSendReportDialog}
						/>
					)}

					{showGenerateContentDialog && (
						<GenerateContentDialog
							campaignId={generateContentCampaignId}
							onClose={toggleGenerateContentDialog}
						/>
					)}

					{showJobDetailsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<h4 className="mb-3">Job Details</h4>
								<form className="row">
									{jobData &&
										Object.keys(jobData).map((jobDataItemKey, index) => {
											let itemValue = jobData[jobDataItemKey];
											let parsedItemValue = '';

											if (
												jobDataItemKey !== 'historyContentItemId' &&
												jobDataItemKey !== 'error'
											) {
												if (typeof itemValue === 'object') {
													for (var itemValueKey in itemValue) {
														if (itemValue.hasOwnProperty(itemValueKey)) {
															parsedItemValue +=
																itemValue[itemValueKey] +
																(jobDataItemKey === 'keywordItems' ? '\n' : ', ');
														}
													}

													if (parsedItemValue && parsedItemValue.length > 2) {
														parsedItemValue = parsedItemValue.substring(
															0,
															parsedItemValue.length - 2
														);
													}
												} else {
													parsedItemValue = itemValue;
												}

												if (parsedItemValue === true) {
													parsedItemValue = 'Yes';
												} else if (parsedItemValue === false) {
													parsedItemValue = 'No';
												}

												return (
													<div className="col-12 mb-2" key={index}>
														<label className="form-label">
															<strong>{`${jobDataItemKey}:`}</strong>
														</label>
														{jobDataItemKey === 'keywordItems' ? (
															<textarea
																className="form-control"
																rows={6}
																value={parsedItemValue}
																readOnly
															/>
														) : (
															<label className="form-label ms-2">{parsedItemValue}</label>
														)}
													</div>
												);
											}
										})}
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row w-100">
									<div className="text-start col-4">
										<button
											className="btn btn-outline-primary me-5"
											type="button"
											onClick={(e) => toggleJobDetailsDialog(e)}
										>
											Cancel
										</button>
									</div>

									{jobData &&
									jobData.type &&
									(jobData.type === 'Content' || jobData.type === 'Content Update') && (
										<div className="text-end col-8 mt-2">
											<input
												type="checkbox"
												className="form-check-input"
												name="isContentRecordCompleted"
												id="isContentRecordCompleted"
												onChange={(e) =>
													markContentRecordAsCompleted(
														jobData.historyContentItemId,
														jobData.id,
														e.target.checked
													)}
												// disabled={
												// 	isGeneratingContent ||
												// 	isRegeneratingContent ||
												// 	isGettingKeywordItems
												// }
											/>
											<label
												htmlFor="isContentRecordCompleted"
												className="form-check-label ms-2 w-auto"
											>
												Set As Completed
											</label>
										</div>
									)}
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showViewJobErrorsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<h4 className="mb-4">Job Errors</h4>
								<p className="mb-2">Following content was not updated:</p>
								<form className="row">
									{jobErrorData &&
										Object.keys(jobErrorData).map((errorItemKey, index) => {
											let errorItemData = jobErrorData[errorItemKey];
											let parsedItemValue = '';

											if (typeof errorItemData === 'object') {
												let isHeadline = errorItemData.hasOwnProperty('IsHeadline')
													? errorItemData['IsHeadline'] === true
														? 'Headline | '
														: 'Content | '
													: '';
												let keyword = errorItemData.hasOwnProperty('Keyword')
													? 'Keyword: ' + errorItemData['Keyword'] + ' | '
													: '';
												let location = errorItemData.hasOwnProperty('Location')
													? 'Location: ' + errorItemData['Location']
													: '';
												let locationState = errorItemData.hasOwnProperty('LocationState')
													? ', ' + errorItemData['LocationState']
													: '';

												parsedItemValue =
													'- ' + isHeadline + keyword + location + locationState;
											}

											return (
												<div className="col-12" key={index}>
													<label className="form-label">{parsedItemValue}</label>
												</div>
											);
										})}
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row w-100">
									<div className="text-start col-4">
										<button
											className="btn btn-outline-primary me-5"
											type="button"
											onClick={(e) => toggleViewJobErrorsDialog()}
										>
											Cancel
										</button>
									</div>
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showTrackingDetailsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<h4 className="mb-4">Report Tracking Details</h4>
								<form className="row">
									{trackingDetailsData && (
										<Fragment>
											<div className="col-5">
												<label className="form-label">
													<strong>Date</strong>
												</label>
											</div>
											<div className="col-4">
												<label className="form-label">
													<strong>IP Address</strong>
												</label>
											</div>
											<div className="col-3">
												<label className="form-label">
													<strong>Type</strong>
												</label>
											</div>

											{trackingDetailsData.map((trackingItem, index) => {
												let trackingItemDate = moment(trackingItem.date).format(
													'DD/MM/YYYY HH:mm:ss'
												);

												return (
													<Fragment key={index}>
														<div className="col-5">
															<label className="form-label">{trackingItemDate}</label>
														</div>
														<div className="col-4">
															<label className="form-label">
																{trackingItem.ipAddress}
															</label>
														</div>
														<div className="col-3">
															<label className="form-label">{trackingItem.type}</label>
														</div>
													</Fragment>
												);
											})}
										</Fragment>
									)}
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row w-100">
									<div className="text-start col-4">
										<button
											className="btn btn-outline-primary me-5"
											type="button"
											onClick={(e) => toggleTrackingDetailsDialog()}
										>
											Cancel
										</button>
									</div>
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showOrderDetailsDialog && (
						<Fragment>
							{isLoadingDialogDetails && (
								<div className="text-center">
									<Spinner />
								</div>
							)}

							{!isLoadingDialogDetails && (
								<ModalDialog position="right-sidebar" className="text-start">
									<ModalDialogContent align="start">
										<h4 className="mb-3">
											{orderDetailsData && orderDetailsData.id ? 'Edit Order' : 'New Order'}
										</h4>
										<form className="row order-details-form">
											<div className="col-12 mb-3">
												<label htmlFor="firstName" className="form-label">
													First Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="firstName"
													value={orderDetailsData.firstName || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
													required
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="lastName" className="form-label">
													Last Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="lastName"
													value={orderDetailsData.lastName || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
													required
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="email" className="form-label">
													Email:
												</label>
												<input
													type="text"
													className="form-control"
													name="email"
													value={orderDetailsData.email || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
													required
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="phoneNumber" className="form-label">
													Phone Number:
												</label>
												<input
													type="text"
													className="form-control"
													name="phoneNumber"
													value={orderDetailsData.phoneNumber || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="salesUsersSelect" className="form-label">
													Sales Rep:
												</label>
												{salesReps &&
												salesReps.length &&
												selectedSalesRep && (
													<Creatable
														options={salesReps}
														isClearable
														required
														defaultValue={selectedSalesRep}
														onChange={(e) =>
															setSelectedSalesRep(e || { value: '', label: '' })}
														disabled={isSavingOrderDetails}
													/>
												)}
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="productName" className="form-label">
													Product Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="productName"
													value={orderDetailsData.productName || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="ahOrderId" className="form-label">
													AH Order Id:
												</label>
												<input
													type="text"
													className="form-control"
													name="ahOrderId"
													value={orderDetailsData.ahOrderId || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
												/>
											</div>
											<div className="col-12 mb-0">
												<label htmlFor="subscriptionID" className="form-label">
													Subscription Id:
												</label>
												<input
													type="text"
													className="form-control"
													name="subscriptionID"
													value={orderDetailsData.subscriptionID || ''}
													onChange={handleOrderDetailsDataChange}
													disabled={isSavingOrderDetails}
													required
												/>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons>
										<button className="btn btn-primary" type="button" onClick={saveOrderDetails}>
											{isSavingOrderDetails ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Saving</span>
												</Fragment>
											) : (
												<span>Save</span>
											)}
										</button>
										{orderDetailsData &&
										orderDetailsData.id && (
											<button
												className="btn btn-primary"
												type="button"
												onClick={() => duplicateOrder(orderDetailsData.id)}
											>
												{isSavingOrderDetails ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Duplicating</span>
													</Fragment>
												) : (
													<span>Duplicate</span>
												)}
											</button>
										)}
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={toggleOrderDetailsDialog}
										>
											Cancel
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}
						</Fragment>
					)}

					{showRequirementsDetailsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<h4 className="mb-3">
									{requirementsDetailsData && requirementsDetailsData.id ? (
										<Fragment>
											{requirementsDetailsData.status === 'Approval' ? (
												'Edit Requirements'
											) : (
												'Requirements'
											)}
										</Fragment>
									) : (
										'New Requirements'
									)}
								</h4>
								<form className="row requirements-details-form">
									{isLoadingDialogDetails ? (
										<div className="text-center">
											<Spinner />
										</div>
									) : (
										<Fragment>
											{/* <div className="col-12 mb-3">
												<label htmlFor="req_order" className="form-label">
													Order:
												</label>
												<select
													className="form-select"
													name="req_order"
													onChange={handleRequirementsDetailsDataChange}
													disabled={isSavingOrderDetails || isSendingForApproval}
													placeholder="- Select Order -"
													value={requirementsDetailsData.order || ''}
												>
													{activeOrdersForSelect && (
														<Fragment>
															<option value="">- Select Order -</option>
															<Fragment>
																{activeOrdersForSelect.map((order) => (
																	<option value={order.id} key={order.id}>
																		{`${order.firstName} ${order.lastName} (${order.email})`}
																	</option>
																))}
															</Fragment>
														</Fragment>
													)}
												</select>
											</div> */}

											<div className="col-12 mb-3">
												<label htmlFor="req_user" className="form-label">
													User:
												</label>
												<div className="input-group">
													{/* <input
														type="text"
														className="form-control"
														name="req_user"
														value={selectedUser ? selectedUser.label : ''}
														onChange={handleRequirementsDetailsDataChange}
														disabled={true}
													/>
													<button
														className="btn btn-primary"
														onClick={(e) => toggleSelectUserDialog(e)}
													>
														Select
													</button> */}
													<div>{selectedUser ? selectedUser.email : ''}</div>
												</div>
											</div>

											<div className="col-12 mb-4">
												<label htmlFor="salesUsersSelect" className="form-label">
													Sales Rep:
												</label>
												{salesReps &&
												salesReps.length &&
												requirementSelectedSalesRep && (
													<Select
														options={salesReps}
														isClearable
														required
														defaultValue={requirementSelectedSalesRep}
														onChange={(e) =>
															setRequirementSelectedSalesRep(
																e || { value: '', label: '' }
															)}
														isDisabled={isSavingOrderDetails || isSendingForApproval}
													/>
												)}
											</div>

											<div className="col-12">
												<ul className="nav nav-tabs" id="tabRequirementsDetails" role="tablist">
													<li
														className="nav-item"
														role="presentation"
														key="requirements-details"
													>
														<button
															className={`nav-link active`}
															id="requirements-details-tab"
															data-bs-toggle="tab"
															data-bs-target="#requirements-details-tab-pane"
															type="button"
															role="tab"
															aria-controls="requirements-details-tab-pane"
															aria-selected="true"
														>
															Details
														</button>
													</li>

													{requirementsInitialData &&
													requirementsInitialData.Id && (
														<li
															className="nav-item"
															role="presentation"
															key="requirements-submitted-data"
														>
															<button
																className={`nav-link`}
																id="requirements-submitted-data-tab"
																data-bs-toggle="tab"
																data-bs-target="#requirements-submitted-data-tab-pane"
																type="button"
																role="tab"
																aria-controls="requirements-submitted-data-tab-pane"
																aria-selected="true"
															>
																Submitted Data
															</button>
														</li>
													)}
												</ul>

												<div className="tab-content border" id="tabRequirementsDetailsContent">
													<div
														className={`tab-pane fade show active p-3`}
														id="requirements-details-tab-pane"
														role="tabpanel"
														aria-labelledby="requirements-details-tab"
														tabIndex="0"
													>
														<div>
															<div className="col-12 mb-3">
																<label htmlFor="req_company" className="form-label">
																	Company:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_company"
																	value={requirementsDetailsData.company || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																/>
															</div>
															<div className="col-12 mb-3">
																<label htmlFor="req_website" className="form-label">
																	Website Url:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_website"
																	value={requirementsDetailsData.website || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
															<div className="col-12 mb-3">
																<label
																	htmlFor="req_googleBusinessUrl"
																	className="form-label"
																>
																	Google Maps Url:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_googleBusinessUrl"
																	value={
																		requirementsDetailsData.googleBusinessUrl || ''
																	}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
															<div className="col-12 mb-3">
																<label htmlFor="req_address" className="form-label">
																	Business Address:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_address"
																	value={requirementsDetailsData.address || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
															<div className="col-12 mb-3">
																<label
																	htmlFor="req_businessPhone"
																	className="form-label"
																>
																	Business Phone:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_businessPhone"
																	value={requirementsDetailsData.businessPhone || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
															<div className="col-12 mb-3">
																<label
																	htmlFor="req_specialInstructions"
																	className="form-label"
																>
																	Special Instructions:
																</label>
																<textarea
																	className="form-control"
																	name="req_specialInstructions"
																	value={
																		requirementsDetailsData.specialInstructions ||
																		''
																	}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	rows={5}
																/>
															</div>
															<div className="col-12 mb-3">
																<label htmlFor="req_industry" className="form-label">
																	Primary Service / Industry:
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_industry"
																	value={requirementsDetailsData.industry || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
															<div className="col-12 mb-3">
																<label htmlFor="req_services" className="form-label">
																	Top 5 Services:
																</label>
																<textarea
																	className="form-control"
																	name="req_services"
																	value={requirementsDetailsData.services || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	rows={5}
																	required
																/>
															</div>
															<div className="col-12">
																<label htmlFor="req_keywords" className="form-label">
																	Keywords:
																</label>
																<textarea
																	className="form-control"
																	name="req_keywords"
																	value={requirementsDetailsData.keywords || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	rows={5}
																	required
																/>
															</div>
															<div className="col-12 pt-2">
																<hr />
															</div>
															<div className="col-12 mb-3">
																<label htmlFor="req_mobilePhone" className="form-label">
																	Mobile Phone (for SMS notifications):
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="req_mobilePhone"
																	value={requirementsDetailsData.mobilePhone || ''}
																	onChange={handleRequirementsDetailsDataChange}
																	disabled={
																		isSavingOrderDetails || isSendingForApproval
																	}
																	required
																/>
															</div>
														</div>
													</div>
													<div
														className={`tab-pane fade p-3`}
														id="requirements-submitted-data-tab-pane"
														role="tabpanel"
														aria-labelledby="requirements-submitted-data-tab"
														tabIndex="0"
													>
														<div>
															{requirementsInitialData &&
																Object.keys(
																	requirementsInitialData
																).map((reqDataItemKey, index) => {
																	let itemValue =
																		requirementsInitialData[reqDataItemKey];
																	let parsedItemValue = '';

																	if (
																		reqDataItemKey !== 'userId' &&
																		reqDataItemKey !== 'campaign' &&
																		reqDataItemKey !== 'order' &&
																		reqDataItemKey !== 'id'
																	) {
																		if (itemValue) {
																			if (typeof itemValue === 'object') {
																				for (var itemValueKey in itemValue) {
																					if (reqDataItemKey === 'user') {
																						if (
																							itemValueKey !==
																								'firstName' &&
																							itemValueKey !== 'lastName'
																						) {
																							continue;
																						}

																						if (
																							itemValue.hasOwnProperty(
																								itemValueKey
																							)
																						) {
																							parsedItemValue +=
																								itemValue[
																									itemValueKey
																								] + ' ';
																						}
																					} else {
																						if (
																							itemValue.hasOwnProperty(
																								itemValueKey
																							)
																						) {
																							parsedItemValue +=
																								itemValue[
																									itemValueKey
																								] + ', ';
																						}
																					}
																				}

																				if (
																					parsedItemValue &&
																					parsedItemValue.length > 2 &&
																					parsedItemValue.trim().endsWith(',')
																				) {
																					parsedItemValue = parsedItemValue.substring(
																						0,
																						parsedItemValue.length - 2
																					);
																				}
																			} else {
																				if (
																					reqDataItemKey === 'keywords' ||
																					reqDataItemKey === 'services'
																				) {
																					itemValue = itemValue.replaceAll(
																						'\n',
																						', '
																					);
																					// .toString();
																				} else if (
																					reqDataItemKey === 'dateCreated'
																				) {
																					itemValue = moment(
																						itemValue
																					).format('DD/MM/YYYY HH:mm');
																				}

																				parsedItemValue = itemValue;
																			}

																			if (parsedItemValue === true) {
																				parsedItemValue = 'Yes';
																			} else if (parsedItemValue === false) {
																				parsedItemValue = 'No';
																			}
																		}

																		return (
																			<div className="col-12 mb-2" key={index}>
																				<label className="form-label">
																					<strong
																					>{`${reqDataItemKey}:`}</strong>
																				</label>
																				<label className="form-label ms-2">
																					{parsedItemValue}
																				</label>
																			</div>
																		);
																	}
																})}
														</div>
													</div>
												</div>
											</div>
										</Fragment>
									)}
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								{(!requirementsDetailsData.id || requirementsDetailsData.status !== 'Completed') && (
									<button
										className="btn btn-primary"
										type="button"
										onClick={() => saveRequirementsDetails()}
									>
										{isSavingOrderDetails ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Saving</span>
											</Fragment>
										) : (
											<span>Save</span>
										)}
									</button>
								)}

								{requirementsDetailsData.id && (
									<Fragment>
										{(requirementsDetailsData.status === 'Active' ||
											requirementsDetailsData.status === 'Approval') && (
											<button
												className="btn btn-primary"
												type="button"
												onClick={() => saveRequirementsDetails(true)}
											>
												{isSendingForApproval ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Sending</span>
													</Fragment>
												) : (
													<span>Send For Approval</span>
												)}
											</button>
										)}

										{requirementsDetailsData.status !== 'Completed' && (
											<button
												className="btn btn-primary"
												type="button"
												onClick={setOrderAsApproved}
											>
												{isApproving ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Approving</span>
													</Fragment>
												) : (
													<span>Set Approved</span>
												)}
											</button>
										)}
									</Fragment>
								)}

								<button
									className="btn btn-outline-primary ms-2"
									type="button"
									onClick={toggleRequirementsDetailsDialog}
								>
									Cancel
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showSelectUserDialog &&
					allUsersForSelect && (
						<Fragment>
							<ModalDialog position="right-sidebar" className="text-start">
								<ModalDialogContent align="start">
									<h4 className="mb-3">Create/Select User</h4>
									<form className="row user-details-form">
										{isLoadingDialogDetails ? (
											<div className="text-center">
												<Spinner />
											</div>
										) : (
											<Fragment>
												{isCreatingNewUserDialog ? (
													<div className="col-12 mb-3 text-center">
														<Spinner />
													</div>
												) : (
													<Fragment>
														<div className="col-12 mb-3">
															{allUsersForSelect && (
																<Select
																	options={allUsersForSelect}
																	isClearable
																	// required
																	value={selectedUser}
																	onChange={(e) => handleUserSelectDataChange(e)}
																/>
															)}
														</div>

														{!showCreateNewUserPanel ? (
															<div>
																<button
																	className="btn btn-link p-0"
																	onClick={() => setShowCreateNewUserPanel(true)}
																>
																	Create New User
																</button>
															</div>
														) : (
															<div>
																<div className="col-12 mb-3">
																	<label
																		htmlFor="userDetails_firstName"
																		className="form-label"
																	>
																		First Name:
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		name="userDetails_firstName"
																		value={userDetailsData.firstName || ''}
																		onChange={handleUserDetailsDataChange}
																		disabled={isSavingOrderDetails}
																		required
																	/>
																</div>
																<div className="col-12 mb-3">
																	<label
																		htmlFor="userDetails_lastName"
																		className="form-label"
																	>
																		Last Name:
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		name="userDetails_lastName"
																		value={userDetailsData.lastName || ''}
																		onChange={handleUserDetailsDataChange}
																		disabled={isSavingOrderDetails}
																		required
																	/>
																</div>
																<div className="col-12 mb-3">
																	<label
																		htmlFor="userDetails_email"
																		className="form-label"
																	>
																		Email:
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		name="userDetails_email"
																		value={userDetailsData.email || ''}
																		onChange={handleUserDetailsDataChange}
																		disabled={isSavingOrderDetails}
																		required
																	/>
																</div>
																<div className="col-12 mb-3">
																	<label
																		htmlFor="userDetails_phoneNumber"
																		className="form-label"
																	>
																		Phone Number:
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		name="userDetails_phoneNumber"
																		value={userDetailsData.phoneNumber || ''}
																		onChange={handleUserDetailsDataChange}
																		disabled={isSavingOrderDetails}
																	/>
																</div>
																<div className="col-12 mb-3">
																	<button
																		className="btn btn-primary"
																		type="button"
																		onClick={saveUserDetails}
																	>
																		{isCreatingNewUserDialog ? (
																			<Fragment>
																				<span className="spinner-border m-0 me-2" />
																				<span>Creating</span>
																			</Fragment>
																		) : (
																			<span>Save</span>
																		)}
																	</button>
																	<button
																		className="btn btn-link p-0 ms-2"
																		onClick={() => setShowCreateNewUserPanel(false)}
																	>
																		Cancel
																	</button>
																</div>
															</div>
														)}
													</Fragment>
												)}
											</Fragment>
										)}
									</form>
								</ModalDialogContent>
								<ModalDialogButtons>
									<button className="btn btn-primary" type="button" onClick={toggleSelectUserDialog}>
										{isSavingOrderDetails ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Saving</span>
											</Fragment>
										) : (
											<span>Save</span>
										)}
									</button>
									<button
										className="btn btn-outline-primary ms-2"
										type="button"
										onClick={(e) => {
											toggleSelectUserDialog();
											// setSelectedUser();
										}}
									>
										Cancel
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						</Fragment>
					)}

					{showApprovalMessagesDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<h4 className="mb-4">Messages</h4>
								{requirementsMessages && (
									<Fragment>
										{requirementsMessages.map((message, index) => {
											let messageDate = moment(message.dateCreated).format('DD/MM/YYYY HH:mm');

											return (
												<Fragment key={index}>
													<div
														className={`message-wrapper ${message.direction.toLowerCase()}`}
													>
														<div className={`message-details`}>
															<pre className="mb-0">{message.message}</pre>
															<div className="message-date">{messageDate}</div>
														</div>
													</div>
												</Fragment>
											);
										})}
									</Fragment>
								)}
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row w-100">
									<div className="text-start col-4">
										<button
											className="btn btn-outline-primary me-5"
											type="button"
											onClick={(e) => toggleRequirementsMessagesDialog()}
										>
											Cancel
										</button>
									</div>
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showOrderSetupDialog &&
					approvedOrderSetup && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<ul className="nav nav-tabs" id="tabApprovedOrder" role="tablist">
									<li className="nav-item" role="presentation" key="approved-order-details">
										<button
											className={`nav-link`}
											id="approved-order-details-tab"
											data-bs-toggle="tab"
											data-bs-target="#approved-order-details-tab-pane"
											type="button"
											role="tab"
											aria-controls="approved-order-details-tab-pane"
											aria-selected="true"
										>
											Order Details
										</button>
									</li>
									<li className="nav-item" role="presentation" key="approved-order-setup">
										<button
											className={`nav-link active`}
											id="approved-order-setup-tab"
											data-bs-toggle="tab"
											data-bs-target="#approved-order-setup-tab-pane"
											type="button"
											role="tab"
											aria-controls="approved-order-setup-tab-pane"
											aria-selected="true"
										>
											Setup
										</button>
									</li>
								</ul>
								<div className="tab-content border" id="tabApprovedOrderContent">
									<div
										className={`tab-pane fade p-3`}
										id="approved-order-details-tab-pane"
										role="tabpanel"
										aria-labelledby="approved-order-details-tab"
										tabIndex="0"
									>
										<form className="row">
											{requirementsDetailsData &&
												Object.keys(requirementsDetailsData).map((reqDataItemKey, index) => {
													let itemValue = requirementsDetailsData[reqDataItemKey];
													let parsedItemValue = '';

													if (
														reqDataItemKey !== 'userId' &&
														reqDataItemKey !== 'campaign' &&
														reqDataItemKey !== 'order' &&
														reqDataItemKey !== 'id' &&
														reqDataItemKey !== 'initialData' &&
														reqDataItemKey !== 'messagesCount' &&
														reqDataItemKey !== 'unreadMessagesCount' &&
														reqDataItemKey !== 'fullName' &&
														reqDataItemKey !== 'firstName' &&
														reqDataItemKey !== 'lastName' &&
														reqDataItemKey !== 'email'
													) {
														if (typeof itemValue === 'object') {
															for (var itemValueKey in itemValue) {
																if (reqDataItemKey === 'user') {
																	if (
																		itemValueKey !== 'firstName' &&
																		itemValueKey !== 'lastName'
																	) {
																		continue;
																	}

																	if (itemValue.hasOwnProperty(itemValueKey)) {
																		parsedItemValue +=
																			itemValue[itemValueKey] + ' ';
																	}
																} else {
																	if (itemValue.hasOwnProperty(itemValueKey)) {
																		parsedItemValue +=
																			itemValue[itemValueKey] + ', ';
																	}
																}
															}

															if (
																parsedItemValue &&
																parsedItemValue.length > 2 &&
																parsedItemValue.trim().endsWith(',')
															) {
																parsedItemValue = parsedItemValue.substring(
																	0,
																	parsedItemValue.length - 2
																);
															}
														} else {
															if (
																reqDataItemKey === 'keywords' ||
																reqDataItemKey === 'services'
															) {
																itemValue = itemValue.split('\n').toString();
															} else if (reqDataItemKey === 'dateCreated') {
																itemValue = moment(itemValue).format(
																	'DD/MM/YYYY HH:mm'
																);
															}

															parsedItemValue = itemValue;
														}

														if (parsedItemValue === true) {
															parsedItemValue = 'Yes';
														} else if (parsedItemValue === false) {
															parsedItemValue = 'No';
														}

														return (
															<div className="col-12 mb-2" key={index}>
																<label className="form-label">
																	<strong>{`${reqDataItemKey}:`}</strong>
																</label>
																<label className="form-label ms-2">
																	{parsedItemValue}
																</label>
															</div>
														);
													}
												})}
										</form>
									</div>
									<div
										className={`tab-pane fade p-3 show active`}
										id="approved-order-setup-tab-pane"
										role="tabpanel"
										aria-labelledby="approved-order-setup-tab"
										tabIndex="0"
									>
										{/* <h4 className="mb-4">Order Setup</h4> */}
										<GbpSelectScreen approvedOrder={approvedOrderSetup} />
									</div>
								</div>
							</ModalDialogContent>
							<ModalDialogButtons>
								<div className="row w-100">
									<div className="text-start col-12">
										{/* <button
											className="btn btn-primary me-2"
											type="button"
											onClick={setOrderForApproval}
										>
											<span>Set For Approval</span>
										</button> */}
										<button
											className="btn btn-outline-primary me-5"
											type="button"
											onClick={(e) => toggleApprovedOrderSetupDialog()}
										>
											Cancel
										</button>
									</div>
								</div>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignsList_NEW;
