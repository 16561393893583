import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	GetCampaign,
	GetCampaignLandingPages,
	AddCampaignLandingPage,
	UpdateCampaignLandingPage,
	GetCampaignLandingPageDetails,
	DeleteCampaignLandingPage,
	UploadCampaignFiles,
	DeleteCampaignFiles,
	GetCampaignFiles
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignLandingPagesScreen.css';
import { toast } from 'react-toastify';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import FileUpload from '../../../components/fileUpload/FileUpload';
import { createGuid } from '../../../utils/Common';

const CampaignLandingPagesScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingDialog, setIsLoadingDialog ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ landingPages, setLandingPages ] = useState([]);
	const [ landingPageImages, setLandingPageImages ] = useState([]);
	const [ formData, setFormData ] = useState({});
	const [ showDialog, setShowDialog ] = useState(false);
	const [ newId, setNewId ] = useState();
	const [ editId, setEditId ] = useState();
	const [ isProcessingHeroImage, setIsProcessingHeroImage ] = useState(false);
	const [ isProcessingAboutUsImage, setIsProcessingAboutUsImage ] = useState(false);
	const [ showImagePreviewDialog, setShowImagePreviewDialog ] = useState(false);
	const [ imagePreviewUrl, setImagePreviewUrl ] = useState();
	const [ heroImageData, setHeroImageData ] = useState();
	const [ aboutUsImageData, setAboutUsImageData ] = useState();
	const [ locationDetails, setLocationDetails ] = useState();
	const { campaignId } = useParams();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignLandingPages();
				getCampaignLandingPagesImages();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;

			setCampaign(response);
		}
	};

	const getCampaignLandingPages = async () => {
		setIsLoading(true);

		const response = await GetCampaignLandingPages(campaignId);

		if (response) {
			let tempLandingPages = response;

			// tempLandingPages = tempLandingPages.sort(function(a, b) {
			// 	return a.orderIndex - b.orderIndex;
			// });

			for (let i = 0; i < tempLandingPages.length; i++) {
				tempLandingPages[i].index = i;
				tempLandingPages[i].state = '';
			}

			setLandingPages(tempLandingPages);
		}

		setIsLoading(false);
	};

	const getCampaignLandingPageDetails = async (id) => {
		setIsLoadingDialog(true);

		const response = await GetCampaignLandingPageDetails(id);

		if (response) {
			let tempLandingPageDetails = response;

			if (tempLandingPageDetails.heroImage) {
				setHeroImageData(landingPageImages.find((x) => x.url === tempLandingPageDetails.heroImage));
			}

			if (tempLandingPageDetails.aboutUsImage) {
				setAboutUsImageData(landingPageImages.find((x) => x.url === tempLandingPageDetails.aboutUsImage));
			}

			setFormData(tempLandingPageDetails);
		}

		setIsLoadingDialog(false);
	};

	const getCampaignLandingPagesImages = async () => {
		const response = await GetCampaignFiles(campaignId);

		if (response) {
			setLandingPageImages(response.filter((x) => x.target.includes('LandingPage')));
		}
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveLandingPage = async (e) => {
		e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSubmitting(true);

		let tempformData = formData;

		if (heroImageData) {
			tempformData.heroImage = heroImageData.url;
		}

		if (aboutUsImageData) {
			tempformData.aboutUsImage = aboutUsImageData.url;
		}

		let response;

		if (editId) {
			response = await UpdateCampaignLandingPage(editId, tempformData);
		} else {
			tempformData.id = newId;
			response = await AddCampaignLandingPage(campaignId, tempformData);
		}

		if (response && response.id) {
			toast.success('Landing page successfully ' + (editId ? 'updated' : 'added'));
			getCampaignLandingPages();
		}

		setIsSubmitting(false);
		setShowDialog(false);
	};

	const handleDeleteLandingPage = async (e, id) => {
		e.preventDefault();

		if (window.confirm('Are you sure that you want to delete selected landing page?')) {
			let response = await DeleteCampaignLandingPage(id);

			if (response) {
				toast.success('Landing page successfully deleted');
				getCampaignLandingPages();
			}
		}
	};

	const handleNext = async () => {
		//e.preventDefault();

		navigate(`/setup/${campaignId}/finish`);
	};

	const handleToggleDialog = async (id) => {
		setShowDialog(!showDialog);

		if (!showDialog) {
			if (id) {
				//edit
				setEditId(id);
				getCampaignLandingPageDetails(id);
			} else {
				//new
				setNewId(createGuid());

				getLocationDetailsByLatLng(campaign.lat, campaign.lng);

				setFormData({
					...formData,
					headline       : 'Check Out Our Google Reviews & Visit Our Website',
					//aboutUsHeadline : `Serving ${locationDetails} Surrounding Areas`,
					aboutUsContent : campaign ? campaign.description : '',
					slug           : createGuid(6)
				});
			}
		} else {
			setFormData({});
			setNewId();
			setEditId();
			setHeroImageData();
			setAboutUsImageData();
		}
	};

	const handleFileUpload = async (files, type) => {
		//upload files on server
		if (files !== undefined) {
			if (type === 'hero') {
				setIsProcessingHeroImage(true);
			} else if (type === 'aboutUs') {
				setIsProcessingAboutUsImage(true);
			}

			let imageId = editId ? editId : newId;

			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('Files', files[i]);
			}

			data.append('Target', `LandingPage-${type}-${imageId}`);

			const response = await UploadCampaignFiles(campaignId, data);

			if (response !== undefined && response.data && response.data.length > 0) {
				let imageUrl = response.data[0].fullCdnUrl;

				setFormData({
					...formData,
					[`${type}Image`]: imageUrl
				});

				getCampaignLandingPagesImages();
			}

			if (type === 'hero') {
				setIsProcessingHeroImage(false);
			} else if (type === 'aboutUs') {
				setIsProcessingAboutUsImage(false);
			}
		}
	};

	useEffect(
		() => {
			if ((editId || newId) && landingPageImages && landingPageImages.length > 0) {
				let id = editId || newId;

				let lpHeroImage = landingPageImages.find((x) => x.target.includes(id) && x.target.includes('hero'));
				let lpAboutUsImage = landingPageImages.find(
					(x) => x.target.includes(id) && x.target.includes('aboutUs')
				);

				if (lpHeroImage) {
					setHeroImageData(lpHeroImage);
				}

				if (lpAboutUsImage) {
					setAboutUsImageData(lpAboutUsImage);
				}
			}
		},
		[ editId, newId, landingPageImages ]
	);

	const handleDeleteImage = async (e, id, type) => {
		e.preventDefault();

		//delete image from database
		if (window.confirm('Are you sure that you want to delete this image?')) {
			if (type === 'hero') {
				setIsProcessingHeroImage(true);
			} else if (type === 'aboutUs') {
				setIsProcessingAboutUsImage(true);
			}

			let data = {
				campaignId : campaignId,
				ids        : [ id ]
			};

			const response = await DeleteCampaignFiles(campaignId, data);

			if (response.success) {
				setFormData({
					...formData,
					[`${type}Image`]: null
				});

				if (type === 'hero') {
					setHeroImageData();
				} else if (type === 'aboutUs') {
					setAboutUsImageData();
				}

				getCampaignLandingPagesImages();
			}

			if (type === 'hero') {
				setIsProcessingHeroImage(false);
			} else if (type === 'aboutUs') {
				setIsProcessingAboutUsImage(false);
			}
		}
	};

	const toggleImagePreviewDialog = async (e, url) => {
		setShowImagePreviewDialog(!showImagePreviewDialog);

		if (!showImagePreviewDialog) {
			setImagePreviewUrl(url);
		} else {
			setImagePreviewUrl();
		}

		if (e) e.preventDefault();
	};

	const getLocationDetailsByLatLng = async (lat, lng) => {
		const google = window.google;
		const geocoder = new google.maps.Geocoder();

		var latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

		geocoder.geocode(
			{
				location : latlng
			},
			async function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					let city = '';
					let stateCode = '';

					if (results[0]) {
						let address_components = results[0].address_components;

						for (let i = 0; i < address_components.length; i++) {
							if (
								address_components[i].types[0] === 'locality' &&
								address_components[i].types[1] === 'political'
							) {
								city = address_components[i].long_name;
							}
							if (
								address_components[i].types[0] === 'administrative_area_level_1' &&
								address_components[i].types[1] === 'political'
							) {
								stateCode = address_components[i].short_name;
							}
						}
					}

					setLocationDetails(`${city ? `${city}, ` : ''}${stateCode}`);
				}
			}
		);
	};

	useEffect(
		() => {
			if (locationDetails) {
				setFormData({
					...formData,
					aboutUsHeadline : locationDetails ? `Serving ${locationDetails} Surrounding Areas` : ''
				});
			}
		},
		[ locationDetails ]
	);

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				<Fragment>
					<div className="campaign-form-container">
						{isLoading && <Spinner />}

						<div className="row">
							<div className="col-12">
								<button
									className="btn btn-primary mb-3"
									type="button"
									onClick={() => handleToggleDialog()}
								>
									Add Landing Page
								</button>
							</div>
						</div>

						<div className="row mb-1">
							<div className="col-12">
								{!isLoading && landingPages && landingPages.length > 0 ? (
									<table className="table border landing-pages-table">
										<thead>
											<tr>
												<th scope="col" className="headline">
													<span>Headline</span>
												</th>
												<th scope="col" className="headline">
													<span>About Us Headline</span>
												</th>
												<th scope="col" className="status">
													<span>Slug</span>
												</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{landingPages.map((landingPage, index) => (
												<tr key={landingPage.id}>
													<td className="headline">{landingPage.headline}</td>
													<td className="headline">{landingPage.aboutUsHeadline}</td>
													<td className="status">{landingPage.slug}</td>
													<td className="keyword-action text-end">
														<button
															href="#"
															className="btn btn-link p-0"
															onClick={() => handleToggleDialog(landingPage.id)}
															title="Edit Landing Page"
														>
															<i className="fa-solid fa-pen" />
														</button>
														<a
															href={
																campaign ? (
																	`https://${campaign.url}/lp/${landingPage.slug}`
																) : (
																	'#'
																)
															}
															className="btn btn-link p-0 ms-2"
															target="_blank"
															title="Open Landing Page"
														>
															<i className="fa-solid fa-up-right-from-square" />
														</a>
														<button
															href="#"
															className="btn btn-link p-0 ms-2"
															onClick={(e) => handleDeleteLandingPage(e, landingPage.id)}
															title="Delete Landing Page"
														>
															<i className="fa-solid fa-trash-can" />
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<div className="p-2 border w-100">
										<i>No landing pages</i>
									</div>
								)}
							</div>
						</div>

						{showDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									{editId && isLoadingDialog ? (
										<Spinner />
									) : (
										<form className="row">
											<div className="col-12 mb-3">
												<label htmlFor="headline" className="form-label">
													Headline:
												</label>
												<input
													type="text"
													className="form-control"
													name="headline"
													value={formData.headline || ''}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
											</div>
											<div className="col-12">
												<label htmlFor="heroImage" className="form-label">
													Hero Image:
												</label>
											</div>
											<div className="col-12 mb-3">
												<div className="row">
													<div className="col-4">
														<FileUpload
															onFileChange={handleFileUpload}
															imageType="hero"
															buttonText="Upload Image"
															multiple={false}
															isDisabled={isSubmitting}
														/>
													</div>
													<div className="image-item my-1 col-8 text-end">
														<Fragment>
															{isProcessingHeroImage ? (
																<Spinner className="spinner-border-sm" />
															) : (
																<Fragment>
																	{heroImageData && (
																		<Fragment>
																			<span className="image-name me-4">
																				{heroImageData.name}
																			</span>
																			<span className="image-preview">
																				<a
																					className="btn btn-link app-link-button me-2"
																					onClick={(e) =>
																						toggleImagePreviewDialog(
																							e,
																							heroImageData.url
																						)}
																					title="Preview"
																				>
																					<i className="fa-solid fa-eye me-0" />
																				</a>
																			</span>
																			<span className="image-delete">
																				<a
																					className="btn btn-link app-link-button me-2"
																					onClick={(e) =>
																						handleDeleteImage(
																							e,
																							heroImageData.id,
																							'hero'
																						)}
																					title="Delete"
																				>
																					<i className="fa-solid fa-xmark me-0 text-danger" />
																				</a>
																			</span>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</Fragment>
													</div>
												</div>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="aboutUsHeadline" className="form-label">
													About Us Headline:
												</label>
												<input
													type="text"
													className="form-control"
													name="aboutUsHeadline"
													value={formData.aboutUsHeadline || ''}
													onChange={handleChange}
													disabled={isSubmitting}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="aboutUsContent" className="form-label">
													About Us Content:
												</label>
												<textarea
													className="form-control"
													name="aboutUsContent"
													value={formData.aboutUsContent || ''}
													onChange={handleChange}
													rows={5}
													disabled={isSubmitting}
												/>
											</div>
											<div className="col-12">
												<label htmlFor="heroImage" className="form-label">
													About Us Image:
												</label>
											</div>
											<div className="col-12 mb-3">
												<div className="row">
													<div className="col-4">
														<FileUpload
															onFileChange={handleFileUpload}
															imageType="aboutUs"
															buttonText="Upload Image"
															multiple={false}
															isDisabled={isSubmitting}
														/>
													</div>
													<div className="image-item my-1 col-8 text-end">
														<Fragment>
															{isProcessingAboutUsImage ? (
																<Spinner className="spinner-border-sm" />
															) : (
																<Fragment>
																	{aboutUsImageData && (
																		<Fragment>
																			<span className="image-name me-4">
																				{aboutUsImageData.name}
																			</span>
																			<span className="image-preview">
																				<a
																					className="btn btn-link app-link-button me-2"
																					onClick={(e) =>
																						toggleImagePreviewDialog(
																							e,
																							aboutUsImageData.url
																						)}
																					title="Preview"
																				>
																					<i className="fa-solid fa-eye me-0" />
																				</a>
																			</span>
																			<span className="image-delete">
																				<a
																					className="btn btn-link app-link-button me-2"
																					onClick={(e) =>
																						handleDeleteImage(
																							e,
																							aboutUsImageData.id,
																							'aboutUs'
																						)}
																					title="Delete"
																				>
																					<i className="fa-solid fa-xmark me-0 text-danger" />
																				</a>
																			</span>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</Fragment>
													</div>
												</div>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="slug" className="form-label">
													Slug:
												</label>
												<input
													type="text"
													className="form-control"
													name="slug"
													value={formData.slug || ''}
													onChange={handleChange}
													disabled={isSubmitting}
													required
												/>
											</div>
										</form>
									)}
								</ModalDialogContent>
								<ModalDialogButtons>
									<Fragment>
										<button
											className="btn btn-primary"
											type="button"
											onClick={handleSaveLandingPage}
										>
											{isSubmitting ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Saving</span>
												</Fragment>
											) : (
												<span>Save</span>
											)}
										</button>
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={handleToggleDialog}
											disabled={isSubmitting}
										>
											Cancel
										</button>
									</Fragment>
								</ModalDialogButtons>
							</ModalDialog>
						)}

						{showImagePreviewDialog && (
							<ModalDialog className="text-start" position="full-screen">
								<ModalDialogContent>
									<div className="container-fluid d-flex h-100 p-0 align-items-center justify-content-center">
										{imagePreviewUrl && <img className="mh-100 border" src={imagePreviewUrl} />}
									</div>
								</ModalDialogContent>
								<ModalDialogButtons>
									<div className="row">
										<div className="text-start col-12">
											<button
												className="btn btn-outline-primary ms-2"
												onClick={(e) => toggleImagePreviewDialog(e)}
											>
												<span>Close</span>
											</button>
										</div>
									</div>
								</ModalDialogButtons>
							</ModalDialog>
						)}
					</div>

					<CampaignButtonsContainer
						campaign={campaign}
						showSaveButton={false}
						onSaveNext={() => handleNext()}
						saveNextButtonText="Next"
						disabled={isSubmitting}
					/>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignLandingPagesScreen;
