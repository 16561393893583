import { Fragment, useEffect, useState } from 'react';
import GridMapPreview from './GridMapPreview';
import './GridReportPreview.css';

const GridReportPreview = ({
	gridData,
	lat,
	lng,
	radiusInMiles,
	numberOfPoints,
	numbers,
	width,
	height,
	hideCircle,
	onCircleMove
}) => {
	const [ targetKeyword, setTargetKeyword ] = useState();

	useEffect(
		() => {
			if (gridData) {
				setTargetKeyword({
					lat              : lat,
					lng              : lng,
					horizontalPoints : numberOfPoints,
					verticalPoints   : numberOfPoints,
					...(gridData && gridData.data ? gridData.data[0] : null)
				});
			}
		},
		[ gridData ]
	);

	const handleCircleMove = (center) => {
		if (onCircleMove) {
			onCircleMove(center);
		}
	};

	return (
		<div className="grid-report">
			<Fragment>
				{targetKeyword && (
					<GridMapPreview
						key="map-0"
						gridData={targetKeyword}
						lat={lat}
						lng={lng}
						radiusInMiles={radiusInMiles}
						numberOfPoints={numberOfPoints}
						numbers={numbers}
						width={width}
						height={height}
						hideCircle={hideCircle}
						onCircleMove={handleCircleMove}
					/>
				)}
			</Fragment>
		</div>
	);
};

export default GridReportPreview;
