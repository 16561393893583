import { Fragment, useEffect, useState } from 'react';
import GridKeywordPicker from './gridKeywordPicker/GridKeywordPicker';
import './GridReportV2.css';
import GenerateSeoReport from '../generateSeoReport/GenerateSeoReport';
import { DeduplicateRankResults, GetReportSettings, MergeReportBackward, MergeReportForward } from '../../Services';
import GridMapV5 from './v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';

const GridReportV2 = ({
	gridData,
	placeId,
	placeCid,
	showAllKeywords,
	campaign,
	onReportKeywordUpdated,
	onKeywordSelected,
	reportId = null,
	onLoadingReport = null
}) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ targetKeyword, setTargetKeyword ] = useState();
	const [ reportSettings, setReportSettings ] = useState();

	useEffect(() => {
		getReportSettings();
	}, []);

	useEffect(
		() => {
			if (gridData && !showAllKeywords) {
				let tempTargetKeyword = {
					lat              : gridData.lat,
					lng              : gridData.lng,
					horizontalPoints : gridData.horizontalPoints,
					verticalPoints   : gridData.verticalPoints,
					...gridData.data[0]
				};

				setTargetKeyword(tempTargetKeyword);

				if (onKeywordSelected) {
					onKeywordSelected(tempTargetKeyword);
				}
			}
		},
		[ gridData, placeId, placeCid, showAllKeywords ]
	);

	const handleKeywordClick = (keyword) => {
		let tempTargetKeyword = {
			lat              : gridData.lat,
			lng              : gridData.lng,
			horizontalPoints : gridData.horizontalPoints,
			verticalPoints   : gridData.verticalPoints,
			...keyword
		};

		setTargetKeyword(tempTargetKeyword);

		if (onKeywordSelected) {
			onKeywordSelected(tempTargetKeyword);
		}
	};

	const handleUpdateReportKeyword = async (report) => {
		if (onReportKeywordUpdated) {
			onReportKeywordUpdated(report);
		}
	};

	const handleMergeReportForward = async () => {
		if (
			window.confirm(
				'Are you sure that you want to merge selected report forward for keyword "' +
					targetKeyword.keyword +
					'"?'
			)
		) {
			if (onLoadingReport) {
				onLoadingReport(true);
			}

			//let tempSelectedReport = selectedReport;
			//setSelectedReport();

			// let tempSelectedKeyword = keywordSelected;
			// setKeywordSelected();

			let data = {
				id            : campaign.id,
				keywords      : [ targetKeyword.keyword ],
				runGridReport : false,
				runRankReport : false
			};

			const response = await MergeReportForward(reportId, data);

			if (response.success) {
				let mergedReport = {
					id : reportId
				};

				toast.success('Report successfully merged');

				//////////////handleReportSelection(mergedReport);
			}

			if (onLoadingReport) {
				onLoadingReport(false);
			}
		}
	};

	const handleMergeReportBackward = async () => {
		if (
			window.confirm(
				'Are you sure that you want to merge selected report backward for keyword "' +
					targetKeyword.keyword +
					'"?'
			)
		) {
			if (onLoadingReport) {
				onLoadingReport(true);
			}

			// let tempSelectedReport = selectedReport;
			// setSelectedReport();

			let data = {
				id            : campaign.id,
				keywords      : [ targetKeyword.keyword ],
				runGridReport : false,
				runRankReport : false
			};

			const response = await MergeReportBackward(reportId, data);

			if (response.success) {
				let mergedReport = {
					id : reportId
				};

				toast.success('Report successfully merged');

				///////////////handleReportSelection(mergedReport);
			}

			if (onLoadingReport) {
				onLoadingReport(false);
			}
		}
	};

	const handleDeduplicateRankResults = async () => {
		if (window.confirm('Are you sure that you want to remove duplicate location records for selected report"?')) {
			if (onLoadingReport) {
				onLoadingReport(true);
			}

			// let tempSelectedReport = selectedReport;
			// setSelectedReport();

			let data = {
				id            : campaign.id,
				runGridReport : false,
				runRankReport : false
			};

			const response = await DeduplicateRankResults(reportId, data);

			if (response.success) {
				let mergedReport = {
					id : reportId
				};

				toast.success('Duplicate records successfully removed');

				//////////////handleReportSelection(mergedReport);
			}

			if (onLoadingReport) {
				onLoadingReport(false);
			}
		}
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	return (
		<div className="grid-report">
			<h6 className="display-5">Grid Report</h6>

			{!showAllKeywords ? (
				<Fragment>
					<GridKeywordPicker
						gridData={gridData}
						activeKeyword={targetKeyword}
						onKeywordSelected={handleKeywordClick}
					/>

					<div className="d-flex mt-2">
						<GenerateSeoReport
							campaign={campaign}
							onComplete={handleUpdateReportKeyword}
							//onStartReport={() => setSelectedReport()}
							reportId={reportId}
							activeKeyword={targetKeyword}
							buttonText="Re-Run Keyword"
							disabled={isLoading}
						/>
						<button
							type="button"
							className="btn btn-primary mb-4 ms-3 campaign-report-input"
							onClick={handleMergeReportForward}
							disabled={isLoading}
						>
							<span>Merge Forward</span>
						</button>
						<button
							type="button"
							className="btn btn-primary mb-4 ms-3 campaign-report-input"
							onClick={handleMergeReportBackward}
							disabled={isLoading}
						>
							<span>Merge Backward</span>
						</button>
						<button
							type="button"
							className="btn btn-primary mb-4 ms-3 campaign-report-input"
							onClick={handleDeduplicateRankResults}
							disabled={isLoading}
						>
							<span>Deduplicate Rank Results</span>
						</button>
					</div>

					{targetKeyword &&
					reportSettings && (
						// <GridMapV2 key="map-0" gridData={targetKeyword} placeId={placeId} placeCid={placeCid} />

						<GridMapV5
							key="map-0"
							gridData={targetKeyword}
							placeId={placeId}
							placeCid={placeCid}
							isFullScreen={false}
							reportSettings={reportSettings}
							fixedHeight={800}
						/>
					)}
				</Fragment>
			) : (
				<Fragment>
					{gridData &&
					gridData.data &&
					gridData.data.length > 0 &&
					reportSettings && (
						<Fragment>
							{gridData.data.map((keyword, index) => {
								const keywordData = {
									lat              : gridData.lat,
									lng              : gridData.lng,
									horizontalPoints : gridData.horizontalPoints,
									verticalPoints   : gridData.verticalPoints,
									...keyword
								};

								return (
									<Fragment key={`fragment-${index}`}>
										<h5 key={`title-${index}`} className="mt-3 mb-3">
											{keyword.keyword}
										</h5>
										{/* <GridMapV2
											key={`map-${index}`}
											gridData={keywordData}
											placeId={placeId}
											placeCid={placeCid}
										/> */}
										<GridMapV5
											key={`map-${index}`}
											gridData={keywordData}
											placeId={placeId}
											placeCid={placeCid}
											isFullScreen={false}
											reportSettings={reportSettings}
										/>
									</Fragment>
								);
							})}
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default GridReportV2;
