import { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../../components/spinner/Spinner';
import './MapSettingsScreen.css';
import { GenerateSEOReportPreview } from '../../Services';
import GridReportPreview from '../mapSettingsScreen/GridReportPreview';

const MapSettingsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ address, setAddress ] = useState('');
	const [ radius, setRadius ] = useState('10');
	const [ points, setPoints ] = useState('15');
	const [ numbers, setNumbers ] = useState('');
	const [ lat, setLat ] = useState();
	const [ lng, setLng ] = useState();
	const [ selectedReport, setSelectedReport ] = useState();
	const [ distance, setDistance ] = useState('');
	const [ mapWidth, setMapWidth ] = useState('');
	const [ mapHeight, setMapHeight ] = useState('');
	const [ hideCircle, setHideCircle ] = useState(false);
	const addressRef = useRef();
	const radiusRef = useRef();
	const numbersRef = useRef();

	const handleGenerateReport = async (e) => {
		e.preventDefault();

		setIsGenerating(true);
		setDistance('');

		if (address.trim() === '') {
			setIsGenerating(false);
			addressRef.current.classList.add('required-highlight');
			addressRef.current.focus();
			return;
		}

		if (radius.trim() === '') {
			setIsGenerating(false);
			radiusRef.current.focus();
			return;
		}

		addressRef.current.classList.remove('required-highlight');
		radiusRef.current.classList.remove('required-highlight');

		//get lat and lng by address
		const google = window.google;
		const geocoder = new google.maps.Geocoder();

		geocoder.geocode(
			{
				address : address
			},
			async function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					setLat(results[0].geometry.location.lat());
					setLng(results[0].geometry.location.lng());

					let data = {
						lat                    : results[0].geometry.location.lat(),
						lng                    : results[0].geometry.location.lng(),
						gridPoints             : points,
						mapCircleRadiusInMiles : radius,
						numbers                : numbers
					};

					setIsLoading(true);

					const response = await GenerateSEOReportPreview(data);

					if (response.success) {
						setSelectedReport(response);

						let pointDistance = radius / ((points - 1) / 2);
						setDistance(pointDistance.toFixed(2));
					} else {
						console.error(response);
					}

					setIsGenerating(false);
				}
			}
		);
	};

	const updateReport = async (e) => {
		e.preventDefault();

		setIsGenerating(true);
		setDistance('');

		if (address.trim() === '') {
			setIsGenerating(false);
			addressRef.current.classList.add('required-highlight');
			addressRef.current.focus();
			return;
		}

		if (radius.trim() === '') {
			setIsGenerating(false);
			radiusRef.current.focus();
			return;
		}

		addressRef.current.classList.remove('required-highlight');
		radiusRef.current.classList.remove('required-highlight');

		let data = {
			lat                    : lat,
			lng                    : lng,
			gridPoints             : points,
			mapCircleRadiusInMiles : radius,
			numbers                : numbers
		};

		setIsLoading(true);

		const response = await GenerateSEOReportPreview(data);

		if (response.success) {
			setSelectedReport(response);

			let pointDistance = radius / ((points - 1) / 2);
			setDistance(pointDistance.toFixed(2));
		} else {
			console.error(response);
		}

		setIsGenerating(false);
	};

	const handleCircleMove = (center) => {
		if (center) {
			setLat(center.lat);
			setLng(center.lng);
		}
	};

	return (
		<Fragment>
			<div className="campaign-v-p-report text-start">
				<div className="report-header container d-block">
					<Fragment>
						<div className="">
							{/* <h5 className="mb-3">Settings</h5> */}
							<form onSubmit={handleGenerateReport}>
								<div className="row mb-3">
									<div className="col-lg-4 col-12">
										<label htmlFor="address" className="form-label">
											Address:
										</label>
										<input
											type="text"
											ref={addressRef}
											className="form-control"
											id="address"
											placeholder="Address"
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</div>
									<div className="col-lg-2 col-12">
										<label htmlFor="radius" className="form-label">
											Radius (miles):
										</label>
										<input
											type="number"
											ref={radiusRef}
											className="form-control"
											id="radius"
											placeholder="Radius"
											value={radius}
											onChange={(e) => setRadius(e.target.value)}
											min={1}
											max={100}
											step={1}
										/>
									</div>
									<div className="col-lg-2 col-12">
										<label htmlFor="points" className="form-label">
											Number of Points:
										</label>
										<input
											type="number"
											className="form-control"
											id="points"
											placeholder="Number of Points"
											value={points}
											onChange={(e) => setPoints(e.target.value)}
											min={1}
											max={25}
											step={1}
										/>
									</div>
									<div className="col-lg-auto col-12 d-flex align-items-end">
										<button type="submit" className="btn btn-primary align-self-end">
											<span>Generate Preview</span>
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div>
											{distance && (
												<Fragment>
													<span className="point-distance">
														Point distance: {distance} miles
													</span>
													<span className="point-distance mx-3">|</span>
												</Fragment>
											)}
											{lat && (
												<Fragment>
													<span className="point-distance">Lat: {lat}</span>
													<span className="point-distance mx-3">|</span>
												</Fragment>
											)}
											{lng && <span className="point-distance">Lng: {lng}</span>}
											{lat &&
											lng && (
												<button
													type="button"
													className="btn btn-primary ms-4"
													onClick={updateReport}
												>
													<span>Update</span>
												</button>
											)}
										</div>
									</div>
								</div>
							</form>
						</div>
					</Fragment>
				</div>
				<div className="container">
					{isGenerating ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="map-preview">
								{selectedReport && (
									<Fragment>
										<GridReportPreview
											gridData={selectedReport.gridResponse}
											lat={lat}
											lng={lng}
											radiusInMiles={radius}
											numberOfPoints={points}
											numbers={numbers}
											width={mapWidth}
											height={mapHeight}
											hideCircle={hideCircle}
											onCircleMove={handleCircleMove}
										/>
										<div className="map-settings-form mt-3">
											<form onSubmit={handleGenerateReport}>
												<div className="row mb-2">
													<div className="col-lg-2 col-12">
														<label htmlFor="address" className="form-label">
															Numbers:
														</label>
														<textarea
															ref={numbersRef}
															className="form-control"
															id="numbers"
															placeholder=""
															value={numbers}
															rows={5}
															onChange={(e) => setNumbers(e.target.value)}
														/>
													</div>
													<div className="col-lg-4 col-12">
														<label htmlFor="mapWidth" className="form-label">
															Map Width/Height (px):
														</label>
														<div className="d-flex">
															<input
																type="text"
																className="form-control me-2"
																id="mapWidth"
																placeholder="Map Width"
																value={mapWidth}
																onChange={(e) => setMapWidth(e.target.value)}
															/>
															<input
																type="text"
																className="form-control"
																id="mapHeight"
																placeholder="Map Height"
																value={mapHeight}
																onChange={(e) => setMapHeight(e.target.value)}
															/>
														</div>
														<div className="d-flex mt-3">
															<input
																type="checkbox"
																className="form-check-input me-2"
																id="hideCircle"
																value={hideCircle}
																onChange={(e) => setHideCircle(e.target.checked)}
															/>
															<label htmlFor="hideCircle" className="form-check-label">
																Hide Circle
															</label>
														</div>
													</div>
												</div>
												<div>
													<div className="col-lg-4 col-12">
														<button
															type="submit"
															className="btn btn-primary align-self-end"
														>
															<span>Update Preview</span>
														</button>
													</div>
												</div>
											</form>
										</div>
									</Fragment>
								)}
							</div>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default MapSettingsScreen;
