import { Fragment, useEffect, useState } from 'react';
import CampaignSelector from '../../components/campaignSelector/CampaignSelector';
import GenerateSeoReport from '../../components/generateSeoReport/GenerateSeoReport';
import GridReportV2 from '../../components/gridReport/GridReportV2';
import ReportSelector from '../../components/reportSelector/ReportSelector';
import Spinner from '../../components/spinner/Spinner';
import {
	ArchiveSEOReport,
	GetCampaign,
	GetCampaignReports,
	GetCampaignTimeZones,
	GetSEOReportGridWithSproc,
	GetSEOReportRankWithSproc,
	GetSEOReportV2,
	MergeReportForward,
	MergeSEOReports,
	UpdateCampaign,
	UpdateCampaignReportDate
} from '../../Services';
import './CampaignReportScreen.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ModalDialog from '../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import moment from 'moment';
import { toast } from 'react-toastify';
import { validateDayOfMonth } from '../../utils/Common';
import RankReportV5 from '../../components/gridReport/v5/rankReport/RankReportV5';
import Select from 'react-select';

const CampaignReportScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingReport, setIsLoadingReport ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ defaultCampaignId, setDefaultCampaignId ] = useState();
	const [ reports, setReports ] = useState();
	const [ selectedReport, setSelectedReport ] = useState();
	const [ campaignFormData, setCampaignFormData ] = useState({});
	const { campaignId } = useParams();
	const [ showSettingsDialog, setShowSettingsDialog ] = useState(false);
	const [ keywordSelected, setKeywordSelected ] = useState();
	const [ mergeNumber, setMergeNumber ] = useState(0);
	const [ nextReportDate, setNextReportDate ] = useState(new Date());
	const [ reportDayOfMonth, setReportDayOfMonth ] = useState(1);
	const [ rankResponse, setRankResponse ] = useState();
	const navigate = useNavigate();
	const [ timeZones, setTimeZones ] = useState([]);
	const [ selectedTimeZone, setSelectedTimeZone ] = useState();
	const defaultTZ = 'Eastern Standard Time';
	const [ appendNearMe, setAppendNearMe ] = useState(false);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign(campaignId);
				setDefaultCampaignId(campaignId);
				getTimeZones();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getReports();
			} else {
				setReports();
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && timeZones && timeZones.length) {
				let tz = timeZones.find((x) => x.value === (campaign.timeZone || defaultTZ));

				setSelectedTimeZone(tz);
			}
		},
		[ campaign, timeZones ]
	);

	const handleCampaignSelection = (selectedCampaign) => {
		navigate(`/campaigns/report/${selectedCampaign.id}`);
	};

	const getCampaign = async (id) => {
		const response = await GetCampaign(id);

		if (response) {
			setCampaign(response);
			setSelectedReport();

			let date = response.nextReportDate ? moment(response.nextReportDate) : moment().add(30, 'days');
			setNextReportDate(date.format('yyyy-MM-DD'));
			setReportDayOfMonth(response.reportDayOfMonth || 1);
			setAppendNearMe(response.addNearMeInReports);

			if (showSettingsDialog) {
				setCampaignFormData(response);
			}
		}
	};

	const getTimeZones = async () => {
		let response = await GetCampaignTimeZones();

		if (response && response.data && response.data.length) {
			let tempTimeZones = [];

			for (let i = 0; i < response.data.length; i++) {
				let tzOffsetDesc = response.data[i].text.substring(0, response.data[i].text.indexOf(')') + 1);
				tempTimeZones.push({
					value  : response.data[i].value,
					label  : `${response.data[i].value} ${tzOffsetDesc}`,
					offset : response.data[i].offset,
					utc    : response.data[i].utc
				});
			}

			setTimeZones(tempTimeZones);
		}
	};

	const getReports = async () => {
		setIsLoading(true);

		let response = await GetCampaignReports(campaign.id);

		if (response.success) {
			setReports(
				response.reports.filter((x) => {
					if (x.status) {
						return x.status === 'Active';
					} else {
						return x;
					}
				})
			);
		}

		setIsLoading(false);
	};

	const handleGenerateReport = async (report) => {
		await getReports();
		setSelectedReport(report);

		//update next report date in campaign based on report day of month
		if (campaign) {
			let nextDate;
			let today = new Date();

			if (campaign.reportDayOfMonth) {
				nextDate = `${today.getFullYear()}-${(today.getMonth() + 1 + 1)
					.toString()
					.padStart(2, '0')}-${campaign.reportDayOfMonth.toString().padStart(2, '0')}`;
			} else {
				nextDate = moment().add(1, 'months'); //1 month from today
			}

			await UpdateCampaignReportDate(campaign.id, {
				nextReportDate   : nextDate,
				reportDayOfMonth : reportDayOfMonth
			});
		}
	};

	const handleUpdateReportKeyword = async (report) => {
		//await getReports();
		setSelectedReport(report);
	};

	const handleKeywordSelected = (targetKeyword) => {
		if (targetKeyword) {
			setKeywordSelected(targetKeyword);
		}
	};

	const handleReportSelection = async (report) => {
		setIsLoadingReport(true);

		const response = await GetSEOReportV2(campaign.id, report.id);

		if (response.success && response.data && response.data.length > 0) {
			let tempReport = response.data[0];

			tempReport.reportId = tempReport.shortName;
			setSelectedReport(tempReport);

			setRankResponse(tempReport.rankResponse);
		}

		setIsLoadingReport(false);
	};

	const getReportRankResults = async (reportShortName) => {
		// setIsLoadingRanking(true);

		if (campaign && reports) {
			//get report id (guid)
			let currentReport = reports.find((x) => x.id === reportShortName);

			if (currentReport && currentReport.recId) {
				// let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

				let response = await GetSEOReportRankWithSproc(campaign.id, currentReport.recId);

				if (response.success) {
					setRankResponse(response.rankResponse);
				}
			}
		}
	};

	const handleArchiveReport = async () => {
		if (selectedReport) {
			setIsLoadingReport(true);

			let data = {
				id            : campaign.id,
				runGridReport : false,
				runRankReport : false
			};

			const response = await ArchiveSEOReport(selectedReport.reportId, data);

			if (response.success) {
				await getReports();
				setSelectedReport();
			}

			setIsLoadingReport(false);
		}
	};

	const handleMergeReports = async () => {
		if (reports) {
			if (window.confirm('Are you sure that you want to start merge reports process?')) {
				setIsLoadingReport(true);

				let data = {
					id          : campaign.id,
					mergeNumber : mergeNumber
				};

				const response = await MergeSEOReports(reports[0].id, data);

				if (response.success) {
				}

				setIsLoadingReport(false);
			}
		}
	};

	const handleMergeAllKeywordsForward = async () => {
		if (window.confirm('Are you sure that you want to merge selected report forward for all keywords?')) {
			setIsLoadingReport(true);

			// let tempSelectedReport = selectedReport;
			// setSelectedReport();

			// let tempSelectedKeyword = keywordSelected;
			// setKeywordSelected();

			if (selectedReport && selectedReport.gridResponse && selectedReport.gridResponse.data) {
				let keywordsToMerge = [];

				for (let i = 0; i < selectedReport.gridResponse.data.length; i++) {
					keywordsToMerge.push(selectedReport.gridResponse.data[i].keyword);
				}

				let data = {
					id            : campaign.id,
					keywords      : keywordsToMerge,
					runGridReport : false,
					runRankReport : false
				};

				const response = await MergeReportForward(selectedReport.reportId, data);

				if (response.success) {
					toast.success('Report successfully merged');
					// let mergedReport = {
					// 	id : tempSelectedReport.reportId
					// };
					// handleReportSelection(mergedReport);
					//setKeywordSelected(tempSelectedKeyword);
				}
			}

			setIsLoadingReport(false);
		}
	};

	const handleToggleSettings = async () => {
		setShowSettingsDialog(!showSettingsDialog);

		if (!showSettingsDialog) {
			const response = await GetCampaign(campaign.id);

			if (response) {
				setCampaignFormData(response);
			}
		}
	};

	const handleChange = async (e) => {
		setCampaignFormData({
			...campaignFormData,
			[e.target.name]: e.target.value
		});
	};

	const handleReportDatesChange = async (e) => {
		if (e.target.name === 'reportDayOfMonth') {
			let dayOfMonth = validateDayOfMonth(e.target.value);

			//set next report date
			let dt = new Date();
			let year = dt.getFullYear();
			let month = (dt.getMonth() + 1 + 1).toString().padStart(2, '0');

			setReportDayOfMonth(dayOfMonth);
			setNextReportDate(`${year}-${month}-${dayOfMonth.toString().padStart(2, '0')}`);
		} else {
			setNextReportDate(e.target.value);
		}
	};

	const handleUpdateSettings = async (e) => {
		e.preventDefault();

		const response = await UpdateCampaign(campaign.id, campaignFormData);

		if (response.success) {
			handleToggleSettings();
		}
	};

	const updateCampaignSettings = async () => {
		let tempCampaignData = campaign;
		tempCampaignData.nextReportDate = nextReportDate;
		tempCampaignData.reportDayOfMonth = reportDayOfMonth;
		tempCampaignData.timeZone = selectedTimeZone ? selectedTimeZone.value : 'Eastern Standard Time';
		tempCampaignData.addNearMeInReports = appendNearMe;

		const response = await UpdateCampaign(campaign.id, tempCampaignData);

		if (response && response.success) {
			toast.success('Campaign settings successfully updated');
		}
	};

	const handleLoadingReport = (isLoading) => {
		setIsLoadingReport(isLoading);
	};

	return (
		<div className="campaign-report">
			<Fragment>
				<CampaignSelector onSelected={handleCampaignSelection} defaultValue={defaultCampaignId} />

				<div className="content p-4">
					{!isLoading ? (
						<Fragment>
							{campaign ? (
								<Fragment>
									<div className="d-flex">
										<GenerateSeoReport
											campaign={campaign}
											onComplete={handleGenerateReport}
											onStartReport={() => setSelectedReport()}
											campaignTimezone={selectedTimeZone}
										/>

										<button
											type="button"
											className="btn btn-primary ms-3 mb-4 campaign-report-input"
											onClick={handleMergeReports}
											disabled={isLoading}
										>
											<span>Merge Reports</span>
										</button>
										<input
											type="number"
											name="mergeNumber"
											className="form-control w-auto ms-2 campaign-report-input merge-number"
											onChange={(e) => setMergeNumber(e.target.value)}
											defaultValue={0}
											min={0}
											max={10}
											step={0.1}
										/>
										<Link
											target="_blank"
											className="btn btn-primary ms-3 mb-4 campaign-report-input"
											to={`/view/report/v5/${campaign.id}`}
										>
											<span>View Reports</span>
										</Link>
										<button
											type="button"
											className="btn btn-primary ms-3 mb-4 campaign-report-input"
											onClick={handleToggleSettings}
											disabled={isLoading}
										>
											<span>Settings</span>
										</button>

										<label className="ms-3 mt-2">Day of Month: </label>
										<input
											type="number"
											className="form-control w-auto ms-2 campaign-report-input day-of-month"
											name="reportDayOfMonth"
											value={reportDayOfMonth}
											onChange={handleReportDatesChange}
											min={1}
											max={31}
										/>

										<label className="ms-3 mt-2">Next Report: </label>
										<input
											type="date"
											className="form-control w-auto ms-2 campaign-report-input"
											name="nextReportDate"
											value={nextReportDate}
											onChange={handleReportDatesChange}
										/>

										{timeZones &&
										timeZones.length &&
										selectedTimeZone && (
											<Fragment>
												<label className="ms-3 mt-2">Time Zone: </label>
												<Select
													className="ms-2 campaign-report-input timezone-select"
													options={timeZones}
													defaultValue={selectedTimeZone}
													onChange={(e) => setSelectedTimeZone(e)}
												/>
											</Fragment>
										)}

										<div className="form-check ms-3 mt-2">
											<input
												type="checkbox"
												className="form-check-input"
												id="addNearMeInReports"
												name="addNearMeInReports"
												onChange={(e) => setAppendNearMe(e.target.checked)}
												checked={appendNearMe}
											/>
											<label htmlFor="addNearMeInReports" className="form-check-label w-auto">
												Append "near me"
											</label>
										</div>

										<button
											type="button"
											className="btn btn-primary ms-4 mb-4 campaign-report-input"
											onClick={updateCampaignSettings}
											disabled={isLoading}
											title="Update Next Report Date"
										>
											<span>Update</span>
										</button>
									</div>

									{showSettingsDialog && (
										<ModalDialog>
											<ModalDialogContent align="start">
												<h4 className="mb-3">Campaign Settings</h4>
												<form className="row">
													<div className="col-12 mb-3">
														<label htmlFor="lat" className="form-label">
															Center Point:
														</label>
														<div className="input-group">
															<input
																type="text"
																className="form-control"
																name="lat"
																value={campaignFormData.lat || ''}
																onChange={handleChange}
																required
															/>
															<input
																type="text"
																className="form-control ms-3"
																name="lng"
																value={campaignFormData.lng || ''}
																onChange={handleChange}
																required
															/>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="gridPoints" className="form-label">
															Number of Points:
														</label>
														<input
															type="number"
															className="form-control"
															name="gridPoints"
															value={campaignFormData.gridPoints || ''}
															onChange={handleChange}
															min={1}
															max={25}
															step={1}
														/>
													</div>
													<div className="col-12 mb-3">
														<label
															htmlFor="gridPointsDistanceInMiles"
															className="form-label"
														>
															Point Distance (miles):
														</label>
														<input
															type="number"
															className="form-control"
															name="gridPointsDistanceInMiles"
															value={campaignFormData.gridPointsDistanceInMiles || ''}
															onChange={handleChange}
															min={0.1}
															max={5}
															step={0.1}
														/>
													</div>
												</form>
											</ModalDialogContent>
											<ModalDialogButtons shaded>
												<button className="btn btn-primary" onClick={handleUpdateSettings}>
													<span>Update</span>
												</button>
												<button
													className="btn btn-outline-primary"
													onClick={handleToggleSettings}
												>
													<span>Cancel</span>
												</button>
											</ModalDialogButtons>
										</ModalDialog>
									)}

									{reports &&
									reports.length > 0 && (
										<Fragment>
											<div className="d-flex">
												<h6 className="display-5">Reports</h6>
												<div className="headline-links-wrapper">
													<a
														target="_blank"
														className="btn btn-link headline-link"
														href={campaign.website}
														title="Website Url"
													>
														<i className="fa-solid fa-globe" />
													</a>
													<a
														target="_blank"
														className="btn btn-link headline-link"
														href={
															campaign.url && campaign.url.indexOf('http') > -1 ? (
																campaign.url
															) : (
																`https://${campaign.url}`
															)
														}
														title="Directory Url"
													>
														<i className="fa-solid fa-map-location-dot" />
													</a>
													<a
														target="_blank"
														className="btn btn-link headline-link"
														href={
															campaign.googleCid &&
															`https://google.com/maps/?cid=${campaign.googleCid}`
														}
														title="Google Profile"
													>
														<i className="fa-brands fa-google" />
													</a>
												</div>
											</div>
											<ReportSelector
												reports={reports}
												onReportSelected={handleReportSelection}
												selectedReport={selectedReport}
											/>
										</Fragment>
									)}

									{isLoadingReport ? (
										<Spinner />
									) : (
										<Fragment>
											{selectedReport && (
												<Fragment>
													<button
														type="button"
														className="btn btn-primary mb-4"
														onClick={handleArchiveReport}
														disabled={isLoading}
													>
														<span>Archive Report</span>
													</button>

													<button
														type="button"
														className="btn btn-primary mb-4 ms-3"
														onClick={handleMergeAllKeywordsForward}
														disabled={isLoading}
													>
														<span>Merge All Forward</span>
													</button>

													<div className="campaign-v-p-report-v5 p-0 text-start">
														<GridReportV2
															gridData={selectedReport.gridResponse}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															campaign={campaign}
															reportId={selectedReport.reportId}
															onReportKeywordUpdated={handleUpdateReportKeyword}
															onKeywordSelected={handleKeywordSelected}
															onLoadingReport={handleLoadingReport}
														/>

														{rankResponse && (
															<div className="mt-4">
																<RankReportV5
																	rankData={rankResponse}
																	// compareToRankData={compareToRankResponse}
																	targetKeyword={
																		keywordSelected ? keywordSelected.keyword : null
																	}
																	campaign={campaign}
																/>
															</div>
														)}
													</div>
												</Fragment>
											)}
										</Fragment>
									)}
								</Fragment>
							) : (
								<Fragment>
									<div className="alert alert-info">
										Please select a campaign from the dropdown above
									</div>
								</Fragment>
							)}
						</Fragment>
					) : (
						<Spinner />
					)}
				</div>
			</Fragment>
		</div>
	);
};

export default CampaignReportScreen;
