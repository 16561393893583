import { Fragment, useRef } from 'react';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';

const style = {
	// border          : '1px dashed gray',
	// padding         : '0.5rem 1rem',
	// marginBottom    : '.5rem',
	// backgroundColor : 'white',
	cursor : 'move'
};

const DragAndDropCard = ({ text, index, moveCard, isTableRow = false }) => {
	const [ { handlerId, isOver }, dropRef ] = useDrop({
		accept  : 'card',
		collect(monitor) {
			return {
				isOver    : !!monitor.isOver(),
				handlerId : monitor.getHandlerId()
			};
		},

		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current ? ref.current.getBoundingClientRect() : null;
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		}
	});

	const [ { isDragging }, dragRef, preview ] = useDrag({
		type    : 'card',
		item    : { index },
		collect : (monitor) => ({
			isDragging : monitor.isDragging()
		})
	});

	const ref = useRef(null);
	const dragDropRef = dragRef(dropRef(ref));

	const itemStyle = isDragging
		? {
				':active' : {
					backgroundColor : 'red'
				},
				//backgroundColor : 'red',
				// border    : '1px dashed gray',
				cursor    : 'move'
				//opacity         : 0
			}
		: isOver
			? {
					backgroundColor : '#eeeeee',
					//border  : '1px dashed #dddddd',
					cursor          : 'move',
					opacity         : 1
				}
			: {
					':active' : {
						backgroundColor : 'red'
					},
					cursor    : 'move',
					opacity   : 1
				};

	return (
		<Fragment>
			<DragPreviewImage connect={preview} src="/img/menu-icon.png" key={new Date().getTime()} />

			{isTableRow ? (
				<tr className="dnd-card" ref={dragDropRef} style={itemStyle} data-handler-id={handlerId}>
					{text}
				</tr>
			) : (
				<div className="dnd-card" ref={dragDropRef} style={itemStyle} data-handler-id={handlerId}>
					{text}
				</div>
			)}
		</Fragment>
	);
};

export default DragAndDropCard;
