import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { GenerateSEOReportPreview, GetSalesCompanyDetailsBasic, UpdateSalesCompany } from '../../../Services';
import GridMapPreview from '../../mapSettingsScreen/GridMapPreview';
import './GridSizeSelector.css';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { StoreSalesTask } from '../../../utils/StorageUtil';
import { toast } from 'react-toastify';

const GridSizeSelector = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ company, setCompany ] = useState();
	const [ lat, setLat ] = useState();
	const [ lng, setLng ] = useState();
	const [ selectedReport, setSelectedReport ] = useState();
	const [ selectedGridSize, setSelectedGridSize ] = useState();
	const [ targetKeyword, setTargetKeyword ] = useState();
	const gridSizes = [
		{ name: 'small', points: 9, distance: 0.5 },
		{ name: 'medium', points: 11, distance: 1 },
		{ name: 'large', points: 11, distance: 2 }
	];
	const navigate = useNavigate();
	const { salesCompanyId } = useParams();
	const [ showUpdateLatLngArea, setShowUpdateLatLngArea ] = useState(false);

	useEffect(
		() => {
			if (salesCompanyId) {
				getSalesCompanyDetails();
			}
		},
		[ salesCompanyId ]
	);

	const getSalesCompanyDetails = async () => {
		const response = await GetSalesCompanyDetailsBasic(salesCompanyId);

		if (response) {
			setCompany(response);
			setLat(response.lat ? parseFloat(response.lat).toFixed(5) : null);
			setLng(response.lng ? parseFloat(response.lng).toFixed(5) : null);
		}

		setIsLoading(false);
	};

	useEffect(
		() => {
			if (selectedGridSize) {
				handleGenerateGrid(lat, lng);
			}
		},
		[ selectedGridSize ]
	);

	const handleSelectGridSize = (e) => {
		let gridSize = gridSizes.find((x) => x.name === e.target.value);

		if (gridSize) {
			setSelectedGridSize(gridSize);
		}
	};

	const handleGenerateGrid = async (centerLat, centerLng) => {
		setIsGenerating(true);

		if (!centerLat || !centerLng) {
			toast.error('Lat/lng coordinates are empty');
			setIsGenerating(false);
			return;
		}

		let data = {
			lat                    : centerLat,
			lng                    : centerLng,
			gridPoints             : selectedGridSize.points,
			mapCircleRadiusInMiles : (selectedGridSize.points - 1) / 2 * selectedGridSize.distance
		};

		const response = await GenerateSEOReportPreview(data);

		if (response.success) {
			setSelectedReport(response);

			setTargetKeyword({
				lat              : centerLat,
				lng              : centerLng,
				horizontalPoints : selectedGridSize.points,
				verticalPoints   : selectedGridSize.points,
				...(response.gridResponse && response.gridResponse.data ? response.gridResponse.data[0] : null)
			});
		} else {
			console.error(response);
		}

		setIsGenerating(false);
	};

	const handleContinue = async () => {
		//save lat and lng
		let data = company;
		data.lat = lat;
		data.lng = lng;

		const response = await UpdateSalesCompany(salesCompanyId, data);

		if (response.success) {
			//
		}

		StoreSalesTask({
			companyId        : salesCompanyId,
			lat              : lat,
			lng              : lng,
			horizontalPoints : selectedGridSize.points,
			verticalPoints   : selectedGridSize.points,
			pointDistance    : selectedGridSize.distance
		});

		navigate('/sales/' + salesCompanyId + '/datagrid');
	};

	const handleCircleMove = (center) => {
		if (center) {
			setLat(center.lat);
			setLng(center.lng);

			handleGenerateGrid(center.lat, center.lng);
		}
	};

	const updateLatLng = async (e) => {
		e.preventDefault();

		//save lat and lng
		let data = company;
		data.lat = lat;
		data.lng = lng;

		const response = await UpdateSalesCompany(salesCompanyId, data);

		if (response.success) {
			setShowUpdateLatLngArea(false);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid sales-grid-size-container pb-0">
				<PageHeadline
					headline={`Grid Size Selector ${company ? '- ' + company.name : ''}`}
					linkText="Tasks List"
					linkUrl={company ? `/sales/${company.id}/tasks` : '#'}
					className="pb-4"
				/>

				<div className="container">
					<form>
						<div className="row mb-3">
							<div className="col-6">
								<select
									className="form-select"
									value={selectedGridSize ? selectedGridSize.name : ''}
									onChange={handleSelectGridSize}
								>
									<option value="">- Select Grid Size -</option>
									{gridSizes.map((gridSize, index) => (
										<option key={index} value={gridSize.name}>
											{`${gridSize.name.toUpperCase()} (${gridSize.points}x${gridSize.points}, ${gridSize.distance} ${gridSize.distance ===
											1
												? 'mile'
												: 'miles'} apart)`}
										</option>
									))}
								</select>
							</div>
							<div className="col-6 d-flex justify-content-end align-items-center">
								<span className="p-1">Lat/Lng:</span>
								<div className={`ms-3 ${showUpdateLatLngArea ? '' : 'mt-1'}`}>
									{lat && lng ? (
										<Fragment>{!showUpdateLatLngArea && <span>{`${lat},${lng}`}</span>}</Fragment>
									) : (
										<Fragment>
											{!showUpdateLatLngArea && (
												<strong className="bg-danger text-white rounded p-1 px-2">
													NOT SET
												</strong>
											)}
										</Fragment>
									)}

									{!showUpdateLatLngArea && (
										<button
											className="btn btn-link p-0 pb-1 ms-3"
											onClick={(e) => {
												e.preventDefault();
												setShowUpdateLatLngArea(true);
											}}
										>
											Set
										</button>
									)}

									{showUpdateLatLngArea && (
										<Fragment>
											<div className="input-group">
												<input
													type="text"
													className="form-control rounded"
													name="lat"
													value={lat || ''}
													onChange={(e) => setLat(e.target.value)}
													//disabled={isSavingOrderDetails}
													required
												/>
												<input
													type="text"
													className="form-control ms-2 rounded"
													name="lng"
													value={lng || ''}
													onChange={(e) => setLng(e.target.value)}
													//disabled={isSavingOrderDetails}
													required
												/>
												<button className="btn btn-primary ms-2 rounded" onClick={updateLatLng}>
													Update
												</button>
												<button
													className="btn btn-link"
													onClick={() => setShowUpdateLatLngArea(false)}
												>
													Cancel
												</button>
											</div>
										</Fragment>
									)}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className="container">
					<div className={`map-preview`}>
						<Fragment>
							{targetKeyword && (
								<GridMapPreview
									key="map-0"
									gridData={targetKeyword}
									lat={lat}
									lng={lng}
									radiusInMiles={(selectedGridSize.points - 1) / 2 * selectedGridSize.distance}
									numberOfPoints={selectedGridSize.points}
									onCircleMove={handleCircleMove}
									zoom={11}
								/>
							)}
							{isGenerating && (
								<div className="loading-map">
									<Spinner />
								</div>
							)}
						</Fragment>
					</div>
					{selectedReport && (
						<div className="text-start my-3">
							<button type="button" className="btn btn-primary" onClick={handleContinue}>
								<span>Continue</span>
							</button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default GridSizeSelector;
