import { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { AddProduct, GetProduct, UpdateProduct } from '../../../../Services';
import './ProductDetailsDialog.css';
import Spinner from '../../../spinner/Spinner';

const ProductDetailsDialog = ({ productId, onSubmit, onClose }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ productTags, setProductTags ] = useState('');

	useEffect(
		() => {
			getProduct();
		},
		[ productId ]
	);

	const getProduct = async () => {
		setIsLoading(true);

		if (productId) {
			let response = await GetProduct(productId);

			if (response) {
				setFormData(response);

				if (response.tags) {
					let tagsArray = response.tags.split(',');

					if (tagsArray && tagsArray.length) {
						let tempProductTags = '';

						tagsArray.forEach((tag) => {
							tempProductTags += tag.trim() + '\n';
						});

						setProductTags(tempProductTags);
					}
				}
			}
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		let type = e.target.type;
		let value = e.target.value;

		if (type === 'checkbox') {
			value = e.target.checked;
		}

		setFormData({
			...formData,
			[e.target.name]: value
		});
	};

	const handleSubmit = async () => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSaving(true);

		let data = formData;

		if (productId) {
			data.id = productId;
		}

		if (productTags) {
			let tagsArray = productTags.split('\n');

			if (tagsArray && tagsArray.length) {
				data.tags = '';

				tagsArray.forEach((tag) => {
					data.tags += tag.trim() + ',';
				});

				data.tags = data.tags.substring(0, data.tags.length - 2);
			}
		}

		let response;

		if (productId) {
			response = UpdateProduct(productId, data);
		} else {
			response = AddProduct(data);
		}

		if (response) {
			toast.success('Product ' + (productId ? 'updated' : 'created') + ' successfully');

			setTimeout(function() {
				if (onSubmit) {
					onSubmit();
				}
			}, 500);
		}

		setIsSaving(false);
	};

	const handleOnCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Fragment>
			<ModalDialog position="right-sidebar" className="text-start">
				<ModalDialogContent align="start">
					{isLoading ? (
						<div className="text-center">
							<Spinner />
						</div>
					) : (
						<form className="row send-report-form">
							<div className="col-12 mb-3">
								<label htmlFor="name" className="form-label">
									Name:
								</label>
								<input
									type="text"
									className="form-control"
									name="name"
									value={formData.name || ''}
									onChange={handleChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="description" className="form-label">
									Description:
								</label>
								<textarea
									className="form-control"
									name="description"
									value={formData.description || ''}
									onChange={handleChange}
									disabled={isSaving}
									rows={3}
								/>
							</div>
							{/* <div className="col-12 mb-3">
								<label htmlFor="price" className="form-label">
									Price:
								</label>
								<input
									type="number"
									className="form-control"
									name="price"
									value={formData.price || ''}
									onChange={handleChange}
									disabled={isSaving}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="trialDays" className="form-label">
									Trial Days:
								</label>
								<input
									type="number"
									className="form-control"
									name="trialDays"
									value={formData.trialDays || ''}
									onChange={handleChange}
									disabled={isSaving}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="discount" className="form-label">
									Discount:
								</label>
								<input
									type="number"
									className="form-control"
									name="discount"
									value={formData.discount || ''}
									onChange={handleChange}
									disabled={isSaving}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="tags" className="form-label">
									Tags:
								</label>
								<textarea
									className="form-control"
									name="tags"
									value={productTags}
									onChange={(e) => setProductTags(e.target.value)}
									disabled={isSaving}
									rows={3}
								/>
							</div> */}
							<div className="col-12 mb-3">
								<label htmlFor="successWebhookUrl" className="form-label">
									Success Webhook Url:
								</label>
								<input
									type="text"
									className="form-control"
									name="successWebhookUrl"
									value={formData.successWebhookUrl || ''}
									onChange={handleChange}
									disabled={isSaving}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="failedWebhookUrl" className="form-label">
									Failed Webhook Url:
								</label>
								<input
									type="text"
									className="form-control"
									name="failedWebhookUrl"
									value={formData.failedWebhookUrl || ''}
									onChange={handleChange}
									disabled={isSaving}
								/>
							</div>
						</form>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					<button className="btn btn-primary" type="button" onClick={() => handleSubmit()}>
						{isSaving ? (
							<Fragment>
								<span className="spinner-border m-0 me-2" />
								<span>Saving</span>
							</Fragment>
						) : (
							<span>Save</span>
						)}
					</button>
					<button className="btn btn-outline-primary ms-2" type="button" onClick={handleOnCloseDialog}>
						Cancel
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default ProductDetailsDialog;
