import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	DeleteCampaignFiles,
	DownloadDPImage,
	GetCampaign,
	GetCampaignFiles,
	GetDPImages,
	GetIcons,
	OptimizeImage,
	UploadCampaignFiles
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignFilesScreen.css';
import { toast } from 'react-toastify';
import FileUpload from '../../../components/fileUpload/FileUpload';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { convertUrlToFile, createGuid, imageWebServiceUrl } from '../../../utils/Common';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';

const CampaignFilesScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSearching, setIsSearching ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ files, setFiles ] = useState([]);
	const { campaignId } = useParams();
	const [ showSettings, setShowSettings ] = useState(false);
	const [ showSelectImageDialog, setShowSelectImageDialog ] = useState(false);
	const [ selectedImageType, setSelectedImageType ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ imagesList, setImagesList ] = useState();
	const [ selectedImage, setSelectedImage ] = useState({});
	const [ selectedCategory, setSelectedCategory ] = useState();
	const [ iconsList, setIconsList ] = useState([]);
	const [ iconsListPage, setIconsListPage ] = useState(1);
	const imageTypes = [ 'Hero', 'Featured', 'Logo', 'Reseller', 'About', 'Favicon', 'Gallery' ];
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignFiles();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (selectedImageType) {
				if (selectedImageType === 'Hero' || selectedImageType === 'Gallery') {
					setSelectedCategory('image');
				} else if (selectedImageType === 'Favicon') {
					setSelectedCategory('icon');
				} else {
					setSelectedCategory();
				}
			}
		},
		[ selectedImageType ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
		}
	};

	const getCampaignFiles = async () => {
		setIsLoading(true);

		const response = await GetCampaignFiles(campaignId);

		if (response) {
			setFiles(response);
		}

		setIsLoading(false);
	};

	const toggleSettings = () => {
		setShowSettings(!showSettings);
	};

	const handleFileUpload = async (files) => {
		//upload files on server
		if (files !== undefined) {
			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('Files', files[i]);
			}

			data.append('Target', selectedImageType);

			const response = await UploadCampaignFiles(campaignId, data);

			if (response !== undefined) {
				getCampaignFiles();
			}
		}
	};

	const handleValidateUpload = (e) => {
		if (!selectedImageType) {
			e.preventDefault();

			toast.error('You have to select image type first.');
			return;
		}
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		// //update campaign status
		// await UpdateCampaignStatus(campaignId, { status: 'Files' });

		if (redirect) {
			navigate(`/setup/${campaignId}/content`);
		} else {
			toast.success('Settings saved.');
		}
	};

	const handleDeleteFile = async (id) => {
		if (id) {
			if (window.confirm('Are you sure that you want to delete campaign file?')) {
				let data = {
					campaignId : campaignId,
					ids        : [ id ]
				};

				const response = await DeleteCampaignFiles(campaignId, data);

				if (response.success) {
					getCampaignFiles();
				}
			}
		}
	};

	const toggleSelectImageDialog = () => {
		if (!showSelectImageDialog) {
			if (!selectedImageType) {
				toast.error('You have to select image type first.');
				return;
			}
		}

		setShowSelectImageDialog(!showSelectImageDialog);
	};

	const onImageSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();

			setSearchTerm(e.target.value);

			if (selectedCategory === 'image') {
				getImages();
			} else {
				getIcons(1);
			}
		}
	};

	const getImages = async () => {
		if (searchTerm && searchTerm !== '') {
			setIsSearching(true);

			//DepositPhoto
			let data = {};
			data.Key = 'd8f3d4af64bd433fa95f33f24469e317';
			data.SearchTerm = searchTerm;
			data.Limit = 200;
			data.Orientation = 'horizontal';

			const response = await GetDPImages(data);

			if (response.IsSuccessful) {
				let tempImagesList = JSON.parse(response.Data);

				setImagesList(tempImagesList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsSearching(false);
		}
	};

	const getIcons = async (pageNumber) => {
		if (searchTerm && searchTerm !== '') {
			let currentIconsList = iconsList;
			setIsSearching(true);

			if (pageNumber === undefined || pageNumber === null || pageNumber <= 1) {
				pageNumber = 1;
				setIconsListPage(1);
				setIconsList([]);
				currentIconsList = [];
			}

			let data = {};
			data.Key = 'e9g3d4af64bd434fa95f43f64469e418';
			data.SearchTerm = searchTerm;
			data.Limit = 50;
			data.Page = pageNumber;
			data.Offset = 0;

			const response = await GetIcons(data);

			if (response.IsSuccessful) {
				if (response.Data === null) {
					setIsSearching(false);
					return;
				}

				let tempIconsList = JSON.parse(response.Data);

				if (currentIconsList) {
					tempIconsList = [ ...currentIconsList, ...tempIconsList ];
				}

				setIconsList(tempIconsList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsSearching(false);
		}
	};

	const viewMoreIcons = () => {
		let tempIconsListPage = iconsListPage;
		tempIconsListPage++;
		setIconsListPage(tempIconsListPage);

		getIcons(tempIconsListPage);
	};

	const selectImage = (e) => {
		let cardSelector = selectedCategory === 'image' ? '.image-card' : '.icon-card';
		Array.from(document.querySelectorAll(cardSelector)).forEach(function(el) {
			el.classList.remove('active');
		});

		e.currentTarget.classList.add('active');

		let imageSelector = selectedCategory === 'image' ? '.image-preview img' : '.icon-image';
		let currentImage = e.currentTarget.querySelector(imageSelector);

		switch (selectedImageType) {
			case 'Hero':
			case 'Gallery':
				setSelectedImage({
					name     : selectedImageType.toLowerCase() + `-${createGuid().substring(0, 8)}`,
					url      : currentImage.dataset.urlBig,
					thumbMax : currentImage.dataset.thumbMax,
					dpid     : currentImage.dataset.dpid
				});
				break;
			case 'Favicon':
				setSelectedImage({
					url  : currentImage.src,
					name : selectedImageType.toLowerCase() + `-${createGuid().substring(0, 8)}`
				});
				break;
		}
	};

	const applySelectedImage = async () => {
		if (selectedImage) {
			toggleSelectImageDialog();
			setIsLoading(true);

			let imageUrl = '';

			if (selectedCategory === 'image') {
				//purchase image from DepositPhotos and optimize it
				let downloadData = {
					Key : 'd8f3d4af64bd433fa95f33f24469e317',
					Id  : selectedImage.dpid
				};
				const responseDownload = await DownloadDPImage(downloadData);

				if (responseDownload.IsSuccessful) {
					let tempDownloadData = JSON.parse(responseDownload.Data);

					if (tempDownloadData) {
						//optimize the image
						let dataOptimization = { ImageName: selectedImage.dpid };
						const responseOptimization = await OptimizeImage(dataOptimization);

						if (responseOptimization.IsSuccessful) {
							let optimizedUrl =
								imageWebServiceUrl() +
								'/assets/downloads/depositphotos/optimized/' +
								selectedImage.dpid +
								'.jpg';

							imageUrl = optimizedUrl;
						}
					}
				}
			} else {
				imageUrl = selectedImage.url;
			}

			//upload file
			var file = await convertUrlToFile(imageUrl, selectedImage.name);

			if (file) {
				var data = new FormData();
				data.append('Files', file);
				data.append('Target', selectedImageType);

				const response = await UploadCampaignFiles(campaignId, data);

				if (response !== undefined) {
					getCampaignFiles();
				}
			}

			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<div className="container campaign-files-container">
				<Fragment>
					<div className="row w-100">
						<div className="col-12 px-0">
							<PageHeadline
								headline={`Campaign - ${campaign ? campaign.name : ''}`}
								linkText="Back To List"
								linkUrl="/campaigns/list"
							/>
						</div>

						<CampaignTabs />

						{isLoading && <Spinner />}

						{!isLoading &&
						files &&
						files.length > 0 && (
							<Fragment>
								<table className="table border files-table">
									<thead>
										<tr>
											<th scope="col" className="target">
												<span>Target</span>
											</th>
											<th scope="col" className="name">
												<span>Name</span>
											</th>
											<th scope="col" className="url">
												<span>Url</span>
											</th>
											<th />
										</tr>
									</thead>
									<tbody>
										{files.map((file, index) => (
											<tr key={index}>
												<td className="target">{file.target}</td>
												<td className="name">{file.name}</td>
												<td className="url">
													<a href={file.url} target="_blank">
														{file.url}
													</a>
												</td>
												<td className="file-action text-end">
													<a
														href="#"
														className="btn btn-link p-0"
														onClick={() => handleDeleteFile(file.id)}
													>
														Delete
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Fragment>
						)}

						<Fragment>
							<div className="radio-image-type-wrapper col-12 mt-3">
								{imageTypes &&
									imageTypes.map((imageType) => (
										<label key={imageType}>
											<input
												type="radio"
												value={imageType}
												name="image-type"
												checked={selectedImageType === imageType}
												onChange={() => setSelectedImageType(imageType)}
											/>
											{imageType}
										</label>
									))}
							</div>
							<div className="radio-image-type-wrapper col-12 my-2 p-0">
								<FileUpload onFileChange={handleFileUpload} onClick={handleValidateUpload} />

								{selectedCategory && (
									<button
										className="btn btn-primary ms-2"
										type="button"
										onClick={toggleSelectImageDialog}
									>
										Select
									</button>
								)}
							</div>
						</Fragment>

						<CampaignButtonsContainer
							campaign={campaign}
							onSave={handleSubmit}
							onSaveNext={() => handleSubmit(true)}
						/>
					</div>

					{showSelectImageDialog && (
						<ModalDialog>
							<ModalDialogContent align="start">
								<h4 className="mb-3">Select {selectedCategory === 'image' ? 'Image' : 'Icon'}</h4>
								<form className="row">
									<div className="col-12 mb-2">
										<div className="search-images-wrapper input-group mb-3">
											<input
												type="search"
												className="form-control"
												name="searchTerm"
												value={searchTerm || ''}
												onChange={(e) => setSearchTerm(e.target.value)}
												onKeyDown={onImageSearchKeyDown}
												placeholder="Enter search term"
											/>
											<span className="input-group-append">
												<button
													type="button"
													className="btn btn-primary border btn-search-images"
													onClick={() =>
														selectedCategory === 'image' ? getImages() : getIcons(1)}
												>
													<i className="fa fa-search" />
												</button>
											</span>
										</div>
									</div>
								</form>

								<div
									className={`images-list ${selectedCategory === 'image'
										? ''
										: 'icons'} border text-center`}
								>
									{isSearching ? (
										<Spinner />
									) : (
										<Fragment>
											{selectedCategory === 'image' ? (
												imagesList && (
													<Fragment>
														{imagesList.map((image) => (
															<a
																className="image-card"
																onClick={selectImage}
																key={image.Id}
																id={`image${image.Id}`}
															>
																<div className="image-preview text-center">
																	<img
																		src={image.ThumbLarge}
																		data-dpid={image.Id}
																		data-title={image.Title}
																		data-thumb={image.Thumb}
																		data-thumb-large={image.ThumbLarge}
																		data-thumb-max={image.ThumbMax}
																		data-url-big={image.UrlBig}
																	/>
																</div>
															</a>
														))}
													</Fragment>
												)
											) : (
												<Fragment>
													{iconsList.map((icon) => (
														<a
															className="icon-card"
															onClick={selectImage}
															key={icon.Id}
															id={`icon${icon.Id}`}
														>
															<div className="icon-card-wrapper">
																<div className="icon-preview text-center">
																	<img className="icon-image" src={icon.PreviewUrl} />
																</div>
															</div>
														</a>
													))}
													{iconsList &&
													iconsList.length >= 50 && (
														<div className="icons-list-view-more">
															<a className="btn btn-link" onClick={viewMoreIcons}>
																View More
															</a>
														</div>
													)}
												</Fragment>
											)}
										</Fragment>
									)}
								</div>
							</ModalDialogContent>
							<ModalDialogButtons shaded align="start">
								<button className="btn btn-primary" onClick={applySelectedImage}>
									<span>Select</span>
								</button>
								<button className="btn btn-outline-primary" onClick={toggleSelectImageDialog}>
									<span>Cancel</span>
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignFilesScreen;
