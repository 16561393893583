import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import './KeywordTool.css';
import { useNavigate } from 'react-router-dom';
import { GetKeywordIdeas } from '../../Services';
import { toast } from 'react-toastify';
import Spinner from '../spinner/Spinner';
import CenterContainer from '../centerContainer/CenterContainer';

const KeywordTool = () => {
    const [seedKeyword, setSeedKeyword] = useState('');
    const [country, setCountry] = useState('2840');
    const [{ user }, dispatch] = useStateValue();
    const [isSearching, setIsSearching] = useState(false);
    const [keywordIdeas, setKeywordIdeas] = useState([]);
    const navigation = useNavigate();

    useEffect(() => {
        if (!user) {
            navigation('/login');
        }
    }, [user]);

    const handleSearchClick = async (event) => {
        event.preventDefault();

        let data = {
            seedKeyword: seedKeyword,
            locationId: country
        };

        setIsSearching(true);

        const response = await GetKeywordIdeas(data);

        if (response && response.length > 0) {
            setKeywordIdeas(response);
            console.log(response);
        } else {
            toast.error(response.ErrorMessage || response.Message);
        }

        setIsSearching(false);
    }

    return (
        <div className='container-fluid p-3'>
            <form onSubmit={handleSearchClick}>
                <div className='search-container'>
                    <div className='search-box'>
                        <input type='text' placeholder='Enter seed keyword' value={seedKeyword} onChange={(e) => setSeedKeyword(e.target.value)} className='form-control' />
                    </div>
                    <div className='search-countries'>
                        <select value={country} onChange={(e) => setCountry(e.target.value)} className='form-select'>
                            <option value='2840'>United States</option>
                            <option value="2826">United Kingdom</option>
                            <option value="2124">Canada</option>
                            <option value="2036">Australia</option>
                        </select>
                    </div>
                    <div className='search-button d-grid'>
                        <button type='submit' className='btn btn-primary'>Search</button>
                    </div>
                </div>
            </form>

            {isSearching && (
                <div className='text-cemter'>
                    <Spinner />
                </div>
            )}

            {!isSearching && (
                <Fragment>
                    {keywordIdeas.length > 0 ? (
                        <Fragment>
                            <h2>Keyword Ideas</h2>

                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Search Volume</th>
                                        <th>Competition</th>
                                        <th>CPC</th>
                                        <th>Low TOP Bid</th>
                                        <th>High TOP Bid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {keywordIdeas.map((keyword, index) => (
                                        <tr key={index}>
                                            <td>{keyword.keyword}</td>
                                            <td>{keyword.searchVolume}</td>
                                            <td>{keyword.competition}</td>
                                            <td>${keyword.cpc}</td>
                                            <td>${keyword.lowTopOfPageBid}</td>
                                            <td>${keyword.highTopOfPageBid}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className='alert alert-info'>
                                <p className='m-0'>Enter a seed keyword and country to get keyword ideas</p>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    )
}

export default KeywordTool;