import ReportItem from './reportItem/ReportItem';
import './ReportSelector.css';

const ReportSelector = ({ reports, onReportSelected, selectedReport }) => {
	return (
		<div className="report-selector">
			{!reports &&
			reports.length === 0 && (
				<div className="alert alert-info">
					This campaign has no reports yet. Generate a new report by clicking the button below.
				</div>
			)}

			{reports &&
			reports.length > 0 && (
				<div className="reports">
					{reports.map((report) => (
						<ReportItem
							key={report.id}
							report={report}
							selectedReport={selectedReport}
							onSelected={onReportSelected}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default ReportSelector;
