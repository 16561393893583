import { Fragment } from 'react';
import './TableFooter.css';
import Pagination from '../../pagination/Pagination';

const TableFooter = ({
	list,
	colspan,
	showRefreshButton = null,
	onListRefresh = null,
	currentPage = 1,
	pageSize,
	totalResults = 0,
	onPageChange
}) => {
	return (
		<Fragment>
			<tfoot className="list-footer">
				<tr>
					<th colSpan={colspan}>
						<div className="items-list-count">
							<span>
								{!list || list.length === 0 ? (
									'0 items'
								) : list.length === 1 ? (
									'1 item'
								) : (
									`${list.length} items`
								)}
							</span>

							{showRefreshButton && (
								<button className="btn btn-link btn-refresh-list" onClick={onListRefresh}>
									Refresh
								</button>
							)}

							<div className="table-pagination">
								{totalResults ? (
									<div className="col-12 mb-3">
										<Pagination
											className="pagination-bar justify-content-center"
											currentPage={currentPage}
											totalCount={totalResults}
											pageSize={pageSize}
											onPageChange={(page) => {
												onPageChange(page);
											}}
										/>
									</div>
								) : (
									<Fragment />
								)}
							</div>
						</div>
					</th>
				</tr>
			</tfoot>
		</Fragment>
	);
};

export default TableFooter;
