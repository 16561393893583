import { Fragment, useEffect, useState } from 'react';
import './CampaignTabs.css';
import { Link, useLocation, useParams } from 'react-router-dom';

const CampaignSubTabs = ({ disabled = false }) => {
	const tabs = [
		{ slug: 'info', label: 'GBP Info' },
		{ slug: 'social', label: 'Social' },
		{ slug: 'directory', label: 'Directory' },
		{ slug: 'services', label: 'Services' },
		{ slug: 'reviews', label: 'Reviews' },
		{ slug: 'headline-and-desc', label: 'Headline & Desc' },
		{ slug: 'cta', label: 'CTA' },
		{ slug: 'requirements', label: 'Requirements' }
	];
	const [ activeTab, setActiveTab ] = useState();
	const { campaignId } = useParams();
	const location = useLocation();

	useEffect(
		() => {
			let tempActiveTab = null;

			tabs.every((tab, index) => {
				let urlSlug = location.pathname.replace('/setup/' + campaignId, '');

				if (urlSlug.indexOf(`/${tab.slug}`) > -1) {
					tempActiveTab = tab.slug;
					return false;
				} else if (urlSlug === '/' || urlSlug === '') {
					tempActiveTab = 'info';
				}

				return true;
			});

			setActiveTab(tempActiveTab);
		},
		[ location ]
	);

	return (
		<Fragment>
			<ul className="campaign-tabs nav nav-tabs" id="tabCampaign" role="tablist">
				{tabs.map((tab, index) => (
					<li className={`nav-item ${disabled ? 'disabled' : ''}`} role="presentation" key={index}>
						<Link
							className={`nav-link ${activeTab === tab.slug ? 'active' : ''}`}
							to={`/setup/${campaignId}/${tab.slug === 'info' ? '' : tab.slug}`}
						>
							{tab.label}
						</Link>
					</li>
				))}
			</ul>
		</Fragment>
	);
};

export default CampaignSubTabs;
