import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { GetUserSalesCompanies } from '../../../Services';
import './CompanyList.css';
import { Link } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { useStateValue } from '../../../StateProvider';

const CompanyList = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ companies, setCompanies ] = useState([]);

	useEffect(
		() => {
			if (user) {
				getCompanies();
			}
		},
		[ user ]
	);

	const getCompanies = async () => {
		let response = await GetUserSalesCompanies(user.id);

		if (response && response.length) {
			//sort companies alphabetically
			response = response.sort((a, b) => a.name.localeCompare(b.name));

			setCompanies(response);
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<div className="container-fluid p-3">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							headline="Companies"
							buttonsHtml={
								<div className="d-flex justify-content-end">
									<Link className="btn btn-primary align-self-start" to="/sales/new">
										Add New Company
									</Link>
								</div>
							}
							linkText="Campaigns"
							linkUrl="/campaigns/list"
						/>
					</div>
				</div>

				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<ul className="nav nav-tabs" id="tabCompanies" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="active-companies-tab"
									data-bs-toggle="tab"
									data-bs-target="#active-companies-tab-pane"
									type="button"
									role="tab"
									aria-controls="active-companies-tab-pane"
									aria-selected="true"
								>
									Active
								</button>
							</li>
							{/* <li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="archived-companies-tab"
									data-bs-toggle="tab"
									data-bs-target="#archived-companies-tab-pane"
									type="button"
									role="tab"
									aria-controls="archived-companies-tab-pane"
									aria-selected="false"
								>
									Archived
								</button>
							</li> */}
						</ul>
						<div className="tab-content border" id="tabCompaniesContent">
							<div
								className="tab-pane fade show active"
								id="active-companies-tab-pane"
								role="tabpanel"
								aria-labelledby="active-companies-tab"
								tabIndex="0"
							>
								<table className="companies-list table table-responsive">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Name</th>
											<th scope="col" />
										</tr>
									</thead>
									<tbody>
										{companies &&
											companies.length > 0 &&
											companies.map((company, index) => (
												<tr key={company.id}>
													<th scope="row">{index + 1}</th>
													<td>{company.name}</td>
													<td className="company-action text-end">
														<Link
															className="btn btn-link py-0"
															to={`/sales/${company.id}/tasks`}
															title="View Tasks"
														>
															<i className="fa-solid fa-magnifying-glass" />
														</Link>
														<Link
															className="btn btn-link py-0"
															to={`/sales/${company.id}/grid-select`}
															title="New Task"
														>
															<i className="fa-regular fa-square-plus" />
														</Link>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default CompanyList;
