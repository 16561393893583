import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCampaign, GetCampaignProducts, UpdateCampaignProducts, UpdateCampaignStatus } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignProductsScreen.css';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';

const CampaignProductsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ products, setProducts ] = useState([]);
	const [ formData, setFormData ] = useState({ products: '' });
	const { campaignId } = useParams();
	const productsRef = useRef();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignProducts();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
		}
	};

	const getCampaignProducts = async () => {
		setIsLoading(true);

		const response = await GetCampaignProducts(campaignId);

		if (response) {
			let tempProducts = response;
			let tempFormData = { products: '' };

			for (let i = 0; i < tempProducts.length; i++) {
				tempFormData.products += tempProducts[i].name + '\n';
			}

			setProducts(tempProducts);
			setFormData(tempFormData);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		if (formData.products && formData.products.trim() !== '') {
			let data = {
				id       : campaignId,
				products : []
			};

			let productsArray = formData.products.split('\n');

			if (productsArray) {
				productsArray.map((product) => {
					if (product.trim() !== '' && product.trim().replace('\n', '') !== '') {
						data.products.push(product);
					}
				});
			}

			const response = await UpdateCampaignProducts(campaignId, data);

			if (response.success) {
				// //update campaign status
				// await UpdateCampaignStatus(campaignId, { status: 'Products' });
			}
		}

		if (redirect) {
			navigate(`/setup/${campaignId}/reviews`);
		} else {
			toast.success('Settings saved.');
		}
	};

	return (
		<Fragment>
			<div className="container campaign-products-container">
				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<div className="row w-100">
							<div className="col-12 px-0">
								<PageHeadline
									campaign={campaign}
									headline={`Campaign - ${campaign ? campaign.name : ''}`}
									linkText="Back To List"
									linkUrl="/campaigns/list"
								/>
							</div>

							<CampaignTabs />

							{products && (
								<div className="col-12 p-0 mb-3">
									<textarea
										ref={productsRef}
										className="form-control"
										name="products"
										value={formData.products || ''}
										onChange={handleChange}
										rows={10}
									/>
								</div>
							)}

							<CampaignButtonsContainer
								campaign={campaign}
								onSave={handleSubmit}
								onSaveNext={() => handleSubmit(true)}
							/>
						</div>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default CampaignProductsScreen;
