import { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../../components/spinner/Spinner';
import { GenerateSEOReportDataGrid, GenerateSEOReportPreview, GetCampaign, GetCampaignKeywords } from '../../Services';
import GridReportDataGridV2 from '../dataGridV2/GridReportDataGridV2';
import AutoComplete from 'react-google-autocomplete';
import './DataGridV2.css';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CampaignTabs from '../../components/campaignTabs/CampaignTabs';

const DataGridV2 = () => {
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ keywords, setKeywords ] = useState([]);
	const [ address, setAddress ] = useState('');
	const [ radius, setRadius ] = useState('10');
	const [ points, setPoints ] = useState('11');
	const [ numbers, setNumbers ] = useState('');
	const [ useLatLng, setUseLatLng ] = useState(false);
	const [ isPreview, setIsPreview ] = useState(false);
	const [ googlePlaceId, setGooglePlaceId ] = useState('');
	const [ keyword, setKeyword ] = useState('');
	const [ pointDistance, setPointDistance ] = useState();
	const [ zoom, setZoom ] = useState(12);
	const [ searchType, setSearchType ] = useState('serp');
	const [ lat, setLat ] = useState();
	const [ lng, setLng ] = useState();
	const [ selectedReport, setSelectedReport ] = useState();
	const { campaignId } = useParams();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignKeywords();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				setGooglePlaceId(campaign.googlePlaceId);
				setLat(campaign.lat);
				setLng(campaign.lng);
				setPoints(campaign.gridPoints);

				if (campaign.streetAddress1 && campaign.city) {
					setAddress(
						campaign.streetAddress1 +
							', ' +
							campaign.city +
							', ' +
							campaign.state +
							' ' +
							campaign.postalCode
					);
				} else {
					setAddress(campaign.name);
				}
			}
		},
		[ campaign ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaign(response);
		}
	};

	const getCampaignKeywords = async () => {
		const response = await GetCampaignKeywords(campaignId);

		if (response) {
			let tempKeywords = response;

			for (let i = 0; i < tempKeywords.length; i++) {
				tempKeywords[i].index = i;
			}

			setKeywords(tempKeywords);

			if (tempKeywords && tempKeywords.length > 0) {
				setKeyword(tempKeywords[0].keyword);
			}
		}
	};

	const handleGeneratePreview = async (e) => {
		e.preventDefault();

		setIsPreview(true);
		setIsGenerating(true);
		setPointDistance('');

		if (address.trim() === '') {
			setIsGenerating(false);
			toast.error('Address is required field');
			return;
		}

		if (radius.trim() === '') {
			setIsGenerating(false);
			toast.error('Radius is required field');
			return;
		}

		const google = window.google;
		const geocoder = new google.maps.Geocoder();
		let tempLat, tempLng;

		let isLatLng = address && address.split(',').length - 1 === 1;

		if (isLatLng) {
			let args = address.split(',');

			if (args && args.length == 2) {
				var latlng = { lat: parseFloat(args[0]), lng: parseFloat(args[1]) };

				geocoder.geocode(
					{
						location : latlng
					},
					async function(results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							tempLat = results[0].geometry.location.lat();
							tempLng = results[0].geometry.location.lng();

							setLat(tempLat);
							setLng(tempLng);
							setGooglePlaceId(results[0].place_id);

							let data = {
								lat                    : tempLat,
								lng                    : tempLng,
								gridPoints             : points,
								mapCircleRadiusInMiles : radius,
								numbers                : numbers
							};

							const response = await GenerateSEOReportPreview(data);

							if (response.success) {
								setSelectedReport(response);

								let pointDistance = parseFloat(radius) / ((points - 1) / 2);
								setPointDistance(pointDistance.toFixed(2));
							} else {
								console.error(response);
							}

							setIsGenerating(false);
						}
					}
				);
			}
		} else {
			let data = {
				lat                    : lat,
				lng                    : lng,
				gridPoints             : points,
				mapCircleRadiusInMiles : radius,
				numbers                : numbers
			};

			const response = await GenerateSEOReportPreview(data);

			if (response.success) {
				setSelectedReport(response);

				let pointDistance = radius / ((points - 1) / 2);
				setPointDistance(pointDistance.toFixed(2));
			} else {
				console.error(response);
			}

			setIsGenerating(false);
		}
	};

	const handleGenerateReport = async (e) => {
		e.preventDefault();

		setIsPreview(false);

		if (googlePlaceId.trim() === '') {
			setIsGenerating(false);
			toast.error('Place Id is required field');
			return;
		}

		if (!!useLatLng) {
			if (lat.toString().trim() === '') {
				toast.error('Lat is required field');
				return;
			}

			if (lng.toString().trim() === '') {
				toast.error('Lng is required field');
				return;
			}
		}

		if (keyword.trim() === '') {
			setIsGenerating(false);
			toast.error('Keyword is required field');
			return;
		}

		if (pointDistance.trim() === '') {
			setIsGenerating(false);
			toast.error('Point distance is required field');
			return;
		}

		setIsGenerating(true);

		let data = {
			campaignId    : campaignId ? campaignId : null,
			googlePlaceId : googlePlaceId,
			keywords      : campaignId ? [ keyword ] : [ keyword ],
			gridPoints    : points,
			pointDistance : pointDistance,
			lat           : parseFloat(lat),
			lng           : parseFloat(lng),
			zoom          : parseInt(zoom),
			gridApiType   : searchType
		};

		const response = await GenerateSEOReportDataGrid(data);

		if (response.success) {
			setSelectedReport(response);
		} else {
			console.error(response.message);
		}

		setIsGenerating(false);

		// const google = window.google;
		// const geocoder = new google.maps.Geocoder();

		// geocoder.geocode(
		// 	{
		// 		placeId : googlePlaceId
		// 	},
		// 	async function(results, status) {
		// 		if (status == google.maps.GeocoderStatus.OK) {
		// 			const tempLat = results[0].geometry.location.lat();
		// 			const tempLng = results[0].geometry.location.lng();

		// 			let data = {
		// 				googlePlaceId : googlePlaceId,
		// 				keyword       : keyword,
		// 				gridPoints    : points,
		// 				pointDistance : pointDistance,
		// 				lat           : useLatLng ? parseFloat(lat) : tempLat,
		// 				lng           : useLatLng ? parseFloat(lng) : tempLng,
		// 				zoom          : parseInt(zoom),
		// 				gridApiType   : searchType
		// 			};

		// 			const response = await GenerateSEOReportDataGrid(data);

		// 			if (response.success) {
		// 				setSelectedReport(response);
		// 			} else {
		// 				console.error(response);
		// 			}

		// 			setIsGenerating(false);
		// 		}
		// 	}
		// );
	};

	const handleCircleMove = (center) => {
		if (center) {
			setLat(center.lat);
			setLng(center.lng);
		}
	};

	const handlePlaceSelected = (place) => {
		setLat(place.geometry.location.lat());
		setLng(place.geometry.location.lng());
		setGooglePlaceId(place.place_id);
		setAddress(place.formatted_address);
	};

	return (
		<Fragment>
			<div className="campaign-v-p-report text-start">
				<div className="report-header container d-block">
					<Fragment>
						<form>
							{campaign && <CampaignTabs />}

							<div className="row mb-3">
								<div className="col-lg-6 col-12">
									<label htmlFor="address" className="form-label">
										Business or Address or Lat/Lng:
									</label>
									<AutoComplete
										apiKey="AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg"
										onPlaceSelected={(place) => handlePlaceSelected(place)}
										options={{
											callback : 'mapInitialized',
											types    : [],
											fields   : [
												'address_component',
												'adr_address',
												'formatted_address',
												'geometry',
												'name',
												'place_id',
												'formatted_phone_number'
											]
										}}
										className="form-control"
										id="address"
										name="address"
										placeholder="Business or Address or Lat,Lng"
										value={address}
									/>
								</div>
								<div className="col-lg-2 col-12">
									<label htmlFor="radius" className="form-label">
										Radius (miles):
									</label>
									<input
										type="number"
										className="form-control"
										id="radius"
										placeholder="Radius"
										value={radius}
										onChange={(e) => setRadius(e.target.value)}
										min={1}
										max={100}
										step={1}
									/>
								</div>
								<div className="col-lg-2 col-12">
									<label htmlFor="points" className="form-label">
										Number of Points:
									</label>
									<input
										type="number"
										className="form-control"
										id="points"
										placeholder="Number of Points"
										value={points}
										onChange={(e) => setPoints(e.target.value)}
										min={1}
										max={25}
										step={1}
									/>
								</div>
								<div className="col-lg-2 col-12 d-flex align-items-end">
									<button
										type="button"
										className="btn btn-primary w-100"
										onClick={handleGeneratePreview}
									>
										<span>Generate Preview</span>
									</button>
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-lg-6 col-12">
									<div className="input-group">
										<label htmlFor="googlePlaceId" className="form-label mt-1 me-3">
											Place Id:
										</label>
										<input
											type="text"
											className="form-control"
											id="googlePlaceId"
											//placeholder="Google Place Id"
											value={googlePlaceId}
											//onChange={(e) => setGooglePlaceId(e.target.value)}
											disabled
										/>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="input-group">
										<label htmlFor="lat" className="form-label mt-1 me-3">
											Lat/Lng:
										</label>
										<input
											type="text"
											className="form-control rounded"
											id="lat"
											value={lat}
											//onChange={(e) => setAddress(e.target.value)}
											disabled
										/>
										<input
											type="text"
											className="form-control ms-2 rounded"
											id="lng"
											value={lng}
											//onChange={(e) => setAddress(e.target.value)}
											disabled
										/>
									</div>
								</div>
							</div>

							<hr className="mb-4" />

							<div className="row mb-2">
								<div className="col-lg-4 col-12">
									<label htmlFor="keyword" className="form-label">
										Keyword:
									</label>
									{campaign ? (
										<select
											className="form-select"
											name="keyword"
											onChange={(e) => setKeyword(e.target.value)}
										>
											{keywords &&
												keywords.length > 0 &&
												keywords.map((keyword, index) => (
													<option key={index} value={keyword.keyword}>
														{keyword.keyword}
													</option>
												))}
										</select>
									) : (
										<input
											type="text"
											className="form-control"
											id="keyword"
											name="keyword"
											placeholder="Keyword"
											value={keyword}
											onChange={(e) => setKeyword(e.target.value)}
										/>
									)}
								</div>
								<div className="col-lg-2 col-12">
									<label htmlFor="pointDistance" className="form-label">
										Point Distance (miles):
									</label>
									<input
										type="number"
										className="form-control"
										id="pointDistance"
										placeholder="Point Distance"
										value={pointDistance}
										onChange={(e) => setPointDistance(e.target.value)}
										min={0.1}
										max={5}
										step={0.1}
									/>
								</div>
								<div className="col-lg-1 col-12">
									<label htmlFor="zoom" className="form-label">
										Zoom:
									</label>
									<select
										className="form-select"
										value={zoom}
										onChange={(e) => setZoom(e.target.value)}
									>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="0">All Zooms</option>
									</select>
								</div>
								<div className="col-lg-2 col-12">
									<label htmlFor="searchType" className="form-label">
										Api:
									</label>
									<select
										className="form-select"
										value={searchType}
										onChange={(e) => setSearchType(e.target.value)}
									>
										<option value="serp">Serp Api</option>
										{/* <option value="google">Google Api</option> */}
										<option value="dseo">DataForSeo Api</option>
									</select>
								</div>
								<div className="col-lg-3 col-12 d-flex align-items-end justify-content-end">
									{/* <div className="input-group"> */}
									{/* <label className="checkbox-container mt-2">
											<input
												type="checkbox"
												value="useLatLng"
												id="useLatLng"
												name="useLatLng"
												className="form-check-input"
												checked={!!useLatLng}
												onChange={(e) => setUseLatLng(e.target.checked)}
											/>
											<label className="form-check-label" htmlFor="useLatLng">
												Use Lat/Lng
											</label>
										</label> */}
									<button
										type="button"
										className="btn btn-primary ms-5 rounded"
										onClick={handleGenerateReport}
									>
										<span>Generate Data</span>
									</button>
									{/* </div> */}
								</div>
							</div>

							{/* {useLatLng && (
								<div className="row mb-2">
									<div className="col-lg-6 col-12">
										<div className="input-group">
											<label htmlFor="latValue" className="form-label mt-1 me-2">
												Lat/Lng:
											</label>
											<input
												type="text"
												className="form-control rounded"
												id="latValue"
												value={lat}
												onChange={(e) => setLat(e.target.value)}
											/>
											<input
												type="text"
												className="form-control ms-2 rounded"
												id="lngValue"
												value={lng}
												onChange={(e) => setLng(e.target.value)}
											/>
										</div>
									</div>
								</div>
							)} */}
						</form>
					</Fragment>
				</div>
				<div className="container">
					{isGenerating ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="map-preview">
								{selectedReport &&
								zoom && (
									<Fragment>
										<GridReportDataGridV2
											gridData={selectedReport.gridResponse}
											lat={lat}
											lng={lng}
											radiusInMiles={radius}
											numberOfPoints={points}
											pointDistance={pointDistance}
											googlePlaceId={googlePlaceId}
											zoom={zoom}
											onCircleMove={handleCircleMove}
											isPreview={isPreview}
										/>
									</Fragment>
								)}
							</div>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default DataGridV2;
