import { Fragment, useEffect, useState } from 'react';
import { GetBusiness, RemoveBusiness } from '../../utils/StorageUtil';
import { useNavigate } from 'react-router-dom';
import {
	RegisterUser,
	CreateCampaign,
	UpdateCampaignReviews,
	UpdateCampaign,
	GetCampaign,
	UpdateCampaignStatus,
	GetGooglePlaceReviews
} from '../../Services';
import Spinner from '../../components/spinner/Spinner';
import './GbpSetupScreen.css';
import { toast } from 'react-toastify';

const GbpSetupScreen = ({ approvedOrder = null }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ profile, setProfile ] = useState();
	const navigate = useNavigate();
	const [ campaignId, setCampaignId ] = useState();
	const [ campaign, setCampaign ] = useState();

	useEffect(() => {
		setIsLoading(true);

		const businessProfile = GetBusiness();

		if (approvedOrder && approvedOrder.id) {
			setCampaignId(approvedOrder.id);

			getCampaign(approvedOrder.id);
		} else if (businessProfile.campaignId) {
			setCampaignId(businessProfile.campaignId);

			getCampaign(businessProfile.campaignId);
		} else {
			setIsLoading(false);
		}

		setProfile(businessProfile);
	}, []);

	const getCampaign = async (id) => {
		const response = await GetCampaign(id);

		if (response) {
			setCampaign(response);
		}

		setIsLoading(false);
	};

	const handleGBPSetup = async () => {
		if (profile) {
			setIsLoading(true);

			//register user
			let userId, email, firstName, lastName;

			if (campaign) {
				userId = campaign.userId;
			} else if (profile.userId) {
				userId = profile.userId;
			}

			let profileName = profile.name;

			//parse address components
			let numberArgs, streetArgs, premiseArgs, subpremiseArgs, cityArgs, stateArgs, postalCodeArgs, countryArgs;
			let addressArgs = profile.address_components;

			if (addressArgs) {
				numberArgs = addressArgs.filter((x) => x.types.includes('street_number'));
				streetArgs = addressArgs.filter((x) => x.types.includes('route'));
				premiseArgs = addressArgs.filter((x) => x.types.includes('premise'));
				subpremiseArgs = addressArgs.filter((x) => x.types.includes('subpremise'));
				cityArgs = addressArgs.filter((x) => x.types.includes('locality'));
				stateArgs = addressArgs.filter((x) => x.types.includes('administrative_area_level_1'));
				postalCodeArgs = addressArgs.filter((x) => x.types.includes('postal_code'));
				countryArgs = addressArgs.filter((x) => x.types.includes('country'));
			}

			let number = numberArgs && numberArgs.length > 0 && numberArgs[0].long_name ? numberArgs[0].long_name : '';
			let street =
				streetArgs && streetArgs.length > 0 && streetArgs[0].short_name ? streetArgs[0].short_name : '';
			let premise =
				premiseArgs && premiseArgs.length > 0 && premiseArgs[0].long_name ? ` ${premiseArgs[0].long_name}` : '';
			let subpremise =
				subpremiseArgs && subpremiseArgs.length > 0 && subpremiseArgs[0].long_name
					? ` ${subpremiseArgs[0].long_name}`
					: '';
			let city = cityArgs && cityArgs.length > 0 && cityArgs[0].long_name ? cityArgs[0].long_name : '';

			if (!userId) {
				if (profileName) {
					email = profileName.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

					let nameArgs = profileName.split(' ');
					if (nameArgs && nameArgs.length > 1) {
						firstName = nameArgs[0];
						lastName = profileName.replace(firstName, '').trim();
					} else {
						firstName = profileName;
					}
				}

				//create user
				let userData = {
					email        : email,
					password     : 'pa$$w0rd',
					firstName    : firstName,
					lastName     : lastName,
					phone        : profile.formatted_phone_number,
					status       : 'Active',
					billingId    : null,
					parentUserId : null
				};

				let userResponse = await RegisterUser(userData);

				if (userResponse && userResponse.id) {
					userId = userResponse.id;
				} else if (userResponse && !userResponse.IsSuccessful) {
					if (
						userResponse.Message &&
						userResponse.Message.response &&
						userResponse.Message.response.data &&
						userResponse.Message.response.data.length
					) {
						let errorMessage = userResponse.Message.response.data[0];

						if (errorMessage.indexOf('already taken') > -1) {
							//add city to the email
							userData.email =
								(profileName + city).toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') +
								'@realwebsite.com';
							userResponse = await RegisterUser(userData);

							if (userResponse && userResponse.id) {
								userId = userResponse.id;
							} else if (userResponse && !userResponse.IsSuccessful) {
								if (
									userResponse.Message &&
									userResponse.Message.response &&
									userResponse.Message.response.data &&
									userResponse.Message.response.data.length
								) {
									let errorMessage = userResponse.Message.response.data[0];
									toast.error(errorMessage);

									setIsLoading(false);
									return;
								}
							}
						}
					}
				}
			}

			if (userId) {
				//create campaign
				let campaignData = {
					userId         : userId,
					name           : profileName,
					streetAddress1 : `${number} ${street}${premise}${subpremise}`,
					city           : city,
					state          :
						stateArgs && stateArgs.length > 0 && stateArgs[0].short_name ? stateArgs[0].short_name : '',
					postalCode     :
						postalCodeArgs && postalCodeArgs.length > 0 && postalCodeArgs[0].short_name
							? postalCodeArgs[0].short_name
							: '',
					country        :
						countryArgs && countryArgs.length > 0 && countryArgs[0].long_name
							? countryArgs[0].long_name
							: ''
				};

				let campaignResponse = {};

				if (campaignId) {
					campaignResponse.data = campaignData;
					campaignResponse.id = campaignId;

					//update campaign status to New
					await UpdateCampaignStatus(campaignId, { status: 'New' });
				} else {
					campaignResponse = await CreateCampaign(campaignData);
				}

				if (campaignResponse && campaignResponse.id) {
					//update some campaigns fields
					let tempCampaignData = { ...campaign, ...campaignResponse.data };

					if (profile) {
						if (profile.website) {
							//tempCampaignData.ctaButtonUrl = profile.website;
							// tempCampaignData.url = `local.${profile.website
							// 	.replace('https://', '')
							// 	.replace('http://', '')}`;
							tempCampaignData.website = profile.website;
						}

						tempCampaignData.lat = profile.lat;
						tempCampaignData.lng = profile.lng;
						tempCampaignData.phone = profile.formatted_phone_number;
						tempCampaignData.googlePlaceId = profile.place_id;
						tempCampaignData.googleCid = profile.cid;
						tempCampaignData.googleBusinessUrl = profile.url;
						tempCampaignData.faviconUrl = profile.icon ? profile.icon : '';

						//cid
						if (!profile.cid && profile.url && profile.url.indexOf('cid=') > -1) {
							const url = new URL(profile.url);
							const searchParams = url.searchParams;
							tempCampaignData.googleCid = searchParams.get('cid');
						}

						//map embed code
						tempCampaignData.mapEmbed =
							'<iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg&q=place_id:' +
							profile.place_id +
							'" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

						//opening hours
						if (
							profile.current_opening_hours &&
							profile.current_opening_hours.periods &&
							profile.current_opening_hours.periods.length > 0
						) {
							let periods = profile.current_opening_hours.periods;

							for (let i = 0; i < 7; i++) {
								let openData = periods.filter((x) => x.open.day === i);
								let closeData = periods.filter((x) => x.close.day === i);

								if (openData && openData.length > 0 && closeData && closeData.length > 0) {
									let openTime = openData[0].open.time;
									let closeTime = closeData[0].close.time;

									let openTimeStr = `${openTime.substring(0, 2)}:${openTime.substring(2, 4)}`;
									let closeTimeStr = `${closeTime.substring(0, 2)}:${closeTime.substring(2, 4)}`;
									let hoursStr = `${openTimeStr}-${closeTimeStr}`;

									if (openTime === '0000' && closeTime === '0000') {
										hoursStr = '00:00-23:59';
									}

									switch (i) {
										case 0:
											tempCampaignData.hrsSunday = hoursStr;
											break;
										case 1:
											tempCampaignData.hrsMonday = hoursStr;
											break;
										case 2:
											tempCampaignData.hrsTuesday = hoursStr;
											break;
										case 3:
											tempCampaignData.hrsWednesday = hoursStr;
											break;
										case 4:
											tempCampaignData.hrsThursday = hoursStr;
											break;
										case 5:
											tempCampaignData.hrsFriday = hoursStr;
											break;
										case 6:
											tempCampaignData.hrsSaturday = hoursStr;
											break;
									}
								}
							}
						} else {
							if (profile.hrsMonday) {
								tempCampaignData.hrsMonday = profile.hrsMonday;
							}
							if (profile.hrsTuesday) {
								tempCampaignData.hrsTuesday = profile.hrsTuesday;
							}
							if (profile.hrsWednesday) {
								tempCampaignData.hrsWednesday = profile.hrsWednesday;
							}
							if (profile.hrsThursday) {
								tempCampaignData.hrsThursday = profile.hrsThursday;
							}
							if (profile.hrsFriday) {
								tempCampaignData.hrsFriday = profile.hrsFriday;
							}
							if (profile.hrsSaturday) {
								tempCampaignData.hrsSaturday = profile.hrsSaturday;
							}
							if (profile.hrsSunday) {
								tempCampaignData.hrsSunday = profile.hrsSunday;
							}
						}
					}

					const updateCampaignResponse = await UpdateCampaign(campaignResponse.id, tempCampaignData);

					//save reviews
					saveReviews(campaignResponse.id);

					//remove local storage business
					RemoveBusiness();

					//redirect to campaign update screen
					navigate(`/setup/${campaignResponse.id}`);
				}
			}

			setIsLoading(false);
		}
	};

	const getSerpApiGooglePlaceReviews = async (placeId) => {
		let reviews = [];

		if (placeId) {
			const response = await GetGooglePlaceReviews(placeId);

			if (response && response.success && response.reviews) {
				for (let i = 0; i < response.reviews.length; i++) {
					reviews.push(response.reviews[i]);
				}
			}
		}

		return reviews;
	};

	const saveReviews = async (campaignId) => {
		//get reviews from Serp Api
		if (profile.place_id) {
			//get SerpApi reviews
			profile.reviews = await getSerpApiGooglePlaceReviews(profile.place_id);

			if (profile.reviews && profile.reviews.length) {
				//get only 5-star rating reviews
				let tempReviews = profile.reviews.filter((x) => x.rating === 5);

				//sort by date desc
				tempReviews = tempReviews.sort(function(a, b) {
					return new Date(b.dateCreated) - new Date(a.dateCreated);
				});

				let data = {
					id      : campaignId,
					reviews : []
				};

				let reviewsCount = tempReviews.length;

				//take first 3 reviews
				if (reviewsCount > 3) {
					reviewsCount = 3;
				}

				for (let i = 0; i < reviewsCount; i++) {
					data.reviews.push({
						rating   : tempReviews[i].rating,
						headline : tempReviews[i].user.name,
						text     : tempReviews[i].snippet,
						source   : tempReviews[i].source
					});
				}

				const response = await UpdateCampaignReviews(campaignId, data);

				if (!response.success) {
					//console.log();
				}
			}
		}
		// }
	};

	return (
		<Fragment>
			<div
				className={`container ${approvedOrder
					? 'pt-4'
					: 'vh-100 d-flex align-items-center justify-content-center'}`}
			>
				{profile &&
					(isLoading ? (
						<Spinner />
					) : (
						<button className="btn btn-primary btn-lg" onClick={handleGBPSetup}>
							Setup GBP
						</button>
					))}
			</div>
		</Fragment>
	);
};

export default GbpSetupScreen;
