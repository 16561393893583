import { Fragment, useEffect, useState, useRef } from 'react';
import GridKeywordPickerArrows from './../gridKeywordPicker/GridKeywordPickerArrows';
import GridMapV3 from './../v3/gridMap/GridMapV3';
import KeywordMapStatsV3 from './../v3/keywordMapStats/KeywordMapStatsV3';
import './GridReportSingleKeywordV3.css';
import GridReportToggleTabs from './gridReportToggleTabs/GridReportToggleTabs';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';

const GridReportSingleKeywordV3 = ({
	gridData,
	gridDataCompareTo,
	placeId,
	placeCid,
	showAllKeywords,
	onKeywordSelected,
	reportName,
	compareToReportName,
	onMobileReportViewChange
}) => {
	const [ targetKeyword, setTargetKeyword ] = useState();
	const [ compareToKeyword, setCompareToKeyword ] = useState();
	const [ compareToGridExists, setCompareToGridExists ] = useState(false);
	const [ allGridsReady, setAllGridsReady ] = useState(false);
	const [ mapFullScreen, setMapFullScreen ] = useState(false);
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(
		() => {
			if (gridData && !showAllKeywords) {
				setTargetKeyword({
					lat              : gridData.lat,
					lng              : gridData.lng,
					horizontalPoints : gridData.horizontalPoints,
					verticalPoints   : gridData.verticalPoints,
					...gridData.data[0]
				});

				if (onKeywordSelected) {
					onKeywordSelected(gridData.data[0].keyword);
				}

				if (gridDataCompareTo) {
					setCompareToKeyword({
						lat              : gridDataCompareTo.lat,
						lng              : gridDataCompareTo.lng,
						horizontalPoints : gridDataCompareTo.horizontalPoints,
						verticalPoints   : gridDataCompareTo.verticalPoints,
						...gridDataCompareTo.data[0]
					});

					setCompareToGridExists(true);
				}

				setAllGridsReady(true);
			}
		},
		[ gridData, placeId, placeCid, showAllKeywords ]
	);

	const handleKeywordClick = (keyword) => {
		setTargetKeyword({
			lat              : gridData.lat,
			lng              : gridData.lng,
			horizontalPoints : gridData.horizontalPoints,
			verticalPoints   : gridData.verticalPoints,
			...keyword
		});

		if (onKeywordSelected) {
			onKeywordSelected(keyword.keyword);
		}

		if (gridDataCompareTo && gridDataCompareTo.data) {
			const selectedCompareToKeyword = gridDataCompareTo.data.find((k) => k.keyword === keyword.keyword);

			setCompareToKeyword({
				lat              : gridDataCompareTo.lat,
				lng              : gridDataCompareTo.lng,
				horizontalPoints : gridDataCompareTo.horizontalPoints,
				verticalPoints   : gridDataCompareTo.verticalPoints,
				...selectedCompareToKeyword
			});
		}
	};

	const handleMapFullScreen = () => {
		setMapFullScreen(!mapFullScreen);
	};

	return (
		<Fragment>
			{!showAllKeywords ? (
				<Fragment>
					<GridKeywordPickerArrows
						gridData={gridData}
						activeKeyword={targetKeyword}
						onKeywordSelected={handleKeywordClick}
					/>
					<KeywordMapStatsV3 keywordData={targetKeyword} mobileReportView={mobileReportView} />
					{allGridsReady &&
						targetKeyword &&
						(compareToGridExists ? (
							<div className={`row grid-report-map-container ${mapFullScreen && 'full-screen'}`}>
								<GridReportToggleTabs
									reportName={reportName}
									compareToReportName={compareToReportName}
								/>

								{(!mobileReportView || mobileReportView === 'base') && (
									<div
										className={`map-wrapper ${gridDataCompareTo
											? 'col-lg-6'
											: ''} col-12 ps-0 pe-lg-1`}
									>
										<GridMapV3
											key="map-0"
											gridData={targetKeyword}
											placeId={placeId}
											placeCid={placeCid}
											isFullScreen={mapFullScreen}
										/>
									</div>
								)}
								{(!mobileReportView || mobileReportView === 'compare') && (
									<div className="map-wrapper col-lg-6 col-12 ps-lg-1 pe-0 mt-3 mt-lg-0">
										<GridMapV3
											key="map-1"
											gridData={compareToKeyword}
											placeId={placeId}
											placeCid={placeCid}
											isFullScreen={mapFullScreen}
										/>
									</div>
								)}
								<div className="map-full-screen-wrapper">
									<button className="btn btn-link" onClick={handleMapFullScreen}>
										{mapFullScreen ? 'Exit Full Screen' : 'View Maps Full Screen'}
									</button>
								</div>
							</div>
						) : (
							<div className={`row grid-report-map-container ${mapFullScreen && 'full-screen'}`}>
								<div className="map-wrapper col-12">
									<GridMapV3
										key="map-0"
										gridData={targetKeyword}
										placeId={placeId}
										placeCid={placeCid}
										isFullScreen={mapFullScreen}
									/>
								</div>
								<div className="map-full-screen-wrapper">
									<button className="btn btn-link" onClick={handleMapFullScreen}>
										{mapFullScreen ? 'Exit Full Screen' : 'View Maps Full Screen'}
									</button>
								</div>
							</div>
						))}
				</Fragment>
			) : (
				<Fragment>
					{gridData &&
					gridData.data &&
					gridData.data.length > 0 && (
						<Fragment>
							{gridData.data.map((keyword, index) => {
								const keywordData = {
									lat              : gridData.lat,
									lng              : gridData.lng,
									horizontalPoints : gridData.horizontalPoints,
									verticalPoints   : gridData.verticalPoints,
									...keyword
								};

								return (
									<Fragment key={`fragment-${index}`}>
										<h5 key={`title-${index}`} className="mt-3 mb-3">
											{keyword.keyword}
										</h5>
										<GridMapV3
											key={`map-${index}`}
											gridData={keywordData}
											placeId={placeId}
											placeCid={placeCid}
										/>
									</Fragment>
								);
							})}
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default GridReportSingleKeywordV3;
