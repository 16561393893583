import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCampaign, UpdateCampaign, UpdateCampaignStatus } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignDirectoryScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';

const CampaignDirectoryScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const days = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		setIsLoading(true);

		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;

			let tempCampaign = response;

			days.forEach((day) => {
				if (tempCampaign[`hrs${day}`] && tempCampaign[`hrs${day}`].indexOf('-') > -1) {
					let dayArgs = tempCampaign[`hrs${day}`].split('-');

					if (dayArgs && dayArgs.length === 2) {
						tempCampaign[`workingHours${day}From`] = moment(dayArgs[0], 'HH:mm');
						tempCampaign[`workingHours${day}To`] = moment(dayArgs[1], 'HH:mm');
						tempCampaign[`workingHours${day}Closed`] = false;
					}
				} else {
					tempCampaign[`workingHours${day}Closed`] = true;
				}
			});

			setCampaign(tempCampaign);
			setFormData(tempCampaign);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		const { type, name, value, checked } = e.target;

		if (type === 'checkbox') {
			setFormData({
				...formData,
				[name] : checked
			});
		} else {
			setFormData({
				...formData,
				[name] : value
			});
		}

		if (name.indexOf('workingHours') > -1) {
		}
	};

	const handleSubmit = async (redirect = false) => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		let tempFormData = formData;
		days.forEach((day) => {
			if (tempFormData[`workingHours${day}Closed`]) {
				tempFormData[`hrs${day}`] = null;
			} else {
				if (tempFormData[`workingHours${day}From`] && tempFormData[`workingHours${day}To`]) {
					tempFormData[`hrs${day}`] =
						tempFormData[`workingHours${day}From`].format('HH:mm') +
						'-' +
						tempFormData[`workingHours${day}To`].format('HH:mm');
				}
			}
		});

		const response = await UpdateCampaign(campaignId, tempFormData);

		if (response.success) {
			if (redirect) {
				navigate(`/setup/${campaignId}/keywords`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	return (
		<div className="container-fluid campaign-main-container">
			<Fragment>
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>
				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="campaign-subform-container">
								<form className="text-start">
									<div className="row mb-3">
										<label htmlFor="url" className="form-label col-1">
											Directory Url:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="url"
												value={formData.url || ''}
												onChange={handleChange}
												required
											/>
										</div>
									</div>

									<div className="row mb-3">
										<label htmlFor="googleAnalytics4Code" className="form-label col-1">
											Analytics Id:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="googleAnalytics4Code"
												value={formData.googleAnalytics4Code || ''}
												onChange={handleChange}
											/>
										</div>
									</div>

									<div className="row mb-4">
										<label htmlFor="privacyLink" className="form-label col-1">
											Privacy Policy Url:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="privacyLink"
												value={formData.privacyLink || ''}
												onChange={handleChange}
											/>
										</div>
									</div>

									<div className="row mb-2">
										<label htmlFor="workingHours" className="form-label col-1">
											Working Hours:
										</label>
										<div className="col-5" />
									</div>

									{days.map((day) => (
										<div className="row mb-2" key={day}>
											<label htmlFor={`${day}From`} className="form-label col-1">
												{`${day}:`}
											</label>
											<div className="col-5">
												<div className="input-group">
													<div className="working-hours from">
														<LocalizationProvider dateAdapter={AdapterMoment}>
															<TimePicker
																name={`workingHours${day}From`}
																value={formData[`workingHours${day}From`]}
																//inputFormat="HH:mm"
																onChange={(newValue) =>
																	setFormData({
																		...formData,
																		[`workingHours${day}From`]: newValue
																	})}
																disabled={formData[`workingHours${day}Closed`] === true}
															/>
														</LocalizationProvider>
													</div>
													<div className="working-hours to ms-3">
														<LocalizationProvider dateAdapter={AdapterMoment}>
															<TimePicker
																name={`workingHours${day}To`}
																value={formData[`workingHours${day}To`]}
																//inputFormat="HH:mm"
																onChange={(newValue) =>
																	setFormData({
																		...formData,
																		[`workingHours${day}To`]: newValue
																	})}
																disabled={formData[`workingHours${day}Closed`] === true}
															/>
														</LocalizationProvider>
													</div>
													<div className="working-hours closed ms-4 mt-2">
														<input
															type="checkbox"
															className="form-check-input"
															name={`workingHours${day}Closed`}
															checked={formData[`workingHours${day}Closed`]}
															id={`workingHours${day}Closed`}
															onChange={handleChange}
														/>
														<label
															htmlFor={`workingHours${day}Closed`}
															className="form-check-label ms-2 w-auto"
														>
															Closed
														</label>
													</div>
												</div>
											</div>
										</div>
									))}
								</form>
							</div>
						</Fragment>
					)}
				</div>
				<CampaignButtonsContainer
					campaign={campaign}
					onSave={handleSubmit}
					onSaveNext={() => handleSubmit(true)}
				/>
			</Fragment>
		</div>
	);
};

export default CampaignDirectoryScreen;
