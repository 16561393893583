import { Route, Routes } from 'react-router';
import './Administration.css';
import { useStateValue } from '../../StateProvider';
import Login from '../login/Login';
import { Fragment } from 'react';
import AdministrationView from './administrationView/AdministrationView';

const Administration = () => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<Routes>
			<Fragment>
				{user && user.isAdmin ? (
					<Fragment>
						<Route path="/" element={<AdministrationView />} />
					</Fragment>
				) : (
					<Route path="/*" element={<Login />} />
				)}
			</Fragment>
		</Routes>
	);
};

export default Administration;
