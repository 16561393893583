import { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { GetSalesCompanyDetailsBasic, GetSalesCompanyTaskDetails } from '../../../Services';
import GridMapDataGrid from '../../dataGrid/GridMapDataGrid';
// import './CompanyReportPublicView.css';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';

const CompanyReportPublicView = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ gridData, setGridData ] = useState();
	const [ company, setCompany ] = useState();
	const [ task, setTask ] = useState();
	const { salesCompanyId, reportId } = useParams();

	useEffect(
		() => {
			if (salesCompanyId && reportId) {
				getSalesCompanyDetails();
				getSalesCompanyTask();
			}
		},
		[ salesCompanyId ]
	);

	const getSalesCompanyDetails = async () => {
		const response = await GetSalesCompanyDetailsBasic(salesCompanyId);

		if (response) {
			setCompany(response);

			//generateReport();
		} else {
			//setIsLoading(false);
		}
	};

	const getSalesCompanyTask = async () => {
		const response = await GetSalesCompanyTaskDetails(reportId);

		if (response) {
			setTask(response);

			//parse grid data
			if (response.content) {
				let gridReportData = JSON.parse(response.content);

				if (gridReportData) {
					setGridData({
						lat              : response.lat,
						lng              : response.lng,
						horizontalPoints : response.gridPoints,
						verticalPoints   : response.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});
				}
			}
		}

		setIsLoading(false);
	};

	return (
		<div className="container text-center mt-3">
			{isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					{company &&
					task && (
						<Fragment>
							<PageHeadline headline={`${company.name}: ${task.keyword}`} />

							<div className="data-grid-map-container">
								<Fragment>
									{gridData && (
										<GridMapDataGrid
											key="map-0"
											mapIdValue="report-map-0"
											gridData={gridData}
											lat={task.lat}
											lng={task.lng}
											googlePlaceId={company.googlePlaceId}
											numberOfPoints={task.gridPoints}
											zoom={11}
										/>
									)}
								</Fragment>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CompanyReportPublicView;
