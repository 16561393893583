import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../StateProvider';
import { useNavigate, useParams } from 'react-router-dom';
import {
	DeleteCampaign,
	DuplicateCampaign,
	GetCampaign,
	GetCampaignTimeZones,
	GetUsers,
	UpdateCampaign,
	UpdateCampaignReportDate,
	UpdateCampaignStatus,
	UpdateUser
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignUpdateScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import moment from 'moment';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';
import { validateDayOfMonth } from '../../../utils/Common';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import Select from 'react-select';

const CampaignUpdateScreen = ({ isEdit = true }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isDuplicating, setIsDuplicating ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ userFormData, setUserFormData ] = useState({});
	const [ userEmail, setUserEmail ] = useState();
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const [ allUsers, setAllUsers ] = useState([]);
	const [ allUsersForSelect, setAllUsersForSelect ] = useState([]);
	const [ salesUsers, setSalesUsers ] = useState([]);
	const [ selectedUser, setSelectedUser ] = useState();
	const [ { host }, dispatch ] = useStateValue();
	const [ showDeleteCampaignDialog, setShowDeleteCampaignDialog ] = useState(false);
	const [ confirmDeleteText, setConfirmDeleteText ] = useState();
	const [ timeZones, setTimeZones ] = useState([]);
	const [ selectedTimeZone, setSelectedTimeZone ] = useState();
	const navigate = useNavigate();
	const defaultTZ = 'Eastern Standard Time';

	useEffect(
		() => {
			setIsLoading(true);

			if (campaignId) {
				getCampaign();
				getUsers();
				getTimeZones();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				let tempCampaign = campaign;

				if (tempCampaign.nextReportDate) {
					tempCampaign.nextReportDate = moment(tempCampaign.nextReportDate).format('yyyy-MM-DD');
				}

				setCampaign(tempCampaign);
				setFormData(tempCampaign);

				setIsLoading(false);
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && timeZones) {
				let tz = timeZones.find((x) => x.value === (campaign.timeZone || defaultTZ));

				setSelectedTimeZone(tz);
			}
		},
		[ campaign, timeZones ]
	);

	useEffect(
		() => {
			if (campaign && campaign.user && allUsersForSelect && allUsersForSelect.length) {
				setSelectedUser({
					value : campaign.user.id,
					label : `${campaign.user.firstName || ''} ${campaign.user.lastName || ''} (${campaign.user.email})`
				});
			}
		},
		[ campaign, allUsersForSelect ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);

			if (response.user) {
				setUserEmail(response.user.email);
			}
		}
	};

	const getUsers = async () => {
		let response = await GetUsers();

		if (response && response.length) {
			//sort users alphabetically
			let tempAllUsers = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

			let tempUsersForSelect = new Array();
			for (let i = 0; i < tempAllUsers.length; i++) {
				tempUsersForSelect.push({
					value : tempAllUsers[i].id,
					label : `${tempAllUsers[i].firstName || ''} ${tempAllUsers[i].lastName || ''} (${tempAllUsers[i]
						.email})`
				});
			}

			setAllUsers(tempAllUsers);
			setAllUsersForSelect(tempUsersForSelect);

			setSalesUsers(tempAllUsers.filter((x) => x.isSalesUser === true));
		}

		setIsLoading(false);
	};

	const getTimeZones = async () => {
		let response = await GetCampaignTimeZones();

		if (response && response.data && response.data.length) {
			let tempTimeZones = [];

			for (let i = 0; i < response.data.length; i++) {
				let tzOffsetDesc = response.data[i].text.substring(0, response.data[i].text.indexOf(')') + 1);
				tempTimeZones.push({
					value : response.data[i].value,
					label : `${response.data[i].value} ${tzOffsetDesc}`
				});
			}

			setTimeZones(tempTimeZones);
		}
	};

	const handleChange = async (e) => {
		let type = e.target.type;
		let value = type === 'checkbox' ? e.target.checked : e.target.value;

		if (e.target.name === 'reportDayOfMonth') {
			let dayOfMonth = validateDayOfMonth(e.target.value);

			//set next report date
			let dt = new Date();

			let year = dt.getFullYear();
			let month = (dt.getMonth() + 1 + 1).toString().padStart(2, '0');

			setFormData({
				...formData,
				[e.target.name]: dayOfMonth,
				nextReportDate  : `${year}-${month}-${dayOfMonth.toString().padStart(2, '0')}`
			});
		} else {
			setFormData({
				...formData,
				[e.target.name]: value
			});
		}
	};

	const handleSubmit = async (redirect) => {
		//e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		//save user email
		if (campaign.user) {
			let userData = {
				...campaign.user,
				email : userEmail
			};

			await UpdateUser(campaign.user.id, userData);
		}

		let tempFormData = formData;

		if (selectedUser) {
			tempFormData.userId = selectedUser.value;
		}

		//map embed code
		if (tempFormData.googlePlaceId) {
			tempFormData.mapEmbed =
				'<iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg&q=place_id:' +
				tempFormData.googlePlaceId +
				'" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

			setFormData(tempFormData);
		}

		if (selectedTimeZone) {
			tempFormData.timeZone = selectedTimeZone.value;
		}

		const response = await UpdateCampaign(campaignId, tempFormData);

		if (response.success) {
			// //save next report date
			// await UpdateCampaignReportDate(campaign.id, {
			// 	nextReportDate : tempFormData.nextReportDate
			// });

			if (redirect) {
				navigate(`/setup/${campaignId}/social`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	const handleChangeStatusCampaign = async (oldStatus, newStatus) => {
		let newStatusDesc = newStatus === 'New' ? 'In Progress' : newStatus;

		if (window.confirm(`Are you sure that you want to change the status of campaign to "${newStatusDesc}"?`)) {
			let tempCampaign = campaign;
			tempCampaign.status = newStatus;
			setCampaign(tempCampaign);

			let data = {
				status : newStatus
			};

			const response = await UpdateCampaignStatus(campaignId, data);

			if (response.success) {
				toast.success(`Campaign status changed to ${newStatusDesc}`);

				navigate(`/setup/${campaignId}`);
			}
		}
	};

	const handleDuplicateCampaign = async () => {
		if (window.confirm('Are you sure that you want to duplicate the current campaign?')) {
			setIsDuplicating(true);

			const response = await DuplicateCampaign(campaignId);

			if (response.success) {
				toast.success('Campaign duplicated successfully');

				setTimeout(function() {
					window.open(`${host}/setup/${response.id}`, '_blank', 'noreferrer');
				}, 2000);
			}

			setIsDuplicating(false);
		}
	};

	const handleDeleteCampaign = async () => {
		if (!confirmDeleteText || confirmDeleteText !== 'DELETE') {
			toast.error('Enter a word DELETE into the box to delete this campaign');
			return;
		}

		setIsDeleting(true);

		let tempCampaign = campaign;
		tempCampaign.status = 'Deleted';
		setCampaign(tempCampaign);

		const response = await DeleteCampaign(campaignId);

		if (response.success) {
			toast.success('Campaign deleted successfully');
			toggleDeleteCampaignDialog();

			navigate(`/setup/${campaignId}`);
		}

		setIsDeleting(false);
	};

	const toggleDeleteCampaignDialog = () => {
		setShowDeleteCampaignDialog(!showDeleteCampaignDialog);
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>
				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					<div className="campaign-subform-container">
						{isLoading || isDuplicating ? (
							<Spinner />
						) : (
							<Fragment>
								<form className="text-start">
									<div className="row mb-3">
										<label htmlFor="listingType" className="form-label col-1">
											Industry:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="listingType"
												value={formData.listingType || ''}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="englishType" className="form-label col-1">
											English Type:
										</label>
										<div className="col-5">
											<select
												className="form-select"
												name="englishType"
												value={formData.englishType || ''}
												onChange={handleChange}
												required
											>
												<option value="American">American</option>
												<option value="United Kingdom">United Kingdom</option>
												<option value="Australian">Australian</option>
											</select>
										</div>
									</div>

									<div className="row my-2">
										<div className="col-6">
											<hr />
										</div>
									</div>

									<div className="row mb-3">
										<label htmlFor="googlePlaceId" className="form-label col-1">
											Place Id:
										</label>
										<div className="col-5">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													name="googlePlaceId"
													value={formData.googlePlaceId || ''}
													onChange={handleChange}
													required
												/>
												<a
													className="btn btn-primary"
													target="_blank"
													href="https://developers-dot-devsite-v2-prod.appspot.com/my-business/content/tools/placeid-lookup"
												>
													Find
												</a>
											</div>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="googleCid" className="form-label col-1">
											Cid:
										</label>
										<div className="col-5">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													name="googleCid"
													value={formData.googleCid || ''}
													onChange={handleChange}
													required
												/>
												<a
													className="btn btn-primary"
													target="_blank"
													href="https://cidfinder.com/"
												>
													Find
												</a>
											</div>
										</div>
									</div>

									<div className="row my-2">
										<div className="col-6">
											<hr />
										</div>
									</div>

									<div className="row mb-3">
										<label htmlFor="name" className="form-label col-1">
											Name:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="name"
												value={formData.name || ''}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="nameExtension" className="form-label col-1">
											Name Extension:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="nameExtension"
												value={formData.nameExtension || ''}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="streetAddress1" className="form-label col-1">
											Address:
										</label>
										<div className="col-5">
											<div className="input-group row mx-0">
												<div className="col-5 ps-0">
													<input
														type="text"
														className="form-control"
														name="streetAddress1"
														value={formData.streetAddress1 || ''}
														onChange={handleChange}
													/>
												</div>
												<div className="col-3 ps-0">
													<input
														type="text"
														className="form-control"
														name="city"
														value={formData.city || ''}
														onChange={handleChange}
													/>
												</div>
												<div className="col-2 ps-0">
													<input
														type="text"
														className="form-control"
														name="state"
														value={formData.state || ''}
														onChange={handleChange}
													/>
												</div>
												<div className="col-2 px-0">
													<input
														type="text"
														className="form-control"
														name="postalCode"
														value={formData.postalCode || ''}
														onChange={handleChange}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="phone" className="form-label col-1">
											Phone:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="phone"
												value={formData.phone || ''}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="website" className="form-label col-1">
											Website Url:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="website"
												value={formData.website || ''}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="email" className="form-label col-1">
											Email:
										</label>
										<div className="col-5">
											<input
												type="email"
												className="form-control"
												name="email"
												value={userEmail || ''}
												onChange={(e) => setUserEmail(e.target.value)}
												required
											/>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="hostingOnly" className="form-label col-1">
											Hosting Only:
										</label>
										<div className="col-5">
											<input
												type="checkbox"
												className="form-check-input"
												name="hostingOnly"
												checked={formData.hostingOnly}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="reportDayOfMonth" className="form-label col-1">
											Report Day of Month:
										</label>
										<div className="col-5">
											<input
												type="number"
												className="form-control"
												name="reportDayOfMonth"
												value={formData.reportDayOfMonth || 1}
												onChange={handleChange}
												min={1}
												max={31}
											/>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="nextReportDate" className="form-label col-1">
											Next Report Date:
										</label>
										<div className="col-5">
											<input
												type="date"
												className="form-control"
												name="nextReportDate"
												value={formData.nextReportDate || ''}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="row mb-2">
										<label htmlFor="timeZone" className="form-label col-1">
											Time Zone:
										</label>
										<div className="col-5">
											{timeZones &&
											selectedTimeZone && (
												<div className="text-start">
													<Select
														options={timeZones}
														defaultValue={selectedTimeZone}
														onChange={(e) => setSelectedTimeZone(e)}
													/>
												</div>
											)}
										</div>
									</div>

									<div className="row my-2">
										<div className="col-6">
											<hr />
										</div>
									</div>

									<div className="row mb-3">
										<label htmlFor="stripeSubId" className="form-label col-1">
											Subscription Id:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="stripeSubId"
												value={formData.stripeSubId || ''}
												onChange={handleChange}
											/>
										</div>
									</div>

									<div className="row mb-3">
										<label htmlFor="ahOrderId" className="form-label col-1">
											AH Order Id:
										</label>
										<div className="col-5">
											<input
												type="text"
												className="form-control"
												name="ahOrderId"
												value={formData.ahOrderId || ''}
												onChange={handleChange}
											/>
										</div>
									</div>

									<div className="row mb-2">
										<label htmlFor="salesRepId" className="form-label col-1">
											Sales Rep:
										</label>
										<div className="col-5">
											<select
												value={formData.salesRepId}
												className="form-select"
												id="salesRepId"
												name="salesRepId"
												onChange={handleChange}
												required
											>
												{salesUsers && (
													<Fragment>
														<option value="" />
														{salesUsers.map((salesUser) => (
															<option value={salesUser.id} key={salesUser.id}>
																{`${salesUser.firstName} ${salesUser.lastName} (${salesUser.email})`}
															</option>
														))}
													</Fragment>
												)}
											</select>
										</div>
									</div>

									<div className="row my-2">
										<div className="col-6">
											<hr />
										</div>
									</div>

									<div className="row mb-1">
										<label htmlFor="userId" className="form-label col-1">
											User:
										</label>
										<div className="col-5">
											{allUsersForSelect && (
												<Select
													options={allUsersForSelect}
													required
													value={selectedUser}
													onChange={(e) => setSelectedUser(e)}
												/>
											)}
										</div>
									</div>

									{/* <div className="row mb-3">
											<label htmlFor="firstName" className="form-label col-1">
												First Name:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="firstName"
													value={userFormData.firstName || ''}
													onChange={handleUserDataChange}
													required
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="lastName" className="form-label col-1">
												Last Name:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="lastName"
													value={userFormData.lastName || ''}
													onChange={handleUserDataChange}
													required
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="email" className="form-label col-1">
												Email:
											</label>
											<div className="col-5">
												<input
													type="email"
													className="form-control"
													name="email"
													value={userFormData.email || ''}
													onChange={handleUserDataChange}
													required
												/>
											</div>
										</div>
										<div className="row mb-1">
											<label htmlFor="phoneNumber" className="form-label col-1">
												Phone:
											</label>
											<div className="col-5">
												<input
													type="phoneNumber"
													className="form-control"
													name="phoneNumber"
													value={userFormData.phoneNumber || ''}
													onChange={handleUserDataChange}
													required
												/>
											</div>
										</div> */}
								</form>
							</Fragment>
						)}
					</div>
				</div>

				{campaign &&
				isEdit && (
					<CampaignButtonsContainer
						campaign={campaign}
						onSave={handleSubmit}
						onSaveNext={() => handleSubmit(true)}
						disabled={isLoading || isDuplicating}
						newOrderId={campaign.status === 'NewOrder' ? campaign.id : null}
						cancelButtonHtml={
							<Fragment>
								{campaign.status === 'Deleted' ? (
									<button
										className="btn btn-danger me-3"
										type="button"
										onClick={() => handleChangeStatusCampaign('Deleted', 'Active')}
										disabled={isLoading || isDuplicating}
									>
										Reactivate Campaign
									</button>
								) : (
									<Fragment>
										<button
											className="btn btn-danger me-3"
											type="button"
											onClick={() =>
												handleChangeStatusCampaign(
													campaign.status,
													campaign.status === 'Canceled' ? 'Active' : 'Canceled'
												)}
											disabled={isLoading || isDuplicating}
										>
											{campaign.status === 'Canceled' ? 'Reactivate Campaign' : 'Cancel Campaign'}
										</button>
										{campaign.status === 'Canceled' && (
											<button
												className="btn btn-danger me-3"
												type="button"
												onClick={toggleDeleteCampaignDialog}
												disabled={isLoading || isDuplicating}
											>
												Delete Campaign
											</button>
										)}
									</Fragment>
								)}
							</Fragment>
						}
						additionalButtonHtml={
							<Fragment>
								{campaign.status !== 'Deleted' && (
									<Fragment>
										{campaign.status === 'Approved' && (
											<button
												className="btn btn-primary ms-2"
												type="button"
												onClick={() => handleChangeStatusCampaign('Approved', 'New')}
												disabled={isLoading || isDuplicating}
											>
												Set In Progress
											</button>
										)}
										{campaign.status === 'New' && (
											<button
												className="btn btn-primary ms-2"
												type="button"
												onClick={() => handleChangeStatusCampaign('New', 'Active')}
												disabled={isLoading || isDuplicating}
											>
												Set Active
											</button>
										)}
										<button
											className="btn btn-primary ms-2"
											type="button"
											onClick={handleDuplicateCampaign}
											disabled={isLoading || isDuplicating}
										>
											Duplicate
										</button>
										<button
											className="btn btn-link ms-2"
											type="button"
											onClick={() => navigate('/campaigns/list')}
											disabled={isLoading || isDuplicating}
										>
											<i className="fa-solid fa-arrow-left-long" />Back To List
										</button>
									</Fragment>
								)}
							</Fragment>
						}
					/>
				)}

				{showDeleteCampaignDialog && (
					<ModalDialog className="text-start">
						<ModalDialogContent align="start">
							<div className="row mb-4">
								<div className="col-12">
									<h4>Confirm Campaign Deletion</h4>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col-12">
									<label htmlFor="confirmDeleteBox" className="form-label mb-3">
										Type the word DELETE in capital letters into the box below and click on the
										Delete button.
										<br /> <strong>NOTE: This action is irreversible!</strong>
									</label>
									<input
										type="text"
										className="form-control form-control-lg"
										name="confirmDeleteBox"
										onChange={(e) => setConfirmDeleteText(e.target.value)}
										placeholder="Type DELETE to confirm deletion"
									/>
								</div>
							</div>
						</ModalDialogContent>
						<ModalDialogButtons>
							<button className="btn btn-danger" type="button" onClick={handleDeleteCampaign}>
								{isDeleting ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Deleting...</span>
									</Fragment>
								) : (
									<span>Delete</span>
								)}
							</button>
							<button
								className="btn btn-outline-primary ms-2"
								type="button"
								onClick={toggleDeleteCampaignDialog}
							>
								Cancel
							</button>
						</ModalDialogButtons>
					</ModalDialog>
				)}
			</div>
		</Fragment>
	);
};

export default CampaignUpdateScreen;
