import { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../../components/spinner/Spinner';
import { GenerateSEOReportDataGrid } from '../../Services';
import GridReportDataGrid from '../dataGrid/GridReportDataGrid';
import './DataGrid.css';

const DataGrid = () => {
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ useLatLng, setUseLatLng ] = useState(false);
	const [ googlePlaceId, setGooglePlaceId ] = useState('');
	const [ keyword, setKeyword ] = useState('');
	const [ pointDistance, setPointDistance ] = useState('1');
	const [ zoom, setZoom ] = useState(13);
	const [ searchType, setSearchType ] = useState('serp');
	const [ lat, setLat ] = useState(0);
	const [ lng, setLng ] = useState(0);
	const [ selectedReport, setSelectedReport ] = useState();
	const [ points, setPoints ] = useState('');
	const googlePlaceIdRef = useRef();
	const latRef = useRef();
	const lngRef = useRef();
	const keywordRef = useRef();
	const pointDistanceRef = useRef();
	const zoomRef = useRef();
	const campaignId = null;

	const handleGenerateReport = async (e) => {
		e.preventDefault();

		if (googlePlaceId.trim() === '') {
			setIsGenerating(false);
			googlePlaceIdRef.current.classList.add('required-highlight');
			googlePlaceIdRef.current.focus();
			return;
		}

		if (!!useLatLng) {
			if (lat.trim() === '') {
				latRef.current.classList.add('required-highlight');
				latRef.current.focus();
				return;
			}

			if (lng.trim() === '') {
				lngRef.current.classList.add('required-highlight');
				lngRef.current.focus();
				return;
			}
		}

		if (keyword.trim() === '') {
			setIsGenerating(false);
			keywordRef.current.classList.add('required-highlight');
			keywordRef.current.focus();
			return;
		}

		if (pointDistance.trim() === '') {
			setIsGenerating(false);
			pointDistanceRef.current.classList.add('required-highlight');
			pointDistanceRef.current.focus();
			return;
		}

		setIsGenerating(true);

		if (googlePlaceIdRef.current) googlePlaceIdRef.current.classList.remove('required-highlight');
		if (latRef.current) latRef.current.classList.remove('required-highlight');
		if (lngRef.current) lngRef.current.classList.remove('required-highlight');
		keywordRef.current.classList.remove('required-highlight');
		pointDistanceRef.current.classList.remove('required-highlight');
		zoomRef.current.classList.remove('required-highlight');

		const google = window.google;
		const geocoder = new google.maps.Geocoder();

		geocoder.geocode(
			{
				placeId : googlePlaceId
			},
			async function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					const tempLat = results[0].geometry.location.lat();
					const tempLng = results[0].geometry.location.lng();

					let data = {
						campaignId    : null,
						googlePlaceId : googlePlaceId,
						keywords      : [ keyword ],
						gridPoints    : points,
						pointDistance : pointDistance,
						lat           : useLatLng ? parseFloat(lat) : tempLat,
						lng           : useLatLng ? parseFloat(lng) : tempLng,
						zoom          : parseInt(zoom),
						gridApiType   : searchType
					};

					const response = await GenerateSEOReportDataGrid(data);

					if (response.success) {
						setSelectedReport(response);
					} else {
						console.error(response);
					}

					setIsGenerating(false);
				}
			}
		);
	};

	return (
		<Fragment>
			<div className="campaign-v-p-report text-start">
				<div className="report-header container d-block">
					<Fragment>
						<div className="">
							<form onSubmit={handleGenerateReport}>
								<div className="row mb-3">
									<div className="col-lg-3 col-12 px-1">
										<label htmlFor="googlePlaceId" className="form-label">
											Google Place Id:
										</label>
										<input
											type="text"
											ref={googlePlaceIdRef}
											className="form-control"
											id="googlePlaceId"
											placeholder="Google Place Id"
											value={googlePlaceId}
											onChange={(e) => setGooglePlaceId(e.target.value)}
										/>
									</div>
									<div className="col-lg-3 col-12 px-1">
										<label htmlFor="keyword" className="form-label">
											Keyword:
										</label>
										<input
											type="text"
											ref={keywordRef}
											className="form-control"
											id="keyword"
											placeholder="Keyword"
											value={keyword}
											onChange={(e) => setKeyword(e.target.value)}
										/>
									</div>
									<div className="col-lg-1 col-12 px-1">
										<label htmlFor="points" className="form-label">
											Points:
										</label>
										<input
											type="number"
											className="form-control"
											id="points"
											placeholder="Number of Points"
											value={points}
											onChange={(e) => setPoints(e.target.value)}
											min={1}
											max={25}
											step={1}
										/>
									</div>
									<div className="col-lg-2 col-12 px-1">
										<label htmlFor="pointDistance" className="form-label">
											Point Distance (miles):
										</label>
										<input
											type="number"
											ref={pointDistanceRef}
											className="form-control"
											id="pointDistance"
											placeholder="Point Distance"
											value={pointDistance}
											onChange={(e) => setPointDistance(e.target.value)}
											min={0.1}
											max={5}
											step={0.1}
										/>
									</div>
									<div className="col-lg-1 col-12 px-1">
										<label htmlFor="zoom" className="form-label">
											Zoom:
										</label>
										<select
											className="form-select"
											ref={zoomRef}
											value={zoom}
											onChange={(e) => setZoom(e.target.value)}
										>
											<option value="11">11</option>
											<option value="12">12</option>
											<option value="13">13</option>
											<option value="14">14</option>
											<option value="0">All Zooms</option>
										</select>
									</div>
									<div className="col-lg-2 col-12 px-1">
										<label htmlFor="searchType" className="form-label">
											Api:
										</label>
										<select
											className="form-select"
											value={searchType}
											onChange={(e) => setSearchType(e.target.value)}
										>
											<option value="serp">Serp Api</option>
											{/* <option value="google">Google Api</option> */}
											<option value="dseo">DataForSeo Api</option>
										</select>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-lg-2 col-12">
										<label className="checkbox-container mt-2">
											<input
												type="checkbox"
												value="useLatLng"
												id="useLatLng"
												name="useLatLng"
												className="form-check-input"
												checked={!!useLatLng}
												onChange={(e) => setUseLatLng(e.target.checked)}
											/>
											<label className="form-check-label" htmlFor="useLatLng">
												Use Lat/Lng
											</label>
										</label>
									</div>
									{useLatLng && (
										<div className="col-lg-10 col-12">
											<div className="row px-0">
												<div className="col-lg-3 col-12">
													<div className="row">
														<div className="col-2">
															<label htmlFor="lat" className="form-label mt-2">
																Lat:
															</label>
														</div>
														{/* <div className="col-8 pe-0">
															<input
																type="range"
																className="slider-input"
																id="lat"
																value={lat}
																onChange={(e) => setLat(e.target.value)}
																min="-90"
																max="90"
																step="0.001"
															/>
														</div> */}
														<div className="col-10">
															<input
																type="text"
																ref={latRef}
																className="form-control"
																id="latValue"
																value={lat}
																onChange={(e) => setLat(e.target.value)}
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-3 col-12">
													<div className="row">
														<div className="col-2">
															<label htmlFor="lng" className="form-label mt-2">
																Lng:
															</label>
														</div>
														{/* <div className="col-8 pe-0">
															<input
																type="range"
																className="slider-input"
																id="lng"
																value={lng}
																onChange={(e) => setLng(e.target.value)}
																min="-180"
																max="180"
																step="0.001"
															/>
														</div> */}
														<div className="col-10">
															<input
																type="text"
																ref={lngRef}
																className="form-control"
																id="lngValue"
																value={lng}
																onChange={(e) => setLng(e.target.value)}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="row">
									<div className="col-12">
										<button type="submit" className="btn btn-primary">
											<span>Generate Report</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</Fragment>
				</div>
				<div className="container">
					{isGenerating ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="map-preview">
								{selectedReport &&
								zoom && (
									<Fragment>
										<GridReportDataGrid
											gridData={selectedReport.gridResponse}
											lat={lat}
											lng={lng}
											numberOfPoints={points}
											pointDistance={pointDistance}
											googlePlaceId={googlePlaceId}
											zoom={zoom}
										/>
									</Fragment>
								)}
							</div>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default DataGrid;
