export const initialState = {
	user              : null,
	adminUser         : null,
	host              : window.location.origin,
	mobileReportView  : 'base',
	reportSendToEmail : 'matt@realwebsite.com', //'matthew.realwebsite@gmail.com'
	reportSendEmailCC : 'mapboost@realwebsite.com'
};

export const actionTypes = {
	SET_USER               : 'SET_USER',
	SET_ADMIN_USER         : 'SET_ADMIN_USER',
	SET_MOBILE_REPORT_VIEW : 'SET_MOBILE_REPORT_VIEW'
};

const reducer = (state, action) => {
	//console.log(action);
	switch (action.type) {
		case actionTypes.SET_USER:
			return {
				...state,
				user : action.user
			};
		case actionTypes.SET_ADMIN_USER:
			return {
				...state,
				adminUser : action.adminUser
			};
		case actionTypes.SET_MOBILE_REPORT_VIEW:
			return {
				...state,
				mobileReportView : action.mobileReportView
			};
		default:
			return state;
	}
};

export default reducer;
