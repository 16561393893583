export const UnixTimestampToDateTime = (timestamp) => {
	const format = new Intl.DateTimeFormat('en-US', {
		year   : 'numeric',
		month  : 'long',
		day    : '2-digit',
		hour   : '2-digit',
		minute : '2-digit',
		hour12 : true
	});

	return format.format(new Date(timestamp * 1000));
};
