import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/spinner/Spinner';
import './PublicCustomerReportInfoPage.css';
import { Fragment } from 'react';
import { AddReportHistoryClickEvent, GetReportHistoryDetails, UpdateReportHistoryRecord } from '../../Services';
import { useStateValue } from '../../StateProvider';
import moment from 'moment';
import { toast } from 'react-toastify';

const PublicCustomerReportInfoPage = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ reportHistory, setReportHistory ] = useState();
	const [ videoEmbedLink, setVideoEmbedLink ] = useState();
	const [ reportDate, setReportDate ] = useState();
	const [ nextReportDate, setNextReportDate ] = useState();
	const [ reportUrl, setReportUrl ] = useState();
	const [ clickEventAdded, setClickEventAdded ] = useState(false);
	const { reportHistoryId, reportHistoryId2, cl } = useParams();
	const [ { host, user }, dispatch ] = useStateValue();
	const [ isEdit, setIsEdit ] = useState(false);

	useEffect(() => {
		//duplicate id fix
		if (reportHistoryId && reportHistoryId2 && reportHistoryId === reportHistoryId2) {
			let currentUrl = window.location.href;
			let newUrl = currentUrl.replace('/' + reportHistoryId2, '');

			window.location.href = newUrl;
		}
	}, []);

	useEffect(() => {
		document.body.classList.add('gray-background');

		getReportHistoryDetails();
	}, []);

	useEffect(
		() => {
			if (cl && clickEventAdded) {
				//remove query param and redirect
				let currentUrl = window.location.href;
				window.location.href = currentUrl.replace('/cl', '');
			}
		},
		[ clickEventAdded ]
	);

	const getReportHistoryDetails = async () => {
		if (reportHistoryId) {
			const response = await GetReportHistoryDetails(reportHistoryId);

			if (response && response.id) {
				let tempReportHistory = response;

				if (cl) {
					let data = {
						campaignReportHistoryId : tempReportHistory.id,
						clickedLink             : tempReportHistory.shareUrl,
						type                    : 'Click'
					};

					await AddReportHistoryClickEvent(tempReportHistory.id, data);

					setClickEventAdded(true);
				}

				if (tempReportHistory.videoUrl) {
					let tempVideoEmbedLink = tempReportHistory.videoUrl.replace('share', 'embed');
					tempVideoEmbedLink += (tempVideoEmbedLink.indexOf('?') === -1 ? '?' : '&') + `hideEmbedTopBar=true`;

					setVideoEmbedLink(tempVideoEmbedLink);
				} else {
					setVideoEmbedLink();
				}

				if (tempReportHistory.shareUrl.indexOf('customer/report') > -1) {
					tempReportHistory.shareUrl = `${host}/view/report/v5/${tempReportHistory.campaign.id}`;
				}

				setReportDate(moment(tempReportHistory.dateCreated).format('dddd, MMMM Do yyyy'));

				if (tempReportHistory.campaign && tempReportHistory.campaign.nextReportDate) {
					setNextReportDate(moment(tempReportHistory.campaign.nextReportDate).format('dddd, MMMM Do yyyy'));
				}

				setReportHistory(tempReportHistory);
			}
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		if (e.target.name === 'videoEmbedLink') {
			//
		} else {
			setReportHistory({
				...reportHistory,
				[e.target.name]: e.target.value
			});
		}
	};

	const saveChanges = async () => {
		const response = await UpdateReportHistoryRecord(reportHistoryId, reportHistory);

		if (response.success) {
			toast.success('Record updated successfully.');

			setIsEdit(false);
			getReportHistoryDetails();
		}
	};

	return (
		<Fragment>
			<div className="container-fluid report-info-container">
				<div className="container">
					{isLoading || cl ? (
						<div className="text-center">
							<Spinner />
						</div>
					) : (
						<div className="report-info-container-inner">
							{reportHistory && (
								<Fragment>
									<div className="row campaign-title-wrapper">
										<div className="col-12">
											<h2 className="campaign-title">{reportHistory.campaign.name}</h2>
										</div>
									</div>

									<div className="row mb-2">
										<div className="col-12">
											<p className="mb-1">
												<strong>{`${reportHistory.subject}:`}</strong>
											</p>
											<p>{reportDate}</p>
										</div>
									</div>

									<div className="row mb-2">
										<div className="col-12">
											<p className="mb-1">
												<strong>Next Report Date:</strong>
											</p>
											<p>{nextReportDate}</p>
										</div>
									</div>

									<div className="row mb-2">
										<div className="col-12">
											<hr />
										</div>
									</div>

									<div className="row mb-2 pt-2">
										<div className="col-12">
											<p className="mb-1">
												<strong>Map Boost Directory Site:</strong>
											</p>
											<p>
												<a
													target="_blank"
													href={
														reportHistory.campaign.url &&
														reportHistory.campaign.url.indexOf('http') > -1 ? (
															reportHistory.campaign.url
														) : (
															`https://${reportHistory.campaign.url}`
														)
													}
												>
													{reportHistory.campaign.url}
												</a>
											</p>
										</div>
									</div>

									<div className="row mb-2 pt-2">
										<div className="col-12">
											<p className="mb-1">
												<strong>Business Profile:</strong>
											</p>
											<p>
												<a
													target="_blank"
													href={`https://google.com/maps/?cid=${reportHistory.campaign
														.googleCid}`}
												>
													{`https://google.com/maps/?cid=${reportHistory.campaign.googleCid}`}
												</a>
											</p>
										</div>
									</div>

									{/* ------ comments ------ */}
									{reportHistory.comments && (
										<div className="row mb-2">
											<div className="col-12">
												<hr />
											</div>
										</div>
									)}

									{isEdit ? (
										<div className="row mb-2 pt-2">
											<div className="col-12">
												<p className="mb-3">
													<strong>Report Comments:</strong>
												</p>
											</div>
											<div className="col-12">
												<textarea
													name="comments"
													className="form-control"
													value={reportHistory.comments}
													onChange={handleChange}
													rows={8}
												/>
											</div>
										</div>
									) : (
										<Fragment>
											{reportHistory.comments && (
												<div className="row mb-2 pt-2">
													<div className="col-12">
														<p className="mb-3">
															<strong>Report Comments:</strong>
														</p>
													</div>
													<div className="col-12">
														<pre>{reportHistory.comments}</pre>
													</div>
												</div>
											)}
										</Fragment>
									)}

									{/* ------ video embed link, report url ------ */}
									{videoEmbedLink && (
										<div className="row mb-2">
											<div className="col-12">
												<hr />
											</div>
										</div>
									)}

									{isEdit ? (
										<Fragment>
											<div className="row mb-4 pt-2">
												<div className="col-12">
													<p>
														<strong>Explainer Video:</strong>
													</p>
												</div>
												<div className="col-12">
													<input
														type="text"
														name="videoUrl"
														className="form-control"
														value={reportHistory.videoUrl || ''}
														onChange={handleChange}
													/>
												</div>
											</div>
											<div className="row mb-4 pt-2">
												<div className="col-12">
													<p>
														<strong>Report Url:</strong>
													</p>
												</div>
												<div className="col-12">
													<textarea
														name="shareUrl"
														className="form-control"
														value={reportHistory.shareUrl || ''}
														onChange={handleChange}
													/>
												</div>
											</div>
										</Fragment>
									) : (
										<Fragment>
											{videoEmbedLink && (
												<div className="row mb-4 pt-2">
													<div className="col-12">
														<p>
															<strong>Explainer Video:</strong>
														</p>
													</div>
													<div className="col-12">
														<div className="video-wrapper">
															<iframe
																src={videoEmbedLink}
																// frameborder="0"
																webkitallowfullscreen="true"
																mozallowfullscreen="true"
																allowFullScreen
															/>
														</div>
													</div>
												</div>
											)}
										</Fragment>
									)}

									<div className="row mb-2">
										<div className="col-12">
											<hr />
										</div>
									</div>

									<div className="row mb-2 pt-3">
										<div className="col-12">
											<a
												href={reportHistory.shareUrl}
												className="btn report-url-link"
												target="_blank"
											>
												View Report
											</a>
										</div>
									</div>
								</Fragment>
							)}
						</div>
					)}

					{user &&
					user.isAdmin &&
					!isLoading && (
						<div className="row pt-3">
							<div className="col-12">
								{isEdit ? (
									<Fragment>
										<button className="btn btn-primary" onClick={saveChanges}>
											Save Changes
										</button>
										<button className="btn btn-link ms-3" onClick={() => setIsEdit(false)}>
											Cancel
										</button>
									</Fragment>
								) : (
									<button className="btn btn-primary" onClick={() => setIsEdit(true)}>
										Edit
									</button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default PublicCustomerReportInfoPage;
