import { useEffect, useState } from 'react';
import { copyToClipboard } from '../../utils/Common';

const GridMapDataGrid = ({ gridData, lat, lng, googlePlaceId, numberOfPoints, zoom, mapIdValue }) => {
	const [ mapId, setMapId ] = useState();
	const [ markers, setMarkers ] = useState();

	useEffect(
		() => {
			if (gridData) {
				setMapId(mapIdValue);
				clearMarkers();
			} else {
				setMapId();
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (!markers) {
				if (mapId) {
					initializeGrid();
				}
			}
		},
		[ markers, mapId, zoom ]
	);

	useEffect(
		() => {
			if (mapId) {
				initializeGrid();
			}
		},
		[ zoom ]
	);

	const clearMarkers = () => {
		if (markers && markers.length > 0) {
			markers.forEach((marker) => {
				marker.map = null;
			});

			setMarkers();
		}
	};

	const initializeGrid = () => {
		const center = { lat: lat, lng: lng };

		const google = window.google;

		const map = new google.maps.Map(document.getElementById(mapId), {
			//zoom   : parseInt(zoom),
			center : center,
			mapId  : mapId
		});

		let bounds = new google.maps.LatLngBounds();

		const keyword = gridData;

		let tempMarkers = [];

		for (let i = 0; i < numberOfPoints; i++) {
			for (let j = 0; j < numberOfPoints; j++) {
				const x = i + 1;
				const y = j + 1;
				const targetPoint = keyword.points.find((p) => p.pointXIndex == x && p.pointYIndex == y);

				if (targetPoint) {
					const targetPosition = {
						lat : targetPoint.lat,
						lng : targetPoint.lng
					};

					const markerView = new google.maps.marker.AdvancedMarkerView({
						map,
						position : {
							lat : targetPosition.lat,
							lng : targetPosition.lng
						},
						content  : buildContent(targetPoint, googlePlaceId, null)
					});

					const element = markerView.element;

					[ 'focus', 'pointerenter' ].forEach((event) => {
						element.addEventListener(event, () => {
							highlight(markerView, null);
						});
					});
					[ 'blur', 'pointerleave' ].forEach((event) => {
						element.addEventListener(event, () => {
							unhighlight(markerView, null);
						});
					});
					[ 'click' ].forEach((event) => {
						element.addEventListener(event, () => {
							let latLngValue = markerView.position.lat + ',' + markerView.position.lng;
							copyToClipboard(latLngValue, 'Lat and long coordinates copied!');
						});
					});

					tempMarkers.push(markerView);

					bounds.extend(targetPosition);
				}
			}
		}

		map.fitBounds(bounds);

		setTimeout(() => {
			map.setZoom(parseInt(zoom));
		}, 50);

		setMarkers(tempMarkers);

		//exportMapAsImage();
	};

	function highlight(markerView, property) {
		markerView.content.classList.add('highlight');
		markerView.element.style.zIndex = 1;
	}

	function unhighlight(markerView, property) {
		markerView.content.classList.remove('highlight');
		markerView.element.style.zIndex = '';
	}

	function buildContent(point, rankedPlaceId, rankedPlaceCid) {
		const content = document.createElement('div');

		content.classList.add('ranking-bubble');
		// first let's check if the place we're checking for is in the list of
		// results and assign the proper className
		let className = 'bad';
		let position = '21+';
		let rankedPlace;

		if (
			(rankedPlaceId && rankedPlaceId.trim() !== '') ||
			(rankedPlaceCid && rankedPlaceCid.trim() !== '' && point.results && point.results.length > 0)
		) {
			if (rankedPlaceId && rankedPlaceId.trim() !== '') {
				if (point.results && point.results.length > 0) {
					rankedPlace = point.results.find((p) => p.place_id && p.place_id.indexOf(rankedPlaceId) > -1);
				}
			} else if (!rankedPlace && rankedPlaceCid && rankedPlaceCid.trim() !== '') {
				rankedPlace = point.results.find((p) => p.data_cid && p.data_cid.indexOf(rankedPlaceCid) > -1);
			}
		}

		if (rankedPlace) {
			const rankedPosition = rankedPlace.position;
			position = rankedPosition;

			if (position <= 3) {
				className = 'excellent';
				// } else if (position <= 6) {
				// 	className = 'good';
			} else if (position <= 10) {
				className = 'decent';
			}

			if (position > 20) position = '21+';
		} else if (!point.results || point.results.length === 0) {
			className = 'not-found';
			content.innerHTML = `<div class="icon"><span class="value">NF</span></div>`;
			content.classList.add(className);

			return content;
		}

		// console.log('ranked place', rankedPlace);

		// now append the class to the element
		content.classList.add(className);

		if (point.results) {
			// now let's build the rest of the content
			content.innerHTML = `
		<div class="icon"><span class="value">${position}</span></div>
	`;

			const details = document.createElement('div');
			details.classList.add('details');

			const businessList = document.createElement('div');
			businessList.classList.add('business-list');

			for (let i = 0; i < point.results.length && i < 3; i++) {
				// loop first three results and add them to details
				const result = point.results[i];

				const business = document.createElement('div');
				business.classList.add('business-container');
				business.innerHTML = `
				<div class="ranking">${result.position}</div>
				<div class="name">${result.title}</div>
				<!--<div class="url"><a href="${result.website}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>-->
			`;

				if (
					rankedPlace &&
					((result.place_id && result.place_id.indexOf(rankedPlace.place_id) > -1) ||
						(result.data_cid && result.data_cid.indexOf(rankedPlace.data_cid) > -1))
				) {
					business.classList.add('target');
				}

				businessList.appendChild(business);
			}

			if (point.results.length > 0) {
				details.appendChild(businessList);
				content.appendChild(details);
			}
		}

		if (point.improvement) {
			const improvement = document.createElement('div');
			improvement.classList.add('improvement');

			const improvementValue = point.improvement;
			let improvementClass = 'bad';
			let improvementIcon = '<i class="fa-solid fa-arrow-down"></i>';

			if (improvementValue >= 0) {
				improvementClass = 'good';
				improvementIcon = '<i class="fa-solid fa-arrow-up"></i>';
			}

			improvement.innerHTML = `<div class="improvement value ${improvementClass}">${improvementIcon}<span>${improvementValue
				.toString()
				.replace('-', '')}</span></div>`;

			content.querySelector('.details').appendChild(improvement);

			content.classList.add(`improvement-${improvementClass}`);
		}

		return content;
	}

	return (
		<div className="grid-report-map">
			<div className="map" id={mapId} />
		</div>
	);
};

export default GridMapDataGrid;
