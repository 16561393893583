import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCampaign, GetCampaignReviews, UpdateCampaignReviews, UpdateCampaignStatus } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignReviewsScreen.css';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';

const CampaignReviewsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ reviews, setReviews ] = useState([]);
	const [ formData, setFormData ] = useState({});
	const { campaignId } = useParams();
	const [ showDialog, setShowDialog ] = useState(false);
	const [ editIndex, setEditIndex ] = useState();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignReviews();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
		}
	};

	const getCampaignReviews = async () => {
		setIsLoading(true);

		const response = await GetCampaignReviews(campaignId);

		if (response) {
			let tempReviews = response;

			for (let i = 0; i < tempReviews.length; i++) {
				tempReviews[i].index = i;
			}

			setReviews(tempReviews);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleItemRemove = async (index) => {
		if (window.confirm('Are you sure that you want to remove this review?')) {
			let tempReviews = reviews.map((x) => x);

			tempReviews = tempReviews.filter((x) => x.index !== index);

			setReviews(tempReviews);
		}
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		let tempReviews = reviews.map((x) => x);

		if (!tempReviews || tempReviews.length === 0) {
			toast.error('Please add review first');
			return;
		}

		let data = {
			id      : campaignId,
			reviews : []
		};

		for (let i = 0; i < tempReviews.length; i++) {
			data.reviews.push({
				rating   : tempReviews[i].rating,
				headline : tempReviews[i].headline,
				text     : tempReviews[i].text,
				source   : 'Google'
			});
		}

		const response = await UpdateCampaignReviews(campaignId, data);

		if (response.success) {
			// //update campaign status
			// 	await UpdateCampaignStatus(campaignId, { status: 'Reviews' });

			if (redirect) {
				navigate(`/setup/${campaignId}/headline-and-desc`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	const handleSaveItem = (e) => {
		e.preventDefault();

		if (!formData.headline || formData.headline.trim() === '') {
			toast.error('Name is required!');
			return;
		}

		let tempReviews = reviews.map((x) => x);

		if (editIndex !== undefined) {
			//edit
			let currentReview = tempReviews.find((x) => x.index === editIndex);

			if (currentReview) {
				currentReview.rating = 5;
				currentReview.headline = formData.headline;
				currentReview.text = formData.text;
			}
		} else {
			//new
			tempReviews.push({
				index    : tempReviews.length,
				rating   : 5,
				headline : formData.headline,
				text     : formData.text
			});
		}

		setReviews(tempReviews);
		setFormData({});
		setShowDialog(false);
	};

	const handleToggleDialog = async (index) => {
		setShowDialog(!showDialog);

		if (!showDialog) {
			if (index !== undefined) {
				//edit
				setEditIndex(index);
				let currentReview = reviews.find((x) => x.index === index);

				if (currentReview) {
					setFormData(currentReview);
				}
			} else {
				//new
				setEditIndex();
				setFormData({});
			}
		} else {
			setFormData({});
		}
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="campaign-subform-container">
								{isLoading && <Spinner />}

								{!isLoading && (
									<div className="row">
										<div className="col-12">
											<button
												className="btn btn-primary mb-3"
												type="button"
												onClick={() => handleToggleDialog()}
											>
												Add Review
											</button>
											{reviews &&
											reviews.length > 0 && (
												<Fragment>
													<table className="table border reviews-table">
														<thead>
															<tr>
																{/* <th scope="col" className="rating">
															<span>Rating</span>
														</th> */}
																<th scope="col" className="headline">
																	<span>Name</span>
																</th>
																<th scope="col" className="text">
																	<span>Text</span>
																</th>
																<th />
															</tr>
														</thead>
														<tbody>
															{reviews.map((review, index) => (
																<tr key={index}>
																	{/* <td className="rating">{review.rating}</td> */}
																	<td className="headline">{review.headline}</td>
																	<td
																		className="text text-truncate"
																		title={review.text}
																	>
																		{review.text}
																	</td>
																	<td className="review-action text-end">
																		<button
																			href="#"
																			className="btn btn-link p-0"
																			onClick={() => handleToggleDialog(index)}
																			title="Edit Review"
																		>
																			<i className="fa-solid fa-pen" />
																		</button>
																		<button
																			href="#"
																			className="btn btn-link p-0 ms-2"
																			onClick={() => handleItemRemove(index)}
																			title="Remove Review"
																		>
																			<i className="fa-solid fa-trash-can" />
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</Fragment>
											)}
										</div>
									</div>
								)}
							</div>

							{showDialog && (
								<ModalDialog position="right-sidebar">
									<ModalDialogContent align="start">
										<form className="row">
											{/* <div className="col-12 mb-3">
											<label htmlFor="rating" className="form-label">
												Rating:
											</label>
											<input
												type="number"
												className="form-control"
												name="rating"
												value={formData.rating || ''}
												onChange={handleChange}
												min={1}
												max={5}
												step={1}
											/>
										</div> */}
											<div className="col-12 mb-3">
												<label htmlFor="headline" className="form-label">
													Name:
												</label>
												<input
													type="text"
													className="form-control"
													name="headline"
													value={formData.headline || ''}
													onChange={handleChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="text" className="form-label">
													Text:
												</label>
												<textarea
													className="form-control"
													name="text"
													value={formData.text || ''}
													onChange={handleChange}
													rows={5}
												/>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons>
										<button className="btn btn-primary" type="button" onClick={handleSaveItem}>
											Save
										</button>
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={handleToggleDialog}
										>
											Cancel
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}
						</Fragment>
					)}
				</div>
				<CampaignButtonsContainer
					campaign={campaign}
					onSave={handleSubmit}
					onSaveNext={() => handleSubmit(true)}
				/>
			</div>
		</Fragment>
	);
};

export default CampaignReviewsScreen;
