import { Fragment, useEffect, useState } from 'react';
import GridKeywordPickerArrows from './gridKeywordPicker/GridKeywordPickerArrows';
import GridMapV2 from './gridMap/GridMapV2';
import './GridReportSingleKeyword.css';
import KeywordMapStats from './keywordMapStats/KeywordMapStats';

const GridReportSingleKeyword = ({
	gridData,
	placeId,
	placeCid,
	showAllKeywords,
	onKeywordSelected
}) => {
	const [ targetKeyword, setTargetKeyword ] = useState();

	useEffect(
		() => {
			// console.log('gridData', gridData);

			if (gridData && !showAllKeywords) {
				setTargetKeyword({
					lat              : gridData.lat,
					lng              : gridData.lng,
					horizontalPoints : gridData.horizontalPoints,
					verticalPoints   : gridData.verticalPoints,
					...gridData.data[0]
				});

				if (onKeywordSelected) {
					onKeywordSelected(gridData.data[0].keyword);
				}
			}
		},
		[ gridData, placeId, placeCid, showAllKeywords ]
	);

	const handleKeywordClick = (keyword) => {
		setTargetKeyword({
			lat              : gridData.lat,
			lng              : gridData.lng,
			horizontalPoints : gridData.horizontalPoints,
			verticalPoints   : gridData.verticalPoints,
			...keyword
		});

		if (onKeywordSelected) {
			onKeywordSelected(keyword.keyword);
		}
	};

	return (
		<Fragment>
			{/* <h6 className="display-5">Grid Report</h6> */}

			{!showAllKeywords ? (
				<Fragment>
					<GridKeywordPickerArrows
						gridData={gridData}
						activeKeyword={targetKeyword}
						onKeywordSelected={handleKeywordClick}
					/>
					<KeywordMapStats keywordData={targetKeyword} />
					{targetKeyword && (
						<GridMapV2
							key="map-0"
							gridData={targetKeyword}
							placeId={placeId}
							placeCid={placeCid}
						/>
					)}
				</Fragment>
			) : (
				<Fragment>
					{gridData &&
					gridData.data &&
					gridData.data.length > 0 && (
						<Fragment>
							{gridData.data.map((keyword, index) => {
								const keywordData = {
									lat              : gridData.lat,
									lng              : gridData.lng,
									horizontalPoints :
										gridData.horizontalPoints,
									verticalPoints   : gridData.verticalPoints,
									...keyword
								};

								return (
									<Fragment key={`fragment-${index}`}>
										<h5
											key={`title-${index}`}
											className="mt-3 mb-3"
										>
											{keyword.keyword}
										</h5>
										<GridMapV2
											key={`map-${index}`}
											gridData={keywordData}
											placeId={placeId}
											placeCid={placeCid}
										/>
									</Fragment>
								);
							})}
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default GridReportSingleKeyword;
