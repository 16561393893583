import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCEEditor = ({ onEditorChange, value, height, basicToolbar, inline, toolbarMode }) => {
	const editorRef = useRef(null);

	let toolbarOptions = basicToolbar
		? 'formatselect | bold italic backcolor | align bullist numlist'
		: 'undo redo | bold italic underline | align numlist bullist | blocks fontfamily fontsize | link emoticons | ' +
			'forecolor removeformat | code fullscreen preview | removeformat';

	return (
		<Editor
			apiKey="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={value}
			init={{
				height             : height ? height : 150,
				convert_urls       : false,
				relative_urls      : false,
				remove_script_host : false,
				menubar            : false,
				//inline             : inline,
				toolbar_mode       : toolbarMode ? toolbarMode : 'floating',
				plugins            : [
					'advlist',
					'autolink',
					'lists',
					'link',
					'image',
					'charmap',
					'preview',
					'anchor',
					'searchreplace',
					'visualblocks',
					'code',
					'fullscreen',
					'insertdatetime',
					'media',
					'table',
					'help',
					'wordcount'
				],
				toolbar            : toolbarOptions,
				setup              : (editor) => {
					editor.on('change', () => {
						if (onEditorChange) {
							onEditorChange(editor.getContent());
						}
					});
				}
				// setup              : (editor) => {
				// 	editor.on('change', (e) => {
				// 		alert('The TinyMCE rich text editor content has changed.');
				// 	});
				// }
				// onEditorChange     : (newValue, editor) => {
				// 	const content = editor.getContent();
				// 	onEditorChange(newValue);
				// }
			}}
		/>
	);
};

export default TinyMCEEditor;
