import { useState, useEffect } from 'react';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import './Login.css';
import { AppLogin } from '../../Services';
import { StoreAdminUser, setToken } from '../../utils/StorageUtil';
import { useNavigate } from 'react-router-dom';

const Login = ({ onSuccessfulLogin }) => {
	const [ { user, adminUser }, dispatch ] = useStateValue();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ wrongCredentials, setWrongCredentials ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			if (user.isAdmin) {
				navigate('/campaigns/list');
			} else if (user.isSalesUser) {
				navigate('/sales');
			} else if (!user.isAdmin && !user.isSalesUser) {
				navigate('/campaigns/list');
			}
		}
	});

	const login = async (e) => {
		e.preventDefault();

		let data = { email: email, password: password };

		const response = await AppLogin(data);

		if (response && response.token) {
			const { token, ...tempUser } = response;

			setToken(token);

			dispatch({
				type : actionTypes.SET_USER,
				user : tempUser
			});

			if (tempUser.isAdmin) {
				dispatch({
					type      : actionTypes.SET_ADMIN_USER,
					adminUser : tempUser
				});

				StoreAdminUser(response);
			}

			if (onSuccessfulLogin) {
				onSuccessfulLogin(tempUser);
			}

			if (tempUser.isAdmin) {
				navigate('/campaigns/list');
			} else if (tempUser.isSalesUser) {
				navigate('/sales');
			} else if (!tempUser.isAdmin && !tempUser.isSalesUser) {
				navigate('/campaigns/list');
			}
		} else {
			setWrongCredentials(true);
		}
	};

	return (
		<div className="container">
			<div className="login-wrapper">
				<form onSubmit={login}>
					<div className="row mb-2">
						<div className="col-12">
							<label htmlFor="email" className="form-label">
								Email:
							</label>
							<input
								type="email"
								className="form-control"
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-12">
							<label htmlFor="password" className="form-label">
								Password:
							</label>
							<input
								type="password"
								className="form-control"
								id="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<button className="btn btn-primary" type="submit">
								Login
							</button>
							{wrongCredentials && <div className="mt-2">Wrong email or password!</div>}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
