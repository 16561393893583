import { Fragment, useEffect, useState } from 'react';
import GridMapDataGridV2 from './GridMapDataGridV2';
import './GridReportDataGridV2.css';

const GridReportDataGridV2 = ({
	gridData,
	lat,
	lng,
	googlePlaceId,
	numberOfPoints,
	zoom,
	radiusInMiles,
	onCircleMove,
	isPreview = false
}) => {
	const [ targetKeyword, setTargetKeyword ] = useState();
	const allZooms = [ 11, 12, 13, 14 ];

	useEffect(
		() => {
			if (gridData && zoom) {
				setTargetKeyword({
					lat              : lat,
					lng              : lng,
					horizontalPoints : numberOfPoints,
					verticalPoints   : numberOfPoints,
					...(gridData && gridData.data ? gridData.data[0] : null)
				});
			}
		},
		[ gridData, zoom ]
	);

	const handleCircleMove = (center) => {
		if (onCircleMove) {
			onCircleMove(center);
		}
	};

	return (
		<div className="data-grid-report">
			<Fragment>
				{targetKeyword &&
					(zoom === '0' ? (
						<Fragment>
							<span className="mt-2">Zoom: 11</span>
							<GridMapDataGridV2
								key={`map-0`}
								mapIdValue={`report-map-0`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								radiusInMiles={radiusInMiles}
								zoom={11}
								onCircleMove={handleCircleMove}
								isPreview={isPreview}
							/>
							<span className="mt-2">Zoom: 12</span>
							<GridMapDataGridV2
								key={`map-1`}
								mapIdValue={`report-map-1`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								radiusInMiles={radiusInMiles}
								zoom={12}
								onCircleMove={handleCircleMove}
								isPreview={isPreview}
							/>
							<span className="mt-2">Zoom: 13</span>
							<GridMapDataGridV2
								key={`map-2`}
								mapIdValue={`report-map-2`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								radiusInMiles={radiusInMiles}
								zoom={13}
								onCircleMove={handleCircleMove}
								isPreview={isPreview}
							/>
							<span className="mt-2">Zoom: 14</span>
							<GridMapDataGridV2
								key={`map-3`}
								mapIdValue={`report-map-3`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								radiusInMiles={radiusInMiles}
								zoom={14}
								onCircleMove={handleCircleMove}
								isPreview={isPreview}
							/>
						</Fragment>
					) : (
						<GridMapDataGridV2
							key="map-0"
							mapIdValue="report-map-0"
							gridData={targetKeyword}
							lat={lat}
							lng={lng}
							googlePlaceId={googlePlaceId}
							numberOfPoints={numberOfPoints}
							radiusInMiles={radiusInMiles}
							zoom={zoom}
							onCircleMove={handleCircleMove}
							isPreview={isPreview}
						/>
					))}
			</Fragment>
		</div>
	);
};

export default GridReportDataGridV2;
