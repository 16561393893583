import { Fragment } from 'react';
import './ModalDialog.css';

const ModalDialog = ({ children, position = 'default', onClose }) => {
	return (
		<Fragment>
			<div className="modal-background" />
			<div className={`modal-dialog-container ${position === 'default' ? '' : position}`}>
				<div className="modal-dialog shadow-large">{children}</div>
			</div>
		</Fragment>
	);
};

export default ModalDialog;
