import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCampaign, UpdateCampaign, UpdateCampaignStatus } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignSocialScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';

const CampaignSocialScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const navigate = useNavigate();

	useEffect(
		() => {
			setIsLoading(true);

			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
			setFormData(response);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		const response = await UpdateCampaign(campaignId, formData);

		if (response.success) {
			if (redirect) {
				navigate(`/setup/${campaignId}/directory`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<Fragment>
					<PageHeadline
						campaign={campaign}
						headline={`Campaign - ${campaign ? campaign.name : ''}`}
						linkText="Back To List"
						linkUrl="/campaigns/list"
					/>
					<CampaignTabs />

					<div className="campaign-form-container no-overflow">
						<CampaignSubTabs />

						{isLoading ? (
							<Spinner />
						) : (
							<Fragment>
								<div className="campaign-subform-container">
									<form className="text-start">
										<div className="row mb-3">
											<label htmlFor="facebook" className="form-label col-1">
												Facebook:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="facebook"
													value={formData.facebook || ''}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="instagram" className="form-label col-1">
												Instagram:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="instagram"
													value={formData.instagram || ''}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="linkedIn" className="form-label col-1">
												LinkedIn:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="linkedIn"
													value={formData.linkedIn || ''}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="youtube" className="form-label col-1">
												YouTube:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="youtube"
													value={formData.youtube || ''}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<label htmlFor="twitter" className="form-label col-1">
												Twitter:
											</label>
											<div className="col-5">
												<input
													type="text"
													className="form-control"
													name="twitter"
													value={formData.twitter || ''}
													onChange={handleChange}
												/>
											</div>
										</div>
									</form>
								</div>
							</Fragment>
						)}
					</div>
					<CampaignButtonsContainer
						campaign={campaign}
						onSave={handleSubmit}
						onSaveNext={() => handleSubmit(true)}
					/>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignSocialScreen;
