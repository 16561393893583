import { Fragment, useEffect, useState } from 'react';
import RankingFiltersV3 from './rankingFilters/RankingFiltersV3';
import './RankReportV3.css';
import GridReportToggleTabs from '../gridReportToggleTabs/GridReportToggleTabs';
import { useStateValue } from '../../../../StateProvider';
import { actionTypes } from '../../../../reducer';

const RankReportV3 = ({
	rankData,
	compareToRankData,
	onMobileReportViewChange,
	reportName,
	compareToReportName,
	targetKeyword = null
}) => {
	const [ rankStats, setRankStats ] = useState();
	const [ compareToRankStats, setCompareToRankStats ] = useState();
	const [ activeFilter, setActiveFilter ] = useState('all');
	const [ compareToActiveFilter, setCompareToActiveFilter ] = useState('all');
	const [ rankings, setRankings ] = useState();
	const [ compareToRankings, setCompareToRankings ] = useState();
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(
		() => {
			if (rankData && rankData.results && rankData.results.length > 0) {
				let tempRankStats = {};

				if (!targetKeyword) {
					tempRankStats = {
						total     : rankData.results.length,
						notRanked : rankData.results.filter((r) => !r.ranking).length,
						top3      : rankData.results.filter((r) => r.ranking && r.ranking < 4).length,
						top10     : rankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3).length,
						other     : rankData.results.filter((r) => r.ranking && r.ranking > 10).length
					};
				} else {
					tempRankStats = {
						total     : rankData.results.filter((r) => r.keyword == targetKeyword).length,
						notRanked : rankData.results.filter((r) => r.keyword == targetKeyword && !r.ranking).length,
						top3      : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4
						).length,
						top10     : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
						).length,
						other     : rankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking > 10
						).length
					};
				}

				// console.log(tempRankStats);
				// console.log(rankData);

				setRankStats(tempRankStats);
			} else {
				setRankStats();
			}

			if (compareToRankData && compareToRankData.results && compareToRankData.results.length > 0) {
				let tempRankStats = {};

				if (!targetKeyword) {
					tempRankStats = {
						total     : compareToRankData.results.length,
						notRanked : compareToRankData.results.filter((r) => !r.ranking).length,
						top3      : compareToRankData.results.filter((r) => r.ranking && r.ranking < 4).length,
						top10     : compareToRankData.results.filter(
							(r) => r.ranking && r.ranking < 11 && r.ranking > 3
						).length,
						other     : compareToRankData.results.filter((r) => r.ranking && r.ranking > 10).length
					};
				} else {
					tempRankStats = {
						total     : compareToRankData.results.filter((r) => r.keyword == targetKeyword).length,
						notRanked : compareToRankData.results.filter((r) => r.keyword == targetKeyword && !r.ranking)
							.length,
						top3      : compareToRankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4
						).length,
						top10     : compareToRankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
						).length,
						other     : compareToRankData.results.filter(
							(r) => r.keyword == targetKeyword && r.ranking && r.ranking > 10
						).length
					};
				}

				setCompareToRankStats(tempRankStats);
			} else {
				setCompareToRankStats();
			}

			setActiveFilter('all');
		},
		[ rankData, compareToRankData, targetKeyword ]
	);

	const handleFilterChange = (filter) => {
		setActiveFilter(filter);
		setCompareToActiveFilter(filter);
	};

	useEffect(
		() => {
			// console.log('targetKeyword', targetKeyword);
			if (rankData && rankData.results && rankData.results.length > 0) {
				if (!targetKeyword) {
					if (activeFilter == 'all') {
						setRankings(rankData.results);
					} else if (activeFilter == 'top3') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking < 4));
					} else if (activeFilter == 'top10') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3));
					} else if (activeFilter == 'top20') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10));
					} else if (activeFilter == 'over20') {
						setRankings(rankData.results.filter((r) => r.ranking && r.ranking > 20));
					} else if (activeFilter == 'notRanked') {
						setRankings(rankData.results.filter((r) => r.ranking && !r.ranking));
					}
				} else {
					if (activeFilter == 'all') {
						setRankings(rankData.results.filter((r) => r.keyword == targetKeyword));
					} else if (activeFilter == 'top3') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4)
						);
					} else if (activeFilter == 'top10') {
						setRankings(
							rankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
							)
						);
					} else if (activeFilter == 'top20') {
						setRankings(
							rankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 21 && r.ranking > 10
							)
						);
					} else if (activeFilter == 'over20') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && r.ranking > 20)
						);
					} else if (activeFilter == 'notRanked') {
						setRankings(
							rankData.results.filter((r) => r.keyword == targetKeyword && r.ranking && !r.ranking)
						);
					}
				}
			} else {
				setRankings();
			}

			if (compareToRankData && compareToRankData.results && compareToRankData.results.length > 0) {
				if (!targetKeyword) {
					if (compareToActiveFilter == 'all') {
						setCompareToRankings(compareToRankData.results);
					} else if (compareToActiveFilter == 'top3') {
						setCompareToRankings(compareToRankData.results.filter((r) => r.ranking && r.ranking < 4));
					} else if (compareToActiveFilter == 'top10') {
						setCompareToRankings(
							compareToRankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3)
						);
					} else if (compareToActiveFilter == 'top20') {
						setCompareToRankings(
							compareToRankData.results.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10)
						);
					} else if (compareToActiveFilter == 'over20') {
						setCompareToRankings(compareToRankData.results.filter((r) => r.ranking && r.ranking > 20));
					} else if (compareToActiveFilter == 'notRanked') {
						setCompareToRankings(compareToRankData.results.filter((r) => r.ranking && !r.ranking));
					}
				} else {
					if (compareToActiveFilter == 'all') {
						setCompareToRankings(compareToRankData.results.filter((r) => r.keyword == targetKeyword));
					} else if (compareToActiveFilter == 'top3') {
						setCompareToRankings(
							compareToRankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 4
							)
						);
					} else if (compareToActiveFilter == 'top10') {
						setCompareToRankings(
							compareToRankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 11 && r.ranking > 3
							)
						);
					} else if (compareToActiveFilter == 'top20') {
						setCompareToRankings(
							compareToRankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking < 21 && r.ranking > 10
							)
						);
					} else if (compareToActiveFilter == 'over20') {
						setCompareToRankings(
							compareToRankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && r.ranking > 20
							)
						);
					} else if (compareToActiveFilter == 'notRanked') {
						setCompareToRankings(
							compareToRankData.results.filter(
								(r) => r.keyword == targetKeyword && r.ranking && !r.ranking
							)
						);
					}
				}
			} else {
				setCompareToRankings();
			}
		},
		[ rankData, compareToRankData, activeFilter, compareToActiveFilter, targetKeyword ]
	);

	const handleRankLocationUrl = (locationUrl) => {
		let urlObj = new URL(locationUrl);
		let q = urlObj.searchParams.get('q');

		if (q) {
			//remove comma
			q = q.replace(',', '');
		}

		let newLocationUrl = `${urlObj.origin}${urlObj.pathname}?q=${encodeURI(q)}`;

		return newLocationUrl;
	};

	return (
		<Fragment>
			<RankingFiltersV3
				activeFilter={activeFilter}
				onChange={handleFilterChange}
				targetKeyword={targetKeyword}
				rankData={rankData}
				compareToRankData={compareToRankData}
				mobileReportView={mobileReportView}
			/>

			<div className="row mb-5">
				{compareToRankData && (
					<GridReportToggleTabs reportName={reportName} compareToReportName={compareToReportName} />
				)}
				{(!mobileReportView || mobileReportView === 'base') && (
					<div className={`${compareToRankings ? 'col-lg-6' : ''} col-12`}>
						{rankings &&
						rankings.length > 0 && (
							<div className="rank-report">
								<table className="table b-0 rank-table">
									<thead>
										<tr>
											<th scope="col" className="rank" colSpan={2}>
												<div className="rank-table-header">
													<div>Rank</div>
													<div className="text-right">
														{`${rankings.length} ${rankings.length > 1
															? 'results'
															: 'result'}`}
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{rankings.map((result) => {
											let rowClass = 'btn-secondary';

											if (result.ranking) {
												if (result.ranking < 4) {
													rowClass = 'btn-success';
												} else if (result.ranking < 11) {
													rowClass = 'btn-warning';
												} else {
													rowClass = 'btn-danger';
												}
											} else {
												rowClass = 'btn-secondary';
											}

											return (
												<tr key={`${result.keyword}-${result.location}`}>
													<td className="rank">{`${result.ranking
														? result.ranking
														: '0'}`}</td>
													<td>
														<a
															className={`btn ${rowClass} d-block`}
															href={handleRankLocationUrl(result.searchLink)}
															target="_blank"
														>
															<span className="rank-details">
																<span className="rank-location">{result.location}</span>
																{compareToRankData &&
																!!result.improvement &&
																result.improvement !== 0 && (
																	<Fragment>
																		<span
																			className={`rank-difference ${result.improvement >
																			0
																				? 'good'
																				: 'bad'}`}
																		>
																			<i
																				className={`fa-solid ${result.improvement >
																				0
																					? 'fa-arrow-up'
																					: 'fa-arrow-down'}`}
																			/>
																			<span className="rank-difference-value">
																				{result.improvement}
																			</span>
																		</span>
																	</Fragment>
																)}
															</span>
														</a>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
				{compareToRankings &&
				compareToRankings.length > 0 &&
				(!mobileReportView || mobileReportView === 'compare') && (
					<div className="col-lg-6 col-12 ps-0">
						<div className="rank-report">
							<table className="table b-0 rank-table">
								<thead>
									<tr>
										<th scope="col" className="rank" colSpan={2}>
											<div className="rank-table-header">
												<div>Rank</div>
												<div className="text-right">
													{`${compareToRankings.length} ${compareToRankings.length > 1
														? 'results'
														: 'result'}`}
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{compareToRankings.map((result) => {
										let rowClass = 'btn-secondary';

										if (result.ranking) {
											if (result.ranking < 4) {
												rowClass = 'btn-success';
											} else if (result.ranking < 11) {
												rowClass = 'btn-warning';
											} else {
												rowClass = 'btn-danger';
											}
										} else {
											rowClass = 'btn-secondary';
										}

										return (
											<tr key={`${result.keyword}-${result.location}`}>
												<td className="rank">{`${result.ranking ? result.ranking : '0'}`}</td>
												<td>
													<a
														className={`btn ${rowClass} d-block`}
														href={`${result.searchLink}`}
														target="_blank"
													>
														{result.location}
													</a>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				)}

				{!compareToRankings ||
					(compareToRankings.length === 0 && (
						<div className="col-lg-6 col-12 ps-0">
							<div className="rank-report">
								<div className="map-data-not-available">
									<div className="box">No Data Available</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</Fragment>
	);
};

export default RankReportV3;
