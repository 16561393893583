import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../../StateProvider';
import { actionTypes } from '../../../../reducer';
import './GridReportToggleTabsV5.css';

const GridReportToggleTabsV5 = ({ reportName, compareToReportName }) => {
	const [ { mobileReportView }, dispatch ] = useStateValue();

	const handleMobileReportView = (view) => {
		dispatch({
			type             : actionTypes.SET_MOBILE_REPORT_VIEW,
			mobileReportView : view
		});
	};

	return (
		<Fragment>
			<div className="mobile-view-buttons mb-3 d-lg-none d-flex">
				<button
					className={`report-toggle-button me-2 ${mobileReportView === 'compare' ? 'active' : ''}`}
					onClick={() => handleMobileReportView('compare')}
				>
					{compareToReportName ? compareToReportName : 'Compare To Report'}
				</button>
				<button
					className={`report-toggle-button ms-2 ${mobileReportView === 'base' ? 'active' : ''}`}
					onClick={() => handleMobileReportView('base')}
				>
					{reportName ? reportName : 'Base Report'}
				</button>
			</div>
		</Fragment>
	);
};

export default GridReportToggleTabsV5;
