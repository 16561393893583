import { Fragment, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import './DistanceFinder.css';

const DistanceFinder = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ startPoint, setStartPoint ] = useState('');
	const [ locations, setLocations ] = useState('');
	const [ result, setResult ] = useState('');
	const [ outputArray, setOutputArray ] = useState([]);

	useEffect(
		() => {
			if (outputArray && outputArray.length) {
				//reorder array items to match locations on the left side textarea
				let orderedArray = [];

				var locationsArray = locations.split('\n');

				for (let location of locationsArray) {
					//find item in outpuArray by location
					let outputItem = outputArray.find((x) => x.location.trim() === location.trim());

					if (outputItem) {
						orderedArray.push(outputItem);
					}
				}

				let tempResult = '';
				orderedArray.map((result) => {
					tempResult += result.distance + '\n';
				});

				setResult(tempResult);
			}
		},
		[ outputArray ]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setResult('');

		if (startPoint.trim() === '') {
			setIsLoading(false);
			toast.error('Start Point field cannot be empty');
			return;
		}

		if (locations.trim() === '') {
			setIsLoading(false);
			toast.error('Locations field cannot be empty');
			return;
		}

		setIsLoading(true);

		//get start lat and lng
		let startLat, startLng;

		let startPointArgs = startPoint.split(',');

		if (startPointArgs && startPointArgs.length == 2) {
			startLat = parseFloat(startPointArgs[0].trim());
			startLng = parseFloat(startPointArgs[1].trim());
		}

		if (isNaN(startLat) || isNaN(startLng)) {
			setIsLoading(false);
			toast.error('Start point value should be: {lat},{lng}');
			return;
		}

		var locationsArray = locations.split('\n');

		for (let location of locationsArray) {
			await getDistance(location, startLat, startLng);
		}

		setIsLoading(false);
		setOutputArray([]);
	};

	const getDistance = async (location, startLat, startLng) => {
		const google = window.google;
		const geocoder = new google.maps.Geocoder();

		if (location.trim() !== '' && location.trim().replace('\n', '') !== '') {
			geocoder.geocode(
				{
					address : location
				},
				function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						let lat = results[0].geometry.location.lat();
						let lng = results[0].geometry.location.lng();

						let startPointPosition = { lat: startLat, lng: startLng };
						let locationPosition = { lat: lat, lng: lng };

						var distance = google.maps.geometry.spherical.computeDistanceBetween(
							startPointPosition,
							locationPosition
						);
						var distanceInMiles = distance / 1000 * 0.621371;

						setOutputArray((prevState) => [
							...prevState,
							{ location: location, distance: distanceInMiles.toFixed(1) }
						]);
					}
				}
			);
		}
	};

	return (
		<Fragment>
			<div className="campaign-v-p-report text-start">
				<div className="report-header container d-block">
					<form onSubmit={handleSubmit}>
						<div className="row mb-3">
							<div className="col-lg-4 col-12">
								<label htmlFor="locations" className="form-label">
									Start Point:
								</label>
								<input
									type="text"
									className="form-control"
									name="startPoint"
									value={startPoint}
									onChange={(e) => setStartPoint(e.target.value)}
								/>
							</div>
							<div className="col-lg-4 col-12">
								<label htmlFor="locations" className="form-label">
									Locations:
								</label>
								<textarea
									className="form-control"
									name="locations"
									value={locations}
									rows={20}
									onChange={(e) => setLocations(e.target.value)}
								/>
							</div>
							<div className="col-lg-4 col-12">
								<label htmlFor="result" className="form-label">
									Distance (miles):
								</label>
								<textarea className="form-control" name="result" defaultValue={result} rows={20} />
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<button type="submit" className="btn btn-primary">
									<span>Submit</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default DistanceFinder;
