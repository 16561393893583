import { Fragment, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import './UserHeaderMenu.css';
import { RemoveAdminUser, deleteToken } from '../../../utils/StorageUtil';

const UserHeaderMenu = ({ hideMenu }) => {
	const [ { user }, dispatch ] = useStateValue();
	const accMenuRef = useRef(null);
	const navigate = useNavigate();

	useEffect(
		() => {
			window.addEventListener('mousedown', handleClickOutside);

			return () => {
				window.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[ accMenuRef ]
	);

	const handleClickOutside = (e) => {
		if (accMenuRef.current && !accMenuRef.current.contains(e.target)) {
			hideMenu();
		}
	};

	const logout = () => {
		deleteToken();

		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});

		dispatch({
			type      : actionTypes.SET_ADMIN_USER,
			adminUser : null
		});

		RemoveAdminUser();

		navigate('/login');
	};

	return (
		<Fragment>
			{user && (
				<div ref={accMenuRef} className={`header-account-menu shadow-large`}>
					<div className="header-account-menu-content">
						<div className="profile-details">
							<div className={`credits-info ${user.credits === 0 ? 'warning' : ''}`}>
								Credits: {user.credits}
							</div>

							{user.isAdmin && (
								<Fragment>
									<Link to="/admin" onClick={hideMenu} className="btn btn-block">
										<i className="fas fa-sliders-h" />
										<span>Administration</span>
									</Link>

									<hr className="my-2" />
								</Fragment>
							)}

							<button onClick={logout} className="btn btn-block">
								<i className="fas fa-sign-out-alt" />
								<span>Log out</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default UserHeaderMenu;
