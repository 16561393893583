import { Fragment } from 'react';
import './KeywordMapStatsV3.css';
import KeywordStatItemV3 from './keywordStatItem/KeywordStatItemV3';

const KeywordMapStatsV3 = ({ keywordData, mobileReportView }) => {
	return (
		<div className="keyword-map-stats-v3">
			{keywordData && (
				<Fragment>
					<KeywordStatItemV3
						label="Average"
						changeSymbol="%"
						value={keywordData.averageRank}
						change={keywordData.averageRankChangePercentage}
					/>

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV3 label="Top 3" value={keywordData.top3} change={keywordData.top3Change} />
					<KeywordStatItemV3 label="4-10" value={keywordData.top10} change={keywordData.top10Change} />

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV3 label="11-20" value={keywordData.top20} change={keywordData.top20Change} />
					{keywordData.over20 &&
					keywordData.over20 != 0 && (
						<KeywordStatItemV3 label="21+" value={keywordData.over20} change={keywordData.over20Change} />
					)}
				</Fragment>
			)}
		</div>
	);
};

export default KeywordMapStatsV3;
