import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../spinner/Spinner';
import './GridKeywordPickerArrows.css';

const GridKeywordPickerArrows = ({
	gridData,
	onKeywordSelected,
	activeKeyword
}) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ keywords, setKeywords ] = useState();

	useEffect(
		() => {
			if (gridData) {
				let tempKeywords = [];
				gridData.data.forEach((keyword) => {
					tempKeywords.push({
						...keyword
					});
				});

				console.log('tempKeywords', tempKeywords);

				setKeywords(tempKeywords);
			} else {
				setKeywords([]);
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (keywords) {
				setIsLoading(false);
			}
		},
		[ keywords ]
	);

	const handleKeywordClick = (keyword) => {
		if (onKeywordSelected) {
			onKeywordSelected(keyword);
		}
	};

	const handleChange = (e) => {
		const keyword = e.target.value;
		const selectedKeyword = keywords.find((k) => k.keyword === keyword);

		handleKeywordClick(selectedKeyword);
	};

	const handleDirection = (direction = -1) => {
		const currentIndex = keywords.findIndex(
			(k) => k.keyword === activeKeyword.keyword
		);
		const nextIndex = currentIndex + direction;

		if (nextIndex >= 0 && nextIndex < keywords.length) {
			handleKeywordClick(keywords[nextIndex]);
		} else if (nextIndex < 0) {
			handleKeywordClick(keywords[keywords.length - 1]);
		} else {
			handleKeywordClick(keywords[0]);
		}
	};

	return (
		<div className="grid-report-keywords">
			{isLoading && <Spinner />}

			{!isLoading &&
			keywords &&
			keywords.length > 0 && (
				<Fragment>
					<div className="input-group">
						<button
							type="button"
							className="btn btn-secondary btn-left"
						>
							<i
								className="fas fa-arrow-left"
								onClick={() => handleDirection(-1)}
							/>
						</button>

						<select
							className="form-control form-control-lg"
							value={activeKeyword.keyword}
							onChange={handleChange}
						>
							{keywords.map((keyword) => (
								<option
									key={keyword.keyword}
									value={keyword.keyword}
								>
									{keyword.keyword}
								</option>
							))}
						</select>

						<button
							type="button"
							className="btn btn-secondary btn-right"
						>
							<i
								className="fas fa-arrow-right"
								onClick={() => handleDirection(1)}
							/>
						</button>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default GridKeywordPickerArrows;
