import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import {
	AddOffer,
	GetOffer,
	GetProducts,
	GetSalesUsers,
	GetSystemOffers,
	GetUsers,
	UpdateOffer
} from '../../../Services';
import './OfferDetailsDialog.css';
import Spinner from '../../../components/spinner/Spinner';
import Select from 'react-select';
import CreateOfferItem from '../createOfferItem/CreateOfferItem';
import { createGuid, formatCurrency } from '../../../utils/Common';
import moment from 'moment';
import { useStateValue } from '../../../StateProvider';
import TinyMCEEditor from '../../../components/tinyMCEEditor/TinyMCEEditor';

const OfferDetailsDialog = ({ offerId, onSubmit, onClose, onDuplicate, duplicateSourceId, isSystem = false }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ offerData, setOfferData ] = useState({});
	const [ activeUsers, setActiveUsers ] = useState();
	const [ offerUserId, setOfferUserId ] = useState();
	const [ offerProductId, setOfferProductId ] = useState();
	const [ offerDescription, setOfferDescription ] = useState('');
	const [ selectedUser, setSelectedUser ] = useState();
	const [ products, setProducts ] = useState();
	const [ productsForSelect, setProductsForSelect ] = useState();
	const [ selectedProduct, setSelectedProduct ] = useState();
	const [ invoiceItems, setInvoiceItems ] = useState([]);
	const [ subtotal, setSubtotal ] = useState(0);
	const [ total, setTotal ] = useState(0);
	const [ discount, setDiscount ] = useState(0);
	const [ showSubscriptionDetails, setShowSubscriptionDetails ] = useState(false);
	const [ showDMD, setShowDMD ] = useState(false);
	const [ dmdExpirationDays, setDmdExpirationDays ] = useState();
	const [ dmdExpirationDate, setDmdExpirationDate ] = useState();
	const [ showSystemOffersList, setShowSystemOffersList ] = useState(false);
	const [ systemOffers, setSystemOffers ] = useState();
	const [ salesReps, setSalesReps ] = useState([]);
	const [ offerSalesRepId, setOfferSalesRepId ] = useState();
	const [ offerSelectedSalesRep, setOfferSelectedSalesRep ] = useState();
	const emptySelectItem = {
		value : null,
		label : ''
	};
	const emptyItem = {
		name             : '',
		description      : '',
		price            : 0,
		paymentDaysDelay : 0
	};

	useEffect(
		() => {
			getProducts();
			getActiveUsers();
			getSalesUsers();

			if (duplicateSourceId) {
				getOffer(duplicateSourceId);
			} else {
				if (!offerId) {
					if (!isSystem) {
						getSystemOffers();
						setShowSystemOffersList(true);
					}

					setOfferData({ isSystem: isSystem, numberOfLocations: 1, createSubscriptionPerLocation: isSystem });
					setInvoiceItems([ { ...emptyItem, id: createGuid() } ]);
				} else {
					getOffer(offerId);
				}
			}
		},
		[ offerId, duplicateSourceId ]
	);

	useEffect(
		() => {
			if (isLoading && activeUsers && productsForSelect) {
				let tempSelectedUser;

				if (duplicateSourceId || offerId) {
					if (offerUserId) {
						tempSelectedUser = activeUsers.find((x) => x.value === offerUserId);
						setSelectedUser(tempSelectedUser || emptySelectItem);
					} else {
						setSelectedUser(emptySelectItem);
					}

					if (offerProductId) {
						let tempSelectedProduct = productsForSelect.find((x) => x.value === offerProductId);
						setSelectedProduct(tempSelectedProduct);
					} else {
						setSelectedProduct(emptySelectItem);
					}
				} else {
					setSelectedUser(emptySelectItem);

					if (isSystem) {
						setSelectedProduct(emptySelectItem);
					}
				}

				setIsLoading(false);
			}
		},
		[ offerUserId, offerProductId, activeUsers, products, productsForSelect ]
	);

	useEffect(
		() => {
			if (isLoading && systemOffers && activeUsers) {
				setSelectedUser(emptySelectItem);

				setIsLoading(false);
			}
		},
		[ systemOffers, activeUsers ]
	);

	useEffect(
		() => {
			if (invoiceItems) {
				if (invoiceItems.length > 1) {
					//uncheck and disable DMD section
					setOfferData({
						...offerData,
						hasDMD : false
					});
				}
			}
		},
		[ invoiceItems ]
	);

	useEffect(
		() => {
			if (salesReps && salesReps.length > 0) {
				if (offerId) {
					setOfferSelectedSalesRep(salesReps.find((x) => x.value === (offerSalesRepId || null)));
				} else {
					setOfferSelectedSalesRep(salesReps.find((x) => x.value === null));
				}
			}
		},
		[ salesReps, offerSalesRepId ]
	);

	const getOffer = async (id, isSelectedSystemOffer = false) => {
		setIsLoading(true);

		if (id) {
			let response = await GetOffer(id);

			if (response) {
				let tempOffer = response;

				setOfferData(tempOffer);
				setOfferUserId(tempOffer.userId);
				setOfferProductId(tempOffer.productId);
				setOfferSalesRepId(tempOffer.salesRepId);
				setDiscount(tempOffer.discount);
				setDmdExpirationDays(tempOffer.dmdExpirationDays);
				setDmdExpirationDate(tempOffer.dmdExpirationDate ? moment(tempOffer.dmdExpirationDate) : null);
				setOfferDescription(tempOffer.description);
				setShowDMD(tempOffer.hasDMD);

				if (tempOffer.offerItems && tempOffer.offerItems.length > 0) {
					setInvoiceItems(tempOffer.offerItems);
				}

				setShowSubscriptionDetails(tempOffer.isSubscription);

				if (isSelectedSystemOffer) {
					tempOffer.isSystem = false;
					tempOffer.isDefault = false;
					tempOffer.isReseller = false;

					if (!isSystem && productsForSelect && productsForSelect.length) {
						let tempSelectedProduct = productsForSelect.find((x) => x.value === tempOffer.productId);
						setSelectedProduct(tempSelectedProduct);
					}
				}
			}
		}
	};

	const getActiveUsers = async () => {
		let response = await GetUsers();

		if (response && response.length) {
			let tempUsers = response.filter((x) => x.status === 'Active');
			let tempUsersForSelect = [];
			tempUsersForSelect.push(emptySelectItem);

			//sort users alphabetically
			tempUsers = tempUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));

			for (let i = 0; i < tempUsers.length; i++) {
				tempUsersForSelect.push({
					value : tempUsers[i].id,
					label : `${tempUsers[i].firstName} ${tempUsers[i].lastName} (${tempUsers[i].email}`
				});
			}

			setActiveUsers(tempUsersForSelect);
		}
	};

	const getSalesUsers = async () => {
		let response = await GetSalesUsers();

		if (response && response.length) {
			//sort users alphabetically
			let tempSalesReps = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

			let tempSalesRepsForSelect = [];
			tempSalesRepsForSelect.push(emptySelectItem);

			for (let i = 0; i < tempSalesReps.length; i++) {
				tempSalesRepsForSelect.push({
					value : response[i].id,
					label : response[i].email
				});
			}

			setSalesReps(tempSalesRepsForSelect);
		}
	};

	const getProducts = async () => {
		let response = await GetProducts();

		if (response && response.length) {
			let tempProducts = response.filter((x) => x.status === 'Active');
			let tempProductsForSelect = [];
			tempProductsForSelect.push(emptySelectItem);

			//sort products alphabetically
			tempProducts = tempProducts.sort((a, b) => a.name.localeCompare(b.name));

			for (let i = 0; i < tempProducts.length; i++) {
				tempProductsForSelect.push({
					value       : response[i].id,
					label       : response[i].name,
					// label1      : `${response[i].name} - $${response[i].price}`,
					// label2      : `Trial: ${response[i].trialDays ? response[i].trialDays + ' days' : 'no'}`,
					description : response[i].description
				});
			}

			setProducts(tempProducts);
			setProductsForSelect(tempProductsForSelect);
		}
	};

	const getSystemOffers = async () => {
		setIsLoading(true);

		let response = await GetSystemOffers();

		if (response && response.length) {
			setSystemOffers(response);
		}
	};

	const handleChange = async (e) => {
		let type = e.target.type;
		let name = e.target.name;
		let value = e.target.value;

		if (type === 'checkbox') {
			value = e.target.checked;

			if (name === 'hasDMD') {
				setShowDMD(value);
			}
		}

		if (name === 'multipleLocations' && value) {
			setOfferData({
				...offerData,
				[name]            : value,
				numberOfLocations : 2
			});
		} else {
			setOfferData({
				...offerData,
				[name] : value
			});
		}
	};

	const handleSubmit = async () => {
		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		if (!offerData.name || offerData.name === '') {
			toast.error('Offer name is required');
			return;
		}

		if (!selectedProduct || !selectedProduct.value) {
			toast.error('Product is required');
			return;
		}

		if (!invoiceItems || invoiceItems.length === 0) {
			toast.error('At least a single offer item is required');
			return;
		}

		let tempInvoiceItems = invoiceItems.filter((x) => x);

		if (!tempInvoiceItems || tempInvoiceItems.length === 0) {
			toast.error('At least a single offer item is required');
			return;
		}

		invoiceItems.forEach((invoiceItem) => {
			if (!invoiceItem.price || invoiceItem.price === '' || parseFloat(invoiceItem.price) === 0) {
				toast.error('Payment amount value is not valid');
				return;
			}
		});

		if (!offerSelectedSalesRep || !offerSelectedSalesRep.value) {
			toast.error('Sales rep is required');
			return;
		}

		if (offerData.hasDMD) {
			if (!discount || discount === '' || parseFloat(discount) === 0) {
				toast.error('Discount amount value is not valid');
				return;
			}

			if (!dmdExpirationDays || dmdExpirationDays === '' || parseFloat(dmdExpirationDays) === 0) {
				toast.error('Expiration days value is not valid');
				return;
			}
		}

		if (offerData.multipleLocations) {
			if (
				!offerData.numberOfLocations ||
				offerData.numberOfLocations === '' ||
				parseInt(offerData.numberOfLocations) < 2
			) {
				toast.error('Number of locations should be 2 or greater');
				return;
			}
		}

		if (showSubscriptionDetails) {
			if (
				!offerData.subscriptionAmount ||
				offerData.subscriptionAmount === '' ||
				parseFloat(offerData.subscriptionAmount) === 0
			) {
				toast.error('Subscription amount value is not valid');
				return;
			}

			if (
				!offerData.subscriptionFirstPaymentDays ||
				offerData.subscriptionFirstPaymentDays === '' ||
				parseInt(offerData.subscriptionFirstPaymentDays) === 0
			) {
				toast.error('Subscription recurring days value is not valid');
				return;
			}
		}

		if (!total || total === '' || parseFloat(total) === 0) {
			toast.error('Total value is not valid');
			return;
		}

		setIsSaving(true);

		let data = offerData;
		data.userId = selectedUser ? selectedUser.value : null;
		data.productId = selectedProduct ? selectedProduct.value : null;
		data.description = offerDescription;
		data.price = total;
		data.isSubscription = showSubscriptionDetails;
		data.salesRepId = offerSelectedSalesRep.value;

		if (user.isSalesUser && !user.isAdmin) {
			//if sales rep is logged in
			data.salesRepId = user.id;
		}

		if (offerData.hasDMD) {
			data.discount = discount;
			data.dmdExpirationDays = dmdExpirationDays;
			data.dmdExpirationDate = dmdExpirationDate;
		} else {
			data.discount = null;
			data.dmdExpirationDays = null;
			data.dmdExpirationDate = null;
		}

		if (!offerData.multipleLocations) {
			data.numberOfLocations = 1;
			data.createSubscriptionPerLocation = false;
		}

		//only system offers can be default or reseller
		if (!data.isSystem) {
			data.isDefault = false;
			data.isReseller = false;
		}

		if (invoiceItems && invoiceItems.length > 0) {
			data.offerItems = invoiceItems.map((x) => x);
		}

		let response;

		if (offerId) {
			data.id = offerId;
			response = await UpdateOffer(offerId, data);
		} else {
			data.status = 'Active';
			response = await AddOffer(data);
		}

		if (response && response.id) {
			toast.success('Offer ' + (offerId ? 'updated' : 'created') + ' successfully');

			setTimeout(function() {
				if (onSubmit) {
					onSubmit();
				}
			}, 500);
		}

		setIsSaving(false);
	};

	const handleDuplicate = async (id) => {
		if (onDuplicate) {
			onDuplicate(id);
		}
	};

	const handleOnCloseDialog = () => {
		setOfferData({});
		setOfferProductId();
		setOfferDescription('');
		setOfferUserId();
		setDiscount(0);
		setShowDMD(false);
		setSubtotal(0);
		setTotal(0);
		setInvoiceItems([]);

		if (onClose) {
			onClose();
		}
	};

	const handleItemUpdate = (oldItem, newItem) => {
		let itemIndex = invoiceItems.findIndex((x) => x === oldItem);

		if (itemIndex > -1) {
			let tempArray = invoiceItems.slice();

			tempArray.splice(itemIndex, 1, newItem);

			setInvoiceItems(tempArray);
		}
	};

	const handleAddItem = () => {
		let tempArray = invoiceItems.slice();

		tempArray.push({ ...emptyItem, id: createGuid(), paymentDaysDelay: tempArray.length * 30 });

		setInvoiceItems(tempArray);
	};

	const handleDeleteItem = (item) => {
		let itemIndex = invoiceItems.findIndex((x) => x === item);

		if (itemIndex > -1) {
			let tempArray = invoiceItems.slice();

			tempArray.splice(itemIndex, 1);

			setInvoiceItems(tempArray);
		}
	};

	useEffect(
		() => {
			if (invoiceItems && invoiceItems.length > 0) {
				let tempSubtotal = 0;
				let tempTotal = 0;
				let tempDiscount = discount;

				if (!showDMD) {
					tempDiscount = 0;
				}

				for (let i = 0; i < invoiceItems.length; i++) {
					let invoiceItem = invoiceItems[i];

					if (invoiceItem.price && invoiceItem.price > 0) {
						tempSubtotal += invoiceItem.price;
					}
				}

				if (tempSubtotal > 0) {
					if (tempDiscount > 0) {
						tempTotal = tempSubtotal - tempDiscount;
					} else {
						tempTotal = tempSubtotal;
					}
				}

				setSubtotal(tempSubtotal.toFixed(2));
				setTotal(tempTotal);
			} else {
				setSubtotal(0);
				setTotal(0);
			}
		},
		[ invoiceItems, discount ]
	);

	// once attach subscription checkbox is toggled we need to
	// set predefined values for the subscription
	useEffect(
		() => {
			if (!isLoading) {
				if (showSubscriptionDetails) {
					setOfferData({
						...offerData,
						subscriptionAmount           : 47,
						subscriptionTrialDays        : 30,
						subscriptionFirstPaymentDays : 30,
						subscriptionPeriod           : 'Monthly'
					});
				} else {
					setOfferData({
						...offerData,
						subscriptionAmount           : null,
						subscriptionTrialDays        : null,
						subscriptionFirstPaymentDays : null,
						subscriptionPeriod           : null
					});
				}
			}
		},
		[ showSubscriptionDetails ]
	);

	useEffect(
		() => {
			if (dmdExpirationDays) {
				setDmdExpirationDate(moment().add(parseInt(dmdExpirationDays), 'days'));
			}
		},
		[ dmdExpirationDays ]
	);

	const handleSelectSystemOffer = async (id) => {
		let systemOfferData = await getOffer(id, true);
		setShowSystemOffersList(false);
	};

	return (
		<Fragment>
			<ModalDialog position="right-sidebar" className="text-start">
				<ModalDialogContent align="start">
					{isLoading ? (
						<div className="text-center">
							<Spinner />
						</div>
					) : (
						<Fragment>
							{showSystemOffersList ? (
								<Fragment>
									<h4 className="mb-3">Select Offer</h4>
									<table className="campaigns-list table table-responsive table-bordered system-offers-list">
										{/* <thead>
											<tr>
												<th className="name" scope="col">
													Name
												</th>
												<th className="select-offer" scope="col" />
											</tr>
										</thead> */}
										<tbody>
											{systemOffers && systemOffers.length > 0 ? (
												systemOffers.map((systemOffer, index) => {
													let offerExpireingDate = moment(systemOffer.dateCreated)
														.add(parseInt(dmdExpirationDays), 'days')
														.format('MM-DD-yyyy');

													return (
														<tr key={index}>
															<td className="offer-details">
																<div className="offer-name">{systemOffer.name}</div>
																<div>
																	<span className="me-2">Price:</span>
																	{formatCurrency(systemOffer.price, true)}
																</div>
																{systemOffer.hasDMD && (
																	<Fragment>
																		<div>
																			<span className="me-2">Discount:</span>
																			{formatCurrency(systemOffer.discount, true)}
																		</div>
																		{/* <div>
																			<span className="me-2">
																				Discount Expires At:
																			</span>
																			{offerExpireingDate}
																		</div> */}
																		<div>
																			<span className="me-2">
																				Discount Expires After:
																			</span>
																			{`${systemOffer.dmdExpirationDays} Days`}
																		</div>
																	</Fragment>
																)}
															</td>
															<td className="select-offer align-middle">
																<button
																	className="btn btn-link p-0"
																	onClick={(e) =>
																		handleSelectSystemOffer(systemOffer.id)}
																>
																	Select
																</button>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td className="p-3" colSpan={8}>
														<i>No system offers</i>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</Fragment>
							) : (
								<Fragment>
									<h4 className="mb-3">{offerId ? 'Edit Offer' : 'Add New Offer'}</h4>
									<form className="row send-report-form">
										{!isSystem && (
											<div className="mb-3 text-start">
												<label className="form-label">User:</label>
												{activeUsers &&
												selectedUser && (
													<Select
														options={activeUsers}
														value={selectedUser}
														onChange={(e) => {
															setSelectedUser(e);
														}}
														classNamePrefix="users_select"
														//isClearable={true}
														required
													/>
												)}
											</div>
										)}

										<div className="col-12 mb-3">
											<label htmlFor="name" className="form-label">
												Name:
											</label>
											<input
												type="text"
												className="form-control"
												name="name"
												value={offerData.name || ''}
												onChange={handleChange}
												disabled={isSaving}
												//required
											/>
										</div>

										<div className="col-12 mb-3">
											<label htmlFor="name" className="form-label">
												Product:
											</label>
											{isSystem ? (
												<Fragment>
													{productsForSelect &&
													selectedProduct && (
														<Select
															options={productsForSelect}
															value={selectedProduct}
															onChange={(e) => {
																setSelectedProduct(e);
																setOfferData({
																	...offerData,
																	description : e.description
																});
															}}
															classNamePrefix="products_select"
															required
															isSearchable={false}
															// isDisabled={true}
															// formatOptionLabel={function(data) {
															// 	return (
															// 		<div>
															// 			<div
															// 				dangerouslySetInnerHTML={{ __html: data.label1 }}
															// 			/>
															// 			<div
															// 				dangerouslySetInnerHTML={{ __html: data.label2 }}
															// 			/>
															// 		</div>
															// 	);
															// }}
														/>
													)}
												</Fragment>
											) : (
												<div>
													<label>{offerData.product ? offerData.product.name : ''}</label>
												</div>
											)}
										</div>

										<div className="col-12 mb-3">
											<label htmlFor="description" className="form-label">
												Description:
											</label>
											<TinyMCEEditor
												id="description"
												name="description"
												value={offerData.description}
												height={300}
												basicToolbar={false}
												onEditorChange={(content) => setOfferDescription(content)}
											/>
										</div>

										{(user.isAdmin || !user.isSalesUser) &&
										offerSelectedSalesRep && (
											<div className="col-12 mb-3">
												<label htmlFor="salesUsersSelect" className="form-label">
													Sales Rep:
												</label>
												<Select
													options={salesReps}
													isClearable
													required
													defaultValue={offerSelectedSalesRep}
													onChange={(e) => setOfferSelectedSalesRep(e)}
													isDisabled={isSaving}
												/>
											</div>
										)}

										<div className="col-12 mb-3">
											<label htmlFor="specialInstructions" className="form-label">
												Special Instructions:
											</label>
											<textarea
												className="form-control"
												name="specialInstructions"
												value={offerData.specialInstructions || ''}
												onChange={handleChange}
												disabled={isSaving}
												rows={3}
											/>
										</div>

										{invoiceItems && invoiceItems.length > 0 ? (
											<Fragment>
												<div className="col-12">
													<div className="form-inputs">
														<div className="offer-item-container offer-item-header">
															<div className="offer-item-details">
																<div className="offer-item">Payment</div>
																<div className="offer-item-amount">Amount</div>
																<div className="offer-item-days">Days</div>
																<div className="offer-item-action" />
															</div>
														</div>

														{invoiceItems.map((item, index) => (
															<CreateOfferItem
																key={index}
																item={item}
																index={index}
																//products={products}
																onUpdate={handleItemUpdate}
																onDelete={handleDeleteItem}
																isDisabled={isSaving}
															/>
														))}
													</div>
												</div>
											</Fragment>
										) : (
											<div className="py-3">No items</div>
										)}

										<div className="col-12 mb-2">
											<div className="total-line-item">
												<div className="total-line-item-details">
													Subtotal:
													<span>${subtotal}</span>
												</div>
											</div>
										</div>

										<div className="col-12 mb-3">
											<button
												type="button"
												className="btn btn-primary"
												onClick={handleAddItem}
												disabled={isSaving}
											>
												<span>Add New Item</span>
											</button>
										</div>

										<div className="col-12">
											<hr className="border opacity-50 my-2" />
										</div>

										<div className="col-12 mt-2">
											<input
												id="hasDMD"
												name="hasDMD"
												type="checkbox"
												className="form-check-input"
												checked={offerData.hasDMD}
												onChange={handleChange}
												disabled={isSaving || (invoiceItems && invoiceItems.length > 1)}
											/>
											<label htmlFor="hasDMD" className="form-label ms-2">
												Show DMD section
											</label>
										</div>

										{offerData &&
										offerData.hasDMD && (
											<div className="col-12 mt-1">
												<div className="total-line-item">
													<div className="total-line-item-details">Discount (DMD):</div>
												</div>
												<div className="total-line-item">
													<div className="total-line-item-details">
														<span className="total-line-item-label me-3">Amount:</span>
														<div className="input-group w-25 justify-content-end">
															<input
																type="number"
																className="form-control"
																value={discount || ''}
																onChange={(e) =>
																	setDiscount(parseFloat(e.target.value))}
																disabled={isSaving}
															/>
															<div className="input-group-append">
																<div className="input-group-text">$</div>
															</div>
														</div>
													</div>
												</div>
												<div className="total-line-item">
													<div className="total-line-item-details">
														<span className="total-line-item-label me-3">
															Expiration Days:
														</span>
														<div className="input-group w-25 justify-content-end">
															<input
																type="number"
																className="form-control"
																value={dmdExpirationDays || ''}
																onChange={(e) => setDmdExpirationDays(e.target.value)}
																disabled={isSaving}
															/>
														</div>
													</div>
												</div>
												<div className="total-line-item">
													<div className="total-line-item-details">
														<span className="total-line-item-label me-3">Expires At:</span>
														<div className="input-group w-25 justify-content-end">
															<span>
																{dmdExpirationDate ? (
																	dmdExpirationDate.format('MM-DD-yyyy')
																) : (
																	''
																)}
															</span>
														</div>
													</div>
												</div>
												<div className="total-line-item">
													<div className="total-line-item-details pb-3">
														<strong>Total:</strong>

														<span>${total.toFixed(2)}</span>
													</div>
												</div>
											</div>
										)}

										<div className="col-12">
											<hr className="border opacity-50 my-2" />
										</div>

										<div className="col-12 mt-2">
											<input
												id="multipleLocations"
												name="multipleLocations"
												type="checkbox"
												className="form-check-input"
												checked={offerData.multipleLocations}
												onChange={handleChange}
												disabled={isSaving}
											/>
											<label htmlFor="multipleLocations" className="form-label ms-2">
												Multiple Locations
											</label>
										</div>

										{offerData &&
										offerData.multipleLocations && (
											<Fragment>
												<div className="col-12 my-2">
													<label htmlFor="numberOfLocations" className="form-label">
														Number of locations:
													</label>
													<input
														type="number"
														className="form-control"
														name="numberOfLocations"
														value={offerData.numberOfLocations || ''}
														onChange={handleChange}
														disabled={isSaving}
														min={2}
														max={10}
														//required
													/>
												</div>
												<div className="col-12 my-2">
													<input
														id="createSubscriptionPerLocation"
														name="createSubscriptionPerLocation"
														type="checkbox"
														className="form-check-input"
														checked={offerData.createSubscriptionPerLocation}
														onChange={handleChange}
														disabled={isSaving}
													/>
													<label
														htmlFor="createSubscriptionPerLocation"
														className="form-label ms-2"
													>
														Create separate subscription per location
													</label>
												</div>
											</Fragment>
										)}

										<div className="col-12">
											<hr className="border opacity-50 my-2" />
										</div>

										<div className="attach-subscription-toggle mt-2">
											<input
												id="isSubscription"
												type="checkbox"
												className="form-check-input"
												checked={showSubscriptionDetails}
												onChange={() => setShowSubscriptionDetails((prev) => !prev)}
												disabled={isSaving}
											/>
											<label htmlFor="isSubscription" className="form-label ms-2">
												Attach subscription to this offer
											</label>
										</div>

										{showSubscriptionDetails && (
											<Fragment>
												<div className="subscription-details">
													{/* <div className="presets-container">
														<div>
															Presets:
															{subscriptionPresets.map((preset, index) => (
																<a
																	key={index}
																	className="btn btn-outline-primary px-2 py-0 ms-2"
																	onClick={() => handlePresetSelect(preset)}
																>
																	{preset.name}
																</a>
															))}
														</div>
													</div> */}

													<div className="container-fluid pb-0 pt-1">
														<div className="row">
															<div className="col-lg-4 col-sm-12">
																<label
																	className="form-label"
																	htmlFor="subscriptionTrialDays"
																>
																	Trial Days:
																</label>
																<div className="input-group">
																	<input
																		id="subscriptionTrialDays"
																		name="subscriptionTrialDays"
																		type="number"
																		className="form-control"
																		value={offerData.subscriptionTrialDays || ''}
																		onChange={handleChange}
																		disabled={isSaving}
																	/>
																	<div
																		className="input-group-text"
																		title="The number of days before we first bill the customer for this subscription"
																	>
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>

															<div className="col-lg-4 col-sm-12">
																<label
																	className="form-label"
																	htmlFor="subscriptionAmount"
																>
																	Amount:
																</label>
																<div className="input-group">
																	<div className="input-group-prepend">
																		<div className="input-group-text ">$</div>
																	</div>
																	<input
																		id="subscriptionAmount"
																		name="subscriptionAmount"
																		type="number"
																		className="form-control"
																		value={offerData.subscriptionAmount || ''}
																		onChange={handleChange}
																		disabled={isSaving}
																	/>
																	<div
																		className="input-group-text"
																		title="The amount to bill for during this subscription"
																	>
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>

															<div className="col-lg-4 col-sm-12">
																<label
																	className="form-label"
																	htmlFor="subscriptionFirstPaymentDays"
																>
																	Recurring Days:
																</label>
																<div className="input-group">
																	<input
																		id="subscriptionFirstPaymentDays"
																		name="subscriptionFirstPaymentDays"
																		type="number"
																		className="form-control"
																		value={
																			offerData.subscriptionFirstPaymentDays || ''
																		}
																		onChange={handleChange}
																		disabled={isSaving}
																	/>
																	<div
																		className="input-group-text"
																		title="The number of days between each subscription payment"
																	>
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>
														</div>

														{/* <div className="row mt-3">
													<div className="col-sm-12">
														<label htmlFor="tagToApply">Tag to Apply:</label>
														<div className="input-group">
															<input
																id="tagToApply"
																name="tagToApply"
																type="text"
																className="form-control"
																value={subscriptionDetails.tagToApply}
																onChange={handleChange}
																disabled={isSaving}
															/>
															<div
																className="input-group-append"
																title="Enter a tag you wish to be applied to the customer account when this invoice is paid for"
															>
																<div className="input-group-text">
																	<i className="fas fa-question" />
																</div>
															</div>
														</div>
													</div>
												</div> */}
													</div>
												</div>
											</Fragment>
										)}
									</form>
								</Fragment>
							)}
						</Fragment>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					<div className="row">
						<div className="col-7">
							{showSystemOffersList ? (
								<button
									className="btn btn-primary"
									type="button"
									onClick={() => {
										setShowSystemOffersList(false);
									}}
								>
									<span>Continue</span>
								</button>
							) : (
								<Fragment>
									<button className="btn btn-primary" type="button" onClick={() => handleSubmit()}>
										{isSaving ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Saving</span>
											</Fragment>
										) : (
											<span>Save</span>
										)}
									</button>
									{offerId && (
										<button
											className="btn btn-primary"
											type="button"
											onClick={() => handleDuplicate(offerId)}
										>
											<span>Duplicate</span>
										</button>
									)}
								</Fragment>
							)}
							<button className="btn btn-outline-primary" type="button" onClick={handleOnCloseDialog}>
								Cancel
							</button>
						</div>

						<div className="col-5">
							{user.isAdmin &&
							!showSystemOffersList && (
								<Fragment>
									<div class="form-check form-check-inline">
										<input
											type="checkbox"
											className="form-check-input"
											id="isSystem"
											name="isSystem"
											onChange={handleChange}
											checked={offerData.isSystem || false}
											disabled={isSaving}
										/>
										<label htmlFor="isSystem" className="form-check-label">
											System
										</label>
									</div>

									{offerData.isSystem && (
										<Fragment>
											<div class="form-check form-check-inline mx-3">
												<input
													type="checkbox"
													className="form-check-input"
													id="isDefault"
													name="isDefault"
													onChange={handleChange}
													checked={offerData.isDefault || false}
													disabled={isSaving}
												/>
												<label htmlFor="isDefault" className="form-check-label">
													Default
												</label>
											</div>
											<div class="form-check form-check-inline mt-1">
												<input
													type="checkbox"
													className="form-check-input"
													id="isReseller"
													name="isReseller"
													onChange={handleChange}
													checked={offerData.isReseller || false}
													disabled={isSaving}
												/>
												<label htmlFor="isReseller" className="form-check-label">
													Reseller
												</label>
											</div>
										</Fragment>
									)}
								</Fragment>
							)}
						</div>
					</div>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default OfferDetailsDialog;
