import { Fragment } from 'react';
import './RankingFiltersSummary.css';

const RankingFiltersSummary = ({
	activeFilter,
	onChange,
	rankData,
	compareToRankData,
	mobileReportView,
	reportType,
	showChanges = true
}) => {
	const handleClick = (filter) => {
		if (onChange) onChange(filter);
	};

	const getRankingResults = (type) => {
		let distinctRankData;
		let distinctCompareToRankData;

		//remove possible duplicates
		if (rankData && rankData.results) {
			distinctRankData = rankData;

			distinctRankData.results = rankData.results.reduce((accumulator, current) => {
				if (!accumulator.find((item) => item.location === current.location)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);
		}

		if (compareToRankData && compareToRankData.results) {
			distinctCompareToRankData = compareToRankData;

			distinctCompareToRankData.results = compareToRankData.results.reduce((accumulator, current) => {
				if (!accumulator.find((item) => item.location === current.location)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);
		}

		var data = reportType === 'base' ? distinctRankData : distinctCompareToRankData;

		if (data) {
			if (data.results && data.results.length > 0) {
				if (type === 'all') {
					return data.results.length;
				} else if (type === 'top3') {
					return data.results.filter((r) => r.ranking && r.ranking < 4).length;
				} else if (type === 'top10') {
					return data.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3).length;
				} else if (type === 'top20') {
					return data.results.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10).length;
				} else if (type === 'over20') {
					return data.results.filter((r) => r.ranking && r.ranking > 20).length;
				} else if (type === 'notRanked') {
					return data.results.filter((r) => r.ranking && !r.ranking).length;
				}
			}
		}
	};

	const getRankingResultsDifference = (type) => {
		let value = 0;

		if (
			rankData &&
			rankData.results &&
			rankData.results.length > 0 &&
			compareToRankData &&
			compareToRankData.results &&
			compareToRankData.results.length > 0
		) {
			if (type === 'all') {
				value = rankData.results.length - compareToRankData.results.length;
			} else if (type === 'top3') {
				value =
					rankData.results.filter((r) => r.ranking && r.ranking < 4).length -
					compareToRankData.results.filter((r) => r.ranking && r.ranking < 4).length;
			} else if (type === 'top10') {
				value =
					rankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3).length -
					compareToRankData.results.filter((r) => r.ranking && r.ranking < 11 && r.ranking > 3).length;
			} else if (type === 'top20') {
				value =
					rankData.results.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10).length -
					compareToRankData.results.filter((r) => r.ranking && r.ranking < 21 && r.ranking > 10).length;
			} else if (type === 'over20') {
				value =
					rankData.results.filter((r) => r.ranking && r.ranking > 20).length -
					compareToRankData.results.filter((r) => r.ranking && r.ranking > 20).length;
			} else if (type === 'notRanked') {
				value =
					rankData.results.filter((r) => r.ranking && !r.ranking).length -
					compareToRankData.results.filter((r) => r.ranking && !r.ranking).length;
			}
		}

		return (
			<Fragment>
				{value > 0 && (
					<div className={`ranking-stat-item ${value && value > 0 ? 'good' : 'bad'}`}>
						{value > 0 ? '+' : ''}
						{value}
					</div>
				)}
			</Fragment>
		);
	};

	return (
		<div className="ranking-filters-summary">
			<ul className="nav nav-pills">
				<li className={`nav-item ${activeFilter === 'all' ? 'active' : ''}`}>
					<a className="nav-link all p-0" onClick={() => handleClick('all')}>
						<small className="label">All Pages</small>
						<div className="ranking-results">{getRankingResults('all')}</div>
					</a>
					{showChanges && getRankingResultsDifference('all')}
				</li>

				{mobileReportView && <li className="flex-line-break" />}

				<li className={`nav-item ${activeFilter === 'top3' ? 'active' : ''}`}>
					<a className="nav-link p-0" onClick={() => handleClick('top3')}>
						<small className="label">Top 3</small>
						<div className="ranking-results">{getRankingResults('top3')}</div>
					</a>
					{showChanges && getRankingResultsDifference('top3')}
				</li>
				<li className={`nav-item ${activeFilter === 'top10' ? 'active' : ''}`}>
					<a className="nav-link p-0" onClick={() => handleClick('top10')}>
						<small className="label">4-10</small>
						<div className="ranking-results">{getRankingResults('top10')}</div>
					</a>
					{showChanges && getRankingResultsDifference('top10')}
				</li>

				{mobileReportView && <li className="flex-line-break" />}

				<li className={`nav-item ${activeFilter === 'top20' ? 'active' : ''}`}>
					<a className="nav-link p-0" onClick={() => handleClick('top20')}>
						<small className="label">11-20</small>
						<div className="ranking-results">{getRankingResults('top20')}</div>
					</a>
					{showChanges && getRankingResultsDifference('top20')}
				</li>
				<li className={`nav-item ${activeFilter === 'over20' ? 'active' : ''}`}>
					<a className="nav-link p-0" onClick={() => handleClick('over20')}>
						<small className="label">21+</small>
						<div className="ranking-results">{getRankingResults('over20')}</div>
					</a>
					{showChanges && getRankingResultsDifference('over20')}
				</li>
			</ul>
		</div>
	);
};

export default RankingFiltersSummary;
