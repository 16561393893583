import { Fragment, useEffect } from 'react';
import './CampaignButtonsContainer.css';
import { Link } from 'react-router-dom';

const CampaignButtonsContainer = ({
	campaign,
	onSave,
	onSaveNext,
	showSaveButton = true,
	saveButtonText = 'Save',
	saveNextButtonText = 'Save & Next',
	additionalButtonHtml = null,
	cancelButtonHtml = null,
	newOrderId = null,
	disabled = false
}) => {
	useEffect(() => {
		document.title = 'Campaigns List';
	}, []);

	const handleSave = () => {
		if (onSave) {
			onSave();
		}
	};

	const handleSaveNext = () => {
		if (onSaveNext) {
			onSaveNext();
		}
	};

	return (
		<div className="campaign-buttons-container">
			<div className="col-6 text-start">
				{cancelButtonHtml}

				{newOrderId && (
					<Link to={`/${newOrderId}`} className="btn btn-primary" disabled={disabled}>
						Set Company Profile
					</Link>
				)}
			</div>

			{campaign &&
			campaign.status !== 'Deleted' && (
				<div className="col-6">
					{showSaveButton && (
						<button className="btn btn-primary" type="button" onClick={handleSave} disabled={disabled}>
							{disabled ? (
								<Fragment>
									<span className="spinner-border m-0 me-2" />
									<span>{`${saveButtonText}...`}</span>
								</Fragment>
							) : (
								<span>{saveButtonText}</span>
							)}
						</button>
					)}
					<button className="btn btn-primary ms-2" type="button" onClick={handleSaveNext} disabled={disabled}>
						{saveNextButtonText}
					</button>
					{additionalButtonHtml}
				</div>
			)}
		</div>
	);
};

export default CampaignButtonsContainer;
