import { Fragment, useState, useEffect } from 'react';
import { useStateValue } from '../../../StateProvider';
import './UserProfileInfo.css';
import UserHeaderMenu from '../userHeaderMenu/UserHeaderMenu';
import { actionTypes } from '../../../reducer';

const UserProfileInfo = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ headerMenuVisible, setHeaderMenuVisible ] = useState(false);

	const toggleHeaderMenu = (e) => {
		e.preventDefault();

		if (headerMenuVisible) {
			hideHeaderMenu();
		} else {
			setHeaderMenuVisible(true);
		}
	};

	const hideHeaderMenu = () => {
		setTimeout(() => {
			setHeaderMenuVisible(false);
		}, 100);
	};

	return (
		<Fragment>
			{user && (
				<div className="profile-info-container ms-5">
					<div className="profile-info">
						<div className="profile-name">
							<div className="name">
								{user.firstName} {user.lastName}
							</div>
						</div>

						<a
							href="#"
							onClick={toggleHeaderMenu}
							className={`btn-profile-menu ${headerMenuVisible ? 'active' : ''}`}
						>
							<i className="fas fa-caret-down" />
						</a>

						{headerMenuVisible && <UserHeaderMenu hideMenu={hideHeaderMenu} />}
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default UserProfileInfo;
