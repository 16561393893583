import './Loader.css';

const Loader = () => {
	return (
		<div className="spinner-wrapper">
			<div className="spinner-border" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default Loader;
