import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import GridReportSingleKeywordV4 from '../../components/gridReport/v4/GridReportSingleKeywordV4';
import Spinner from '../../components/spinner/Spinner';
import {
	AddDemoReport,
	GetCampaign,
	GetCampaignKeywords,
	GetCampaignReports,
	GetDemoReport,
	GetReportHistoryDetails,
	GetSEOReport,
	GetSalesUsers,
	SendReportByEmail,
	UpdateCampaignReportDate
} from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import RankReportV4 from '../../components/gridReport/v4/rankReport/RankReportV4';
import GridKeywordPickerArrowsV4 from '../../components/gridReport/v4/gridKeywordPicker/GridKeywordPickerArrowsV4';
import './PublicViewCampaignReportScreenV4.css';
import { useStateValue } from '../../StateProvider';
import { copyToClipboard, validateDayOfMonth } from '../../utils/Common';
import ModalDialog from '../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import Creatable from 'react-select/creatable';

const PublicViewCampaignReportScreenV4 = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoadingReport, setIsLoadingReport ] = useState(false);
	const [ isSubmittingSales, setIsSubmittingSales ] = useState(false);
	const [ isSubmittingCustomer, setIsSubmittingCustomer ] = useState(false);
	const [ isSettingAsSent, setIsSettingAsSent ] = useState(false);
	const { campaignId, demoId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ demoReport, setDemoReport ] = useState();
	const [ campaignReports, setCampaignReports ] = useState();
	const [ report, setReport ] = useState();
	const [ reportIdValue, setReportIdValue ] = useState();
	const [ activeKeyword, setActiveKeyword ] = useState();
	const [ targetKeyword, setTargetKeyword ] = useState();
	const [ compareToKeyword, setCompareToKeyword ] = useState();
	const [ selectedReportName, setSelectedReportName ] = useState('');
	const [ compareToReportId, setCompareToReportId ] = useState('');
	const [ compareToReport, setCompareToReport ] = useState();
	const [ compareToReportName, setCompareToReportName ] = useState('');
	const [ compareToReports, setCompareToReports ] = useState();
	const [ mobileReportView, setMobileReportView ] = useState('base');
	const [ mapFullScreen, setMapFullScreen ] = useState(false);
	const [ isCampaignCanceled, setIsCampaignCanceled ] = useState(false);
	const [ noReports, setNoReports ] = useState(false);
	const [ queryParameters ] = useSearchParams();
	const [ queryParamStartDate, setQueryParamStartDate ] = useState();
	const [ queryParamEndDate, setQueryParamEndDate ] = useState();
	const [ queryParamKeyword, setQueryParamKeyword ] = useState();
	const [ queryParamsLoaded, setQueryParamsLoaded ] = useState(false);
	const [ keywordObjects, setKeywordObjects ] = useState();
	const [ { host, user, reportSendToEmail, reportSendEmailCC }, dispatch ] = useStateValue();
	const shareLinkRef = useRef();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ showSendReportDialog, setShowSendReportDialog ] = useState(false);
	const [ reportHistory, setReportHistory ] = useState();
	const [ sendReportFormData, setSendReportFormData ] = useState({
		sendTo  : reportSendToEmail,
		subject : 'Map Boost 30 Day Campaign Report'
	});
	const [ salesUsers, setSalesUsers ] = useState([]);
	const [ selectedSalesUser, setSelectedSalesUser ] = useState({});
	const [ emailSubjectChoices, setEmailSubjectChoices ] = useState([
		{ label: 'Map Boost Campaign Launched', value: 'Map Boost Campaign Launched' },
		{ label: 'Map Boost 30 Day Campaign Report', value: 'Map Boost 30 Day Campaign Report' },
		{ label: 'Map Boost Monthly Report', value: 'Map Boost Monthly Report' }
	]);

	useEffect(() => {
		//redirect user to V5 version
		let currentUrl = window.location.href;

		//if (currentUrl.indexOf('/v4/') > -1 && user && user.email === 'sasha_kotlo@hotmail.com') {
		if (currentUrl.indexOf('/v4/') > -1) {
			let newUrl = currentUrl.replace('/v4/', '/v5/');
			window.location.replace(newUrl);
		}
	}, []);

	useEffect(
		() => {
			if (queryParameters) {
				setQueryParamStartDate(queryParameters.get('startDate'));
				setQueryParamEndDate(queryParameters.get('endDate'));
				setQueryParamKeyword(queryParameters.get('keyword'));

				setQueryParamsLoaded(true);
			}
		},
		[ queryParameters ]
	);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign(campaignId);
			} else if (demoId) {
				getDemoReport(demoId);
			}
		},
		[ campaignId, demoId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getCampaignReports();
				getCampaignKeywords();
				getCampaignReportHistory();
				getSalesUsers();
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && salesUsers && salesUsers.length) {
				//console.log('sales users: ' + JSON.stringify(salesUsers));

				let tempSendReportFormData = sendReportFormData;
				let tempSelectedSalesUser;

				//set default selected value for report send to email
				if (campaign.salesRepId) {
					//console.log('campaign salesRepId: ' + campaign.salesRepId);

					tempSelectedSalesUser = salesUsers.find((x) => x.value === campaign.salesRepId);
				} else {
					//console.log('campaign salesRepId not set, sending to default: ' + reportSendToEmail);

					tempSelectedSalesUser = salesUsers.find((x) => x.label === reportSendToEmail);
				}

				if (tempSelectedSalesUser) {
					//console.log('1) tempSelectedSalesUser: ' + JSON.stringify(tempSelectedSalesUser));
					setSelectedSalesUser(tempSelectedSalesUser);
				}

				if (reportHistory) {
					//console.log('history record sentTo: ' + reportHistory.sentTo);

					tempSendReportFormData.sendTo = reportHistory.sentTo;
					tempSendReportFormData.videoUrl = reportHistory.videoUrl;
					tempSendReportFormData.comments = reportHistory.comments;

					if (reportHistory.sentTo) {
						tempSelectedSalesUser = salesUsers.find((x) => x.label === reportHistory.sentTo);

						if (tempSelectedSalesUser) {
							//console.log('2) tempSelectedSalesUser: ' + JSON.stringify(tempSelectedSalesUser));
						} else {
							// console.log(
							// 	'tempSelectedSalesUser not found. custom value: ' +
							// 		JSON.stringify({ value: '', label: reportHistory.sentTo })
							// );
						}

						setSelectedSalesUser(tempSelectedSalesUser || { value: '', label: reportHistory.sentTo });
					}
				} else {
					//console.log('no history record found');

					//console.log('3) tempSelectedSalesUser: ' + JSON.stringify(tempSelectedSalesUser));

					setSelectedSalesUser(tempSelectedSalesUser);
				}

				setSendReportFormData({ ...sendReportFormData, ...tempSendReportFormData });
			}
		},
		[ campaign, salesUsers, reportHistory ]
	);

	useEffect(
		() => {
			if (campaign) {
				let tempSendReportFormData = {
					ahOrderId           : campaign.ahOrderId,
					campaignDateCreated : campaign.dateCreated,
					lastReportDate      : campaign.lastReportDate,
					nextReportDate      : campaign.nextReportDate
						? moment(campaign.nextReportDate).format('yyyy-MM-DD')
						: null,
					reportDayOfMonth    : campaign.reportDayOfMonth || 1,
					customerEmail       : campaign.user ? campaign.user.email : ''
				};

				setSendReportFormData({ ...sendReportFormData, ...tempSendReportFormData });
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (isCampaignCanceled) {
				setIsLoading(false);
			}
		},
		[ isCampaignCanceled ]
	);

	useEffect(
		() => {
			if (noReports) {
				setIsLoading(false);
			}
		},
		[ noReports ]
	);

	useEffect(
		() => {
			if (reportIdValue && compareToReportId) {
				if (compareToReportId !== '0') {
					getReport(reportIdValue);
					getCompareToReport(compareToReportId);
				} else {
					getReport(reportIdValue);
				}
			}
		},
		[ reportIdValue, compareToReportId ]
	);

	useEffect(
		() => {
			if (campaignReports && reportIdValue) {
				let currentReport = campaignReports.find((r) => r.id === reportIdValue);

				if (currentReport) {
					let tempCompareToReports = campaignReports.filter(
						//(r) => r.id !== reportIdValue && r.dateUnix < report.reportGeneratedAtUnix
						(r) => r.id !== currentReport.id && r.dateUnix < currentReport.dateUnix
					);

					setCompareToReports(tempCompareToReports);

					//find the oldest report
					if (tempCompareToReports && tempCompareToReports.length > 0) {
						const oldestCompareToReport = tempCompareToReports.reduce(
							(r, o) => (o.dateCreated < r.dateCreated ? o : r)
						);

						if (queryParamStartDate) {
							setCompareToReportId(queryParamStartDate);
						} else if (demoReport) {
							setCompareToReportId(demoReport.startDate);
						} else {
							if (oldestCompareToReport) {
								setCompareToReportId(oldestCompareToReport.id);
								//getCompareToReport(oldestCompareToReport.id);
							} else {
								setCompareToReportId('0');
							}
						}
					} else {
						setCompareToReportId('0');
					}
				} else {
					setCompareToReportId('0');
				}
			} else if (!isLoading && campaignReports && !reportIdValue) {
				//setReportIdValue(campaignReports[0].id);
			} else {
				setCompareToReports();
			}
		},
		[ campaignReports, reportIdValue ]
	);

	useEffect(
		() => {
			if (campaign && report) {
				if (report.gridResponse && report.gridResponse.data && report.gridResponse.data.length > 0) {
					let currentKeywordData;

					if (queryParamKeyword) {
						currentKeywordData = report.gridResponse.data.find(
							(k) => k.keyword.toLowerCase() === queryParamKeyword.toLowerCase()
						);
					} else if (demoReport) {
						currentKeywordData = report.gridResponse.data.find(
							(k) => k.keyword.toLowerCase() === demoReport.keyword.toLowerCase()
						);
					} else {
						currentKeywordData = report.gridResponse.data[0];
					}

					setTargetKeyword({
						lat              : report.gridResponse.lat,
						lng              : report.gridResponse.lng,
						horizontalPoints : report.gridResponse.horizontalPoints,
						verticalPoints   : report.gridResponse.verticalPoints,
						...currentKeywordData
					});

					if (
						compareToReport &&
						compareToReport.gridResponse &&
						compareToReport.gridResponse.data &&
						compareToReport.gridResponse.data.length > 0
					) {
						let gridDataCompareTo = compareToReport.gridResponse;
						const selectedCompareToKeyword = gridDataCompareTo.data.find(
							(k) => k.keyword === currentKeywordData.keyword
						);

						if (selectedCompareToKeyword) {
							setCompareToKeyword({
								lat              : gridDataCompareTo.lat,
								lng              : gridDataCompareTo.lng,
								horizontalPoints : gridDataCompareTo.horizontalPoints,
								verticalPoints   : gridDataCompareTo.verticalPoints,
								...selectedCompareToKeyword
							});
						}
					}

					setActiveKeyword(currentKeywordData.keyword);
				}

				if (compareToReportId && compareToReportId !== '0') {
					if (compareToReport) {
						setIsLoading(false);
					}
				} else {
					setIsLoading(false);
				}
			}
		},
		[ campaign, report, compareToReport ]
	);

	useEffect(
		() => {
			if (queryParamsLoaded && !isLoading) {
				setQueryParamStartDate();
				setQueryParamEndDate();
				setQueryParamKeyword();
			}
		},
		[ queryParamsLoaded, isLoading ]
	);

	const getCampaign = async (id) => {
		const response = await GetCampaign(id);

		if (response) {
			if (response.status === 'Canceled') {
				setIsCampaignCanceled(true);
			}

			setCampaign(response);
		}
	};

	const getSalesUsers = async () => {
		if (campaign) {
			let response = await GetSalesUsers();

			if (response && response.length) {
				//sort users alphabetically
				response = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

				let tempSalesUsers = new Array();
				for (let i = 0; i < response.length; i++) {
					tempSalesUsers.push({
						value : response[i].id,
						label : response[i].email
					});
				}

				setSalesUsers(tempSalesUsers);
			}
		}
	};

	const getDemoReport = async (id) => {
		const response = await GetDemoReport(id);

		if (response) {
			setDemoReport(response);
			setCampaign(response.campaign);
		}
	};

	const getCampaignReports = async () => {
		if (campaign) {
			const response = await GetCampaignReports(campaign.id);

			if (response && response.reports && response.reports.length > 0) {
				setCampaignReports(
					response.reports.filter((x) => {
						if (x.status) {
							return x.status === 'Active';
						} else {
							return x;
						}
					})
				);

				if (queryParamEndDate) {
					setReportIdValue(queryParamEndDate);
				} else if (demoReport) {
					setReportIdValue(demoReport.endDate);
				} else {
					setReportIdValue(response.reports[0].id);
					//await getReport(response.reports[0].id);
				}
			} else {
				setCampaignReports();
				setCompareToReports();
				setNoReports(true);
			}
		}
	};

	const getCampaignKeywords = async () => {
		if (campaign) {
			const response = await GetCampaignKeywords(campaign.id);

			if (response) {
				setKeywordObjects(
					response.sort(function(a, b) {
						return a.orderIndex - b.orderIndex;
					})
				);
			}
		}
	};

	const getCampaignReportHistory = async () => {
		if (queryParameters && queryParameters.get('hid')) {
			const response = await GetReportHistoryDetails(queryParameters.get('hid'));

			if (response) {
				setReportHistory(response);
			} else {
				setReportHistory();
			}
		}
	};

	const getReport = async (id) => {
		setIsLoadingReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaign.id, id, targetId, compareToReportId);

		if (response.success) {
			let tempReport = response;

			tempReport.reportId = id;

			//order keywords by order index
			if (tempReport.gridResponse && tempReport.gridResponse.data && tempReport.gridResponse.data.length > 0) {
				let orderedKeywordsData = [];

				if (keywordObjects) {
					tempReport.gridResponse.data.forEach((gridKeywordObject) => {
						//get order index from db keywords and apply it to appropriate grid keyword
						let dbKeywordObject = keywordObjects.find((x) => x.keyword === gridKeywordObject.keyword);

						if (dbKeywordObject) {
							gridKeywordObject.orderIndex = dbKeywordObject.orderIndex;
						}

						orderedKeywordsData.push({
							...gridKeywordObject
						});
					});

					//order keywords by index
					orderedKeywordsData = orderedKeywordsData.sort(function(a, b) {
						return a.orderIndex - b.orderIndex;
					});

					tempReport.gridResponse.data = orderedKeywordsData;
				}
			}

			setReport(tempReport);
			setSelectedReportName(getReportNameByDate(tempReport.reportGeneratedAtUnix));
		} else {
			setReport();
			setReportIdValue();
		}

		setIsLoadingReport(false);
	};

	const getCompareToReport = async (id) => {
		setIsLoadingReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaign.id, id, targetId);

		if (response.success) {
			response.reportId = id;
			setCompareToReport(response);
			setCompareToReportName(getReportNameByDate(response.reportGeneratedAtUnix));
		} else {
			setCompareToReport();
		}

		setIsLoadingReport(false);
	};

	const handleKeywordSelected = (keywordData) => {
		let gridData = report.gridResponse;

		setTargetKeyword({
			lat              : gridData.lat,
			lng              : gridData.lng,
			horizontalPoints : gridData.horizontalPoints,
			verticalPoints   : gridData.verticalPoints,
			...keywordData
		});

		if (compareToReport && compareToReport.gridResponse && compareToReport.gridResponse.data) {
			let gridDataCompareTo = compareToReport.gridResponse;
			const selectedCompareToKeyword = gridDataCompareTo.data.find((k) => k.keyword === keywordData.keyword);

			setCompareToKeyword({
				lat              : gridDataCompareTo.lat,
				lng              : gridDataCompareTo.lng,
				horizontalPoints : gridDataCompareTo.horizontalPoints,
				verticalPoints   : gridDataCompareTo.verticalPoints,
				...selectedCompareToKeyword
			});
		}

		setActiveKeyword(keywordData.keyword);
	};

	const handleMobileReportView = (type) => {
		setMobileReportView(type);
	};

	const getReportNameByDate = (timestamp) => {
		const format = new Intl.DateTimeFormat('en-US', {
			year  : 'numeric',
			month : 'long',
			day   : '2-digit'
		});

		return format.format(new Date(timestamp * 1000));
	};

	const handleMapFullScreen = (isMapFullScreen) => {
		setMapFullScreen(isMapFullScreen);
	};

	const getReportShareUrl = () => {
		var url = new URL(`${host}/view/report/v4/${campaign.id}/`);

		url.searchParams.set('keyword', targetKeyword.keyword);

		if (compareToReportId) {
			url.searchParams.append('startDate', compareToReportId);
		}

		if (reportIdValue) {
			url.searchParams.append('endDate', reportIdValue);
		}

		return url.href;
	};

	const getCustomerReportInfoUrl = (reportHistoryId) => {
		var url = new URL(`${host}/customer/report/${reportHistoryId || ''}`);

		return url.href;
	};

	const handleGenerateShareLink = () => {
		var url = getReportShareUrl();

		shareLinkRef.current.title = `Clik to Copy: ${url}`;

		copyToClipboard(url);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 2000);
	};

	const toggleSendReportDialog = async () => {
		setShowSendReportDialog(!showSendReportDialog);
	};

	const handleSendReportFormDataChange = async (e) => {
		if (e.target.name === 'reportDayOfMonth') {
			let dayOfMonth = validateDayOfMonth(e.target.value);

			//set next report date
			let dt = new Date();

			let year = dt.getFullYear();
			let month = (dt.getMonth() + 1 + 1).toString().padStart(2, '0');

			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: dayOfMonth,
				nextReportDate  : `${year}-${month}-${dayOfMonth.toString().padStart(2, '0')}`
			});
		} else {
			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleSalesUsersChange = (selectedSalesUser) => {
		if (selectedSalesUser) {
			setSendReportFormData({
				...sendReportFormData,
				sendTo : selectedSalesUser.label
			});
		}
	};

	const handleEmailSubjectChange = (selectedSubject) => {
		if (selectedSubject) {
			setSendReportFormData({
				...sendReportFormData,
				subject : selectedSubject.label
			});
		}
	};

	const sendReport = async (recipientType) => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		if (recipientType === 'sales') setIsSubmittingSales(true);
		else setIsSubmittingCustomer(true);

		setCampaign({
			...campaign,
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : recipientType === 'sales' ? getReportShareUrl() : getCustomerReportInfoUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : sendReportFormData.comments
		};

		data.recipientType = recipientType;

		if (recipientType === 'customer') {
			//CC recipients
			data.sendTo = (data.sendTo ? data.sendTo + ',' : '') + reportSendEmailCC;
		}

		console.log(recipientType + ' data.sendTo: ' + data.sendTo);

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			toggleSendReportDialog();
			toast.success('Report sent successfully.');

			setSendReportFormData({
				sendTo              : sendReportFormData.sendTo,
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});
		} else {
			toggleSendReportDialog();
		}

		if (recipientType === 'sales') setIsSubmittingSales(false);
		else setIsSubmittingCustomer(false);
	};

	const setAsSentReport = async () => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSettingAsSent(true);

		setCampaign({
			...campaign,
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : getReportShareUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : sendReportFormData.comments,
			sendEmail  : false
		};

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			toast.success('Report set as sent successfully.');

			setSendReportFormData({
				sendTo              : sendReportFormData.sendTo,
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});
		}

		toggleSendReportDialog();
		setIsSettingAsSent(false);
	};

	const updateReportDates = async () => {
		//update report dates
		let response = await UpdateCampaignReportDate(campaign.id, {
			lastReportDate   : sendReportFormData.lastReportDate,
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		if (response.success) {
			toast.success('Report dates successfully updated');
		}
	};

	const createDemoReport = async () => {
		let data = {
			campaignId : campaign.id,
			keyword    : targetKeyword.keyword,
			startDate  : compareToReportId,
			endDate    : reportIdValue
		};

		const response = await AddDemoReport(campaign.id, data);

		if (response.success && response.data && response.data.id) {
			//toast.success('Report set as sent successfully.');

			window.open(`${host}/demo/${response.data.id}`, '_blank', 'noreferrer');
		}
	};

	return (
		<div className="campaign-v-p-report-v4 pt-0">
			{isLoading && <Spinner />}

			{!isLoading && (
				<Fragment>
					<div className="report-header container mb-0 p-0">
						<div className="report-header-left">
							<h1 className="display-5 text-truncate">{campaign.name}</h1>
						</div>
						<div className="report-header-right">
							{campaign && (
								<div className="report-headline-links-wrapper">
									{showShareLinkCopiedMessage && (
										<button className="btn btn-outline-success py-0 px-2 me-3">Copied!</button>
									)}

									{!demoReport && (
										<button
											target="_blank"
											className="btn btn-link report-headline-link"
											href="#"
											onClick={handleGenerateShareLink}
											title="Report Link"
											ref={shareLinkRef}
										>
											<i className="fa-solid fa-share-nodes" />
										</button>
									)}

									<a
										target="_blank"
										className="btn btn-link report-headline-link"
										href={
											campaign.url && campaign.url.indexOf('http') > -1 ? (
												campaign.url
											) : (
												`https://${campaign.url}`
											)
										}
										title="Directory Url"
									>
										<i className="fa-solid fa-map-location-dot" />
									</a>
									{campaign.googleCid && (
										<a
											target="_blank"
											className="btn btn-link report-headline-link"
											href={`https://google.com/maps/?cid=${campaign.googleCid}`}
											title="Google Profile"
										>
											<i className="fa-brands fa-google" />
										</a>
									)}
									{user &&
									(user.isAdmin || user.isSalesUser) &&
									!demoReport && (
										<Fragment>
											<span className="report-headline-link-separator">|</span>
											<button
												target="_blank"
												className="btn btn-link report-headline-link"
												href="#"
												onClick={createDemoReport}
												title="Create Demo Report"
											>
												<i className="fa-solid fa-dollar-sign" />
											</button>

											<button
												target="_blank"
												className="btn btn-link report-headline-link"
												href="#"
												onClick={toggleSendReportDialog}
												title="Send Report"
											>
												<i className="fa-regular fa-envelope" />
											</button>
										</Fragment>
									)}
								</div>
							)}
							{/* <div className="text-muted text-truncate">{`${campaign.streetAddress1}, ${campaign.city}, ${campaign.postalCode} ${campaign.state}`}</div> */}
						</div>
					</div>

					{isCampaignCanceled && !user ? (
						<div className="canceled-campaign-warning">This campaign is currently inactive</div>
					) : (
						<Fragment>
							{noReports && <div className="no-reports-notice">No reports</div>}

							{isCampaignCanceled && (
								<div className="canceled-campaign-warning">This campaign is currently inactive</div>
							)}

							{report &&
							report.gridResponse && (
								<GridKeywordPickerArrowsV4
									campaign={campaign}
									gridData={report.gridResponse}
									activeKeywordData={targetKeyword}
									onKeywordSelected={handleKeywordSelected}
									mapFullScreen={mapFullScreen}
									demoId={demoId}
								/>
							)}

							{!!campaignReports &&
							campaignReports.length > 0 && (
								<div className="campaign-reports container">
									<div className="row">
										<div className="col-lg-6 col-12">
											{!!compareToReports &&
											compareToReports.length > 0 && (
												<Fragment>
													<div className="flex-fill">
														<small className="text-muted">Begin Date</small>
													</div>
													<div>
														<select
															className="form-select"
															onChange={(e) => {
																setCompareToReportId(e.target.value);
															}}
															value={compareToReportId}
															disabled={demoId ? 'disabled' : ''}
														>
															<option value="" disabled>
																Compare to...
															</option>
															{compareToReports.map((report) => (
																<option value={report.id} key={report.id}>
																	{UnixTimestampToDateTime(report.dateUnix)}
																</option>
															))}
														</select>
													</div>
												</Fragment>
											)}
										</div>
										<div
											className={`${!!compareToReports &&
												compareToReports.length > 0 &&
												'col-lg-6 ps-lg-0'} col-12 mb-lg-0 mb-2`}
										>
											<div className="flex-fill">
												<small className="text-muted">
													{!!compareToReports && compareToReports.length > 0 ? (
														'End Date'
													) : (
														'Report Date'
													)}
												</small>
											</div>
											<div>
												<select
													value={reportIdValue}
													className="form-select"
													onChange={(e) => {
														setReportIdValue(e.target.value);
														setCompareToReportId('');
														setReport();
														setIsLoading(true);
													}}
													disabled={demoId ? 'disabled' : ''}
												>
													{campaignReports.map((report) => (
														<option value={report.id} key={report.id}>
															{UnixTimestampToDateTime(report.dateUnix)}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className="container p-0">
								<div className="report-body">
									{isLoadingReport ? (
										<Spinner />
									) : (
										<Fragment>
											{report && (
												<Fragment>
													{report.gridResponse &&
													compareToReportId &&
													compareToReportId !== '0' &&
													compareToReport &&
													compareToReport.gridResponse ? (
														<GridReportSingleKeywordV4
															gridData={report.gridResponse}
															gridDataCompareTo={compareToReport.gridResponse}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															onMobileReportViewChange={handleMobileReportView}
															reportName={selectedReportName}
															compareToReportName={compareToReportName}
															targetKeywordData={targetKeyword}
															compareToKeywordData={compareToKeyword}
															onMapFullScreen={handleMapFullScreen}
														/>
													) : (
														<GridReportSingleKeywordV4
															gridData={report.gridResponse}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															reportName={selectedReportName}
															compareToReportName={compareToReportName}
															targetKeywordData={targetKeyword}
														/>
													)}

													{report.rankResponse && (
														<RankReportV4
															rankData={report.rankResponse}
															compareToRankData={
																compareToReport && compareToReport.rankResponse ? (
																	compareToReport.rankResponse
																) : null
															}
															targetKeyword={activeKeyword}
														/>
													)}
												</Fragment>
											)}
										</Fragment>
									)}
								</div>
							</div>

							{showSendReportDialog && (
								<ModalDialog position="right-sidebar" className="text-start">
									<ModalDialogContent align="start">
										<form className="row send-report-form">
											<div className="col-12 mb-3">
												<label htmlFor="sendTo" className="form-label">
													Send To:
												</label>
												<Creatable
													options={salesUsers}
													isMulti={false}
													closeMenuOnSelect={false}
													openMenuOnClick
													isClearable
													defaultValue={selectedSalesUser}
													onChange={handleSalesUsersChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="customerEmail" className="form-label">
													Customer Email:
												</label>
												<input
													type="email"
													className="form-control"
													name="customerEmail"
													value={sendReportFormData.customerEmail || ''}
													onChange={handleSendReportFormDataChange}
													required
													disabled={isSubmittingSales || isSubmittingCustomer}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="subject" className="form-label">
													Subject:
												</label>
												{/* <input
													type="text"
													className="form-control"
													name="subject"
													value={sendReportFormData.subject || ''}
													onChange={handleSendReportFormDataChange}
													required
													disabled={isSubmittingSales || isSubmittingCustomer}
												/> */}
												<Creatable
													options={emailSubjectChoices}
													isMulti={false}
													isClearable
													required
													defaultValue={emailSubjectChoices[1]}
													//value={emailSubjectChoices[1]}
													onChange={handleEmailSubjectChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="videoUrl" className="form-label">
													Loom Video:
												</label>
												<input
													type="text"
													className="form-control"
													name="videoUrl"
													value={sendReportFormData.videoUrl || ''}
													onChange={handleSendReportFormDataChange}
													disabled={isSubmittingSales || isSubmittingCustomer}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="comments" className="form-label">
													Comments:
												</label>
												<textarea
													className="form-control"
													name="comments"
													value={sendReportFormData.comments || ''}
													onChange={handleSendReportFormDataChange}
													rows={5}
													disabled={isSubmittingSales || isSubmittingCustomer}
												/>
											</div>
											<div className="col-12 mb-2">
												<label htmlFor="comments" className="form-label one-line-input-label">
													AH Order Id:
												</label>
												<span className="ms-2">{sendReportFormData.ahOrderId}</span>
											</div>
											<div className="col-12 mb-2">
												<label htmlFor="comments" className="form-label one-line-input-label">
													Date Created:
												</label>
												<span className="ms-2">
													{sendReportFormData.campaignDateCreated && (
														<Moment format="MM/DD/YYYY">
															{sendReportFormData.campaignDateCreated}
														</Moment>
													)}
												</span>
											</div>
											<div className="col-12 mb-2">
												<label htmlFor="comments" className="form-label one-line-input-label">
													Last Report Date:
												</label>
												<span className="ms-2">
													{sendReportFormData.lastReportDate && (
														<Moment format="MM/DD/YYYY">
															{sendReportFormData.lastReportDate}
														</Moment>
													)}
												</span>
											</div>

											<div className="row mb-0">
												<div className="col-5">
													<label htmlFor="nextReportDate" className="form-label">
														Report Day of Month:
													</label>
												</div>
												<div className="col-5">
													<label htmlFor="nextReportDate" className="form-label">
														Next Report Date:
													</label>
												</div>
											</div>

											<div className="row mb-3">
												<div className="col-5">
													<input
														type="number"
														className="form-control"
														name="reportDayOfMonth"
														value={sendReportFormData.reportDayOfMonth || 1}
														onChange={handleSendReportFormDataChange}
														min={1}
														max={31}
													/>
												</div>
												<div className="col-5">
													<input
														type="date"
														className="form-control"
														name="nextReportDate"
														value={sendReportFormData.nextReportDate || ''}
														onChange={handleSendReportFormDataChange}
														disabled={isSubmittingSales || isSubmittingCustomer}
													/>
												</div>
												<div className="col-2">
													<button
														className="btn btn-primary"
														type="button"
														onClick={updateReportDates}
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</ModalDialogContent>
									<ModalDialogButtons>
										<button
											className="btn btn-primary"
											type="button"
											onClick={() => sendReport('sales')}
										>
											{isSubmittingSales ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Sending</span>
												</Fragment>
											) : (
												<span>Send Sales</span>
											)}
										</button>
										<button
											className="btn btn-primary"
											type="button"
											onClick={() => sendReport('customer')}
										>
											{isSubmittingCustomer ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Sending</span>
												</Fragment>
											) : (
												<span>Send Customer</span>
											)}
										</button>
										{(!reportHistory || (reportHistory && reportHistory.status === 'Ready')) && (
											<button className="btn btn-primary" type="button" onClick={setAsSentReport}>
												{isSettingAsSent ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Setting As Sent</span>
													</Fragment>
												) : (
													<span>Set As Sent</span>
												)}
											</button>
										)}
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={toggleSendReportDialog}
										>
											Cancel
										</button>
									</ModalDialogButtons>
								</ModalDialog>
							)}
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default PublicViewCampaignReportScreenV4;
