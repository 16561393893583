import { useEffect, useState } from 'react';

const ReportItem = ({ report, onSelected, selectedReport }) => {
	const [ name, setName ] = useState('');

	useEffect(
		() => {
			setName(report.name);

			// console.log('matchup:', report, selectedReport);
		},
		[ report, selectedReport ]
	);

	const handleSelected = () => {
		if (onSelected) onSelected(report);
	};

	return (
		<div
			className={`campaign-report ${selectedReport && selectedReport.reportId === report.id ? 'active' : ''}`}
			onClick={handleSelected}
		>
			<div className="report-name">{name}</div>
		</div>
	);
};

export default ReportItem;
