import { useEffect, useState, useRef, Fragment } from 'react';
import { copyToClipboard } from '../../../../utils/Common';
import { useStateValue } from '../../../../StateProvider';
import { GetReportSettings } from '../../../../Services';

const GridMapSummary = ({
	gridData,
	rankData,
	placeId,
	placeCid,
	isFullScreen,
	isSinglePublicView = false,
	reportSettings
}) => {
	const [ mapId, setMapId ] = useState();
	const [ markers, setMarkers ] = useState();
	const [ targetId, setTargetId ] = useState();
	const [ mapHeight, setMapHeight ] = useState('');
	const [ mapDataNotAvailable, setMapDataNotAvailable ] = useState(false);
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (gridData && (placeId || placeCid)) {
				setMapId('report-map-summary' + '-' + Math.floor(Math.random() * 100));
				setTargetId(placeId ? placeId : placeCid);
				clearMarkers();
				setMapDataNotAvailable(!gridData);
			} else {
				setMapId();
			}
		},
		[ gridData, placeId, placeCid, reportSettings ]
	);

	useEffect(
		() => {
			if (targetId && !markers) {
				if (mapId) {
					let map = document.getElementById(mapId);

					if (map) {
						setMapHeight(map.offsetWidth);
					}

					initializeGrid();
				} else {
					setMapDataNotAvailable(true);
				}
			}
		},
		[ targetId, markers, mapId ]
	);

	const clearMarkers = () => {
		if (markers && markers.length > 0) {
			markers.forEach((marker) => {
				marker.map = null;
			});

			setMarkers();
		}
	};

	const initializeGrid = async () => {
		const center = { lat: gridData.lat, lng: gridData.lng };

		const google = window.google;

		const { Map } = await google.maps.importLibrary('maps');

		const map = new Map(document.getElementById(mapId), {
			zoom   : 12,
			center : center,
			mapId  : mapId
		});

		if (mobileReportView) {
			map.setOptions({
				disableDefaultUI : true,
				// draggable        : false,
				zoomControl      : false
				// scrollwheel: false,
				// disableDoubleClickZoom: true
			});
		}

		let bounds = new google.maps.LatLngBounds();

		let tempMarkers = [];

		for (let i = 0; i < gridData.horizontalPoints; i++) {
			for (let j = 0; j < gridData.verticalPoints; j++) {
				const x = i + 1;
				const y = j + 1;

				let targetPoints = [];

				for (let k = 0; k < gridData.data.length; k++) {
					const keywordData = gridData.data[k];

					if (keywordData.points) {
						let foundPoint = keywordData.points.find((p) => p.pointXIndex == x && p.pointYIndex == y);

						if (foundPoint) {
							targetPoints.push(foundPoint);
						}
					}
				}

				if (targetPoints && targetPoints.length > 0) {
					const targetPosition = {
						lat : targetPoints[0].lat,
						lng : targetPoints[0].lng
					};

					const markerView = new google.maps.marker.AdvancedMarkerView({
						map,
						position : {
							lat : targetPosition.lat,
							lng : targetPosition.lng
						},
						content  : buildContent(targetPoints, placeId, placeCid)
					});

					const element = markerView.element;

					[ 'focus', 'pointerenter' ].forEach((event) => {
						element.addEventListener(event, () => {
							highlight(markerView, null);
						});
					});
					[ 'blur', 'pointerleave' ].forEach((event) => {
						element.addEventListener(event, () => {
							unhighlight(markerView, null);
						});
					});
					[ 'click' ].forEach((event) => {
						element.addEventListener(event, () => {
							let latLngValue = markerView.position.lat + ',' + markerView.position.lng;
							copyToClipboard(latLngValue, 'Lat and long coordinates copied!');
							// let mapUrl = `https://www.google.com/maps/search/${keyword.keyword}/@${markerView.position
							// 	.lat},${markerView.position.lng},13z`;
							// copyToClipboard(mapUrl, 'Map URL copied!');
						});
					});

					tempMarkers.push(markerView);

					bounds.extend(targetPosition);
				}
			}
		}

		//get all locations with top3 ranking and draw a circle around
		if (rankData && rankData.results && rankData.results.length > 0) {
			//remove possible duplicates
			var distinctLocations = rankData.results.reduce((accumulator, current) => {
				if (!accumulator.find((item) => item.location === current.location)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);

			if (distinctLocations && distinctLocations.length > 0) {
				let locations = distinctLocations.filter((r) => r.ranking && r.ranking < 21);

				if (locations && locations.length > 0) {
					locations.sort((a, b) => a.location.localeCompare(b.location));

					for (let i = 0; i < locations.length; i++) {
						let locData = locations[i];

						if (locData.lat && locData.lng) {
							let fillColor;
							let strokeColor;

							if (locData.ranking < 4) {
								fillColor = '#95d09c';
								strokeColor = '#1f7244';
							} else if (locData.ranking < 11 && locData.ranking > 3) {
								fillColor = '#ffa264';
								strokeColor = '#ffa264';
							} else if (locData.ranking < 21 && locData.ranking > 10) {
								fillColor = '#ff3d3d';
								strokeColor = '#ff3d3d';
							}

							if (fillColor && strokeColor) {
								let mapCircle = new google.maps.Circle({
									map           : map,
									strokeColor   : strokeColor,
									strokeOpacity : 0.6,
									strokeWeight  : 1,
									fillColor     : fillColor,
									fillOpacity   : 0.4,
									center        : { lat: locData.lat, lng: locData.lng },
									radius        : 0.7 * 1609.34
								});
							}
						}
					}
				}
			}
		}

		map.fitBounds(bounds);

		setMarkers(tempMarkers);
	};

	function highlight(markerView, property) {
		markerView.content.classList.add('highlight');
		markerView.element.style.zIndex = 1;
	}

	function unhighlight(markerView, property) {
		markerView.content.classList.remove('highlight');
		markerView.element.style.zIndex = '';
	}

	function buildContent(points, rankedPlaceId, rankedPlaceCid) {
		//get the XY point with the best ranking
		let bestPosition = 99;
		let bestPoint;
		let resultsNotFound = true;

		for (let i = 0; i < points.length; i++) {
			let currentPoint = points[i];

			if (currentPoint.results && currentPoint.results.length > 0) {
				resultsNotFound = false;
				let pointResult;

				if (placeId && placeId.trim() !== '') {
					pointResult = currentPoint.results.find((p) => p.place_id && p.place_id.indexOf(placeId) > -1);
				} else if (placeCid && placeCid.trim() !== '') {
					pointResult = currentPoint.results.find((p) => p.data_cid && p.data_cid.indexOf(placeCid) > -1);
				}

				if (pointResult && pointResult.position && bestPosition && pointResult.position < bestPosition) {
					bestPosition = pointResult.position;
					bestPoint = currentPoint;
				}
			}
		}

		if (!bestPoint) {
			//take the first point
			bestPoint = points[0];
		}

		const content = document.createElement('div');

		content.classList.add('ranking-bubble');
		// first let's check if the place we're checking for is in the list of
		// results and assign the proper className
		let className = 'low';
		let position = '21+';
		let rankedPlace;
		let customStyle = '';

		if (resultsNotFound) {
			// className = 'not-found';
			// content.innerHTML = `<div class="icon"><span class="value">NF</span></div>`;
			// content.classList.add(className);

			// return content;
			return document.createElement('div');
		}

		if (
			(rankedPlaceId && rankedPlaceId.trim() !== '') ||
			(rankedPlaceCid && rankedPlaceCid.trim() !== '' && bestPoint.results && bestPoint.results.length > 0)
		) {
			if (rankedPlaceId && rankedPlaceId.trim() !== '') {
				if (bestPoint.results && bestPoint.results.length > 0) {
					rankedPlace = bestPoint.results.find((p) => p.place_id && p.place_id.indexOf(rankedPlaceId) > -1);
				}
			} else if (!rankedPlace && rankedPlaceCid && rankedPlaceCid.trim() !== '') {
				rankedPlace = bestPoint.results.find((p) => p.data_cid && p.data_cid.indexOf(rankedPlaceCid) > -1);
			}
		}

		if (rankedPlace) {
			const rankedPosition = rankedPlace.position;
			position = rankedPosition;

			if (position <= 3) {
				className = 'excellent';

				if (reportSettings.mapMarkerTop3BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop3BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop3TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop3TextColor}; `;
				}
				if (reportSettings.mapMarkerTop3BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop3BorderColor}; `;
				}
			} else if (position <= 10) {
				className = 'decent';

				if (reportSettings.mapMarkerTop10BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop10BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop10TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop10TextColor}; `;
				}
				if (reportSettings.mapMarkerTop10BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop10BorderColor}; `;
				}
			} else if (position > 10 && position <= 20) {
				className = 'bad';

				if (reportSettings.mapMarkerTop20BackgroundColor) {
					customStyle += `background-color: ${reportSettings.mapMarkerTop20BackgroundColor}; `;
				}
				if (reportSettings.mapMarkerTop20TextColor) {
					customStyle += `color: ${reportSettings.mapMarkerTop20TextColor}; `;
				}
				if (reportSettings.mapMarkerTop20BorderColor) {
					customStyle += `border-color: ${reportSettings.mapMarkerTop20BorderColor}; `;
				}
			}

			if (position > 20) position = '21+';
		}

		if (position === '21+') {
			return document.createElement('div');
		}

		// now append the class to the element
		content.classList.add(className);

		if (bestPoint.results) {
			// now let's build the rest of the content
			content.innerHTML = `<div class="icon"><span class="value"${customStyle !== ''
				? ` style="${customStyle}"`
				: ''}>${position}</span></div>`;

			const details = document.createElement('div');
			details.classList.add('details');

			const businessList = document.createElement('div');
			businessList.classList.add('business-list');

			for (let i = 0; i < bestPoint.results.length && i < 3; i++) {
				// loop first three results and add them to details
				const result = bestPoint.results[i];

				const business = document.createElement('div');
				business.classList.add('business-container');
				business.innerHTML = `
				<div class="ranking">${result.position}</div>
				<div class="name">${result.title}</div>
				<!--<div class="url"><a href="${result.website}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>-->
			`;

				if (
					rankedPlace &&
					((result.place_id && result.place_id.indexOf(rankedPlace.place_id) > -1) ||
						(result.data_cid && result.data_cid.indexOf(rankedPlace.data_cid) > -1))
				) {
					business.classList.add('target');
				}

				businessList.appendChild(business);
			}

			if (bestPoint.results.length > 0) {
				details.appendChild(businessList);
				content.appendChild(details);
			}
		}

		if (bestPoint.improvement) {
			const improvement = document.createElement('div');
			improvement.classList.add('improvement');

			const improvementValue = bestPoint.improvement;
			let improvementClass = 'bad';
			let improvementIcon = '<i class="fa-solid fa-arrow-down"></i>';

			if (improvementValue >= 0) {
				improvementClass = 'good';
				improvementIcon = '<i class="fa-solid fa-arrow-up"></i>';
			}

			improvement.innerHTML = `<div class="improvement value ${improvementClass}">${improvementIcon}<span>${improvementValue
				.toString()
				.replace('-', '')}</span></div>`;

			content.querySelector('.details').appendChild(improvement);

			content.classList.add(`improvement-${improvementClass}`);
		}

		return content;
	}

	return (
		<Fragment>
			{targetId && (
				<div
					className={`map ${isSinglePublicView ? 'single-public-view' : ''}`}
					id={mapId}
					style={!isFullScreen ? { height: mapHeight } : { height: '100%' }}
				>
					{mapDataNotAvailable && (
						<div className="map-data-not-available">
							<div className="box">No Data Available</div>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default GridMapSummary;
