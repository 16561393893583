import { useRef, Fragment } from 'react';
import './FileUpload.css';

const FileUpload = ({
	onClick,
	onFileChange = true,
	isLinkButton = false,
	imageType = null,
	keywordId = null,
	multiple = true
}) => {
	const uploadFileRef = useRef();

	const onFileUpload = (e) => {
		e.preventDefault();

		let uploadButton = uploadFileRef.current;

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const handleFileChange = async (e) => {
		if (onFileChange) {
			onFileChange(uploadFileRef.current.files, imageType, keywordId);
		}
	};

	const handleClick = async (e) => {
		if (onClick) {
			onClick(e);
		}
	};

	return (
		<Fragment>
			<button type="button" className={`btn btn-${isLinkButton ? 'link p-0' : 'primary'}`} onClick={onFileUpload}>
				<span>Upload</span>
			</button>
			<input
				type="file"
				onChange={handleFileChange}
				onClick={handleClick}
				className="d-none"
				id="uploadFile"
				multiple={multiple}
				ref={uploadFileRef}
			/>
		</Fragment>
	);
};

export default FileUpload;
