import { Fragment, useEffect, useState } from 'react';
import GridMapDataGrid from './GridMapDataGrid';
import './GridReportDataGrid.css';

const GridReportDataGrid = ({ gridData, lat, lng, googlePlaceId, numberOfPoints, zoom }) => {
	const [ targetKeyword, setTargetKeyword ] = useState();
	const allZooms = [ 11, 12, 13, 14 ];

	useEffect(
		() => {
			if (gridData && zoom) {
				setTargetKeyword({
					lat              : lat,
					lng              : lng,
					horizontalPoints : numberOfPoints,
					verticalPoints   : numberOfPoints,
					...(gridData && gridData.data ? gridData.data[0] : null)
				});
			}
		},
		[ gridData, zoom ]
	);

	return (
		<div className="data-grid-report">
			<Fragment>
				{targetKeyword &&
					(zoom === '0' ? (
						// allZooms.map((zoomValue, index) => {
						// 	<GridMapDataGrid
						// 		key={`map-${index}`}
						// 		mapIdValue={`report-map-${index}`}
						// 		gridData={targetKeyword}
						// 		lat={lat}
						// 		lng={lng}
						// 		googlePlaceId={googlePlaceId}
						// 		numberOfPoints={numberOfPoints}
						// 		zoom={zoomValue}
						// 	/>;
						// })
						<Fragment>
							<span className="mt-2">Zoom: 11</span>
							<GridMapDataGrid
								key={`map-0`}
								mapIdValue={`report-map-0`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								zoom={11}
							/>
							<span className="mt-2">Zoom: 12</span>
							<GridMapDataGrid
								key={`map-1`}
								mapIdValue={`report-map-1`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								zoom={12}
							/>
							<span className="mt-2">Zoom: 13</span>
							<GridMapDataGrid
								key={`map-2`}
								mapIdValue={`report-map-2`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								zoom={13}
							/>
							<span className="mt-2">Zoom: 14</span>
							<GridMapDataGrid
								key={`map-3`}
								mapIdValue={`report-map-3`}
								gridData={targetKeyword}
								lat={lat}
								lng={lng}
								googlePlaceId={googlePlaceId}
								numberOfPoints={numberOfPoints}
								zoom={14}
							/>
						</Fragment>
					) : (
						<GridMapDataGrid
							key="map-0"
							mapIdValue="report-map-0"
							gridData={targetKeyword}
							lat={lat}
							lng={lng}
							googlePlaceId={googlePlaceId}
							numberOfPoints={numberOfPoints}
							zoom={zoom}
						/>
					))}
			</Fragment>
		</div>
	);
};

export default GridReportDataGrid;
