import { useStateValue } from '../../StateProvider';
import './BottomNote.css';

const BottomNote = () => {
	const [ { host }, dispatch ] = useStateValue();

	return (
		<div className="app-page-note">
			<span>
				{host.indexOf('localhost') > -1 || host.indexOf('dev.') > -1 ? (
					'dev-api.gbpboost.com'
				) : (
					'api.gbpboost.com'
				)}
			</span>
		</div>
	);
};

export default BottomNote;
