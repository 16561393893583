import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	AddCampaignProcess,
	GenerateCampaignAllContent,
	GenerateSEOReport,
	GetCampaign,
	GetCampaignTimeZones,
	MergeCampaignReportsIntoDb,
	ProcessQueueReportJobs,
	RemoveRunningCampaignProcess,
	UpdateCampaignReportDate,
	UpdateCampaignStatus,
	UpdateNewCampaignSettings
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignContentScreen.css';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import { campaignDefaultSettings } from '../../../utils/Common';
import moment from 'moment';
import momentTZ from 'moment-timezone';

const CampaignContentScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const [ runReport, setRunReport ] = useState(false);
	const [ isRunningReport, setIsRunningReport ] = useState(false);
	const [ runReportDone, setRunReportDone ] = useState(false);
	const [ generateContent, setGenerateContent ] = useState(false);
	const [ isGeneratingContent, setIsGeneratingContent ] = useState(false);
	const [ generateContentDone, setGenerateContentDone ] = useState(false);
	const [ campaignSettings, setCampaignSettings ] = useState();
	const defaultCampaignSettings = campaignDefaultSettings();
	const navigate = useNavigate();
	const [ currentTimezoneTime, setCurrentTimezoneTime ] = useState();
	const [ isOutsideBusinessHours, setIsOutsideBusinessHours ] = useState(false);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getTimeZones();
			}
		},
		[ campaign ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);

			if (response.settings) {
				let tempCampaignSettingsObj = JSON.parse(response.settings);
				setCampaignSettings(tempCampaignSettingsObj);
			}
		}
	};

	const getTimeZones = async () => {
		let response = await GetCampaignTimeZones();

		if (response && response.data && response.data.length) {
			let campaignTimezone = response.data.find((x) => x.value === campaign.timeZone);

			if (campaignTimezone && campaignTimezone.utc && campaignTimezone.utc.length > 0) {
				let tzTimeHour;

				campaignTimezone.utc.some((tzName) => {
					let tzTime = momentTZ().tz(tzName);

					if (tzTime) {
						setCurrentTimezoneTime(tzTime);

						tzTimeHour = tzTime.hours();

						return true;
					}
				});

				if (tzTimeHour && (tzTimeHour < 9 || tzTimeHour > 17)) {
					setIsOutsideBusinessHours(true);
				}
			}
		}
	};

	const handleStartTasks = async (e) => {
		handleRunReport();
		handleGenerateContent();
	};

	const handleRunReport = async () => {
		if (runReport) {
			if (campaign.hostingOnly) {
				toast.error('Report cannot be generated bacause campaign is set as Hosting Only.');
				return;
			}

			let timezoneConfirmed = true;

			if (isOutsideBusinessHours) {
				timezoneConfirmed = window.confirm(
					'Curent time in selected timezone (' +
						currentTimezoneTime.format('HH:mm') +
						') is outside of business hours. Do you still want to generate report?'
				);
			}

			if (timezoneConfirmed) {
				setIsRunningReport(true);

				let data = {
					id            : campaignId,
					runGridReport : true,
					runRankReport : true,
					gridApiType   : 'dseo',
					rankApiType   : 'dseo'
				};

				addCampaignQueueProcess('Report', data);

				// //check if report should be merged into db
				// if (!campaign.mergedIntoDb || campaign.mergedIntoDb === null) {
				// 	let mergeResponse = await MergeCampaignReportsIntoDb(campaign.id);

				// 	if (mergeResponse.success) {
				// 		setCampaign({ ...campaign, mergedIntoDb: true });
				// 	}
				// }

				// // OLD LOGIC ------------
				// const response = await GenerateSEOReport(data);

				// if (response.success) {
				// 	setIsRunningReport(false);
				// 	setRunReportDone(true);

				// 	//update next report date in campaign based on report day of month
				// 	if (campaign) {
				// 		let nextDate;
				// 		let today = new Date();

				// 		if (campaign.reportDayOfMonth) {
				// 			nextDate = `${today.getFullYear()}-${(today.getMonth() + 1 + 1)
				// 				.toString()
				// 				.padStart(2, '0')}-${campaign.reportDayOfMonth.toString().padStart(2, '0')}`;
				// 		} else {
				// 			nextDate = moment().add(1, 'months'); //1 month from today
				// 		}

				// 		await UpdateCampaignReportDate(campaignId, {
				// 			nextReportDate : nextDate
				// 		});
				// 	}
				// } else {
				// 	//remove current process
				// 	await RemoveRunningCampaignProcess(campaignId, {
				// 		campaignId : campaignId,
				// 		type       : 'Report'
				// 	});

				// 	toast.error(response.message);
				// 	console.error(response.message);
				// }
				// //------------------------

				//check for queue records
				await ProcessQueueReportJobs();

				setIsRunningReport(false);
			}
		}
	};

	const handleGenerateContent = async () => {
		if (generateContent) {
			setIsGeneratingContent(true);

			let data = {
				id : campaignId
			};

			await addCampaignProcess('Content', data);

			const response = await GenerateCampaignAllContent(campaignId, data);

			if (response.success) {
				setIsGeneratingContent(false);
				setGenerateContentDone(true);

				// //update campaign status
				// await UpdateCampaignStatus(campaignId, { status: 'Content' });
			} else {
				toast.error(response.message);
				console.error(response);

				//remove current process
				await RemoveRunningCampaignProcess(campaignId, {
					campaignId : campaignId,
					type       : 'Content'
				});
			}

			setIsGeneratingContent(false);
		}
	};

	const addCampaignProcess = async (type, inputData) => {
		let data = {
			campaignId : campaignId,
			type       : type,
			status     : type === 'Report' ? 'Queue' : 'Running',
			data       : JSON.stringify(inputData)
		};

		const response = await AddCampaignProcess(campaignId, data);

		if (!response.success) {
			console.error(response);
		}
	};

	const addCampaignQueueProcess = async (type, inputData) => {
		let data = {
			campaignId : campaign.id,
			type       : type,
			status     : 'Queue',
			data       : JSON.stringify(inputData)
		};

		const response = await AddCampaignProcess(campaign.id, data);

		if (!response.success) {
			console.error(response);
		} else {
			toast.success('Report added to queue.');
		}
	};

	const handleSettingsChange = (e) => {
		// let tempCampaignSettings = campaignSettings;
		// tempCampaignSettings[e.target.name] = e.target.checked;

		// setCampaignSettings(tempCampaignSettings);

		setCampaignSettings({
			...campaignSettings,
			[e.target.name]: e.target.checked
		});
	};

	const handleSave = async () => {
		//save settings
		let data = {
			settings : JSON.stringify(campaignSettings)
		};

		const response = await UpdateNewCampaignSettings(campaignId, data);

		if (response.success) {
			// //update campaign status
			// await UpdateCampaignStatus(campaignId, { status: 'Content' });

			toast.success('Settings saved.');
		} else {
			//toast.error(response.message);
			console.error(response);
		}
	};

	const handleFinish = async () => {
		//save settings
		let data = {
			settings : JSON.stringify(campaignSettings)
		};

		const response = await UpdateNewCampaignSettings(campaignId, data);

		if (response.success) {
			// //update campaign status
			// await UpdateCampaignStatus(campaignId, { status: 'Content' });
		} else {
			//toast.error(response.message);
			console.error(response);
		}

		navigate('/campaigns/list');
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs disabled={isRunningReport || isGeneratingContent} />

				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<div className="campaign-form-container">
							{defaultCampaignSettings &&
								defaultCampaignSettings.map((setting, index) => {
									let settingIsDone = false;

									if (
										typeof campaignSettings === 'object' &&
										campaignSettings[setting.name] !== undefined &&
										campaignSettings[setting.name] === true
									) {
										settingIsDone = true;
									}

									return (
										<Fragment>
											<div className="row mb-2 px-3" key={index}>
												<div className="form-check col-6">
													<input
														type="checkbox"
														className="form-check-input"
														id={setting.name}
														name={setting.name}
														onChange={handleSettingsChange}
														checked={settingIsDone}
													/>
													<label htmlFor={setting.name} className="form-check-label">
														{setting.label}
													</label>
												</div>
											</div>
										</Fragment>
									);
								})}

							<div className="row px-3 pt-3">
								<hr className="col-6" />
							</div>

							<div className="row mb-2 px-3">
								<div className="form-check col-6">
									<input
										type="checkbox"
										className="form-check-input"
										name="runReport"
										id="runReport"
										onChange={(e) => setRunReport(e.target.checked)}
									/>
									<label htmlFor="runReport" className="form-check-label">
										Run Report
									</label>
									{isRunningReport && (
										<span className="ms-3">
											<strong>(in progress)</strong>
											<i className="fa-solid fa-cog fa-spin ms-2" />
										</span>
									)}
									{runReportDone && (
										<span className="task-done ms-3">
											<strong>Done!</strong>
											<i className="fa-solid fa-check ms-2" />
										</span>
									)}
								</div>
							</div>
							<div className="row mb-4 px-3">
								<div className="form-check col-6">
									<input
										type="checkbox"
										className="form-check-input"
										name="generateContent"
										id="generateContent"
										onChange={(e) => setGenerateContent(e.target.checked)}
									/>
									<label htmlFor="generateContent" className="form-check-label">
										Generate Website Content
									</label>
									{isGeneratingContent && (
										<span className="ms-3">
											<strong>(in progress)</strong>
											<i className="fa-solid fa-cog fa-spin ms-2" />
										</span>
									)}
									{generateContentDone && (
										<span className="task-done ms-3">
											<strong>Done!</strong>
											<i className="fa-solid fa-check ms-2" />
										</span>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									{runReportDone && generateContentDone ? (
										<button
											className="btn btn-primary"
											type="button"
											onClick={handleFinish}
											disabled={isRunningReport || isGeneratingContent}
										>
											Finish
										</button>
									) : (
										<button
											className="btn btn-primary"
											type="button"
											onClick={handleStartTasks}
											disabled={isRunningReport || isGeneratingContent}
										>
											Start
										</button>
									)}
								</div>
							</div>
						</div>
						<CampaignButtonsContainer
							campaign={campaign}
							onSave={handleSave}
							onSaveNext={handleFinish}
							saveNextButtonText="Save & Finish"
						/>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default CampaignContentScreen;
