import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { DeleteSalesCompanyTask, GetSalesCompanyDetailsBasic, GetSalesCompanyTasks } from '../../../Services';
import './CompanyTasksList.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';

const CompanyTasksList = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ company, setCompany ] = useState();
	const [ companyTasks, setCompanyTasks ] = useState([]);
	const navigate = useNavigate();
	const { salesCompanyId } = useParams();

	useEffect(() => {
		getSalesCompanyDetails();
		getCompanyTasks();
	}, []);

	// useEffect(
	// 	() => {
	// 		if (company && companyTasks) {
	// 			setIsLoading(false);
	// 		}
	// 	},
	// 	[ company, companyTasks ]
	// );

	const getSalesCompanyDetails = async () => {
		const response = await GetSalesCompanyDetailsBasic(salesCompanyId);

		if (response) {
			setCompany(response);
		}
	};

	const getCompanyTasks = async () => {
		let response = await GetSalesCompanyTasks(salesCompanyId);

		if (response && response.length) {
			//sort company tasks by keyword
			response = response.sort((a, b) => a.keyword.localeCompare(b.keyword));

			setCompanyTasks(response);
		} else {
			setCompanyTasks([]);
		}

		setIsLoading(false);
	};

	const handleNewTask = () => {
		navigate('/sales/' + company.id + '/grid-select');
	};

	const handleDeleteTask = async (id) => {
		if (window.confirm('Are you sure that you want to delete this task?')) {
			setIsLoading(true);
			let response = await DeleteSalesCompanyTask(salesCompanyId, id);

			if (response.success) {
				getCompanyTasks();
			} else {
				setIsLoading(false);
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid p-3">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							headline={`Tasks - ${company ? company.name : ''}`}
							linkText="Companies List"
							linkUrl="/sales"
							buttonsHtml={
								<div className="d-flex justify-content-end">
									<button className="btn btn-primary align-self-start" onClick={handleNewTask}>
										New Task
									</button>
								</div>
							}
						/>
					</div>
				</div>

				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<table className="items-list table table-responsive border">
							<thead>
								<tr>
									<th scope="col">Keyword</th>
									<th scope="col">Date</th>
									<th scope="col" />
								</tr>
							</thead>
							<tbody>
								{companyTasks &&
									companyTasks.length > 0 &&
									companyTasks.map((companyTask, index) => (
										<tr key={companyTask.id}>
											<td>{companyTask.keyword}</td>
											<td>
												<Moment format="MM/DD/YYYY">{companyTask.dateCreated}</Moment>
											</td>
											<td className="company-task-action text-end">
												<Link
													className="btn btn-link py-0"
													to={`/sales/${company.id}/tasks/${companyTask.id}`}
													title="View Task"
												>
													<i className="fa-solid fa-magnifying-glass" />
												</Link>
												<button
													className="btn btn-link py-0"
													onClick={() => handleDeleteTask(companyTask.id)}
													title="Delete Task"
												>
													<i className="fa-solid fa-trash-can" />
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default CompanyTasksList;
