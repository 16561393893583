import { useEffect, useState } from 'react';
import { GetCampaignsForSelect } from '../../Services';
import Spinner from '../spinner/Spinner';
import './CampaignSelector.css';

const CampaignSelector = ({ onSelected, defaultValue = '' }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ campaigns, setCampaigns ] = useState([]);
	const [ selectedCampaign, setSelectedCampaign ] = useState(' ');

	useEffect(
		() => {
			getCampaigns();
		},
		[ defaultValue ]
	);

	useEffect(
		() => {
			if (campaigns && campaigns.length) {
				if (defaultValue && selectedCampaign) {
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			}
		},
		[ campaigns, selectedCampaign ]
	);

	const handleChange = (e) => {
		setSelectedCampaign(e.target.value);
		if (onSelected) {
			const campaign = campaigns.find((campaign) => campaign.id === e.target.value);
			//console.log(campaign);
			onSelected(campaign);
		}
	};

	const getCampaigns = async () => {
		let response = await GetCampaignsForSelect();

		if (response) {
			//sort campaign alphabetically
			response = response.sort((a, b) => a.name.localeCompare(b.name));

			setCampaigns(response);

			if (defaultValue) {
				setSelectedCampaign(defaultValue);
			}
		}
	};

	return (
		<div className="campaign-selector">
			{!isLoading ? (
				<select className="form-select" onChange={handleChange} value={selectedCampaign}>
					<option value=" " disabled>
						Select a campaign
					</option>
					{campaigns.map((campaign) => (
						<option key={campaign.id} value={campaign.id}>
							{campaign.name}
						</option>
					))}
				</select>
			) : (
				<Spinner />
			)}
		</div>
	);
};

export default CampaignSelector;
