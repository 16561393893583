import { Fragment } from 'react';
import './KeywordMapStats.css';
import KeywordStatItem from './keywordStatItem/KeywordStatItem';

const KeywordMapStats = ({ keywordData }) => {
	return (
		<div className="keyword-map-stats">
			{keywordData && (
				<Fragment>
					<div className="full-width">
						<KeywordStatItem
							label="Average"
							changeSymbol="%"
							value={keywordData.averageRank}
							change={keywordData.averageRankChangePercentage}
						/>
					</div>
					<div className="half-width">
						<KeywordStatItem
							label="Top 3"
							value={keywordData.top3}
							change={keywordData.top3Change}
						/>
						<KeywordStatItem
							label="4-10"
							value={keywordData.top10}
							change={keywordData.top10Change}
						/>
					</div>
					<div className="half-width">
						<KeywordStatItem
							label="11-20"
							value={keywordData.top20}
							change={keywordData.top20Change}
						/>
						{keywordData.over20 &&
						keywordData.over20 != 0 && (
							<KeywordStatItem
								label="21+"
								value={keywordData.over20}
								change={keywordData.over20Change}
							/>
						)}
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default KeywordMapStats;
