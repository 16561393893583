import { Fragment, useEffect, useState } from 'react';
import './MapStatsSummary.css';
import KeywordStatItemV4 from '../../v4/keywordMapStats/keywordStatItem/KeywordStatItemV4';

const MapStatsSummary = ({ gridData, mobileReportView, showValue = true, selectable = false, onItemSelect }) => {
	const [ gridStats, setGridStats ] = useState({});

	useEffect(
		() => {
			if (gridData && gridData.data) {
				let tempStats = {
					top3                        : 0,
					top10                       : 0,
					top20                       : 0,
					over20                      : 0,
					averageRank                 : 0,
					previousTop3                : 0,
					previousTop10               : 0,
					previousTop20               : 0,
					previousOver20              : 0,
					previousAverageRank         : 0,
					top3Change                  : 0,
					top10Change                 : 0,
					top20Change                 : 0,
					over20Change                : 0,
					averageRankChangePercentage : 0
				};

				for (let i = 0; i < gridData.data.length; i++) {
					const keywordData = gridData.data[i];

					tempStats.top3 += keywordData.top3 ? parseInt(keywordData.top3) : 0;
					tempStats.top10 += keywordData.top10 ? parseInt(keywordData.top10) : 0;
					tempStats.top20 += keywordData.top20 ? parseInt(keywordData.top20) : 0;
					tempStats.over20 += keywordData.over20 ? parseInt(keywordData.over20) : 0;
					tempStats.averageRank += keywordData.averageRank ? parseFloat(keywordData.averageRank) : 0;

					tempStats.previousTop3 += keywordData.previousTop3 ? parseInt(keywordData.previousTop3) : 0;
					tempStats.previousTop10 += keywordData.previousTop10 ? parseInt(keywordData.previousTop10) : 0;
					tempStats.previousTop20 += keywordData.previousTop20 ? parseInt(keywordData.previousTop20) : 0;
					tempStats.previousOver20 += keywordData.previousOver20 ? parseInt(keywordData.previousOver20) : 0;
					tempStats.previousAverageRank += keywordData.previousAverageRank
						? parseFloat(keywordData.previousAverageRank)
						: 0;
				}

				tempStats.averageRank = tempStats.averageRank ? tempStats.averageRank / gridData.data.length : null;
				tempStats.previousAverageRank = tempStats.previousAverageRank
					? tempStats.previousAverageRank / gridData.data.length
					: null;

				//change percentage
				tempStats.top3Change =
					tempStats.top3 && tempStats.top3 > 0 && tempStats.previousTop3 && tempStats.previousTop3 > 0
						? tempStats.top3 - tempStats.previousTop3
						: null;
				tempStats.top10Change =
					tempStats.top10 && tempStats.top10 > 0 && tempStats.previousTop10 && tempStats.previousTop10 > 0
						? tempStats.top10 - tempStats.previousTop10
						: null;
				tempStats.top20Change =
					tempStats.top20 && tempStats.top20 > 0 && tempStats.previousTop20 && tempStats.previousTop20 > 0
						? tempStats.top20 - tempStats.previousTop20
						: null;
				tempStats.over20Change =
					tempStats.over20 && tempStats.over20 > 0 && tempStats.previousOver20 && tempStats.previousOver20 > 0
						? tempStats.over20 - tempStats.previousOver20
						: null;
				tempStats.averageRankChangePercentage =
					tempStats.averageRank &&
					tempStats.averageRank > 0 &&
					tempStats.previousAverageRank &&
					tempStats.previousAverageRank > 0
						? parseFloat(
								(tempStats.averageRank - tempStats.previousAverageRank) / tempStats.previousAverageRank
							) * 100
						: null;

				setGridStats(tempStats);
			}
		},
		[ gridData ]
	);

	const handleSelectItem = (itemSelected, label) => {
		if (onItemSelect) {
			onItemSelect(itemSelected, label);
		}
	};

	return (
		<div className="keyword-map-stats-v4">
			{gridData && (
				<Fragment>
					<KeywordStatItemV4
						label={selectable ? 'All' : 'Average'}
						changeSymbol="%"
						value={showValue ? gridStats.averageRank || 0 : ''}
						change={gridStats.averageRankChangePercentage || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV4
						label="Top 3"
						value={showValue ? gridStats.top3 || 0 : ''}
						change={gridStats.top3Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
					<KeywordStatItemV4
						label="4-10"
						value={showValue ? gridStats.top10 || 0 : ''}
						change={gridStats.top10Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>

					{mobileReportView && <div className="flex-line-break" />}

					<KeywordStatItemV4
						label="11-20"
						value={showValue ? gridStats.top20 || 0 : ''}
						change={gridStats.top20Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
					<KeywordStatItemV4
						label="21+"
						value={showValue ? gridStats.over20 || 0 : ''}
						change={gridStats.over20Change || ''}
						selectable={selectable}
						onItemSelect={handleSelectItem}
					/>
				</Fragment>
			)}
		</div>
	);
};

export default MapStatsSummary;
