import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCampaign, GetCampaignServices, UpdateCampaignServices, UpdateCampaignStatus } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignServicesScreen.css';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toTitleCase } from '../../../utils/Common';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import CampaignSubTabs from '../../../components/campaignTabs/CampaignSubTabs';

const CampaignServicesScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ services, setServices ] = useState([]);
	const { campaignId } = useParams();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignServices();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
		}
	};

	const getCampaignServices = async () => {
		setIsLoading(true);

		const response = await GetCampaignServices(campaignId);

		if (response) {
			let tempServicesArray = response;
			let tempServices = '';

			for (let i = 0; i < tempServicesArray.length; i++) {
				tempServices += tempServicesArray[i].name + '\n';
			}

			setServices(tempServices);
		}

		setIsLoading(false);
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		if (services && services.trim() !== '') {
			let data = {
				id       : campaignId,
				services : []
			};

			let servicesArray = services.split('\n');

			if (servicesArray) {
				servicesArray.map((service) => {
					if (service.trim() !== '' && service.trim().replace('\n', '') !== '') {
						data.services.push(service);
					}
				});
			}

			const response = await UpdateCampaignServices(campaignId, data);

			if (response.success) {
				// //update campaign status
				// await UpdateCampaignStatus(campaignId, { status: 'Services' });
			}
		}

		if (redirect) {
			navigate(`/setup/${campaignId}/reviews`);
		} else {
			toast.success('Settings saved.');
		}
	};

	const setTitleCase = () => {
		if (services && services.trim() !== '') {
			let servicesArray = services.split('\n');

			if (servicesArray) {
				let tempServices = '';

				servicesArray.map((service) => {
					if (service.trim() !== '' && service.trim().replace('\n', '') !== '') {
						let titleCasedValue = toTitleCase(service);

						tempServices += titleCasedValue + '\n';
					}
				});

				setServices(tempServices);
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				<div className="campaign-form-container no-overflow">
					<CampaignSubTabs />

					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="campaign-subform-container">
								<div className="row">
									<label htmlFor="services" className="form-label col-1">
										Services:
									</label>
									<div className="col-5">
										<textarea
											className="form-control"
											name="services"
											value={services || ''}
											onChange={(e) => setServices(e.target.value)}
											rows={10}
										/>
										<div className="form-controls-additional">
											<button className="btn btn-link p-0" onClick={setTitleCase}>
												Title Case
											</button>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
				<CampaignButtonsContainer
					campaign={campaign}
					onSave={handleSubmit}
					onSaveNext={() => handleSubmit(true)}
				/>
			</div>
		</Fragment>
	);
};

export default CampaignServicesScreen;
