export const getToken = () => {
	return localStorage.getItem('gbp-token');
};

export const setToken = (token) => {
	localStorage.setItem('gbp-token', token);
};

export const deleteToken = () => {
	localStorage.removeItem('gbp-token');
};

export const StoreBusiness = (business) => {
	localStorage.setItem('gbp-business', JSON.stringify(business));
};

export const GetBusiness = (business) => {
	if (localStorage.getItem('gbp-business')) {
		return JSON.parse(localStorage.getItem('gbp-business'));
	}

	return null;
};

export const RemoveBusiness = () => {
	localStorage.removeItem('gbp-business');
};

export const RemoveSalesBusiness = () => {
	localStorage.removeItem('gbp-sales-business');
};

export const StoreSalesBusiness = (business) => {
	localStorage.setItem('gbp-sales-business', JSON.stringify(business));
};

export const GetSalesBusiness = () => {
	if (localStorage.getItem('gbp-sales-business')) {
		return JSON.parse(localStorage.getItem('gbp-sales-business'));
	}

	return null;
};

export const RemoveSalesTask = () => {
	localStorage.removeItem('gbp-sales-task');
};

export const StoreSalesTask = (task) => {
	localStorage.setItem('gbp-sales-task', JSON.stringify(task));
};

export const GetSalesTask = () => {
	if (localStorage.getItem('gbp-sales-task')) {
		return JSON.parse(localStorage.getItem('gbp-sales-task'));
	}

	return null;
};

export const StoreV4Notice = () => {
	localStorage.setItem('gbp-v4-notice', '1');
};

export const GetV4Notice = () => {
	if (localStorage.getItem('gbp-v4-notice')) {
		return localStorage.getItem('gbp-v4-notice');
	}

	return null;
};

export const RemoveV4Notice = () => {
	localStorage.removeItem('gbp-v4-notice');
};

//admin user
export const StoreAdminUser = (user) => {
	localStorage.setItem('gbp-au', JSON.stringify(user));
};

export const GetAdminUser = () => {
	if (localStorage.getItem('gbp-au')) {
		return JSON.parse(localStorage.getItem('gbp-au'));
	}

	return null;
};

export const RemoveAdminUser = () => {
	localStorage.removeItem('gbp-au');
};
