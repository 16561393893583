import './RankingFilters.css';

const RankingFilters = ({ activeFilter, onChange }) => {
	const handleClick = (filter) => {
		if (onChange) onChange(filter);
	};

	return (
		<div className="ranking-filters mt-2">
			<ul className="nav nav-pills">
				<li className="nav-item">
					<a
						className={`nav-link ${activeFilter === 'all' ? 'active' : ''}`}
						onClick={() => handleClick('all')}
					>
						All
					</a>
				</li>
				<li className="nav-item">
					<a
						className={`nav-link ${activeFilter === 'top3' ? 'active' : ''}`}
						onClick={() => handleClick('top3')}
					>
						Top 3
					</a>
				</li>
				<li className="nav-item">
					<a
						className={`nav-link ${activeFilter === 'top10' ? 'active' : ''}`}
						onClick={() => handleClick('top10')}
					>
						4-10
					</a>
				</li>
				<li className="nav-item">
					<a
						className={`nav-link ${activeFilter === 'other' ? 'active' : ''}`}
						onClick={() => handleClick('other')}
					>
						10+
					</a>
				</li>
			</ul>
		</div>
	);
};

export default RankingFilters;
