import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../spinner/Spinner';
import './GridKeywordPickerArrowsV5.css';
import { useStateValue } from '../../../../StateProvider';

const GridKeywordPickerArrowsV5NEW = ({ gridData, onKeywordSelected, activeKeyword, mapFullScreen, demoId }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ keywords, setKeywords ] = useState();
	const [ { mobileReportView }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (gridData) {
				let tempKeywords = [];
				gridData.forEach((keyword) => {
					tempKeywords.push({
						...keyword
					});
				});

				setKeywords(tempKeywords);
			} else {
				setKeywords([]);
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (keywords) {
				setIsLoading(false);
			}
		},
		[ keywords ]
	);

	// const handleKeywordClick = (keyword) => {
	// 	if (onKeywordSelected) {
	// 		onKeywordSelected(keyword);
	// 	}
	// };

	const handleChange = (e, selectedKeyword) => {
		//const keyword = e.target.value;
		//const selectedKeyword = keywords.find((k) => k.keyword === keyword);

		if (onKeywordSelected) {
			onKeywordSelected(selectedKeyword);
		}
	};

	return (
		<div className={`grid-report-keywords-v5 container p-0 ${mapFullScreen ? 'is-full-screen' : ''}`}>
			{isLoading && <Spinner />}

			{!isLoading &&
			keywords &&
			keywords.length > 0 && (
				<Fragment>
					{mobileReportView ? (
						<select
							className="form-select w-100"
							onChange={handleChange}
							disabled={demoId ? 'disabled' : ''}
						>
							{keywords.map((keyword, index) => (
								<option key={index} value={keyword.keyword}>
									{keyword.keyword}
								</option>
							))}
						</select>
					) : (
						<ul className={`nav nav-pills ${demoId ? 'disabled' : ''}`}>
							{keywords.map((keyword, index) => (
								<li className="nav-item" key={index}>
									<button
										type="button"
										className={`nav-link ${activeKeyword && activeKeyword === keyword.keyword
											? 'active'
											: ''}`}
										onClick={(e) => handleChange(e, keyword)}
										value={keyword.keyword}
									>
										{keyword.keyword}
									</button>
								</li>
							))}
						</ul>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default GridKeywordPickerArrowsV5NEW;
