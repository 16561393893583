import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Spinner from '../../components/spinner/Spinner';
import {
	AddDemoReport,
	GetCampaign,
	GetCampaignKeywords,
	GetCampaignReports,
	GetCampaignSummaryReportStats,
	GetDemoReport,
	GetReportHistoryDetails,
	GetReportSettings,
	GetSEOReport,
	GetSalesUsers,
	SendReportByEmail,
	UpdateCampaignReportDate,
	UpdateReportSettings
} from '../../Services';
import { UnixTimestampToDateTime } from '../../utils/DateTime';
import './PublicViewCampaignReportScreenSummary.css';
import { useStateValue } from '../../StateProvider';
import { copyToClipboard, validateDayOfMonth } from '../../utils/Common';
import ModalDialog from '../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import Creatable from 'react-select/creatable';
import GridReportToggleTabsV5 from '../../components/gridReport/v5/gridReportToggleTabs/GridReportToggleTabsV5';
import { actionTypes } from '../../reducer';
import GridMapSummary from '../../components/gridReport/summary/gridMap/GridMapSummary';
import MapStatsSummary from '../../components/gridReport/summary/mapStats/MapStatsSummary';
import RankReportSummary from '../../components/gridReport/summary/rankReport/RankReportSummary';
import Chart from '../../components/chart/Chart';

const PublicViewCampaignReportScreenSummary = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoadingCampaign, setIsLoadingCampaign ] = useState(true);
	const [ isLoadingReport, setIsLoadingReport ] = useState(true);
	const [ isLoadingCompareReport, setIsLoadingCompareReport ] = useState(true);
	const [ isLoadingChart, setIsLoadingChart ] = useState(true);
	const [ isSubmittingSales, setIsSubmittingSales ] = useState(false);
	const [ isSubmittingCustomer, setIsSubmittingCustomer ] = useState(false);
	const [ isSettingAsSent, setIsSettingAsSent ] = useState(false);
	const { campaignId, demoId } = useParams();
	const [ campaign, setCampaign ] = useState();
	const [ demoReport, setDemoReport ] = useState();
	const [ campaignReports, setCampaignReports ] = useState();
	const [ report, setReport ] = useState();
	const [ reportIdValue, setReportIdValue ] = useState();
	const [ selectedReportName, setSelectedReportName ] = useState('');
	const [ compareToReportId, setCompareToReportId ] = useState('');
	const [ compareToReport, setCompareToReport ] = useState();
	const [ compareToReportName, setCompareToReportName ] = useState('');
	const [ compareToReports, setCompareToReports ] = useState();
	//const [ mobileReportView, setMobileReportView ] = useState('base');
	const [ mapFullScreen, setMapFullScreen ] = useState(false);
	const [ isCampaignCanceled, setIsCampaignCanceled ] = useState(false);
	const [ noReports, setNoReports ] = useState(false);
	const [ queryParameters ] = useSearchParams();
	const [ queryParamStartDate, setQueryParamStartDate ] = useState();
	const [ queryParamEndDate, setQueryParamEndDate ] = useState();
	const [ queryParamsLoaded, setQueryParamsLoaded ] = useState(false);
	const [ keywordObjects, setKeywordObjects ] = useState();
	const [ { host, user, reportSendToEmail, reportSendEmailCC, mobileReportView }, dispatch ] = useStateValue();
	const shareLinkRef = useRef();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ showSendReportDialog, setShowSendReportDialog ] = useState(false);
	const [ reportHistory, setReportHistory ] = useState();
	const [ sendReportFormData, setSendReportFormData ] = useState({
		sendTo  : reportSendToEmail,
		subject : 'Map Boost 30 Day Campaign Report'
	});
	const [ salesUsers, setSalesUsers ] = useState([]);
	const [ selectedSalesUser, setSelectedSalesUser ] = useState({});
	const [ emailSubjectChoices, setEmailSubjectChoices ] = useState([
		{ label: 'Map Boost Campaign Launched', value: 'Map Boost Campaign Launched' },
		{ label: 'Map Boost 30 Day Campaign Report', value: 'Map Boost 30 Day Campaign Report' },
		{ label: 'Map Boost Monthly Report', value: 'Map Boost Monthly Report' }
	]);
	const [ chartLabels, setChartLabels ] = useState();
	const [ chartData, setChartData ] = useState();
	const [ showReportSettingsDialog, setShowReportSettingsDialog ] = useState(false);
	const [ reportSettingsFormData, setReportSettingsFormData ] = useState({});

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	useEffect(
		() => {
			if (queryParameters) {
				setQueryParamStartDate(queryParameters.get('startDate'));
				setQueryParamEndDate(queryParameters.get('endDate'));

				setQueryParamsLoaded(true);
			}
		},
		[ queryParameters ]
	);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign(campaignId);
			} else if (demoId) {
				getDemoReport(demoId);
			}
		},
		[ campaignId, demoId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getCampaignReports();
				getCampaignKeywords();
				getCampaignReportHistory();
				getSalesUsers();
				getChartData();
				getReportSettings();
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign && salesUsers && salesUsers.length) {
				let tempSendReportFormData = sendReportFormData;
				let tempSelectedSalesUser;

				//set default selected value for report send to email
				if (campaign.salesRepId) {
					tempSelectedSalesUser = salesUsers.find((x) => x.value === campaign.salesRepId);
				} else {
					tempSelectedSalesUser = salesUsers.find((x) => x.label === reportSendToEmail);
				}

				if (tempSelectedSalesUser) {
					setSelectedSalesUser(tempSelectedSalesUser);
				}

				if (reportHistory) {
					tempSendReportFormData.sendTo = reportHistory.sentTo;
					tempSendReportFormData.videoUrl = reportHistory.videoUrl;
					tempSendReportFormData.comments = reportHistory.comments;

					if (reportHistory.sentTo) {
						tempSelectedSalesUser = salesUsers.find((x) => x.label === reportHistory.sentTo);

						setSelectedSalesUser(tempSelectedSalesUser || { value: '', label: reportHistory.sentTo });
					}
				} else {
					setSelectedSalesUser(tempSelectedSalesUser);
				}

				setSendReportFormData({ ...sendReportFormData, ...tempSendReportFormData });
			}
		},
		[ campaign, salesUsers, reportHistory ]
	);

	useEffect(
		() => {
			if (campaign) {
				let tempSendReportFormData = {
					ahOrderId           : campaign.ahOrderId,
					campaignDateCreated : campaign.dateCreated,
					lastReportDate      : campaign.lastReportDate,
					nextReportDate      : campaign.nextReportDate
						? moment(campaign.nextReportDate).format('yyyy-MM-DD')
						: null,
					reportDayOfMonth    : campaign.reportDayOfMonth || 1,
					customerEmail       : campaign.user ? campaign.user.email : ''
				};

				setSendReportFormData({ ...sendReportFormData, ...tempSendReportFormData });
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (isCampaignCanceled) {
				setIsLoading(false);
			}
		},
		[ isCampaignCanceled ]
	);

	useEffect(
		() => {
			if (noReports) {
				setIsLoading(false);
			}
		},
		[ noReports ]
	);

	useEffect(
		() => {
			if (reportIdValue) {
				if (compareToReportId && compareToReportId !== '0') {
					getReport(reportIdValue);
					getCompareToReport(compareToReportId);
				} else {
					getReport(reportIdValue);
				}
			}
		},
		[ reportIdValue, compareToReportId ]
	);

	useEffect(
		() => {
			if (campaignReports && reportIdValue) {
				let currentReport = campaignReports.find((r) => r.id === reportIdValue);

				if (currentReport) {
					let tempCompareToReports = campaignReports.filter(
						(r) => r.id !== currentReport.id && r.dateUnix < currentReport.dateUnix
					);

					// setCompareToReports(tempCompareToReports);

					// //find the oldest report
					// if (tempCompareToReports && tempCompareToReports.length > 0) {
					// 	const oldestCompareToReport = tempCompareToReports.reduce(
					// 		(r, o) => (o.dateCreated < r.dateCreated ? o : r)
					// 	);

					// 	if (queryParamStartDate) {
					// 		setCompareToReportId(queryParamStartDate);
					// 	} else if (demoReport) {
					// 		setCompareToReportId(demoReport.startDate);
					// 	} else {
					// 		if (oldestCompareToReport) {
					// 			setCompareToReportId(oldestCompareToReport.id);
					// 		} else {
					// 			setCompareToReportId('0');
					// 		}
					// 	}
					// } else {
					// 	setCompareToReportId('0');
					// }
				} else {
					setCompareToReportId('0');
				}
			} else if (!isLoading && campaignReports && !reportIdValue) {
				//setReportIdValue(campaignReports[0].id);
			} else {
				setCompareToReports();
			}
		},
		[ campaignReports, reportIdValue ]
	);

	useEffect(
		() => {
			if (campaign && report) {
				if (compareToReportId && compareToReportId !== '0') {
					if (compareToReport) {
						setIsLoading(false);
						setIsLoadingReport(false);
						setIsLoadingCompareReport(false);
					}
				} else {
					setIsLoading(false);
					setIsLoadingReport(false);
					setIsLoadingCompareReport(false);
				}
			}
		},
		[ campaign, report, compareToReport ]
	);

	useEffect(
		() => {
			if (queryParamsLoaded && !isLoading) {
				setQueryParamStartDate();
				setQueryParamEndDate();
			}
		},
		[ queryParamsLoaded, isLoading ]
	);

	const getCampaign = async (id) => {
		const response = await GetCampaign(id);

		if (response) {
			if (response.status === 'Canceled') {
				setIsCampaignCanceled(true);
			}

			setCampaign(response);
		}

		setIsLoadingCampaign(false);
	};

	const getSalesUsers = async () => {
		if (campaign) {
			let response = await GetSalesUsers();

			if (response && response.length) {
				//sort users alphabetically
				response = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

				let tempSalesUsers = new Array();
				for (let i = 0; i < response.length; i++) {
					tempSalesUsers.push({
						value : response[i].id,
						label : response[i].email
					});
				}

				setSalesUsers(tempSalesUsers);
			}
		}
	};

	const getDemoReport = async (id) => {
		const response = await GetDemoReport(id);

		if (response) {
			setDemoReport(response);
			setCampaign(response.campaign);
		}
	};

	const getCampaignReports = async () => {
		if (campaign) {
			const response = await GetCampaignReports(campaign.id);

			if (response && response.reports && response.reports.length > 0) {
				setCampaignReports(
					response.reports.filter((x) => {
						if (x.status) {
							return x.status === 'Active';
						} else {
							return x;
						}
					})
				);

				if (queryParamEndDate) {
					setReportIdValue(queryParamEndDate);
				} else if (demoReport) {
					setReportIdValue(demoReport.endDate);
				} else {
					setReportIdValue(response.reports[0].id);
					//await getReport(response.reports[0].id);
				}
			} else {
				setCampaignReports();
				setCompareToReports();

				setNoReports(true);
				//setIsLoading(false);
				setIsLoadingReport(false);
				setIsLoadingCompareReport(false);
			}
		}
	};

	const getCampaignKeywords = async () => {
		if (campaign) {
			const response = await GetCampaignKeywords(campaign.id);

			if (response) {
				setKeywordObjects(
					response.sort(function(a, b) {
						return a.orderIndex - b.orderIndex;
					})
				);
			}
		}
	};

	const getCampaignReportHistory = async () => {
		if (queryParameters && queryParameters.get('hid')) {
			const response = await GetReportHistoryDetails(queryParameters.get('hid'));

			if (response) {
				setReportHistory(response);
			} else {
				setReportHistory();
			}
		}
	};

	const getReport = async (id) => {
		setIsLoadingReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaign.id, id, targetId, compareToReportId);

		if (response.success) {
			let tempReport = response;

			tempReport.reportId = id;

			//order keywords by order index
			if (tempReport.gridResponse && tempReport.gridResponse.data && tempReport.gridResponse.data.length > 0) {
				let orderedKeywordsData = [];

				if (keywordObjects) {
					tempReport.gridResponse.data.forEach((gridKeywordObject) => {
						//get order index from db keywords and apply it to appropriate grid keyword
						let dbKeywordObject = keywordObjects.find((x) => x.keyword === gridKeywordObject.keyword);

						if (dbKeywordObject) {
							gridKeywordObject.orderIndex = dbKeywordObject.orderIndex;
						}

						orderedKeywordsData.push({
							...gridKeywordObject
						});
					});

					//order keywords by index
					orderedKeywordsData = orderedKeywordsData.sort(function(a, b) {
						return a.orderIndex - b.orderIndex;
					});

					tempReport.gridResponse.data = orderedKeywordsData;
				}
			}

			setReport(tempReport);
			setSelectedReportName(getReportNameByDate(tempReport.reportGeneratedAtUnix));
		} else {
			setReport();
			setReportIdValue();
		}
	};

	const getCompareToReport = async (id) => {
		setIsLoadingCompareReport(true);

		let targetId = campaign.googlePlaceId ? campaign.googlePlaceId : campaign.googleCid;

		const response = await GetSEOReport(campaign.id, id, targetId);

		if (response.success) {
			response.reportId = id;
			setCompareToReport(response);
			setCompareToReportName(getReportNameByDate(response.reportGeneratedAtUnix));
		} else {
			setCompareToReport();
		}
	};

	const getReportNameByDate = (timestamp) => {
		const format = new Intl.DateTimeFormat('en-US', {
			year  : 'numeric',
			month : 'long',
			day   : '2-digit'
		});

		return format.format(new Date(timestamp * 1000));
	};

	const handleMapFullScreen = (isMapFullScreen) => {
		setMapFullScreen(isMapFullScreen);

		if (!isMapFullScreen) {
			document.querySelector('body').scrollIntoView({
				behavior : 'instant'
			});
		}

		document.body.classList.toggle('body-full-screen');
	};

	const getReportShareUrl = () => {
		var url = new URL(`${host}/view/report/v5/${campaign.id}/`);

		if (compareToReportId) {
			url.searchParams.append('startDate', compareToReportId);
		}

		if (reportIdValue) {
			url.searchParams.append('endDate', reportIdValue);
		}

		return url.href;
	};

	const getCustomerReportInfoUrl = (reportHistoryId) => {
		var url = new URL(`${host}/customer/report/${reportHistoryId || ''}`);

		return url.href;
	};

	const handleGenerateShareLink = () => {
		var url = getReportShareUrl();

		shareLinkRef.current.title = `Clik to Copy: ${url}`;

		copyToClipboard(url);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 2000);
	};

	const toggleSendReportDialog = async () => {
		setShowSendReportDialog(!showSendReportDialog);
	};

	const handleSendReportFormDataChange = async (e) => {
		if (e.target.name === 'reportDayOfMonth') {
			let dayOfMonth = validateDayOfMonth(e.target.value);

			//set next report date
			let dt = new Date();

			let year = dt.getFullYear();
			let month = (dt.getMonth() + 1 + 1).toString().padStart(2, '0');

			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: dayOfMonth,
				nextReportDate  : `${year}-${month}-${dayOfMonth.toString().padStart(2, '0')}`
			});
		} else {
			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleSalesUsersChange = (selectedSalesUser) => {
		if (selectedSalesUser) {
			setSendReportFormData({
				...sendReportFormData,
				sendTo : selectedSalesUser.label
			});
		}
	};

	const handleEmailSubjectChange = (selectedSubject) => {
		if (selectedSubject) {
			setSendReportFormData({
				...sendReportFormData,
				subject : selectedSubject.label
			});
		}
	};

	const sendReport = async (recipientType) => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		if (recipientType === 'sales') setIsSubmittingSales(true);
		else setIsSubmittingCustomer(true);

		setCampaign({
			...campaign,
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : recipientType === 'sales' ? getReportShareUrl() : getCustomerReportInfoUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : sendReportFormData.comments
		};

		data.recipientType = recipientType;

		if (recipientType === 'customer') {
			//CC recipients
			data.sendTo = data.sendTo + ',' + reportSendEmailCC;
		}

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			toggleSendReportDialog();
			toast.success('Report sent successfully.');

			setSendReportFormData({
				sendTo              : sendReportFormData.sendTo,
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});
		} else {
			toggleSendReportDialog();
		}

		if (recipientType === 'sales') setIsSubmittingSales(false);
		else setIsSubmittingCustomer(false);
	};

	const setAsSentReport = async () => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSettingAsSent(true);

		setCampaign({
			...campaign,
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : getReportShareUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : sendReportFormData.comments,
			sendEmail  : false
		};

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			toast.success('Report set as sent successfully.');

			setSendReportFormData({
				sendTo              : sendReportFormData.sendTo,
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});
		}

		toggleSendReportDialog();
		setIsSettingAsSent(false);
	};

	const updateReportDates = async () => {
		//update report dates
		let response = await UpdateCampaignReportDate(campaign.id, {
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		if (response.success) {
			toast.success('Report dates successfully updated');
		}
	};

	const createDemoReport = async () => {
		let data = {
			campaignId : campaign.id,
			keyword    : '{summary}',
			startDate  : compareToReportId,
			endDate    : reportIdValue
		};

		const response = await AddDemoReport(campaign.id, data);

		if (response.success && response.data && response.data.id) {
			//toast.success('Report set as sent successfully.');

			window.open(`${host}/demo/${response.data.id}`, '_blank', 'noreferrer');
		}
	};

	const getChartData = async () => {
		let tempLabels = [];
		let tempData = {};

		let response = await GetCampaignSummaryReportStats(campaign.id);

		if (response && response.items) {
			//sort by report date from oldest to newest
			let items = response.items.sort(function(a, b) {
				return new Date(a.reportDate) - new Date(b.reportDate);
			});

			tempData.top3GoogleProfileRankingItems = [];
			tempData.top3LocationRankingItems = [];

			items.forEach((item) => {
				tempLabels.push(moment(item.reportDate).format('MMM YY'));

				//google profile ranking items
				if (item.top3GoogleProfileRankingCount !== null) {
					tempData.top3GoogleProfileRankingItems.push(item.top3GoogleProfileRankingCount);
				}

				// location ranking items
				if (item.top3LocationRankingCount !== null) {
					tempData.top3LocationRankingItems.push(item.top3LocationRankingCount);
				}
			});

			setChartLabels(tempLabels);
			setChartData(tempData);
		}

		setIsLoadingChart(false);
	};

	const toggleReportSettingsDialog = async () => {
		setShowReportSettingsDialog(!showReportSettingsDialog);

		if (!showReportSettingsDialog) {
			getReportSettings();
		}
	};

	const handleReportSettingsFormDataChange = async (e) => {
		setReportSettingsFormData({
			...reportSettingsFormData,
			[e.target.name]: e.target.value
		});

		if (e.target.name.indexOf('ColorValue') > -1) {
			let colorControlName = e.target.name.replace('Value', '');
			setReportSettingsFormData({
				...reportSettingsFormData,
				[colorControlName] : e.target.value
			});
		}
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettingsFormData(response.data);
		}
	};

	const handleSaveReportSettings = async () => {
		setIsSubmittingSales(true);

		let data = { dataJson: JSON.stringify(reportSettingsFormData) };

		let response = await UpdateReportSettings(data);

		if (response.success) {
			toggleReportSettingsDialog();
			toast.success('Report settings updated successfully.');
		} else {
			toast.error(response.message);
		}

		setIsSubmittingSales(false);
	};

	return (
		<div className="campaign-v-p-report-summary pt-0">
			{isCampaignCanceled && !user ? (
				<Fragment>
					{isLoading ? (
						<Spinner />
					) : (
						<Fragment>
							<div className="container text-start px-0 py-2">
								<h5 className="text-truncate">{campaign.name}</h5>
							</div>
							<div className="canceled-campaign-warning">This campaign is currently inactive</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<div className="report-header-container">
						{isLoadingCampaign ? (
							<Spinner />
						) : (
							<div className="container text-start px-0 py-2">
								{campaign && (
									<div className="row">
										<div
											className={`${mobileReportView
												? 'col-12 text-center mb-2'
												: 'col-8'} align-self-center`}
										>
											<h5 className="text-truncate">{campaign.name}</h5>
										</div>
										<div className={`${mobileReportView ? 'col-12' : 'col-4'}`}>
											<div className="report-headline-links-wrapper">
												{showShareLinkCopiedMessage && (
													<button className="btn btn-outline-success py-0 px-2 me-3">
														Copied!
													</button>
												)}

												{!demoReport && (
													<button
														target="_blank"
														className="btn btn-link report-headline-link"
														href="#"
														onClick={handleGenerateShareLink}
														title="Report Link"
														ref={shareLinkRef}
													>
														<i className="fa-solid fa-share-nodes" />
													</button>
												)}

												<a
													target="_blank"
													className="btn btn-link report-headline-link"
													href={
														campaign.url && campaign.url.indexOf('http') > -1 ? (
															campaign.url
														) : (
															`https://${campaign.url}`
														)
													}
													title="Directory Url"
												>
													<i className="fa-solid fa-map-location-dot" />
												</a>
												{campaign.googleCid && (
													<a
														target="_blank"
														className="btn btn-link report-headline-link"
														href={`https://google.com/maps/?cid=${campaign.googleCid}`}
														title="Google Profile"
													>
														<i className="fa-brands fa-google" />
													</a>
												)}
												{user &&
												(user.isAdmin || user.isSalesUser) && (
													<Fragment>
														{!demoReport && (
															<Fragment>
																<button
																	target="_blank"
																	className="btn btn-link report-headline-link"
																	href="#"
																	onClick={createDemoReport}
																	title="Create Demo Report"
																>
																	<i className="fa-solid fa-dollar-sign" />
																</button>

																<button
																	target="_blank"
																	className="btn btn-link report-headline-link"
																	href="#"
																	onClick={toggleSendReportDialog}
																	title="Send Report"
																>
																	<i className="fa-regular fa-envelope" />
																</button>
															</Fragment>
														)}

														<button
															target="_blank"
															className="btn btn-link report-headline-link ms-4"
															href="#"
															onClick={toggleReportSettingsDialog}
															title="Report Settings"
														>
															<i className="fa-solid fa-gear" />
														</button>
													</Fragment>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						)}

						{noReports && <div className="no-reports-notice">No reports</div>}

						{isCampaignCanceled && (
							<div className="canceled-campaign-warning">This campaign is currently inactive</div>
						)}
					</div>

					<div className="report-body">
						{mobileReportView ? (
							<Fragment>
								<div className="container campaign-reports mb-3">
									<div className="row">
										{!!compareToReports &&
										compareToReports.length > 0 && (
											<div className="col-12">
												{/* <div className="flex-fill mb-2">
													<small className="text-muted">Begin Date</small>
												</div> */}

												<div className="mb-3">
													<select
														className="form-select"
														onChange={(e) => {
															setCompareToReportId(e.target.value);
														}}
														value={compareToReportId}
														disabled={demoId ? 'disabled' : ''}
													>
														<option value="" disabled>
															Compare to...
														</option>
														{compareToReports.map((report) => (
															<option value={report.id} key={report.id}>
																{UnixTimestampToDateTime(report.dateUnix)}
															</option>
														))}
													</select>
												</div>
											</div>
										)}

										<div className="col-12">
											{/* <div className="flex-fill mb-2">
												<small className="text-muted">End Date</small>
											</div> */}

											<div className="mb-1">
												<select
													value={reportIdValue}
													className="form-select"
													onChange={(e) => {
														setReportIdValue(e.target.value);
														setCompareToReportId('');
														setReport();
														setIsLoading(true);
														setIsLoadingCompareReport(true);
													}}
													disabled={demoId ? 'disabled' : ''}
												>
													{!!campaignReports &&
														campaignReports.length > 0 &&
														campaignReports.map((report) => (
															<option value={report.id} key={report.id}>
																{UnixTimestampToDateTime(report.dateUnix)}
															</option>
														))}
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className="container campaign-reports mb-3">
									<div className="row">
										<GridReportToggleTabsV5
											reportName={selectedReportName}
											compareToReportName={compareToReportName}
										/>
									</div>

									<div className="row">
										{compareToReport &&
										!!compareToReports &&
										compareToReports.length > 0 &&
										mobileReportView === 'compare' && (
											<div className="col-12">
												<div className="mb-3">
													<MapStatsSummary
														gridData={compareToReport.gridResponse}
														mobileReportView={mobileReportView}
													/>

													<div className="map-wrapper mb-1">
														<GridMapSummary
															key="map-1"
															gridData={compareToReport.gridResponse}
															rankData={compareToReport.rankResponse}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															isFullScreen={mapFullScreen}
															reportSettings={reportSettingsFormData}
														/>
													</div>
												</div>
											</div>
										)}

										<div className="col-12">
											{mobileReportView === 'base' &&
											report && (
												<div className="col-12">
													<div className="mb-3">
														<MapStatsSummary
															gridData={report.gridResponse}
															mobileReportView={mobileReportView}
														/>
													</div>

													<div className="map-wrapper mb-1">
														<GridMapSummary
															key="map-0"
															gridData={report.gridResponse}
															rankData={report.rankResponse}
															placeId={campaign.googlePlaceId}
															placeCid={campaign.googleCid}
															isFullScreen={mapFullScreen}
															reportSettings={reportSettingsFormData}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="container campaign-reports">
									{report &&
									report.rankResponse && (
										<RankReportSummary
											rankData={report.rankResponse}
											compareToRankData={
												compareToReport && compareToReport.rankResponse ? (
													compareToReport.rankResponse
												) : null
											}
										/>
									)}
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="container mb-3">
									<div className="row">
										{isLoadingChart ? (
											<div className="col-lg-12 p-0">
												<div className="campaign-reports py-5">
													<Spinner />
												</div>
											</div>
										) : (
											<Fragment>
												<div className="col-lg-12 p-0">
													<div className="campaign-reports py-5">
														<Chart type="line" labels={chartLabels} data={chartData} />
													</div>
												</div>
											</Fragment>
										)}
									</div>
								</div>

								<div className="container mb-3">
									<div className="row">
										<div className="col-lg-6 col-12 p-0">
											{isLoadingCompareReport ? (
												<div className="campaign-reports me-2 py-5">
													<Spinner />
												</div>
											) : (
												<Fragment>
													{!!campaignReports &&
													campaignReports.length > 0 &&
													compareToReport &&
													!!compareToReports &&
													compareToReports.length > 0 && (
														<div className="campaign-reports me-2">
															{/* <div className="flex-fill mb-2">
																<small className="text-muted">Begin Date</small>
															</div> */}

															<div className="mb-3">
																<select
																	className="form-select"
																	onChange={(e) => {
																		setCompareToReportId(e.target.value);
																	}}
																	value={compareToReportId}
																	disabled={demoId ? 'disabled' : ''}
																>
																	<option value="" disabled>
																		Compare to...
																	</option>
																	{compareToReports.map((report) => (
																		<option value={report.id} key={report.id}>
																			{UnixTimestampToDateTime(report.dateUnix)}
																		</option>
																	))}
																</select>
															</div>

															{(!mobileReportView || mobileReportView === 'compare') && (
																<div className="mb-3">
																	<MapStatsSummary
																		gridData={compareToReport.gridResponse}
																		mobileReportView={mobileReportView}
																	/>
																</div>
															)}

															<div className="map-wrapper mb-1">
																<GridMapSummary
																	key="map-1"
																	gridData={compareToReport.gridResponse}
																	rankData={compareToReport.rankResponse}
																	placeId={campaign.googlePlaceId}
																	placeCid={campaign.googleCid}
																	isFullScreen={mapFullScreen}
																	reportSettings={reportSettingsFormData}
																/>
															</div>
														</div>
													)}
												</Fragment>
											)}
										</div>

										{isLoadingReport ? (
											<div className="col-lg-6 col-12 p-0">
												<div className="campaign-reports py-5">
													<Spinner />
												</div>
											</div>
										) : (
											<Fragment>
												{!!campaignReports &&
												campaignReports.length > 0 && (
													<div
														className={`${!!compareToReports &&
															compareToReports.length > 0 &&
															'col-lg-6'} col-12 p-0`}
													>
														<div
															className={`campaign-reports ${!!compareToReports &&
																compareToReports.length > 0 &&
																'ms-2'}`}
														>
															{/* <div className="flex-fill mb-2">
																<small className="text-muted">
																	{!!compareToReports && compareToReports.length > 0 ? (
																		'End Date'
																	) : (
																		'Report Date'
																	)}
																</small>
															</div> */}

															<div className="mb-3">
																<select
																	value={reportIdValue}
																	className="form-select"
																	onChange={(e) => {
																		setReportIdValue(e.target.value);
																		setCompareToReportId('');
																		setReport();
																		setIsLoading(true);
																		setIsLoadingReport(true);
																	}}
																	disabled={demoId ? 'disabled' : ''}
																>
																	{campaignReports.map((report) => (
																		<option value={report.id} key={report.id}>
																			{UnixTimestampToDateTime(report.dateUnix)}
																		</option>
																	))}
																</select>
															</div>

															{report &&
															report.gridResponse && (
																<Fragment>
																	{(!mobileReportView ||
																		mobileReportView === 'base') && (
																		<div className="mb-3">
																			<MapStatsSummary
																				gridData={report.gridResponse}
																				mobileReportView={mobileReportView}
																			/>
																		</div>
																	)}

																	<div className="map-wrapper mb-1">
																		<GridMapSummary
																			key="map-0"
																			gridData={report.gridResponse}
																			rankData={report.rankResponse}
																			placeId={campaign.googlePlaceId}
																			placeCid={campaign.googleCid}
																			isFullScreen={mapFullScreen}
																			reportSettings={reportSettingsFormData}
																			isSinglePublicView={
																				!!compareToReports &&
																				compareToReports.length > 0 ? (
																					false
																				) : (
																					true
																				)
																			}
																		/>
																	</div>
																</Fragment>
															)}
														</div>
													</div>
												)}
											</Fragment>
										)}
									</div>
								</div>

								<div className="container mb-3 p-0">
									{isLoadingReport ? (
										<div className="campaign-reports">
											<Spinner />
										</div>
									) : (
										<Fragment>
											{!!campaignReports &&
											campaignReports.length > 0 && (
												<div className="campaign-reports">
													{report &&
													report.rankResponse && (
														<RankReportSummary
															rankData={report.rankResponse}
															compareToRankData={
																compareToReport && compareToReport.rankResponse ? (
																	compareToReport.rankResponse
																) : null
															}
														/>
													)}
												</div>
											)}
										</Fragment>
									)}
								</div>
							</Fragment>
						)}
					</div>

					{showSendReportDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<form className="row send-report-form">
									<div className="col-12 mb-3">
										<label htmlFor="sendTo" className="form-label">
											Send To:
										</label>
										<Creatable
											options={salesUsers}
											isMulti={false}
											closeMenuOnSelect={false}
											openMenuOnClick
											isClearable
											defaultValue={selectedSalesUser}
											onChange={handleSalesUsersChange}
										/>
									</div>
									<div className="col-12 mb-3">
										<label htmlFor="customerEmail" className="form-label">
											Customer Email:
										</label>
										<input
											type="email"
											className="form-control"
											name="customerEmail"
											value={sendReportFormData.customerEmail || ''}
											onChange={handleSendReportFormDataChange}
											required
											disabled={isSubmittingSales || isSubmittingCustomer}
										/>
									</div>
									<div className="col-12 mb-3">
										<label htmlFor="subject" className="form-label">
											Subject:
										</label>
										{/* <input
													type="text"
													className="form-control"
													name="subject"
													value={sendReportFormData.subject || ''}
													onChange={handleSendReportFormDataChange}
													required
													disabled={isSubmittingSales || isSubmittingCustomer}
												/> */}
										<Creatable
											options={emailSubjectChoices}
											isMulti={false}
											isClearable
											required
											defaultValue={emailSubjectChoices[1]}
											//value={emailSubjectChoices[1]}
											onChange={handleEmailSubjectChange}
										/>
									</div>
									<div className="col-12 mb-3">
										<label htmlFor="videoUrl" className="form-label">
											Loom Video:
										</label>
										<input
											type="text"
											className="form-control"
											name="videoUrl"
											value={sendReportFormData.videoUrl || ''}
											onChange={handleSendReportFormDataChange}
											disabled={isSubmittingSales || isSubmittingCustomer}
										/>
									</div>
									<div className="col-12 mb-3">
										<label htmlFor="comments" className="form-label">
											Comments:
										</label>
										<textarea
											className="form-control"
											name="comments"
											value={sendReportFormData.comments || ''}
											onChange={handleSendReportFormDataChange}
											rows={5}
											disabled={isSubmittingSales || isSubmittingCustomer}
										/>
									</div>
									<div className="col-12 mb-2">
										<label htmlFor="comments" className="form-label one-line-input-label">
											AH Order Id:
										</label>
										<span className="ms-2">{sendReportFormData.ahOrderId}</span>
									</div>
									<div className="col-12 mb-2">
										<label htmlFor="comments" className="form-label one-line-input-label">
											Date Created:
										</label>
										<span className="ms-2">
											{sendReportFormData.campaignDateCreated && (
												<Moment format="MM/DD/YYYY">
													{sendReportFormData.campaignDateCreated}
												</Moment>
											)}
										</span>
									</div>
									<div className="col-12 mb-2">
										<label htmlFor="comments" className="form-label one-line-input-label">
											Last Report Date:
										</label>
										<span className="ms-2">
											{sendReportFormData.lastReportDate && (
												<Moment format="MM/DD/YYYY">{sendReportFormData.lastReportDate}</Moment>
											)}
										</span>
									</div>

									<div className="row mb-0">
										<div className="col-5">
											<label htmlFor="reportDayOfMonth" className="form-label">
												Report Day of Month:
											</label>
										</div>
										<div className="col-5">
											<label htmlFor="nextReportDate" className="form-label">
												Next Report Date:
											</label>
										</div>
									</div>

									<div className="row mb-3">
										<div className="col-5">
											<input
												type="number"
												className="form-control"
												name="reportDayOfMonth"
												value={sendReportFormData.reportDayOfMonth || 1}
												onChange={handleSendReportFormDataChange}
												min={1}
												max={31}
											/>
										</div>
										<div className="col-5">
											<input
												type="date"
												className="form-control"
												name="nextReportDate"
												value={sendReportFormData.nextReportDate || ''}
												onChange={handleSendReportFormDataChange}
												disabled={isSubmittingSales || isSubmittingCustomer}
											/>
										</div>
										<div className="col-2">
											<button
												className="btn btn-primary"
												type="button"
												onClick={updateReportDates}
											>
												Update
											</button>
										</div>
									</div>
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<button className="btn btn-primary" type="button" onClick={() => sendReport('sales')}>
									{isSubmittingSales ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											<span>Sending</span>
										</Fragment>
									) : (
										<span>Send Sales</span>
									)}
								</button>
								<button
									className="btn btn-primary"
									type="button"
									onClick={() => sendReport('customer')}
								>
									{isSubmittingCustomer ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											<span>Sending</span>
										</Fragment>
									) : (
										<span>Send Customer</span>
									)}
								</button>
								{(!reportHistory || (reportHistory && reportHistory.status === 'Ready')) && (
									<button className="btn btn-primary" type="button" onClick={setAsSentReport}>
										{isSettingAsSent ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Setting As Sent</span>
											</Fragment>
										) : (
											<span>Set As Sent</span>
										)}
									</button>
								)}
								<button
									className="btn btn-outline-primary ms-2"
									type="button"
									onClick={toggleSendReportDialog}
								>
									Cancel
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					{showReportSettingsDialog && (
						<ModalDialog position="right-sidebar" className="text-start">
							<ModalDialogContent align="start">
								<form className="report-settings-form">
									<div className="row mb-2">
										<div className="col-12">
											<h5>Map Settings</h5>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop3BackgroundColor" className="form-label col-6 mt-2">
											Top 3 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop3BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop3BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop3TextColor" className="form-label col-6 mt-2">
											Top 3 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3TextColor"
												value={reportSettingsFormData.mapMarkerTop3TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3TextColorValue"
												value={reportSettingsFormData.mapMarkerTop3TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop3BorderColor" className="form-label col-6 mt-2">
											Top 3 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop3BorderColor"
												value={reportSettingsFormData.mapMarkerTop3BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop3BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop3BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>

									<div className="row mb-1">
										<label
											htmlFor="mapMarkerTop10BackgroundColor"
											className="form-label col-6 mt-2"
										>
											Top 10 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop10BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop10BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop10TextColor" className="form-label col-6 mt-2">
											Top 10 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10TextColor"
												value={reportSettingsFormData.mapMarkerTop10TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10TextColorValue"
												value={reportSettingsFormData.mapMarkerTop10TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop10BorderColor" className="form-label col-6 mt-2">
											Top 10 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop10BorderColor"
												value={reportSettingsFormData.mapMarkerTop10BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop10BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop10BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>

									<div className="row mb-1">
										<label
											htmlFor="mapMarkerTop20BackgroundColor"
											className="form-label col-6 mt-2"
										>
											Top 20 Background Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20BackgroundColor"
												value={reportSettingsFormData.mapMarkerTop20BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20BackgroundColorValue"
												value={reportSettingsFormData.mapMarkerTop20BackgroundColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-1">
										<label htmlFor="mapMarkerTop20TextColor" className="form-label col-6 mt-2">
											Top 20 Text Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20TextColor"
												value={reportSettingsFormData.mapMarkerTop20TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20TextColorValue"
												value={reportSettingsFormData.mapMarkerTop20TextColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<label htmlFor="mapMarkerTop20BorderColor" className="form-label col-6 mt-2">
											Top 20 Border Color:
										</label>
										<div className="col-auto pe-0">
											<input
												type="color"
												className="form-control form-control-color"
												name="mapMarkerTop20BorderColor"
												value={reportSettingsFormData.mapMarkerTop20BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
												disabled={isSubmittingSales}
											/>
										</div>
										<div className="col-3">
											<input
												type="text"
												className="form-control ms-1 color-output-control"
												name="mapMarkerTop20BorderColorValue"
												value={reportSettingsFormData.mapMarkerTop20BorderColor || ''}
												onChange={handleReportSettingsFormDataChange}
											/>
										</div>
									</div>
								</form>
							</ModalDialogContent>
							<ModalDialogButtons>
								<button className="btn btn-primary" type="button" onClick={handleSaveReportSettings}>
									{isSubmittingSales ? (
										<Fragment>
											<span className="spinner-border m-0 me-2" />
											<span>Saving</span>
										</Fragment>
									) : (
										<span>Save</span>
									)}
								</button>
								<button
									className="btn btn-outline-primary ms-2"
									type="button"
									onClick={toggleReportSettingsDialog}
								>
									Cancel
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default PublicViewCampaignReportScreenSummary;
