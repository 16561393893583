import { Fragment, useEffect, useState } from 'react';
import LocationsGenerator from '../../components/locationsGenerator/LocationsGenerator';
import GoogleBusinessProfile from '../../components/googleBusinessProfile/GoogleBusinessProfile';
import { GetBusiness } from '../../utils/StorageUtil';
import './ReportSettingsScreen.css';
import KeywordsList from '../../components/keywordsList/KeywordsList';
import { GenerateSEOReport } from '../../Services';

const ReportSettingsScreen = () => {
	const [ profile, setProfile ] = useState();
	const [ location, setLocation ] = useState();
	const [ generatedLocations, setGeneratedLocations ] = useState();
	const [ keywords, setKeywords ] = useState();

	useEffect(() => {
		const businessProfile = GetBusiness();

		setProfile(businessProfile);

		// setGeneratedLocations([
		// 	{
		// 		name      : 'Sarasota',
		// 		state     : 'Florida',
		// 		stateAbbr : 'FL'
		// 	},
		// 	{
		// 		name      : 'Bradenton',
		// 		state     : 'Florida',
		// 		stateAbbr : 'FL'
		// 	},
		// 	{
		// 		name      : 'Tampa',
		// 		state     : 'Florida',
		// 		stateAbbr : 'FL'
		// 	}
		// ]);
	}, []);

	useEffect(
		() => {
			if (profile) {
				const tempLocation = {
					lat : profile.lat,
					lng : profile.lng
				};

				setLocation(tempLocation);
			}
		},
		[ profile ]
	);

	const handleGenerateReport = async () => {
		let data = {
			Business        : profile,
			ReportKeywords  : keywords,
			ReportLocations : generatedLocations
		};

		// let data = {
		// 	SEOReportRequest : { ...seoReportRequest }
		// };

		const response = await GenerateSEOReport(data);

		console.log(response);
	};

	const handleLocationsUpdate = (locations) => {
		setGeneratedLocations(locations);
	};

	return (
		<Fragment>
			<div className="report-settings">
				{profile && (
					<div className="sidebar">
						<GoogleBusinessProfile profile={profile} />
					</div>
				)}
				<div className="content">
					<LocationsGenerator
						location={location}
						generatedLocations={generatedLocations}
						onLocationsUpdate={handleLocationsUpdate}
					/>
					<KeywordsList
						keywords={keywords}
						locations={generatedLocations}
						onKeywordsUpdate={(kws) => setKeywords(kws)}
					/>
				</div>
			</div>
			<div className="report-actions">
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleGenerateReport}
				>
					<span>Generate Report</span>
				</button>
			</div>
		</Fragment>
	);
};

export default ReportSettingsScreen;
