import { useEffect, useState } from 'react';
import { copyToClipboard } from '../../utils/Common';
import html2canvas from 'html2canvas';

const GridMapDataGridV2 = ({
	gridData,
	lat,
	lng,
	googlePlaceId,
	numberOfPoints,
	zoom,
	mapIdValue,
	radiusInMiles,
	onCircleMove,
	isPreview = false
}) => {
	const [ mapId, setMapId ] = useState();
	const [ markers, setMarkers ] = useState();
	const [ circleMarkers, setCircleMarkers ] = useState();
	const [ mapHeight, setMapHeight ] = useState('');
	const [ mapImageBinaryData, setMapImageBinaryData ] = useState();

	useEffect(
		() => {
			if (gridData) {
				setMapId(mapIdValue);
				clearMarkers();
			} else {
				setMapId();
			}
		},
		[ gridData ]
	);

	useEffect(
		() => {
			if (!markers) {
				if (mapId) {
					initializeGrid();

					let map = document.getElementById(mapId);
					if (map) {
						setMapHeight(map.offsetWidth);
					}
				}
			}
		},
		[ markers, mapId, zoom ]
	);

	useEffect(
		() => {
			if (mapId) {
				initializeGrid();
			}
		},
		[ zoom ]
	);

	const clearMarkers = () => {
		if (markers && markers.length > 0) {
			markers.forEach((marker) => {
				marker.map = null;
			});

			setMarkers();
		}
	};

	const clearCircleMarkers = () => {
		if (circleMarkers && circleMarkers.length > 0) {
			circleMarkers.forEach((circleMarker) => {
				circleMarker.map = null;
			});

			setCircleMarkers();
		}
	};

	const initializeGrid = () => {
		const center = { lat: lat, lng: lng };

		const google = window.google;

		const map = new google.maps.Map(document.getElementById(mapId), {
			//zoom   : parseInt(zoom),
			center : center,
			mapId  : mapId
		});

		//add map circle
		let circle = new google.maps.Circle({
			map           : map,
			strokeColor   : '#000000',
			strokeOpacity : 0.6,
			strokeWeight  : 2,
			fillColor     : '#000000',
			fillOpacity   : 0.1,
			center        : center,
			radius        : radiusInMiles * 1609.34,
			draggable     : true
		});

		let bounds = new google.maps.LatLngBounds();

		const keyword = gridData;

		let tempMarkers = [];

		//set numbers
		let numbersArr = [];
		let pointCounter = 1;

		// if (numbers) {
		// 	numbersArr = numbers.split(/\r?\n/);
		// }

		for (let i = 0; i < numberOfPoints; i++) {
			for (let j = 0; j < numberOfPoints; j++) {
				const x = i + 1;
				const y = j + 1;
				const targetPoint = keyword.points.find((p) => p.pointXIndex == x && p.pointYIndex == y);

				if (targetPoint) {
					const targetPosition = {
						lat : targetPoint.lat,
						lng : targetPoint.lng
					};

					if (isPreview) {
						let pointPosition = '21+';

						// if (numbersArr && numbersArr[pointCounter - 1] !== undefined) {
						// 	pointPosition = numbersArr[pointCounter - 1];
						// }

						// var distance = google.maps.geometry.spherical.computeDistanceBetween(targetPosition, center);

						// if (distance < radiusInMiles * 1609.34) {
						const markerView = new google.maps.marker.AdvancedMarkerView({
							map,
							position : {
								lat : targetPosition.lat,
								lng : targetPosition.lng
							},
							content  : buildContentPreview(pointPosition)
						});

						tempMarkers.push(markerView);
						// }
					} else {
						const markerView = new google.maps.marker.AdvancedMarkerView({
							map,
							position : {
								lat : targetPosition.lat,
								lng : targetPosition.lng
							},
							content  : buildContent(targetPoint, googlePlaceId, null)
						});

						const element = markerView.element;

						[ 'focus', 'pointerenter' ].forEach((event) => {
							element.addEventListener(event, () => {
								highlight(markerView, null);
							});
						});
						[ 'blur', 'pointerleave' ].forEach((event) => {
							element.addEventListener(event, () => {
								unhighlight(markerView, null);
							});
						});
						[ 'click' ].forEach((event) => {
							element.addEventListener(event, () => {
								let latLngValue = markerView.position.lat + ',' + markerView.position.lng;
								copyToClipboard(latLngValue, 'Lat and long coordinates copied!');
							});
						});

						tempMarkers.push(markerView);
					}

					bounds.extend(targetPosition);
				}
			}
		}

		circle.addListener('dragend', function(event) {
			let tempCircleMarkers = [];
			clearCircleMarkers();

			let circleCenter = this.getCenter();

			//create marker in circle center
			var circleCenterMarker = new google.maps.Marker({
				position  : circleCenter,
				draggable : true,
				icon      : {
					url    : 'https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png',
					size   : new google.maps.Size(8, 8),
					anchor : new google.maps.Point(4, 4)
				},
				map       : map
			});

			tempCircleMarkers.push(circleCenterMarker);
			setCircleMarkers(tempCircleMarkers);

			let newLat = circleCenter.lat().toFixed(5);
			let newLng = circleCenter.lng().toFixed(5);

			handleCircleMove({ lat: parseFloat(newLat), lng: parseFloat(newLng) });
		});

		map.fitBounds(bounds);

		setTimeout(() => {
			map.setZoom(zoom ? parseInt(zoom) : 11);
		}, 50);

		setMarkers(tempMarkers);

		//exportMapAsImage();
	};

	const handleCircleMove = (center) => {
		if (onCircleMove) {
			onCircleMove(center);
		}
	};

	function highlight(markerView, property) {
		markerView.content.classList.add('highlight');
		markerView.element.style.zIndex = 1;
	}

	function unhighlight(markerView, property) {
		markerView.content.classList.remove('highlight');
		markerView.element.style.zIndex = '';
	}

	function buildContentPreview(position) {
		const content = document.createElement('div');

		content.classList.add('ranking-bubble');

		let className = 'bad';

		if (position === 'NF' || position === 'nf') {
			className = 'not-found';
		} else if (position <= 3) {
			className = 'excellent';
		} else if (position <= 10) {
			className = 'decent';
		} else if (position > 20) {
			position = '21+';
		}

		content.classList.add(className);

		content.innerHTML = `<div class="icon"><span class="value">${position}</span></div>`;

		return content;
	}

	function buildContent(point, rankedPlaceId, rankedPlaceCid) {
		const content = document.createElement('div');

		content.classList.add('ranking-bubble');

		// first let's check if the place we're checking for is in the list of
		// results and assign the proper className
		let className = 'bad';
		let position = '21+';
		let rankedPlace;

		if (
			(rankedPlaceId && rankedPlaceId.trim() !== '') ||
			(rankedPlaceCid && rankedPlaceCid.trim() !== '' && point.results && point.results.length > 0)
		) {
			if (rankedPlaceId && rankedPlaceId.trim() !== '') {
				if (point.results && point.results.length > 0) {
					rankedPlace = point.results.find((p) => p.place_id && p.place_id.indexOf(rankedPlaceId) > -1);
				}
			} else if (!rankedPlace && rankedPlaceCid && rankedPlaceCid.trim() !== '') {
				rankedPlace = point.results.find((p) => p.data_cid && p.data_cid.indexOf(rankedPlaceCid) > -1);
			}
		}

		if (rankedPlace) {
			const rankedPosition = rankedPlace.position;
			position = rankedPosition;

			if (position <= 3) {
				className = 'excellent';
				// } else if (position <= 6) {
				// 	className = 'good';
			} else if (position <= 10) {
				className = 'decent';
			}

			if (position > 20) position = '21+';
		} else if (!point.results || point.results.length === 0) {
			className = 'not-found';
			content.innerHTML = `<div class="icon"><span class="value">NF</span></div>`;
			content.classList.add(className);

			return content;
		}

		// now append the class to the element
		content.classList.add(className);

		if (point.results) {
			// now let's build the rest of the content
			content.innerHTML = `
		<div class="icon"><span class="value">${position}</span></div>
	`;

			const details = document.createElement('div');
			details.classList.add('details');

			const businessList = document.createElement('div');
			businessList.classList.add('business-list');

			for (let i = 0; i < point.results.length && i < 3; i++) {
				// loop first three results and add them to details
				const result = point.results[i];

				const business = document.createElement('div');
				business.classList.add('business-container');
				business.innerHTML = `
				<div class="ranking">${result.position}</div>
				<div class="name">${result.title}</div>
				<!--<div class="url"><a href="${result.website}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>-->
			`;

				if (
					rankedPlace &&
					((result.place_id && result.place_id.indexOf(rankedPlace.place_id) > -1) ||
						(result.data_cid && result.data_cid.indexOf(rankedPlace.data_cid) > -1))
				) {
					business.classList.add('target');
				}

				businessList.appendChild(business);
			}

			if (point.results.length > 0) {
				details.appendChild(businessList);
				content.appendChild(details);
			}
		}

		if (point.improvement) {
			const improvement = document.createElement('div');
			improvement.classList.add('improvement');

			const improvementValue = point.improvement;
			let improvementClass = 'bad';
			let improvementIcon = '<i class="fa-solid fa-arrow-down"></i>';

			if (improvementValue >= 0) {
				improvementClass = 'good';
				improvementIcon = '<i class="fa-solid fa-arrow-up"></i>';
			}

			improvement.innerHTML = `<div class="improvement value ${improvementClass}">${improvementIcon}<span>${improvementValue
				.toString()
				.replace('-', '')}</span></div>`;

			content.querySelector('.details').appendChild(improvement);

			content.classList.add(`improvement-${improvementClass}`);
		}

		return content;
	}

	// function exportMapAsImage() {
	// 	html2canvas(document.querySelector('#map'), {
	// 		useCORS    : true,
	// 		onrendered : function(canvas) {
	// 			var img = canvas.toDataURL('image/png');
	// 			img = img.replace('data:image/png;base64,', '');
	// 			var finalImageSrc = 'data:image/png;base64,' + img;

	// 			setMapImageBinaryData(finalImageSrc);
	// 		}
	// 	});
	// }

	return (
		<div className="grid-report-map">
			<div className="map" id={mapId} style={{ height: mapHeight }} />

			{mapImageBinaryData && <img className="mt-5" height={600} src={mapImageBinaryData} />}
		</div>
	);
};

export default GridMapDataGridV2;
