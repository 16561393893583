import { Fragment, useEffect, useState } from 'react';
import './ProductsList.css';
import ProductDetailsDialog from './productDetailsDialog/ProductDetailsDialog';

const ProductsList = ({ allProducts, onSubmit }) => {
	const [ products, setProducts ] = useState([]);
	const [ showDialog, setShowDialog ] = useState(false);
	const [ editedProductId, setEditedProductId ] = useState(null);

	useEffect(() => {
		if (allProducts && allProducts.length > 0) {
			setProducts(allProducts);
		}
	}, []);

	const handleToggleDialog = async (e, id) => {
		if (e) e.preventDefault();

		setShowDialog(!showDialog);

		if (!showDialog) {
			if (id !== undefined) {
				//edit
				setEditedProductId(id);
			} else {
				//new
				setEditedProductId(null);
			}
		} else {
			setEditedProductId(null);
		}
	};

	const handleOnProductSubmit = async () => {
		if (onSubmit) {
			onSubmit();
		}

		handleToggleDialog();
	};

	return (
		<Fragment>
			<Fragment>
				<div className="new-product-wrapper">
					<button className="btn btn-primary" onClick={(e) => handleToggleDialog(e, null)}>
						Add New Product
					</button>
				</div>
				<table className="products-list items-list table table-responsive">
					<thead>
						<tr>
							<th className="number" scope="col">
								#
							</th>
							<th scope="col">Name</th>
							<th scope="col">Description</th>
							{/* <th className="count" scope="col">
								Price
							</th>
							<th className="count" scope="col">
								Discount
							</th>
							<th className="count" scope="col">
								Trial Days
							</th>
							<th className="count" scope="col">
								Tags
							</th> */}
							<th className="product-action" scope="col" />
						</tr>
					</thead>
					<tbody>
						{products &&
							products.length > 0 &&
							products.map((product, index) => (
								<tr key={product.id}>
									<th className="number" scope="row">
										{index + 1}
									</th>
									<td className="name text-truncate">{product.name}</td>
									<td>{product.description}</td>
									{/* <td className="count">{product.price}</td>
									<td className="count">{product.discount}</td>
									<td className="count">{product.trialDays}</td>
									<td className="count">{product.tags}</td> */}
									<td className="product-action text-end">
										<button
											className="btn btn-link py-0"
											onClick={(e) => handleToggleDialog(e, product.id)}
											title="Edit Product Details"
										>
											<i className="fa-solid fa-pen" />
										</button>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</Fragment>

			{showDialog && (
				<ProductDetailsDialog
					productId={editedProductId}
					onSubmit={handleOnProductSubmit}
					onClose={handleToggleDialog}
				/>
			)}
		</Fragment>
	);
};

export default ProductsList;
